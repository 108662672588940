import { AxiosPromise } from 'axios';

import api from './api';

type CEPResponse = {
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  erro?: true;
};

const ViaCep = {
  callData(cep: string): AxiosPromise<CEPResponse> {
    return api.get(`https://viacep.com.br/ws/${cep}/json`, { headers: null });
  },
};

export default ViaCep;
