import styled, { keyframes } from 'styled-components';

import { ImageBackground } from '~/assets/images';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  background: url(${ImageBackground}) 0% / cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-top: 64px;
  padding-bottom: 128px;

  img {
    width: 198px;
    height: 121px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    max-width: 420px;
    width: 100%;
    animation: ${appearFromRight} 1s;
    input {
      width: 100%;
    }
  }

  p {
    margin-top: 20px;
    margin-left: 25px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    max-width: 364px;

    strong {
      color: #fdf5a6;
      cursor: pointer;
    }
  }

  button {
    margin-top: 20px;
    max-width: 420px;
    width: 100%;
    height: auto;
    padding: 14px;
  }

  h6 {
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin-top: 15px;
  }

  h6 a {
    color: #fdf5a6;
  }

  @media (max-height: 720.9px) {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768.9px) {
    padding: 10px 20px;

    p {
      margin-left: 0;
    }
  }

  @media (max-width: 768.9px) and (max-height: 673.9px) {
    width: 100%;
    height: 100%;
  }
`;
