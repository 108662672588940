import clamp from 'lodash.clamp';

import { removeMark } from './yupHelpers';

export interface PriceConstructor {
  price: number;
  basePrice: number;
  limitPrice?: number;
}

export interface PriceCurrency
  extends Partial<Omit<PriceConstructor, 'price'>> {
  price: string;
}

export default class Price {
  static readonly CHARGE_PERCENT = 12.5;

  static readonly BASE_PRICE = 19.9;

  static readonly COURSE_BASE_PRICE = 29.9;

  private static readonly formatFloatToCurrency = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'currency',
      currency: 'BRL',
    },
  ).format;

  private static readonly formatDecimalUSToBRL = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  ).format;

  private price: number;

  private basePrice: number;

  private limitPrice: number;

  /**
   * @throws {RangeError}
   */
  constructor({
    price = 0,
    basePrice = 0,
    limitPrice = Infinity,
  }: Partial<PriceConstructor>) {
    if (price < 0) {
      throw new RangeError();
    }
    this.basePrice = basePrice;
    this.limitPrice = limitPrice;
    this.price = clamp(price, basePrice, limitPrice);
  }

  static fixDecimal(price: number): number {
    const priceDecimalString = price.toFixed(2);
    const priceInFloat = parseFloat(priceDecimalString);
    return priceInFloat;
  }

  static stringToCurrency(price: string): string {
    const priceInFloat = parseFloat(price);
    return Price.floatToCurrency(priceInFloat);
  }

  static floatToCurrency(price: number): string {
    // if (price < 0) {
    //   throw new RangeError();
    // }
    const priceFixDecimal = Price.fixDecimal(price < 0 ? 0 : price);
    const priceCurrency = Price.formatFloatToCurrency(priceFixDecimal);
    return priceCurrency;
  }

  static decimalUSToBr(decimalUS: number): string {
    const decimalBR = Price.formatDecimalUSToBRL(decimalUS);
    return decimalBR;
  }

  /**
   * @throws {TypeError} Caso a string nao seja um numero
   */
  static currencyToFloat(currency: string): number {
    const currencyWithoutDecimal = removeMark(currency) ?? '0.00';
    const priceWithoutDecimal = parseFloat(currencyWithoutDecimal);
    if (Number.isNaN(priceWithoutDecimal)) {
      throw new TypeError();
    }
    const price = priceWithoutDecimal / 100;
    return price;
  }

  static fromCurrency({ price, basePrice, limitPrice }: PriceCurrency): Price {
    const priceInFloat = Price.currencyToFloat(price);
    const priceInstance = new Price({
      price: priceInFloat,
      basePrice,
      limitPrice,
    });
    return priceInstance;
  }

  getChargeInCurrency(): string {
    const charge = this.getChargeInFloat();
    const chargeFixed = Price.fixDecimal(charge);
    const chargeInCurrency = Price.floatToCurrency(chargeFixed);
    return chargeInCurrency;
  }

  getAmountReceivableInCurrency(): string {
    const charge = this.getPriceOriginalFromCharge();
    const chargeFixed = Price.fixDecimal(charge);
    const chargeInCurrency = Price.floatToCurrency(chargeFixed);
    return chargeInCurrency;
  }

  getChargeInDecimalBR(): string {
    const charge = this.getChargeInFloat();
    const chargeFixed = Price.fixDecimal(charge);
    const chargeInDecimalBR = Price.decimalUSToBr(chargeFixed);
    return chargeInDecimalBR;
  }

  getPriceOriginalFromCharge(): number {
    return this.getAmountReceivable(true);
  }

  getChargeInFloat(): number {
    return this.getAmountReceivable(false);
  }

  private getAmountReceivable(isAmountReceivable: boolean): number {
    const priceCharged = this.price * 1.125 + 0.39;
    const priceAmountReceivable = (this.price - 0.39) / 1.125;
    const newPrice = isAmountReceivable ? priceAmountReceivable : priceCharged;
    //    return clamp(newPrice, this.basePrice, this.limitPrice);
    return newPrice;
  }

  toCurrencyFormat(): string {
    const priceFixDecimal = Price.fixDecimal(this.price);
    const currency = Price.formatFloatToCurrency(priceFixDecimal);
    return currency;
  }
}
