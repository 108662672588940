import styled from 'styled-components';

import { Button } from '~/components';
import { colors } from '~/styles/settings/colors';

export const ButtonBack = styled(Button)`
  background-color: ${colors.gray};
  margin-top: 2rem;
  max-width: 373px;
`;
