import { ReactNode, ButtonHTMLAttributes, FC } from 'react';

import { ButtonBlackColor, ButtonBlackStyle } from '~/models/Common';

import { Container } from './styles';

interface ButtonBlackProps
  extends Partial<ButtonBlackStyle>,
    ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  isLabelFile?: boolean;
}

const ButtonBlack: FC<ButtonBlackProps> = ({
  children,
  stateUpload = ButtonBlackColor.NORMAL,
  isLabelFile = true,
  ...rest
}) => (
  <Container {...rest} {...{ stateUpload, isLabelFile }}>
    {children}
  </Container>
);

export default ButtonBlack;
