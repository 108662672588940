import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: row;
    border: 1px solid ${colors.gray};
    border-radius: 4px;
  }

  .item {
    padding: 1rem;
    color: ${colors.gray};
    border-left-width: 1px;
    border-left: 1px solid ${colors.gray};
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      color: ${colors.black};
      background: ${colors.terciary};
    }
  }

  .container .control:first-child {
    border: none;
  }

  .control {
    transition: 500ms color;
  }

  .control:focus,
  .control:hover {
    color: ${colors.black};
  }

  .control.disabled + .item {
    border-left: none;
  }

  .item.active {
    color: ${colors.black};
    background: ${colors.terciary};
  }

  .disabled {
    display: none;
  }
`;
