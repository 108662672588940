import { AxiosPromise } from 'axios';
import { a } from 'react-spring';

import {
  Categories,
  DatabaseFields,
  Professionscategories,
} from '~/models/Common';
import {
  ICancelationJunoSignature,
  IMigrationPayment,
} from '~/models/MigrationPayment';

import api from './api';

interface AssociateRequest {
  studentId: number;
  coupon_name?: string;
}

interface AssociateCourseRequest extends AssociateRequest {
  courseId: number;
}

interface AssociateTrainingRequest extends AssociateRequest {
  professionalId: number;
  creditCardHash: string;
  coupon_name?: string;
}

export interface AssociateResponseError {
  message: string[];
}

export interface AssociateResponse extends DatabaseFields {
  professional_id: number;
  student_id: number;
  course_id: number;
  due_date: string;
  status: 'ACTIVE' | 'INACTIVE';
  type: 'course' | 'training';
  price: number;
  payment_url: string;
  juno_id: string;
  sessionId: string;
}

export interface ProfessionalProfile {
  id: number;
  professional_id: number;
  student_id: number;
  course_id: number;
  due_date: null | string;
  status: string;
  type: string;
  created_at: string;
  updated_at: string;
  price: number;
  original_price: number;
  payment_url: null | string;
  juno_id: string;
  live_id: null | number;
  stripe_payment_intent: null | string;
  stripe_subscription_id: null | string;
  id_stripe: null | string;
  professional: {
    id: number;
    cref: number;
    created_at: string;
    updated_at: string;
    user_id: number;
    amount_receivable: number;
    public_name: string;
    logo_key: null | string;
    logo_path: null | string;
    nick_name: string;
    pix_key: string;
    due_date: number;
    account_stripe: string;
    cnpj: string;
    profession_id: number;
    user: {
      id: number;
      name: string;
      email: string;
      cpf: string;
      cell_phone: string;
      date_of_birth: string;
      address_zip_code: string;
      address_street: string;
      address_number: string;
      address_complement: string;
      address_neighborhood: string;
      address_city: string;
      address_state: string;
      role: string;
      created_at: string;
      updated_at: string;
      customer_id: string;
      avatar: string;
    };
  };
}

const StudentService = {
  associateCourse({
    courseId,
    studentId,
    coupon_name,
  }: AssociateCourseRequest): any {
    return api.post(`/students/${studentId}/courses/${courseId}/subscribe`, {
      headers: null,
      couponCode: coupon_name,
    });
  },

  subscribeToCourse({
    courseId,
    studentId,
    creditCardHash,
  }: {
    courseId: number;
    studentId: number;
    creditCardHash: string;
  }): AxiosPromise<AssociateResponse> {
    return api.put(`/students/${studentId}/courses/${courseId}/subscribe`, {
      headers: null,
      creditCardHash,
    });
  },

  associatePlano({
    professionalId,
    studentId,
    creditCardHash,
    coupon_name,
  }: AssociateTrainingRequest): any {
    return api.put(`/students/${studentId}/professionals/${professionalId}`, {
      headers: null,
      creditCardHash,
      couponCode: coupon_name,
    });
  },

  getPlan(studentId: number): AxiosPromise<any> {
    return api.get(`/students/${studentId}/plans`);
  },

  cancelPlan(studentId: number, payment_id: number): AxiosPromise<any> {
    return api.get(`/students/${studentId}/plans/${payment_id}/cancelation`);
  },

  migrationPayment(): AxiosPromise<IMigrationPayment> {
    return api.get('/students/getsubscriptionJuno');
  },

  cancelationJunoSignature(): AxiosPromise<ICancelationJunoSignature> {
    return api.get('/students/cancelationSignature/');
  },

  getProfessionsStudentAssociatedProfessional(
    publicname: string,
  ): AxiosPromise<Categories[]> {
    return api.get(`professions/student/associated/professional/${publicname}`);
  },

  getProfessionalProfiles(): AxiosPromise<{ payment: ProfessionalProfile[] }> {
    return api.get(`students/professionals/get`);
  },
};

export default StudentService;
