import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, Modal } from '~/components';
import { ModalLike } from '~/models/Common';
import { Workouts as WorkoutsInterface } from '~/models/Workouts';
import Price from '~/utils/Price';

import { Content, Image, ImageWrapper, ModalContent } from './styles';

interface ViewWorkoutProps extends ModalLike {
  workout: WorkoutsInterface;
  planPrice?: number;
  publicName: string;
}

const ViewWorkout: React.FC<ViewWorkoutProps> = ({
  modalIsOpen,
  setModalIsOpen,
  workout,
  planPrice,
  publicName,
}) => {
  const [price, setPrice] = useState<string>();
  const [categoryName, setCategoryName] = useState<string>('');

  useEffect(() => {
    if (planPrice && planPrice > 0) {
      const priceString = planPrice.toString();
      const currency = Price.stringToCurrency(priceString);
      setPrice(currency);
    } else {
      setPrice('Gratuito');
    }

    if (workout.price) {
      if (workout.price > 0) {
        const priceString = workout.price.toString();
        const currency = Price.stringToCurrency(priceString);
        setPrice(currency);
      } else {
        setPrice('Gratuito');
      }
    }
  }, [planPrice, workout.price]);

  useEffect(() => {
    const category = workout.category.description;

    setCategoryName(category);
  }, [workout.category.id]);

  /** @todo colocar imagem localmente pois poderá dar problema de cors */
  return (
    <Modal {...{ modalIsOpen, setModalIsOpen }}>
      <ModalContent>
        <ImageWrapper>
          <Image
            src={
              workout?.image ??
              'http://dracybeledepaiva.com.br/wp-content/uploads/2016/10/orionthemes-placeholder-image.png'
            }
          />
        </ImageWrapper>
        <Content>
          {/* <h6>{handleCategoryId(workout?.treining_category_id)}</h6> */}
          <h6>{categoryName}</h6>
          <h3>{workout?.title}</h3>
          <p>{workout?.description}</p>
          <strong>Plano de treinamento:</strong>
          <h1>
            <strong>{price}</strong> /mês
          </h1>
          <div>
            <Link to={`/${publicName}/become-a-student`}>
              <Button>Torne-se aluno</Button>
            </Link>
          </div>
        </Content>
      </ModalContent>
    </Modal>
  );
};

export default ViewWorkout;
