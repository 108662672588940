import {
  Dispatch,
  InputHTMLAttributes,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';

import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import { Error } from '../Input/styles';
import { InputRadio, RadioGroup } from './styles';

interface RadioOptions {
  id: string;
  value: string;
  label: string;
}

interface InputRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: RadioOptions[];
  currentValue: string | undefined;
  setValue: Dispatch<SetStateAction<string>>;
}

const Radio: React.FC<InputRadioProps> = ({
  name,
  options,
  currentValue,
  setValue,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]): string =>
        refs.find(ref => ref?.checked)?.value ?? '',
      setValue: (refs: HTMLInputElement[], id: string) => {
        const inputRef = refs.find(ref => ref.id === id);
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs: HTMLInputElement[]) => {
        const inputRef = refs.find(ref => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [fieldName, registerField, defaultValue]);

  return (
    <RadioGroup>
      {options?.map(option => (
        <label htmlFor={option.id} key={option.id}>
          <InputRadio
            ref={ref => inputRefs.current.push(ref as HTMLInputElement)}
            id={option.id}
            type="radio"
            name={name}
            onChange={e => setValue(e.target.value)}
            checked={option.value === currentValue}
            value={option.value}
            {...rest}
          />
          {option.label}
        </label>
      ))}
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#C53030" size={20} />
        </Error>
      )}
    </RadioGroup>
  );
};

export default Radio;
