import { useState, useCallback, useEffect } from 'react';

import { Header, Paginate, Courses } from '~/components';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { PageOptionsRequest, PageOptionsResponse } from '~/models/Common';
import { Courses as ICourses } from '~/models/Courses';
import { cleanRequests } from '~/services/api';
import CourseService from '~/services/CourseService';
import ProfessorService from '~/services/ProfessorService';
import errorHandlerToToast from '~/utils/errorHandler';

import { MyCoursesContainer, Footer } from './styles';

const MyCourses: React.FC = () => {
  const { user } = useAuth();
  const [needUpdate, setNeedUpdate] = useState<boolean>(true);
  const { addToast } = useToast();
  const { setLoading } = useLoading();
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
  });
  const [courses, setCourses] = useState<PageOptionsResponse<ICourses>>({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: 0,
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSelected, setDropdownSelected] = useState<string>();

  const serializedCourses = courses && courses.data;

  const handleCourses = useCallback(async () => {
    if (user.role === 'professional') {
      const { public_name } = user.professional;
      if (public_name) {
        setLoading(true);
        try {
          const { data } = await ProfessorService.getCourses(
            public_name,
            pageOptions,
          );

          setCourses(data);
        } catch (error) {
          const responseErr = errorHandlerToToast({
            error,
          });
          if (responseErr) addToast(responseErr);
        } finally {
          setLoading(false);
        }
      }
    }
  }, [addToast, pageOptions, setLoading, user]);

  const handleCourseDelete = useCallback(
    async (courseId: number) => {
      setLoading(true);

      try {
        if (user.role === 'professional') {
          await CourseService.deleteCourseById(user.professional.id, courseId);

          addToast({
            type: 'success',
            title: 'Curso excluído com sucesso!',
          });

          handleCourses();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        const responseErr = errorHandlerToToast({ error });
        if (responseErr) addToast(responseErr);
      }
    },
    [addToast, handleCourses, setLoading, user],
  );

  useEffect(() => {
    if (needUpdate) {
      handleCourses();
      setNeedUpdate(false);
    }
    return cleanRequests;
  }, [handleCourses, needUpdate]);

  return (
    <>
      <Header
        headerTitle="Meus Cursos"
        buttonText="Novo curso"
        {...{ setNeedUpdate }}
      />

      <MyCoursesContainer>
        <Courses
          courses={serializedCourses}
          handleDeleteCourse={handleCourseDelete}
          isProfessional
          dropdownProps={{
            dropdownOpen,
            dropdownSelected,
            setDropdownOpen,
            setDropdownSelected,
            setNeedUpdate,
          }}
        />

        <Footer>
          <Paginate
            pageRangeDisplayed={pageOptions.page}
            pageCount={courses && courses.lastPage}
            {...{ setPageOptions }}
          />
        </Footer>
      </MyCoursesContainer>
    </>
  );
};
export default MyCourses;
