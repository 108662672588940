import { useCallback, useRef } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Button, Input, Modal } from '~/components';
import { ModalContent } from '~/components/Modal/styles';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { ModalLike, SubmitHandler } from '~/models/Common';
import { ChangePasswordRequest } from '~/models/User';
import UserSevice from '~/services/UserService';
import errorHandlerToToast from '~/utils/errorHandler';

import { Label } from './styles';

const ChangePassword: React.FC<ModalLike> = ({
  modalIsOpen,
  setModalIsOpen,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { setLoading } = useLoading();

  const handleSubmit: SubmitHandler<ChangePasswordRequest> = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const pass = Yup.string()
          .required('Campo obrigatório')
          .min(6, m => `Mínimo de ${m} caracter(es).`);

        const schema = Yup.object().shape({
          old_password: pass,
          new_password: pass.notOneOf(
            [data.old_password],
            'A senha nova não pode ser igual a antiga.',
          ),
          confirm_password: Yup.string()
            .required('Confirmar obrigatório.')
            .oneOf([data.new_password], 'Senha precisa ser igual.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { confirm_password, ...fields } = data;

        if (user) {
          setLoading(true);
          const {
            data: { Message },
          } = await UserSevice.updatePassword(user.role, fields);

          setLoading(false);

          setModalIsOpen(false);
          addToast({
            title: Message,
            type: 'success',
          });
        }
      } catch (error) {
        setLoading(false);

        const responseErr = errorHandlerToToast({
          error,
          formRef,
          description: 'Tente novamente mais tarde.',
        });
        if (responseErr) addToast(responseErr);
      }
    },
    [addToast, setLoading, setModalIsOpen, user],
  );

  return (
    <Modal {...{ setModalIsOpen, modalIsOpen }}>
      <ModalContent>
        <h1>Alterar senha</h1>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Label htmlFor="password">
            Senha atual:
            <Input name="old_password" type="password" id="old_password" />
          </Label>
          <Label htmlFor="new_password">
            Nova senha:
            <Input name="new_password" type="password" id="new_password" />
          </Label>
          <Label htmlFor="confirm_password">
            Confirmar senha:
            <Input
              name="confirm_password"
              type="password"
              id="confirm_password"
            />
          </Label>
          <Button isOutline={false}>Salvar</Button>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ChangePassword;
