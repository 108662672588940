import { FC, useMemo } from 'react';

import { DropdownType } from '~/models/Common';
import { DropdownIcon } from '~/styles/objects/dropdown-icon';
import { ItemsGrid } from '~/styles/objects/items-grid';
import { NotFoundItems } from '~/styles/objects/not-found';
import { handleChangeDropdown } from '~/utils/handleChangeDropdown';

import { DropdownMenu } from '..';

import * as P from './styles';

import { PracticesProps } from './typings';

const Practices: FC<PracticesProps> = ({
  dropdownProps,
  practices,
  course,
  isProfessional,
  handleDeletePractice,
  setPracticeNeedUpdate,
}) => {
  const practicesMemo = useMemo(() => {
    return practices?.map(practice => (
      <P.PracticeWrapper key={practice.id}>
        {isProfessional && (
          <>
            <DropdownIcon
              size={24}
              color="#FFF"
              onClick={() =>
                dropdownProps &&
                handleChangeDropdown({
                  id: practice.id,
                  type: DropdownType.PRACTICE,
                  setIsOpen: dropdownProps.setDropdownOpen,
                  setItem: dropdownProps.setDropdownSelected,
                  isOpen: dropdownProps.dropdownOpen,
                })
              }
            />
            <DropdownMenu
              setNeedUpdate={setPracticeNeedUpdate}
              dropdownIsOpen={dropdownProps.dropdownOpen}
              setDropdownIsOpen={dropdownProps.setDropdownOpen}
              id={`practice_${practice.id.toString()}`}
              dropdownSelected={dropdownProps.dropdownSelected}
              practice={practice}
              course={course}
              handleDelete={() => {
                handleDeletePractice(practice.id);
              }}
            />
          </>
        )}
        <P.PracticeContent>
          <P.PracticeDocument
            src={`https://docs.google.com/gview?url=${practice.image}&embedded=true`}
            title={practice.title}
          />
          <P.PracticeTitle>{practice.title}</P.PracticeTitle>
          <P.PracticeDescription>{practice.description}</P.PracticeDescription>
          <P.PracticeDocumentLink
            href={practice.image}
            target="_blank"
            rel="noreferrer"
          >
            <P.PracticeDocumentButton>Ver Arquivo</P.PracticeDocumentButton>
          </P.PracticeDocumentLink>
        </P.PracticeContent>
      </P.PracticeWrapper>
    ));
  }, [
    course,
    dropdownProps,
    handleDeletePractice,
    isProfessional,
    practices,
    setPracticeNeedUpdate,
  ]);

  if (practices?.length === 0)
    return <NotFoundItems>{/* Nenhuma prática encontrada. */}</NotFoundItems>;

  return <ItemsGrid>{practicesMemo}</ItemsGrid>;
};

export default Practices;
