import styled, { css } from 'styled-components';

import { ImageBackground } from '~/assets/images';

interface CourseProps {
  status: 'Inativo' | 'Ativo';
}

export const Container = styled.div`
  background: url(${ImageBackground}) 0% / cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-top: 64px;
  padding-bottom: 128px;

  img {
    width: 198px;
    height: 121px;
  }

  > h1 {
    letter-spacing: 1.28px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 700;
    margin-top: 40px;
  }

  > h3 {
    letter-spacing: 1.28px;
    color: #fdf5a6;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 400;
    margin-top: 50px;
  }

  > h6 {
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin-top: 15px;
    font-weight: 400;
  }

  p {
    margin-top: 20px;
    margin-left: 25px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    max-width: 364px;

    strong {
      color: #fdf5a6;
      cursor: pointer;
    }
  }

  a {
    max-width: 420px;
    width: 100%;
  }

  button {
    margin-top: 20px;
    max-width: 420px;
    width: 100%;
    height: auto;
    padding: 14px;
  }

  @media (max-width: 768.9px) {
    padding: 10px 20px;

    > h1,
    > h3 {
      text-align: center;
    }

    p {
      margin-left: 0;
    }
  }

  @media (max-width: 768.9px) {
    padding-bottom: 100px;
  }

  @media (max-width: 768.9px) and (max-height: 673.9px) {
    width: 100%;
    height: 100%;
  }
`;

export const PlanBox = styled.div`
  margin-top: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #3e3e3e6b;
  border-radius: 8px;
  max-width: 1042px;
  width: 100%;
  min-height: 276px;
  padding: 20px 50px;
  display: flex;
  align-items: center;

  div {
    padding-left: 70px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5 {
      font-size: 33px;
      font-weight: 700;
      letter-spacing: -0.36px;
      color: #565656;

      strong {
        letter-spacing: -0.74px;
        color: #2c2c2c;
        font-size: 67px;
      }
    }

    button {
      background: #00aa1c 0% 0% no-repeat padding-box;
    }
  }

  @media (max-width: 768.9px) {
    flex-direction: column;
    height: auto;
    padding: 26px;
    div {
      padding-left: 0;

      h5 {
        font-size: 28px;

        strong {
          font-size: 36px;
        }
      }
    }
  }
`;

export const List = styled.ul``;

export const Item = styled.li`
  display: flex;
  align-items: center;
  letter-spacing: -0.19px;
  color: #2c2c2c;
  margin-bottom: 25px;
  max-width: 100%;
  width: 100%;
  font-size: 17px;
  svg {
    margin-right: 10px;
  }

  @media (max-width: 768.9px) {
    font-size: 15px;
  }
`;

export const CourseBox = styled.div`
  margin-top: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #3e3e3e24;
  border-radius: 8px;
  max-width: 1043px;
  width: 100%;
  height: 509px;
  display: flex;
  align-items: center;

  padding: 0 50px;

  position: relative;

  .swiper-container,
  .swiper-container-initialized,
  .swiper-container-horizontal {
    position: unset;
  }

  .swiper-button-next {
    right: 0px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #b88746;
    margin: 0 15px;
  }
`;

export const Course = styled.div<CourseProps>`
  max-width: 393px;
  width: 100%;
  border-radius: 3px;
  padding: 16px;
  cursor: pointer;
  transition: background 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    img {
      max-width: 393px;
      width: 100%;
      max-height: 212px;
      height: 100%;
    }

    span {
      font-size: 13px;
      font-weight: bold;
      color: #009218;
      text-transform: uppercase;
      letter-spacing: 0.52px;

      ${props =>
        props.status === 'Inativo' &&
        css`
          color: #b10000;
        `}
    }

    > h1 {
      letter-spacing: 0.8px;
      color: #2c2c2c;
      font-size: 20px;
      margin-bottom: 5px;
      text-align: left;
    }

    h6 {
      color: #8b8b8b;
      font-size: 16px;
      font-weight: 400;
    }
  }

  button {
    background: #00aa1c 0% 0% no-repeat padding-box;
  }

  @media (max-width: 1679.9px) {
    max-width: 340px;

    img {
      max-width: 310px;
    }
  }

  @media (max-width: 768.9px) {
    max-width: 340px;
    align-items: flex-start;
    img {
      max-width: 290px;
    }

    h1 {
      font-size: 18px;
    }

    div {
      max-width: 90%;
      h6,
      p {
        max-width: 290px;
      }
    }

    button {
      max-width: 120px;
    }
  }
`;
