import styled from 'styled-components';

import { ImageBackground } from '~/assets/images';

export const Header = styled.header`
  background: url(${ImageBackground}) 0% / cover;
  max-width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768.9px) {
    height: 720px;
  }
`;

export const Nav = styled.nav`
  width: 100%;
  padding: 20px 50px;

  @media (max-width: 768.9px) {
    display: flex;
    justify-content: center;
  }
`;

export const User = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768.9px) {
    flex-direction: column;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  position: absolute;

  img {
    height: 100%;
    width: auto;
  }

  h1 {
    letter-spacing: 2.36px;
    color: #ffffff;
    font-size: 42px;
    padding-left: 40px;
    padding-bottom: 140px;
    max-width: 420px;
  }

  @media (max-width: 1400.9px) {
    h1 {
      font-size: 34px;
    }
  }

  @media (max-width: 1242.9px) {
    h1 {
      font-size: 28px;
    }
  }

  @media (max-width: 768.9px) {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin-top: 15px;
      padding-bottom: 50px;
      font-size: 34px;
      text-align: center;
      padding-left: 0 !important;
    }
  }
`;

export const ImageWrapper = styled.div`
  height: 320px;
  width: 320px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-left: 100px;
  background-color: white;

  @media (max-width: 1400.9px) {
    width: 240px;
    height: 240px;
  }

  @media (max-width: 1242.9px) {
    margin-left: 30px;
  }

  @media (max-width: 768.9px) {
    margin: 0;
    width: 180px;
    height: 180px;
  }
`;

export const ButtonGroup = styled.div`
  right: 0;
  position: absolute;
  padding-bottom: 90px;
  max-width: 720px;
  width: 100%;
  display: flex;

  a {
    max-width: 357px;
    width: 100%;
    margin-right: 20px;

    button {
      max-width: 357px;
      width: 100%;
    }
  }

  @media (max-width: 1400.9px) {
    max-width: 560px;

    button {
      max-width: 260px;
    }
  }

  @media (max-width: 1242.9px) {
    flex-direction: column;
    align-items: flex-end;
    max-width: 480px;
    margin-bottom: 20px;

    a {
      max-width: 220px;

      button {
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: 768.9px) {
    flex-direction: row;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 15px;

    a {
      max-width: 280px;
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
`;
