interface WhatsAppUrlProps {
  country?: number;
  ddd: number;
  cell_phone: number;
}

const WhatsAppUrl: React.FC<WhatsAppUrlProps> = ({
  country = 55,
  ddd,
  cell_phone,
}) => {
  const baseUrl = 'https://wa.me/';
  const { href } = new URL(`+${country}${ddd}${cell_phone}`, baseUrl);
  const cell_phone_string = cell_phone.toString();
  const cell_phone_regexp = /^(\d{5})(\d{3,4})$/;
  const cell_phone_mask = cell_phone_string.replace(cell_phone_regexp, '$1-$2');

  return (
    <a {...{ href }} target="_blank" rel="noopener noreferrer">
      ({ddd}) {cell_phone_mask}
    </a>
  );
};

export default WhatsAppUrl;
