import { Styles } from 'react-modal';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const MenuMobileStyles: Styles = {
  content: {
    background: `${colors.black} 0% 0% no-repeat padding-box`,
    borderRadius: '8px',
    maxWidth: '100%',
    maxHeight: '90%',
    height: 'fit-content',
    border: 'none',
    animation: 'bouseIn 1s ease-in-out',
    boxShadow: '0px 8px 15px #3E3E3E6B',
    marginTop: '4rem',
  },
  overlay: {
    zIndex: 100,
    background: 'rgba(44, 44, 44, 0.7)',
  },
};

export const MenuMobileItem = styled(NavLink)`
  display: block;
  color: #fff;
  text-align: center;
  margin-bottom: var(--gap-xs);
`;
