import {
  useState,
  useEffect,
  useCallback,
  useRef,
  InputHTMLAttributes,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
} from 'react';

import { useField } from '@unform/core';
import { FiAlertCircle, FiLink } from 'react-icons/fi';

import { InputPropsAccessibility } from '~/models/Common';

import { Container, Error, IconWrapper } from './styles';
import { useToast } from '~/hooks/Toast';

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, InputPropsAccessibility> {
  accessibilityText?: string;
  name: string;
  parentBlur?: boolean;
  setParentFocus?: () => void;
  icon?: boolean;
  linkValue?: string;
}

const Input = forwardRef(
  (
    {
      name,
      parentBlur,
      setParentFocus,
      type = 'text',
      accessibilityText,
      icon,
      linkValue,
      ...rest
    }: InputProps,
    innerRef?: ForwardedRef<HTMLInputElement>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [accessibilityTextProp] = useState<string | undefined>(() => {
      if (accessibilityText) {
        return accessibilityText;
      }
      if (rest.placeholder) {
        return rest.placeholder;
      }

      return undefined;
    });
    const { addToast } = useToast();

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      innerRef,
      () => inputRef.current,
      [],
    );

    const {
      fieldName,
      defaultValue,
      error: fieldError,
      registerField,
    } = useField(name);

    useEffect(() => {
      registerField<string>({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      });
    }, [fieldName, registerField]);

    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback((): void => {
      setIsFocused(true);
      if (setParentFocus) {
        setParentFocus();
      }
    }, [setParentFocus]);

    const handleInputBlur = (): void => {
      setIsFocused(false);
    };

    useEffect(() => {
      if (parentBlur) {
        handleInputBlur();
      }
    }, [parentBlur]);

    const handleIconClick = useCallback(() => {
      if (linkValue) {
        const textToCopy = linkValue;
        navigator.clipboard.writeText(textToCopy);
        addToast({
          title: 'Link copiado para área de transferência!',
          type: 'success',
        });
      }
    }, [linkValue]);

    return (
      <Container
        hidden={type === 'hidden'}
        isErrored={!!fieldError}
        isFocused={isFocused}
      >
        <input
          {...{ name, type, defaultValue }}
          aria-label={accessibilityTextProp}
          title={accessibilityTextProp}
          ref={inputRef}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...rest}
        />
        {icon && (
          <IconWrapper onClick={handleIconClick}>
            <FiLink />
          </IconWrapper>
        )}

        {fieldError && (
          <Error title={fieldError}>
            <FiAlertCircle color="#C53030" size={20} />
          </Error>
        )}
      </Container>
    );
  },
);

Input.defaultProps = {
  parentBlur: undefined,
  setParentFocus: undefined,
  accessibilityText: undefined,
  icon: false,
  linkValue: '',
};

export default Input;
