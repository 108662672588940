import { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { ImageDance } from '~/assets/images';
import { Modal } from '~/components';
import { ModalLike } from '~/models/Common';
import Courses from '~/models/Courses';
import ProfessorService from '~/services/ProfessorService';
import getMimeTypeByExtVideo from '~/utils/getMimeType';
import Price from '~/utils/Price';

import {
  ButtonGreen,
  BuyCourseDescription,
  BuyCourseImage,
  BuyCourseInfo,
  BuyCoursePrice,
  BuyCourseTitle,
  BuyCourseVideo,
  BuyCourseWrapper,
  ModalContent,
} from './styles';

interface BuyCourseProps extends ModalLike {
  course: Courses;
}

export const BuyCourse: React.FC<BuyCourseProps> = ({
  modalIsOpen,
  setModalIsOpen,
  course,
}) => {
  const [publicname, setPublicName] = useState('');

  const history = useHistory();

  const handleGetProfessionalPublicName = useCallback(async () => {
    const response = await ProfessorService.getProfessionalById(
      course.professional_id,
    );

    setPublicName(response.data.public_name);
  }, [course.professional_id]);

  const handleGoToBuyCourse = useCallback(() => {
    history.push(`/${publicname}/payment?course=${course.id}`);
  }, [course.id, history, publicname]);

  useEffect(() => {
    if (modalIsOpen) {
      handleGetProfessionalPublicName();
    }
  }, [handleGetProfessionalPublicName, modalIsOpen]);

  return (
    <Modal {...{ modalIsOpen, setModalIsOpen }}>
      <ModalContent>
        <BuyCourseWrapper>
          {course?.video ? (
            <BuyCourseVideo
              onContextMenu={() => false}
              controlsList="nodownload"
              loop
              controls
              autoPlay
              muted
            >
              <source
                src={course.video}
                type={getMimeTypeByExtVideo(
                  course.video.split('.').pop() as string,
                )}
              />
            </BuyCourseVideo>
          ) : (
            <BuyCourseImage
              src={course.foto_path ?? ImageDance}
              alt={course.title}
            />
          )}

          <BuyCourseInfo>
            <BuyCourseTitle>{course.title}</BuyCourseTitle>
            <BuyCoursePrice>
              {Price.floatToCurrency(course.price ?? 0)}
            </BuyCoursePrice>
            <BuyCourseDescription>{course.description}</BuyCourseDescription>
            <BuyCourseDescription
              style={{ fontSize: '1.4rem', marginTop: 8 }}
            >{`Tipo de pagamento: ${
              course.payment_type === 'charge' ? 'Cobrança Única' : 'Assinatura'
            }`}</BuyCourseDescription>
          </BuyCourseInfo>
        </BuyCourseWrapper>
        <ButtonGreen onClick={handleGoToBuyCourse}>Comprar curso</ButtonGreen>
      </ModalContent>
    </Modal>
  );
};

export default BuyCourse;
