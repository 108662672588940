import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

export const MonthlyPayment = styled.section`
  display: flex;
  flex-direction: column;
  padding: var(--gap-lg) 0;
`;

export const Title = styled.h2`
  font-size: 3.6rem;
  letter-spacing: 1.52px;
  color: ${colors.lightGrey};
  text-transform: uppercase;
  margin-bottom: var(--gap-md);

  @media (max-width: ${breakpoints.small}) {
    font-size: 2.4rem;
    text-align: center;
  }
`;

export const ButtonGroup = styled.section`
  display: grid;
  gap: var(--gap-sm);
  grid-auto-flow: column;
  grid-column-start: 2;
`;

export const InputGroup = styled.div`
  display: grid;
  column-gap: var(--gap-md);
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    flex-direction: column;
    row-gap: var(--gap-sm);
  }

  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const InputGrouptwo = styled.div`
  display: grid;
  column-gap: var(--gap-md);
  grid-template-columns: 1fr 1fr 1fr 0.5fr;

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    flex-direction: column;
    row-gap: var(--gap-sm);
  }

  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr 1fr 1fr 0.5fr 1fr;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
    text-align: center;
    padding: 5px;
    @media (max-width: 880px) {
      padding: 5px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    strong {
      margin-right: 10px;
    }
  }

  p {
    background-color: ${colors.green};
    display: flex;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
  }
`;

export const Inative = styled.p`
  background-color: ${colors.red};
  display: flex;
  border-radius: 15px;
  width: 70px;
  align-items: center;
  justify-content: center;
`;

export const PublicNameWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const PersonalizeWrapper = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
`;

export const Personalize = styled.div`
  display: flex;
  flex-direction: column;

  > label:last-child {
    max-width: 422px;
    width: 100%;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: ${colors.lightGrey};
  font-size: 2.1rem;
  font-weight: 600;

  > div {
    border-color: ${colors.lightGrey};
    color: ${colors.lightGrey};
    padding-top: 10px;
    padding-left: 5px;

    > input {
      color: ${colors.lightGrey};
      font-size: 21px;
    }
  }
`;

export const BoxImage = styled.label`
  width: 258px;
  height: 258px;
  margin-right: var(--gap-sm);
  background: #2c2c2c 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #57575729;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  cursor: pointer;

  > span {
    color: #ffffff;
    font-weight: 600;
    font-size: 1.6rem;
    margin-top: var(--gap-xs);
  }

  @media (max-width: ${breakpoints.small}) {
    margin-right: 0;
    margin-bottom: var(--gap-sm);
  }
`;

export const UploadImageLabel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #2c2c2c99;
  position: absolute;
  z-index: 2;

  p {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 1.6rem;
    color: #fff;
    margin-top: 34px;
  }
`;

export const CancelButton = styled.button`
  min-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00aa1c;
  outline: none;
  /* padding-right: 30px; */
  color: #fff;
  letter-spacing: 0.3px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 60px;

  &:hover {
    text-decoration: underline;
  }
`;
