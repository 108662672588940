import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const Plan = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 70px;

  label {
    margin-top: 30px !important;
  }
`;

export const PlanLabel = styled.div`
  max-width: 1100px;
  padding: 0 20px;
  padding-bottom: 16px;
  border-bottom: 2px solid #2c2c2c;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 759px) {
    flex-direction: column;
    justify-content: center;
  }

  span {
    display: flex;
    text-align: center;

    @media (max-width: 880px) {
      padding: 5px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    strong {
      margin-right: 10px;
    }
  }

  svg {
    padding-right: 30px;
    cursor: pointer;
  }

  & + div {
    margin-top: 30px;
  }
`;

export const PlanTitle = styled.h2`
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 1.52px;
  color: ${colors.lightGrey};
  text-transform: uppercase;
  margin-bottom: 30px;

  @media (max-width: 768.9px) {
    font-size: 32px;
    text-align: center;
  }
`;

export const ColumnData = styled.div`
  display: flex;
`;

export const CancelButton = styled.button`
  min-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.3px;
  font-size: 15px;
  font-weight: bold;
  text-transform: none;
  color: ${colors.primary};
  cursor: pointer;

  background: transparent;
  border: 2px solid ${colors.primary};
  border-radius: 60px;
  box-shadow: 0px 3px 6px #57575729;

  padding: 12px 24px;

  &:hover {
    text-decoration: underline;
  }
`;

export const PlansWarning = styled.p`
  margin-top: 8px;
  font-size: 12px;
  color: #b10000;
  letter-spacing: 0.3px;
`;
