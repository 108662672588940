import { FC, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { Container, Text } from './styles';

import Button from '../Button';

const CompleteRegistrationAdvise: FC = () => {
  const history = useHistory();

  const handleGoToSettings = useCallback(() => {
    history.push('/professor/config');
  }, [history]);

  return (
    <Container>
      <Text>Não esqueça de completar seu cadastro!</Text>
      <Button onClick={handleGoToSettings}>Ver configurações</Button>
    </Container>
  );
};

export default CompleteRegistrationAdvise;
