import styled, { css } from 'styled-components';

import { ButtonBlackColor, ButtonBlackStyle } from '~/models/Common';
import { colors } from '~/styles/settings/colors';

export const Container = styled.button<ButtonBlackStyle>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isLabelFile }) =>
    isLabelFile &&
    css`
      pointer-events: none;
    `}

  transition-property: background-color;
  transition-duration: 500ms;

  background-color: ${({ stateUpload }) => {
    switch (stateUpload) {
      case ButtonBlackColor.SUCESS:
        return '#00aa1c';
      case ButtonBlackColor.ERROR:
        return '#C53030';
      default:
        return colors.gray;
    }
  }};

  border-radius: 4px;
  color: #fff;

  font-size: 23px;
  font-weight: bold;

  width: 100%;
  height: 55px;
`;
