import React from 'react';

import { ImageLogo } from '~/assets/icons';

import { LogoWrapper } from './styles';

interface LogoProps {
  width?: number;
  height?: number;
}

const Logo: React.FC<LogoProps> = ({ width, height }) => {
  return (
    <LogoWrapper
      src={ImageLogo}
      alt="Seu.coach"
      title="Seu.coach"
      width={width}
      height={height}
    />
  );
};

export default Logo;
