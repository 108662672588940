import { RefObject } from 'react';

import { FormHandles } from '@unform/core';
import { AxiosError } from 'axios';
import { ValidationError } from 'yup';

import { ToastMessage } from '~/hooks/Toast';
import { MessageServer } from '~/models/Common';
import getValidationErrors from '~/utils/getValidationErrors';

import NotFoundCepError from './NotFoundCepError';

interface ValidationErrorFromYup {
  error: ValidationError;
  formRef: RefObject<FormHandles>;
}

interface AxiosErrorResponse<T> {
  error: AxiosError<T> | Error;
  description?: string;
}

type errorHandlerArgs<T> =
  | NotFoundCepError
  | ValidationErrorFromYup
  | AxiosErrorResponse<T>
  | string;

/**
 * Generate info to toast or just set
 * error in form
 */
function errorHandlerToToast<T extends MessageServer>(
  args: errorHandlerArgs<T>,
): Omit<ToastMessage, 'id'> | null {
  if (typeof args === 'string') {
    return {
      title: args,
      type: 'error',
    };
  }
  const description = 'description' in args ? args.description : undefined;
  if (args instanceof NotFoundCepError) {
    return {
      title: args.message,
      type: 'info',
      description,
    };
  }
  const { error } = args;
  if (error instanceof ValidationError) {
    if ('formRef' in args) {
      const errors = getValidationErrors(error);
      args.formRef.current?.setErrors(errors);
    }
  } else if (error instanceof Error) {
    if ('isAxiosError' in error) {
      return {
        title: error.response?.data.message ?? error.message,
        description,
        type: 'error',
      };
    }

    return {
      title: error.message,
      description,
      type: 'error',
    };
  }

  return null;
}

export default errorHandlerToToast;
