import styled, { css } from 'styled-components';

interface CourseProps {
  status: string;
}

export const Video = styled.video`
  width: 300px;
  height: 250px;
  @media (max-width: 768px) {
    height: 320px;
  }
`;

export const Image = styled.img`
  height: 357px;
`;

export const Container = styled.div`
  padding: 200px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 42px;
    font-weight: bold;

    text-align: center;
    letter-spacing: 8.4px;
    text-transform: uppercase;

    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  > button {
    background: #00aa1c 0% 0% no-repeat padding-box;
    margin-top: 50px;
    margin-bottom: 80px;
    max-width: 507px;
    padding: 24px;
    border-radius: 60px;
  }

  @media (max-width: 768.9px) {
    padding: 0;
    margin-top: 30px;
  }
`;

export const Content = styled.div`
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 422px);
  column-gap: 10px;
  row-gap: 30px;
  justify-content: center;
  margin-bottom: 25px;

  @media (max-width: 768.9px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 100%;
  }
`;

export const MediaWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: transparent;

  img,
  video {
    max-width: 393px;
    height: 230px;
    width: auto;
  }
`;

export const Course = styled.div<CourseProps>`
  max-width: 422px;
  width: 100%;
  border-radius: 3px;
  padding: 16px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #fff0dc 0% 0% no-repeat padding-box;
  }

  > svg {
    position: absolute;
    margin-left: 370px;
    margin-top: 5px;
    border-radius: 50%;
    background: #2c2c2c 0% 0% no-repeat padding-box;
    opacity: 0.67;
    margin-right: 15px;
  }

  span {
    font-size: 13px;
    font-weight: bold;
    color: #009218;
    text-transform: uppercase;
    letter-spacing: 0.52px;

    ${props =>
      props.status === 'Inativo' &&
      css`
        color: #b10000;
      `}
  }

  h1 {
    letter-spacing: 0.8px;
    color: #2c2c2c;
    font-size: 20px;
    margin-bottom: 5px;
  }

  h3 {
    letter-spacing: 0px;
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  h6 {
    color: #8b8b8b;
    font-size: 16px;
    font-weight: 400;
  }

  > div {
    > a {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }

  @media (max-width: 768.9px) {
    max-width: 310px;

    img {
      max-width: 290px;
    }

    svg {
      margin-left: 270px;
    }
  }
`;
