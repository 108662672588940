import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding: 4rem;
  /* background: #2c2c2c; */
`;

export const Title = styled.h2`
  color: #f4f4f4;
  text-align: center;
  align-items: center;
`;

export const Profiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  /* padding: 0 4rem; */
  /* border: 2px solid red; */
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
`;

export const Image = styled.img`
  border-radius: 9999px;
  height: 15rem;
  width: 15rem;
  object-fit: cover;
`;

export const ProfileName = styled.h4`
  color: #f4f4f4;
  text-align: center;
`;
