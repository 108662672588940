import styled, { css } from 'styled-components';

import Tooltip from '~/components/Tooltip';

interface SelectBlockProps {
  isFocused: boolean;
  isErrored: boolean;
}

interface SelectLabelProps {
  isFocused: boolean;
  color?: string
}

export const SelectBlock = styled.div<SelectBlockProps>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};

  align-items: center;

  background: transparent;
  width: 100%;
  padding-bottom: 18px;

  margin-bottom: 25px;

  border: 0;
  border-bottom: 2px solid #fff;
  color: #fff;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}
  ${props =>
    props.isFocused &&
    css`
      border-color: #fdf5a6;
    `}
`;

export const SelectField = styled.select<SelectLabelProps>`
  background: transparent;
  width: 100%;
  border: transparent;
  color: ${props => props?.color ? props?.color : '#fff'} ;
   -webkit-appearance: caret;

  option {
    color: #000;
  }

  &::placeholder {
    color: ${props => props?.color ? props?.color : '#fff'} ;
    font-weight: bold;

    /* ${props =>
    props.isFocused &&
    css`
        color: #fdf5a6;
      `} */
  }
`;

export const SelectError = styled(Tooltip)`
  height: 20px;

  svg {
    margin-right: 0;
    margin-left: 15px;
  }
`;
