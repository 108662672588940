import React, { useCallback, useMemo, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Button, Input, Modal } from '~/components';
import { ModalContent } from '~/components/Modal/styles';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import {
  ForgotPasswordFormData,
  ModalLike,
  PairForgetCode,
  ResetPassword,
  SubmitHandler,
} from '~/models/Common';
import UserService from '~/services/UserService';
import errorHandlerToToast from '~/utils/errorHandler';
import { cast } from '~/utils/yupHelpers';

import { ButtonBack } from './style';

interface UserValidateProps {
  email: string;
  code: string;
}

const ForgotPasswordModal: React.FC<ModalLike> = ({
  modalIsOpen,
  setModalIsOpen,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [sendToEmail, setSendToEmail] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const { setLoading } = useLoading();
  const [userValidate, setUserValidate] = useState<UserValidateProps>(
    {} as UserValidateProps,
  );
  const [userId, setUserId] = useState<number>();

  const { addToast } = useToast();

  const handleSubmit: SubmitHandler<ForgotPasswordFormData> = useCallback(
    async data => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório.')
            .email('E-mail inválido.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await UserService.forgotPassword(data);

        setSendToEmail(true);
        setUserValidate({
          email: data.email,
          code: '',
        });
        setLoading(false);

        addToast({
          title: 'E-mail enviado',
          description: 'Verifique sua caixa de spam.',
          type: 'info',
        });
      } catch (error) {
        setLoading(false);
        const responseErr = errorHandlerToToast({ error, formRef });
        if (responseErr) addToast(responseErr);
      }
    },
    [addToast, setLoading],
  );

  const handleSubmitValidate: SubmitHandler<PairForgetCode> = useCallback(
    async data => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório.')
            .email('E-mail inválido.'),
          code: Yup.number().required('Código obrigatório').transform(cast),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        const {
          data: {
            message,
            data: { id: idFromCode },
          },
        } = await UserService.validateCode(data);

        setChangePassword(true);
        setUserId(idFromCode);
        setLoading(false);

        addToast({
          title: message,
          type: 'success',
        });
      } catch (error) {
        setLoading(false);
        const responseErr = errorHandlerToToast({ error, formRef });
        if (responseErr) addToast(responseErr);
      }
    },
    [addToast, setLoading],
  );

  const handleSubmitNewPass: SubmitHandler<ResetPassword> = useCallback(
    async data => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().min(6, 'Minímo 6 carácteres.'),
          confirm: Yup.string()
            .required('Confirmar obrigatório.')
            .equals([data.password], 'Senha precisa ser igual.'),
          id: Yup.number().required().transform(cast),
        });
        // data.id = userId;
        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          data: { message },
        } = await UserService.resetPassword(data);

        setLoading(false);
        setModalIsOpen(false);

        addToast({
          type: 'success',
          title: message,
        });
      } catch (error) {
        setLoading(false);
        const responseErr = errorHandlerToToast({ error, formRef });
        if (responseErr) addToast(responseErr);
      }
    },
    [addToast, setLoading, setModalIsOpen],
  );

  const handleBackToSendMail = (): void => {
    setSendToEmail(false);
    setChangePassword(false);
  };

  const SendButton = (): JSX.Element => (
    <Button isOutline={false} style={{ maxWidth: '373px' }}>
      Enviar
    </Button>
  );

  const Content = useMemo(() => {
    if (sendToEmail) {
      const GroupButtonSendedEmail = (): JSX.Element => (
        <>
          <SendButton />
          <ButtonBack type="button" onClick={handleBackToSendMail}>
            Tentar um novo e-mail
          </ButtonBack>
        </>
      );

      if (changePassword) {
        return (
          <Form onSubmit={handleSubmitNewPass} ref={formRef}>
            <h6>Insira seu o código recebido</h6>
            <Input name="id" type="hidden" value={userId} />
            <Input name="password" type="password" placeholder="Nova senha" />
            <Input
              name="confirm"
              type="password"
              placeholder="Confirmar senha"
            />
            <GroupButtonSendedEmail />
          </Form>
        );
      }

      return (
        <Form
          initialData={userValidate}
          onSubmit={handleSubmitValidate}
          ref={formRef}
        >
          <h6>Insira seu o código recebido</h6>
          <Input name="email" type="email" placeholder="E-mail" />
          <Input name="code" placeholder="Código de validação" />
          <GroupButtonSendedEmail />
        </Form>
      );
    }

    return (
      <>
        <h6>Insira seu e-mail abaixo.</h6>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input name="email" type="email" placeholder="E-mail" />
          <SendButton />
        </Form>
      </>
    );
  }, [
    changePassword,
    handleSubmit,
    handleSubmitNewPass,
    handleSubmitValidate,
    sendToEmail,
    userId,
    userValidate,
  ]);

  return (
    <Modal {...{ setModalIsOpen, modalIsOpen }}>
      <ModalContent>
        <h1>Esqueci minha senha</h1>
        {Content}
      </ModalContent>
    </Modal>
  );
};

export default ForgotPasswordModal;
