import { Link } from 'react-router-dom';

import { ImageLogo, ImageUserPlaceholder } from '~/assets/icons';

import { Button } from '..';

import { UpdateProfessionalResponse as Professional } from '~/models/Common';

import {
  ButtonGroup,
  Header,
  ImageWrapper,
  Nav,
  User,
  UserInfo,
} from './styles';

interface MySiteProps {
  professional: Professional;
}

const HeaderMySite: React.FC<MySiteProps> = ({ professional }) => {
  const { avatar = ImageUserPlaceholder } = professional.user;

  return (
    professional &&
    professional.user && (
      <Header>
        <Nav>
          <img src={ImageLogo} alt="Logo" width={200} />
        </Nav>
        <User>
          <UserInfo>
            <ImageWrapper>
              <img src={avatar ?? ImageUserPlaceholder} alt="Foto de Perfil" />
            </ImageWrapper>
            <h1>{professional.nick_name}</h1>
          </UserInfo>
          <ButtonGroup>
            <Link
              to={{
                pathname: `/${professional?.public_name}/become-a-student`,
                state: { professionalId: professional.id },
              }}
            >
              <Button>Torne-se aluno</Button>
            </Link>
            <Link
              to={{
                pathname: '/signin',
                state: {
                  publicname: professional.public_name,
                },
              }}
            >
              <Button isOutline>Entrar</Button>
            </Link>
          </ButtonGroup>
        </User>
      </Header>
    )
  );
};

export default HeaderMySite;
