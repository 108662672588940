import { createGlobalStyle } from 'styled-components';

import { colors } from '../settings/colors';
import { breakpoints } from '../tools/breakpoints';

export const Reset = createGlobalStyle`
  [hidden] {
    display: none !important;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    font-size: 62.5%; /* 1rem = 10px */
    height: 100%;
    width: 100%;

    @media (min-width: 1981px) {
      font-size: 80%;
    };
  }

  body {
    height: auto;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    background: #1a1a1a;
  }

  body, #root {
    min-height: 100vh;
    position: relative;
    /* padding-bottom: calc(var(--gap-lg) * 2); */
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.small}) {
      padding-bottom: 0;
    }
  }

  #root {
    height: 100%;
  }

  body, input, textarea, button,option, select  {
    font: 1.6rem 'Open Sans', sans-serif;
    line-height: 1.48;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: ${colors.lightnerGrey};
  }

  span {
    color: ${colors.lightGrey};
  }

  a {
    text-decoration: none;
    background: none;
    font-weight: 700;
    cursor: pointer;
    border: 0;
    transition: 180ms ease-in-out;
  }

  button {
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    list-style: none;
    text-align: left;
    padding: 0;
  }

  svg {
    overflow: visible;
  }

  .swiper-wrapper {
    z-index: 0;
  }

  input, textarea, label, select {
    color: ${colors.lightnerGrey} !important;
  }

  td {
    color: ${colors.lightGrey};
  }
`;
