import styled, { css } from 'styled-components';

import { ImageBackground } from '~/assets/images';
import { breakpoints } from '~/styles/tools/breakpoints';

interface CourseProps {
  status?: string;
}

export const Presentation = styled.header`
  height: 320px;
  width: 100%;
  background: url(${ImageBackground}) 0% / cover;
  display: flex;

  @media (max-width: 768.9px) {
    height: auto;
  }
`;

export const ImgContainer = styled.div`
  width: 100%;
  background-color: #fff;
  height: auto;
  max-width: 240px;
  max-height: 240px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: auto;
    height: 100%;
  }
`;

export const Course = styled.div<CourseProps>`
  display: flex;
  padding: 40px;
  margin-left: 60px;

  div {
    margin-left: 50px;
    margin-top: 20px;

    span {
      letter-spacing: 0.88px;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 22px;
      font-weight: bold;
      background: #009218 0% 0% no-repeat padding-box;
      ${props =>
        props.status === 'Inativo' &&
        css`
          background: #b10000 0% 0% no-repeat padding-box;
        `}
      border-radius: 50px;
      padding: 0px 20px;
    }

    h1 {
      letter-spacing: 1.52px;
      color: #ffffff;
      font-size: 38px;
    }

    h3 {
      font-size: 34px;
      font-weight: 600;
      color: #ffffff;
      margin-top: 5px;

      strong {
        padding-left: 10px;
        color: #ffffff;
      }
    }

    h6 {
      color: #ffffff;
      font-size: 28px;
      font-weight: 400;
    }
  }

  @media (max-width: 768.9px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;

    div {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      justify-content: center;
      align-items: center;

      span {
        text-align: center;
        max-width: 200px;
      }

      h1,
      h3,
      h6 {
        text-align: center;
      }
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 393px);
  column-gap: 30px;
  row-gap: 30px;
  justify-content: center;

  padding: 10px 40px 80px 40px;

  @media (max-width: 768.9px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding: 10px 15px 80px 15px;
  }
`;

export const Video = styled.div`
  cursor: pointer;
  max-width: 400px;
  transition: background 0.3s;
  img {
    max-width: 393px;
  }

  > svg {
    position: absolute;
    margin-left: 370px;
    margin-top: 5px;
    border-radius: 50%;
    background: #2c2c2c 0% 0% no-repeat padding-box;
    opacity: 0.67;
    margin-right: 15px;
    cursor: pointer;
  }

  div {
    span {
      text-transform: uppercase;
      font-size: 13px;
      color: #2c2c2c;
    }

    h6 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: bold;
    }

    p {
      color: #636363;
      font-size: 16px;
      max-width: 393px;
    }
  }

  @media (max-width: 768.9px) {
    max-width: 310px;

    img {
      max-width: 290px;
    }

    svg {
      margin-left: 270px;
    }
  }
`;

export const Practice = styled.div`
  max-width: 393px;
  transition: background 0.3s;

  > svg {
    position: absolute;
    margin-left: 370px;
    margin-top: 5px;
    border-radius: 50%;
    background: #2c2c2c 0% 0% no-repeat padding-box;
    opacity: 0.67;
    margin-right: 15px;
    cursor: pointer;
  }

  div {
    span {
      text-transform: uppercase;
      font-size: 13px;
      color: #2c2c2c;
    }

    h6 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: bold;
    }

    p {
      color: #636363;
      font-size: 16px;
      max-width: 393px;
    }

    button {
      background: #2c2c2c 0% 0% no-repeat padding-box;
      margin-top: 15px;
      margin-left: 20px;
    }
  }

  @media (max-width: 768.9px) {
    max-width: 310px;

    img {
      max-width: 290px;
    }

    svg {
      margin-left: 240px;
    }

    div {
      button {
        margin-left: 0;
      }
    }
  }
`;

export const PracticeDocument = styled.iframe`
  width: 100%;
  height: 240px;

  @media (max-width: ${breakpoints.small}) {
    height: 200px;
  }
`;

export const Interaction = styled.div`
  max-width: 393px;
  transition: background 0.3s;
  background: none;
  padding: 16px;
  position: relative;

  &:hover {
    cursor: pointer;
    background: #fff0dc 0% 0% no-repeat padding-box;
  }

  > svg {
    position: absolute;
    margin-left: 370px;
    margin-top: 5px;
    border-radius: 50%;
    background: #2c2c2c 0% 0% no-repeat padding-box;
    opacity: 0.67;
    margin-right: 15px;
    cursor: pointer;
  }

  div {
    span {
      text-transform: uppercase;
      font-size: 13px;
      color: #2c2c2c;

      strong {
        letter-spacing: 0.52px;
        color: #b88746;
      }
    }

    h6 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: bold;
    }

    p {
      color: #636363;
      font-size: 16px;
      max-width: 393px;
    }

    button {
      background: #2c2c2c 0% 0% no-repeat padding-box;
      margin-top: 15px;
      margin-left: 20px;
    }
  }

  @media (max-width: 768.9px) {
    max-width: 310px;

    img {
      max-width: 290px;
    }

    svg {
      margin-left: 240px;
    }

    div {
      button {
        margin-left: 0;
      }
    }
  }
`;
export const Image = styled.img`
  height: 250px;
`;

export const VideoShow = styled.video`
  width: 300px;
  height: 250px;
  @media (max-width: 768px) {
    height: 320px;
  }
`;

export const Container = styled.div`
  display: block;
`;

export const CourseDetailsVideo = styled.video`
  width: 300px;
  height: 250px;

  @media (max-width: ${breakpoints.small}) {
    height: 320px;
  }
`;
