import React, {
  SelectHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from 'react';

import { SelectOptionProps } from '~/models/Common';

import { SelectBlock, SelectField } from './styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  options: SelectOptionProps[];
  parentBlur?: boolean;
  setParentFocus?: () => void;
  setValue: (value: string) => void;
  value: string;
  color?: string;
}

const Select: React.FC<SelectProps> = ({
  name,
  options,
  parentBlur,
  setParentFocus,
  setValue,
  value,
  color,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState(false);
  const ref = useRef<HTMLSelectElement>(null);

  const handleInputFocus = useCallback((): void => {
    setIsFocused(true);
    if (setParentFocus) {
      setParentFocus();
    }
  }, [setParentFocus]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleChange = ({
    target: { value: valueFromSelected },
  }: React.ChangeEvent<HTMLSelectElement>): void => {
    setValue(valueFromSelected);
    // console.log('valueFromSelected', valueFromSelected);
  };

  return (
    <SelectBlock isErrored={!!error} isFocused={isFocused}>
      <SelectField
        ref={ref}
        onChange={handleChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        color={color}
        value={value}
        {...{ isFocused }}
      >
        <option value="" disabled selected>
          Selecione uma opção
        </option>
        {options?.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectField>
    </SelectBlock>
  );
};

export default Select;
