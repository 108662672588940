import { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { serialize } from 'object-to-formdata';
import { GoFileMedia } from 'react-icons/go';
import * as Yup from 'yup';
import { FiTrash } from 'react-icons/fi';

import {
  Button,
  ButtonLink,
  Input,
  InputCurrency,
  InputLink,
  InputMask,
  InputVideos,
  Modal,
  ModalRedirect,
  Radio,
  Textarea,
} from '~/components';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { MediaProps, ModalLike, SubmitHandler } from '~/models/Common';
import Courses from '~/models/Courses';
import ProfessorService from '~/services/ProfessorService';
import { ComplementaryText } from '~/styles/objects/complementary-text';
import errorHandlerToToast from '~/utils/errorHandler';
import { currencyMask } from '~/utils/inputMaskUtils';
import Price from '~/utils/Price';

import {
  Camera,
  Circle,
  CircleDelete,
  CoproducerContent,
  CoproducerData,
  CreateCourse,
  ModalContent,
  PercentageContent,
  PriceBase,
  TotalPercentage,
} from './styles';
import SearchWithSuggestions from './searchInput';

interface NewCourseProps extends ModalLike {
  course?: Courses;
  setNeedUpdate?: (arg: boolean) => void;
}

type UploadMedia = { video: File } | { image: File };

interface NewCouseFormRequest {
  title: string;
  price: string;
  amountReceivable: string;
  description?: string;
  status: '0' | '1';
  link?: string;
  payment_type: string;
}

type NewCourseRequest = Omit<
  NewCouseFormRequest,
  'price' | 'amountReceivable'
> &
  Partial<UploadMedia> & {
    price: number;
    original_price: number;
  };

const BASE_PRICE = Price.COURSE_BASE_PRICE;

const NewCourse: React.FC<NewCourseProps> = ({
  modalIsOpen,
  setModalIsOpen,
  course,
  setNeedUpdate,
}) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { setLoading, setPecent } = useLoading();

  const [uploadMedia, setMedia] = useState<MediaProps>();
  const [courseStatus, setCourseStatus] = useState<string>('');
  const [coursePrice, setCoursePrice] = useState<string>();
  const [coursePaymentType, setCoursePaymentType] = useState<string>('charge');
  const [amountReceivable, setAmountReceivable] = useState<Price>();
  const [modalRedirectVisible, setModalRedirectVisible] = useState(false);
  const [showLinkInput, setShowLinkInput] = useState(false);

  const [options, setOptions] = useState([
    { id: 1, label: 'Option 1', percentage: 0 },
    { id: 2, label: 'Option 2', percentage: 0 },
    { id: 3, label: 'Option 3', percentage: 0 },
  ]);

  const [selectedOptions, setSelectedOptions] = useState<
    { id: number; label: string; percentage: number }[]
  >([{ id: 0, label: 'Você (produtor)', percentage: 100 }]);

  const handleSelectOption = (selectedOption: {
    id: number;
    label: string;
    percentage: number;
  }): void => {
    setSelectedOptions(prev => [...prev, selectedOption]);

    const updatedOptions = options.filter(
      option => option.id !== selectedOption.id,
    );
    setOptions(updatedOptions);
  };

  const handlePercentageChange = (index: number, value: number): void => {
    // Verifica se o novo valor não excede 100 quando somado aos outros
    const currentTotal = selectedOptions.reduce(
      (total, option) => total + option.percentage,
      0,
    );

    const newTotal = currentTotal - selectedOptions[index].percentage + value;

    // Atualiza apenas se o novo total não ultrapassar 100
    if (newTotal <= 100) {
      setSelectedOptions(prev => {
        const newSelectedOptions = [...prev];
        newSelectedOptions[index].percentage = value;
        return newSelectedOptions;
      });
    }
  };

  const incrementPercentage = (index: number): void => {
    setSelectedOptions(prev => {
      const newSelectedOptions = [...prev];
      const currentTotal = newSelectedOptions.reduce(
        (total, option) => total + option.percentage,
        0,
      );
      const incrementedValue = newSelectedOptions[index].percentage + 1;

      if (currentTotal + 1 <= 100) {
        newSelectedOptions[index].percentage = incrementedValue;
      }
      return newSelectedOptions;
    });
  };

  const decrementPercentage = (index: number): void => {
    setSelectedOptions(prev => {
      const newSelectedOptions = [...prev];
      const decrementedValue = newSelectedOptions[index].percentage - 1;

      if (decrementedValue >= 0) {
        newSelectedOptions[index].percentage = decrementedValue;
      }
      return newSelectedOptions;
    });
  };

  const handleRemoveOption = (index: number): void => {
    setSelectedOptions(prev => {
      const newSelectedOptions = [...prev];
      newSelectedOptions.splice(index, 1); // Remove a opção no índice especificado
      return newSelectedOptions;
    });
  };

  const handleSubmit: SubmitHandler<NewCouseFormRequest> = useCallback(
    async ({
      status,
      title,
      description,
      amountReceivable: original_price,
      link,
      payment_type,
    }) => {
      const defaultPrice = new Price({
        basePrice: BASE_PRICE,
      });

      const data: NewCourseRequest = {
        status,
        title,
        description,
        price:
          amountReceivable?.getChargeInFloat() ??
          defaultPrice.getChargeInFloat(),
        original_price: Price.currencyToFloat(original_price),
        link,
        payment_type: coursePaymentType,
      };

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Título obrigatório.'),
          price: Yup.number().required('Valor obrigatório.'),
          status: Yup.boolean()
            .required('Status obrigatório.')
            .transform(x => (x === '' ? undefined : x)),
          description: Yup.string().max(
            255,
            v => `O valor máximo de caracteres é ${v}.`,
          ),
          link: showLinkInput
            ? Yup.string().url().required('Link obrigatório')
            : Yup.string().url().nullable(),
          payment_type: Yup.string().required('Tipo de pagamento obrigatório.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        let convertedData;
        if (!showLinkInput) {
          const media: Partial<UploadMedia> = uploadMedia?.file
            ? { [uploadMedia.type]: uploadMedia.file }
            : {};

          convertedData = serialize<NewCourseRequest>({
            ...data,
            ...media,
          });
        } else {
          convertedData = serialize<NewCourseRequest>({
            ...data,
          });
        }

        setLoading(true);

        const response: any = course
          ? await ProfessorService.updateCourse(
              convertedData,
              course.id,
              setPecent,
            )
          : await ProfessorService.createCourse(convertedData, setPecent);

        setModalIsOpen(false);

        setLoading(false);

        if (response?.response?.status > 300) {
          addToast({
            title: response.response.data.message,
            type: 'error',
          });
          throw new Error(response.response.data.message);
        }

        addToast({
          title: course
            ? 'Curso editado com sucesso'
            : 'Curso criado com sucesso!',
          type: 'success',
        });

        if (setNeedUpdate) setNeedUpdate(true);
      } catch (error: any) {
        setLoading(false);
        const errorToToast = errorHandlerToToast({
          error,
          formRef,
          description: '',
        });
        if (errorToToast) {
          setModalIsOpen(false);
          addToast(errorToToast);
        }
      }
    },
    [
      amountReceivable,
      uploadMedia,
      setLoading,
      course,
      setModalIsOpen,
      addToast,
      setNeedUpdate,
      setPecent,
      showLinkInput,
      coursePaymentType,
    ],
  );

  useEffect(() => {
    if (!modalIsOpen) {
      setMedia(undefined);
    }
  }, [modalIsOpen]);

  useEffect(() => {
    const photo = course?.image;
    if (photo) {
      setMedia({
        blob: photo,
        file: null,
        type: 'image',
      });
    }
    setCourseStatus(course?.status ?? '0');
    setCoursePaymentType(course?.payment_type ?? 'charge');
  }, [course?.image, course?.status, course?.payment_type]);

  const handleAmountReceivableChange = (currency: string): void => {
    const price = Price.fromCurrency({
      price: currency,
      basePrice: BASE_PRICE,
    });
    setAmountReceivable(price);
  };

  useEffect(() => {
    if (course?.price) {
      const priceCouse = new Price({
        price: course.price,
        basePrice: BASE_PRICE,
      });
      setCoursePrice(priceCouse.toCurrencyFormat());

      const priceOriginalInFloat = priceCouse.getPriceOriginalFromCharge();
      const priceOriginal = new Price({
        price: priceOriginalInFloat,
        basePrice: BASE_PRICE,
      });
      setAmountReceivable(priceOriginal);
    }
  }, [course?.price]);

  useEffect(() => {
    if (amountReceivable) {
      const chargeInCurrency = amountReceivable.getChargeInCurrency();
      setCoursePrice(chargeInCurrency);
    }
  }, [amountReceivable]);

  return (
    <>
      <Modal {...{ modalIsOpen, setModalIsOpen }}>
        <ModalContent>
          <>
            <h1>{course ? 'Editar' : 'Novo'} Curso</h1>
            <CreateCourse>
              <Form
                encType="multipart/form-data"
                initialData={{
                  ...course,
                  amountReceivable,
                  price:
                    amountReceivable?.getChargeInFloat() ??
                    (Price.COURSE_BASE_PRICE * (1 + Price.CHARGE_PERCENT / 100))
                      .toFixed(2)
                      .toString()
                      .replace(/\./g, ','),
                }}
                ref={formRef}
                onSubmit={handleSubmit}
              >
                {!showLinkInput && (
                  <>
                    <Camera>
                      <label htmlFor="media">
                        {uploadMedia?.blob && uploadMedia.type === 'image' && (
                          <img
                            src={uploadMedia?.blob}
                            alt={course?.title}
                            title={course?.title}
                          />
                        )}
                        {uploadMedia?.blob && uploadMedia.type === 'video' && (
                          <video
                            controls
                            muted
                            onContextMenu={() => false}
                            controlsList="nodownload"
                            style={{
                              width: '100%',
                              height: '100%',
                              flex: '1 1 100%',
                            }}
                          >
                            <source src={uploadMedia.blob} />
                          </video>
                        )}

                        {!uploadMedia?.blob && (
                          <>
                            <GoFileMedia size={50} />
                            <span>Carregar capa ou</span>
                            <span> vídeo de apresentação</span>
                          </>
                        )}
                        <InputVideos
                          name="media"
                          isCourseVideo
                          formRef={formRef}
                          setFileFunction={(type, newFile) => {
                            setMedia({
                              type,
                              blob: newFile.name,
                              file: newFile.file,
                            });
                          }}
                          showLinkInput={showLinkInput}
                          setShowLinkInput={setShowLinkInput}
                        />
                      </label>
                    </Camera>
                    <p>Dê preferência para imagens horizontais na capa.</p>
                    <ButtonLink setShowLinkInput={setShowLinkInput} />
                  </>
                )}
                {showLinkInput && (
                  <InputLink setShowLinkInput={setShowLinkInput} />
                )}
                <h6>Título</h6>
                <Input name="title" />
                <h6>Descrição</h6>
                <Textarea name="description" />
                <h6>Quanto você irá receber</h6>
                <InputCurrency
                  name="amountReceivable"
                  callbackFunction={handleAmountReceivableChange}
                  defaultValue={
                    amountReceivable
                      ? (
                          amountReceivable.getChargeInFloat() *
                          (1 - Price.CHARGE_PERCENT / 100)
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\./g, ',')
                      : BASE_PRICE.toFixed(2).toString().replace(/\./g, ',')
                  }
                  min={BASE_PRICE}
                />
                <PriceBase>
                  Valor mínimo de {Price.floatToCurrency(BASE_PRICE)}.
                </PriceBase>
                <h6>Quanto será cobrado do seu aluno?</h6>
                <InputMask
                  name="price"
                  mask={currencyMask}
                  maxLength={14}
                  value={coursePrice}
                  disabled
                />
                <ComplementaryText>
                  Acrescentados {Price.CHARGE_PERCENT - 4.5}% da plataforma +
                  4,5% de encargos financeiros + R$: 0.39 centavos de tarifas.
                </ComplementaryText>
                <h6>Adicionar coprodutor</h6>
                <SearchWithSuggestions
                  options={options}
                  onSelectOption={handleSelectOption}
                />
                {selectedOptions.length !== 1 ? (
                  <>
                    <h6> Porcentagem dos coprodutores adicionados (em %)</h6>
                    <>
                      {selectedOptions.map((option, index) => (
                        <CoproducerContent
                          style={{ marginTop: '10px' }}
                          key={option.id}
                        >
                          <>
                            {index !== 0 && (
                              <CircleDelete color="#ef706c">
                                {' '}
                                <FiTrash
                                  onClick={() => handleRemoveOption(index)}
                                />
                              </CircleDelete>
                            )}

                            <h6 style={{ marginLeft: '10px' }}>
                              {option.label}
                            </h6>
                          </>

                          <PercentageContent>
                            <Circle
                              color="#b98e4f"
                              onClick={() => decrementPercentage(index)}
                            >
                              -
                            </Circle>
                            <input
                              maxLength={3}
                              type="number"
                              value={option.percentage}
                              onChange={e =>
                                handlePercentageChange(
                                  index,
                                  Math.max(0, Number(e.target.value)),
                                )
                              }
                            />

                            <Circle
                              color="#606060"
                              onClick={() => incrementPercentage(index)}
                            >
                              +
                            </Circle>
                          </PercentageContent>
                        </CoproducerContent>
                      ))}
                    </>
                    <TotalPercentage>
                      {' '}
                      Total:{' '}
                      {selectedOptions.reduce(
                        (total, option) => total + option.percentage,
                        0,
                      )}
                      %
                    </TotalPercentage>
                  </>
                ) : (
                  <></>
                )}
                <h6>Status:</h6>
                <Radio
                  name="status"
                  currentValue={courseStatus}
                  setValue={setCourseStatus}
                  options={[
                    { id: 'active', label: 'Ativo', value: '1' },
                    { id: 'inactive', label: 'Inativo', value: '0' },
                  ]}
                />

                {!course && (
                  <>
                    <h6> Tipo de pagamento:</h6>
                    <Radio
                      name="payment_type"
                      currentValue={coursePaymentType}
                      setValue={setCoursePaymentType}
                      options={[
                        {
                          id: 'charge',
                          label: 'Cobrança Única',
                          value: 'charge',
                        },
                        {
                          id: 'subscription',
                          label: 'Assinatura',
                          value: 'subscription',
                        },
                      ]}
                    />
                  </>
                )}
                <Button>Salvar</Button>
              </Form>
            </CreateCourse>
          </>
        </ModalContent>
      </Modal>
      <ModalRedirect
        modalIsOpen={modalRedirectVisible}
        setModalIsOpen={setModalRedirectVisible}
      />
    </>
  );
};

export default NewCourse;
