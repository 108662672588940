// //
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { ImageLogo } from '~/assets/icons';
import { Button, Input } from '~/components';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { SubmitHandler } from '~/models/Common';
import { UserCredentials } from '~/models/User';
import errorHandlerToToast from '~/utils/errorHandler';

import { Container, ForgetPasswordLink } from './styles';

import ForgotPasswordModal from './ForgotPasswordModal';

interface SignInRouteData {
  publicname: string;
  courseId: string;
  liveId: string;
  isPlan: boolean;
}

const Signin: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location = useLocation<SignInRouteData>();

  const { addToast } = useToast();
  const { isLoading, setLoading } = useLoading();

  const { signin, user } = useAuth();

  useEffect(() => {
    if (user) {
      const { role } = user;

      if (role === 'student') {
        history.push(`/professional-profiles`);
        return;
      }

      if (role === 'professional') {
        history.push('/professor/my-workouts');
      }
    }
  }, [history, user]);

  const handleSubmit: SubmitHandler<UserCredentials> = useCallback(
    async data => {
      setLoading(!isLoading);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório.')
            .email('E-mail inválido.'),
          password: Yup.string().required('Senha obrigatória.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await signin({
          email: data.email,
          password: data.password,
        });

        if (response?.link) {
          window.open(response.link);
        }

        setLoading(false);

        addToast({
          type: 'success',
          title: 'Usuário logado com sucesso!',
        });
      } catch (error: any) {
        setLoading(false);
        const responseErr = errorHandlerToToast({
          error,
          formRef,
          description: 'Cheque as credenciais.',
        });
        if (responseErr) addToast(responseErr);
      }
    },
    [addToast, isLoading, setLoading, signin],
  );

  const handleOpenModal = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setModalIsOpen(true);
    },
    [],
  );

  return (
    <Container>
      <img src={ImageLogo} alt="" />
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Input name="email" type="email" placeholder="E-mail" />
        <Input name="password" type="password" placeholder="Senha" />
        <ForgetPasswordLink type="button" onClick={handleOpenModal}>
          Esqueci minha senha
        </ForgetPasswordLink>
        <Button type="submit" isOutline={false}>
          Entrar
        </Button>
        <h6>
          Não tem uma conta?{' '}
          <Link to="signup/become-a-professional">Crie aqui.</Link>{' '}
        </h6>
      </Form>
      <ForgotPasswordModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
    </Container>
  );
};

export default Signin;
