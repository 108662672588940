import styled from 'styled-components';

import ButtonBase from '~/components/Button';
import { Container } from '~/styles/objects/container';
import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

export const Button = styled(ButtonBase)`
  margin-top: 30px;
  max-width: 463px;
  max-height: 58px;
  padding: 25px 0;

  @media (max-width: ${breakpoints.small}) {
    max-width: 100%;
  }
`;

export const ConfigurationContainer = styled(Container)`
  padding: var(--gap-lg) 0;
`;

export const Title = styled.h1`
  font-size: 3.6rem;
  letter-spacing: 1.52px;
  color: ${colors.lightGrey};
  text-transform: uppercase;
  margin-bottom: var(--gap-md);

  @media (max-width: ${breakpoints.small}) {
    font-size: 3.2rem;
    text-align: center;
  }
`;

export const Subtext = styled.span`
  font-size: '12px';
  color: 'gray';
  font-weight: 'normal';
`;

export const Data = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--gap-md);
  row-gap: var(--gap-sm);

  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    flex-direction: column;
    row-gap: var(--gap-sm);
  }
`;

export const DataGroupComplemento = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  column-gap: var(--gap-sm);

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    flex-direction: column;
    row-gap: var(--gap-sm);
  }
`;

export const ChangePasswordButton = styled(ButtonBase)`
  border: 2px solid ${colors.primary};
  color: ${colors.primary};
  max-width: 463px;
  text-transform: none;
  margin-top: var(--gap-sm);

  @media (min-width: ${breakpoints.small}) {
    min-width: 463px;
  }

  @media (max-width: ${breakpoints.small}) {
    max-width: 100%;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: ${colors.lightGrey};
  font-size: 2.1rem;
  font-weight: 600;
  width: 100%;

  > div {
    border-color: ${colors.lightGrey};
    color: ${colors.lightGrey};
    padding-top: 10px;
    padding-left: 5px;

    > input,
    > select {
      color: ${colors.lightGrey};
      font-size: 2.1rem;
    }
  }
`;
