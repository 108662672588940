//
import { AxiosPromise } from 'axios';

import { progressConfigUpdateJSON } from '~/utils/handleProgress';

import { PageOptionsRequest, PageOptionsResponse } from '../models/Common';
import { Lives } from '../models/Lives';
import api, { getToken } from './api';

const PER_PAGE_DEFAULT = process.env.REACT_APP_PER_PAGE_DEFAULT ?? 12;

export default {
  postLives(
    professionalId: number,
    data: FormData,
    setPercent: (percent: number) => void,
  ): AxiosPromise {
    return api.post(
      `/professionals/${professionalId}/lives`,
      data,
      progressConfigUpdateJSON(setPercent),
    );
  },
  // stripe - pagamento live
  postAssociateLives(data: {
    studentId: number;
    liveId: string;
    coupon_name?: string;
  }): AxiosPromise {
    return api.post(
      `/students/${data.studentId}/lives/${data.liveId}/associate`,
      {
        headers: null,
        couponCode: data.coupon_name,
      },
    );
  },

  changePaymentMethod(): AxiosPromise<any> {
    return api.get(`/redirect-to-customer-portal`);
  },

  getLives(
    publicname: string,
    isProfessional?: boolean,
    { page, perpage = PER_PAGE_DEFAULT }: PageOptionsRequest = {
      page: 1,
      perpage: PER_PAGE_DEFAULT,
    },
    category?: number,
  ): AxiosPromise<PageOptionsResponse<Lives>> {
    return api.get(
      `professionals/${publicname}/${isProfessional ? 'myLives' : 'lives'}`,
      {
        params: {
          category,
          page,
          perpage,
        },
        cancelToken: getToken().token,
      },
    );
  },

  getProfessionalLives(
    publicname: string,
    category: number,
    { page, perpage = PER_PAGE_DEFAULT }: PageOptionsRequest = {
      page: 1,
      perpage: PER_PAGE_DEFAULT,
    },
  ): AxiosPromise<PageOptionsResponse<Lives>> {
    return this.getLives(
      publicname,
      true,
      {
        page,
        perpage,
      },
      category,
    );
  },

  getStudentLives(
    studentId: number,
    { page, perpage = PER_PAGE_DEFAULT }: PageOptionsRequest = {
      page: 1,
      perpage: PER_PAGE_DEFAULT,
    },
  ): AxiosPromise<PageOptionsResponse<Lives>> {
    return api.get(`students/${studentId}/lives`, {
      params: {
        page,
        perpage,
      },
      cancelToken: getToken().token,
    });
  },

  putUpdateLive(
    where: { professionalId: number; liveId: number },
    data: FormData,
    setPercent: (percent: number) => void,
  ): AxiosPromise {
    return api.put(
      `professionals/${where.professionalId}/lives/${where.liveId}`,
      data,
      progressConfigUpdateJSON(setPercent),
    );
  },

  deleteLive(
    where: { professionalId: number; liveId: number },
    setPercent: (percent: number) => void,
  ): AxiosPromise {
    return api.delete(
      `professionals/${where.professionalId}/lives/${where.liveId}`,
      progressConfigUpdateJSON(setPercent),
    );
  },
};
