import styled, { css, keyframes } from 'styled-components';

import { colors } from '~/styles/settings/colors';

interface CourseProps {
  status: string;
}

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const MediaContainer = styled.div`
  width: 393px;
  height: 212px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Video = styled.video`
  /* width: 300px;
  height: 250px;
  @media (max-width: 768px) {
    height: 320px;
  } */
  width: auto !important;
  height: 100% !important;
`;

export const Image = styled.img`
  /* height: 357px; */
  width: auto !important;
  height: 100% !important;
  max-width: 100%;
  max-height: 100%;
  background-color: white;
`;

export const Container = styled.div`
  padding: 200px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 42px;
    font-weight: bold;

    text-align: center;
    letter-spacing: 8.4px;
    color: #cecece;
    text-transform: uppercase;
  }

  > a {
    max-width: 507px;
    width: 100%;

    button {
      background: #00aa1c 0% 0% no-repeat padding-box;
      margin-top: 50px;
      margin-bottom: 80px;
      max-width: 507px;
      width: 100%;
      border-radius: 60px;
    }
  }

  @media (max-width: 768.9px) {
    padding: 0;
    margin-top: 30px;

    > a {
      max-width: 90%;
    }
  }
`;

export const BoxSlider = styled.div`
  padding: 36px 90px;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px 10px #3e3e3e33;
  border-radius: 8px;
  margin-top: 20px;
  max-width: 95%;
  width: 100%;

  position: relative;
  animation: ${appearFromLeft} 1s;

  .swiper-container,
  .swiper-container-initialized,
  .swiper-container-horizontal {
    position: unset;
  }

  .swiper-button-next {
    right: 0px;
  }

  .swipper-slide {
    height: auto;
  }

  button {
    margin-top: 35px;
  }

  div {
    div {
      ::after {
        color: #000;
        padding: 10px;
      }
    }
  }

  h2,
  h3,
  h6,
  p {
    color: ${colors.black} !important;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 768.9px) {
    /* grid-template-columns: repeat(auto-fit, 310px); */
    padding: 16px;
  }
`;

export const Workout = styled.div`
  max-width: 393px;
  width: 100%;
  min-width: 200px;
  cursor: pointer;
  overflow: hidden;
  transition: background 0.3s;

  button {
    max-width: 393px;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-color: transparent;
  }
  svg {
    position: absolute;
    margin-left: 370px;
    margin-top: 5px;
    border-radius: 50%;
    background: #2c2c2c 0% 0% no-repeat padding-box;
    opacity: 0.67;
    margin-right: 15px;
  }

  div {
    span {
      text-transform: uppercase;
      font-size: 13px;
      color: #2c2c2c;
    }

    h6 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: bold;
    }

    p {
      color: #636363;
      font-size: 16px;
      max-width: 393px;
    }
  }

  @media (max-width: 1679.9px) {
    max-width: 340px;

    /* img {
      max-width: 310px;
    } */
  }

  @media (max-width: 768.9px) {
    max-width: 340px;

    /* img {
      max-width: 290px;
    } */

    div {
      max-width: 90%;
      h6,
      p {
        max-width: 290px;
      }
    }
  }
`;

export const Course = styled.div<CourseProps>`
  max-width: 393px;
  width: 100%;
  border-radius: 3px;
  padding: 16px;
  cursor: pointer;
  transition: background 0.3s;

  img {
    max-width: 393px;
    width: 100%;
    height: 212px;
  }

  span {
    font-size: 13px;
    font-weight: bold;
    color: #009218;
    text-transform: uppercase;
    letter-spacing: 0.52px;

    ${props =>
      props.status === 'Inativo' &&
      css`
        color: #b10000;
      `}
  }

  > h1 {
    letter-spacing: 0.8px;
    color: #2c2c2c;
    font-size: 20px;
    margin-bottom: 5px;
    text-align: left;
  }

  h6 {
    color: #8b8b8b;
    font-size: 16px;
    font-weight: 400;
  }

  @media (max-width: 1679.9px) {
    max-width: 340px;

    img {
      max-width: 310px;
    }
  }

  @media (max-width: 768.9px) {
    max-width: 340px;

    img {
      max-width: 290px;
    }

    div {
      max-width: 90%;
      h6,
      p {
        max-width: 290px;
      }
    }
  }
`;

export const Live = styled.article`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  min-height: 250px;
  height: 100%;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

export const LiveStatus = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  color: #2c2c2c;

  > strong {
    font-size: 16px;
    letter-spacing: 0.52px;
    color: ${colors.primary};
  }
`;

export const LiveTitle = styled.h3`
  color: ${colors.black};
  font-size: 24px;
  font-weight: bold;
`;

export const LiveDescription = styled.p`
  color: ${colors.gray};
  font-size: 16px;
  line-height: 1.5em;
  max-width: 393px;
`;

export const LiveFooter = styled.footer`
  /* position: absolute;
  bottom: 0; */
  width: 100%;
`;

export const LivePrice = styled.strong`
  display: inline-block;
  margin-bottom: var(--gap-xs);
  font-size: 2.4rem;
`;
