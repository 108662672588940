import { ButtonBlackColor } from '~/models/Common';

interface handleStateArg {
  hasValue?: boolean;
  error: boolean;
}

export default function handleState({
  hasValue,
  error,
}: handleStateArg): ButtonBlackColor {
  if (hasValue) {
    return ButtonBlackColor.SUCESS;
  }
  if (error) {
    return ButtonBlackColor.ERROR;
  }

  return ButtonBlackColor.NORMAL;
}
