/* eslint-disable no-warning-comments */
/* eslint-disable @typescript-eslint/no-explicit-any */
import './embla.css';

import React, { useCallback, useEffect, useState } from 'react';

import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';

import { ImageLives, ImageDance } from '~/assets/images';
import { Workouts } from '~/models/Workouts';
import ViewWorkout from '~/pages/Student/Workouts/ViewWorkout';

import { Thumb } from './thumb';
// import imageByIndex from './imageByIndex';

type PropType = {
  data: Data[];
  options?: EmblaOptionsType;
};

interface Data {
  id?: number;
  image?: string;
  url?: string;
  price?: string;
  description?: string;
  category?: {
    description: string;
  };
  title?: string;
}

export const Carousel: React.FC<PropType> = props => {
  const { options, data } = props;
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options);
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
  });

  const [workout, setWorkout] = useState<Workouts>({} as Workouts);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  function handModal(value: any): void {
    setWorkout(value);
    setModalIsOpen(true);
  }

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi]);

  useEffect(() => {
    if (!emblaMainApi) return;
    emblaMainApi.on('reInit', onSelect);
  }, [emblaMainApi, onSelect]);

  return (
    <>
      <div className="embla">
        <div className="embla-thumbs">
          <div className="embla-thumbs__viewport" ref={emblaThumbsRef}>
            <div className="embla-thumbs__container">
              {data.map((item, index) => {
                const defaultImage = item?.url ? ImageLives : ImageDance;

                return (
                  <Thumb
                    index={index}
                    imgSrc={item.image ?? defaultImage}
                    key={item.id}
                    title={item.title}
                    category={item.category?.description}
                    url={item.url}
                    price={item?.price}
                    onClick={() => {
                      handModal({ ...item, image: item.image ?? defaultImage });
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <ViewWorkout
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        workout={workout}
      />
    </>
  );
};
