export const colors = {
  primary: `#b88746`,
  secondary: `#57575729`,
  terciary: `#fdf5a6`,
  green: `#00aa1c`,
  red: `#C53030`,
  gray: `#575757`,
  black: `#2c2c2c`,
  lightGrey: `#cecece`,
  lightnerGrey: `#f6f6f6`,
  modalBackground: `#3b3b3b`,
};
