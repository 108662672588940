/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FiChevronLeft } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';

import { ImageDance } from '~/assets/images';
import { Button, HeaderMySite, Paginate } from '~/components';
import { useProfessor } from '~/hooks/professor';
import { useToast } from '~/hooks/Toast';
import { PageOptionsRequest, PageOptionsResponse } from '~/models/Common';
import CourseInterface from '~/models/Courses';
import ProfessorService from '~/services/ProfessorService';
import getMimeTypeByExtVideo from '~/utils/getMimeType';
import Price from '~/utils/Price';

import {
  Container,
  Content,
  Course,
  Image,
  MediaWrapper,
  Video,
} from './styles';

import ViewCourse from './ViewCourse';

interface routeParams {
  publicname: string;
}

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<PageOptionsResponse<CourseInterface>>({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: 0,
  });
  const [selectedCourse, setSelectedCourse] = useState<CourseInterface>();
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const history = useHistory();
  const { publicname } = useParams() as routeParams;
  const { professor, addProfessor } = useProfessor();
  const { addToast } = useToast();

  useEffect(() => {
    const getProfessional = async (): Promise<void> => {
      try {
        const response = await ProfessorService.getProfessionalByPublicname(
          publicname,
          'courses',
        );
        addProfessor(response.data);
      } catch (err) {
        if (err instanceof Error) {
          addToast({
            type: 'error',
            title: err.message,
          });
        }
      }
    };
    if (!professor?.public_name) {
      if (!professor) getProfessional();
    }
  }, [addProfessor, addToast, professor, publicname]);

  const getCourses = useCallback(
    async (public_name: string): Promise<void> => {
      const response = await ProfessorService.getCourses(
        public_name,
        pageOptions,
      );
      setCourses(response.data);
    },
    [pageOptions],
  );

  useEffect(() => {
    if (publicname) {
      getCourses(publicname);
    }
  }, [getCourses, publicname]);

  const handleOpenModal = useCallback(
    course => {
      setModalIsOpen(!modalIsOpen);
      setSelectedCourse(course);
    },
    [modalIsOpen],
  );

  const coursesMemo = useMemo(() => {
    return courses.data
      .filter(course => Number(course.status) === 1)
      ?.map(course => {
        return (
          <Course key={course.id} status="Ativo">
            <div onClick={() => handleOpenModal(course)}>
              {course.video ? (
                <MediaWrapper>
                  <Video
                    onContextMenu={() => false}
                    controlsList="nodownload"
                    loop
                    controls
                    autoPlay
                    muted
                  >
                    <source
                      src={course.video}
                      type={getMimeTypeByExtVideo(
                        course.video.split('.').pop() as string,
                      )}
                    />
                  </Video>
                </MediaWrapper>
              ) : (
                <MediaWrapper>
                  <Image
                    src={course?.foto_path ?? ImageDance}
                    alt={course?.title}
                    title={course?.title}
                  />
                </MediaWrapper>
              )}

              <span>Ativo</span>
              <h1>{course.title}</h1>
              <h3>
                Valor: <strong>{Price.floatToCurrency(course.price)}</strong>
              </h3>
              <h3>
                Tipo de pagamento:{' '}
                {course.payment_type === 'charge'
                  ? 'Cobrança Única'
                  : 'Assinatura'}
              </h3>

              {/* <h6>{course.students} Alunos</h6> */}
              <Link to={`/${publicname}/signup?course=${course?.id}`}>
                <Button style={{ backgroundColor: '#57AB21' }}>
                  Selecionar curso
                </Button>
              </Link>
            </div>
          </Course>
        );
      });
  }, [courses, handleOpenModal, publicname]);

  return (
    <>
      {professor && <HeaderMySite professional={professor} />}
      <Container>
        <h1>
          <FiChevronLeft onClick={() => history.push(`/${publicname}`)} />
          Módulos e Cursos
        </h1>
        <Content>{coursesMemo}</Content>
        <Paginate
          pageRangeDisplayed={pageOptions?.page ?? 1}
          pageCount={courses.lastPage}
          {...{ setPageOptions }}
        />
      </Container>
      <ViewCourse
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        course={selectedCourse}
        publicname={publicname}
      />
    </>
  );
};

export default Courses;
