import styled from 'styled-components';

import { breakpoints } from '~/styles/tools/breakpoints';

export const MenuMobileToggle = styled.span`
  display: none;

  > span {
    display: none;
  }

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    animation: fade-down 0.5s forwards;

    > span {
      display: flex;
      flex-direction: column;
      box-sizing: initial;
      transition: 0.3s;
      height: 30px;
      cursor: pointer;

      &:after,
      &:before {
        content: '';
        display: block;
        width: 40px;
        border: 2px solid white;
        transition: 0.3s;
      }

      &:after {
        margin-top: var(--gap);
        box-shadow: 0 12px 0 0 white;
      }
    }

    &.active > span {
      &:after {
        box-shadow: none;
        transform: rotate(45deg) translate(-3px, -5.25px);
        transition: 0.3s transform;
        margin-top: 3px;
      }

      &:before {
        transform: rotate(-45deg) translate(0, 0);
        transition: 0.3s transform;
        margin-top: 12px;
      }
    }
  }
`;
