export const removeMark = (s: string): string => s.replaceAll(/\D/g, '');

export const removeDiacrit = (s: string): string => {
  const normalize = s.normalize('NFD');
  const withoutDiacritcs = normalize.replaceAll(/[\u0300-\u036f]/g, '');
  return withoutDiacritcs;
};

export const cast = (value: string): undefined | string =>
  Number.isNaN(value) ? undefined : value;

export const adjustSize = (value: string, size: number): string => {
  const newValue = value.padStart(size, '0');
  return newValue;
};

export const regExpPublicName = /^[a-zA-Z][\w\d&]*(\s[\w\d&]+)*$/g;
