import styled, { css } from 'styled-components';

import { breakpoints } from '../tools/breakpoints';

// items = workouts, courses, lives, etc.
export const ItemsGrid = styled.section<{ withCategories?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, 390px);
  gap: var(--gap-sm);
  justify-content: flex-start;

  ${props =>
    props.withCategories
      ? css`
          padding: 0 0 var(--gap-lg) 0;

          @media (min-width: ${breakpoints.large}) {
            padding: 0 0 var(--gap-xl) 0;
          }
        `
      : css`
          padding: var(--gap-lg) 0;

          @media (min-width: ${breakpoints.large}) {
            padding: var(--gap-xl) 0;
          }
        `}

  @media (max-width: 1352px) {
    justify-content: center;
  }

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
`;
