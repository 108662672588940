import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const Video = styled.video`
  width: 300px;
  height: 250px;
  @media (max-width: 768px) {
    height: 320px;
  }
`;

export const Image = styled.img`
  height: 357px;
`;

export const Content = styled.div`
  max-width: 797px;
  width: 100%;
  margin-left: 40px;
  display: flex;

  h6 {
    letter-spacing: 0.68px;
    color: #2c2c2c;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 400;
  }

  h6 {
    text-transform: uppercase;
    font-size: 42px;
    font-weight: 700;
    color: #b88746;
  }

  h3 {
    letter-spacing: 1.36px;
    color: color: ${colors.lightGrey};;
    font-size: 34px;
    margin-top: 5px;
  }

  p {
    color: #636363;
    font-size: 20px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 15px;
  }

  > div {
    margin-top: 50px;
    max-width: 280px !important;
    img {
      max-width: 280px;
      max-height: 280px;
      width: 100%;
      height: 100%;
    }
  }

  div {
    & + div {
      flex: 1;
      max-width: 100% !important;
      display: flex;
      flex-direction: column;
      margin-left: 40px;
    }
  }

  @media (max-width: 768.9px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px !important;
    h3 {
      font-size: 26px;
    }

    h6 {
      font-size: 32px;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3,
      p {
        text-align: center;
      }
      & + div {
        margin-left: 0px !important;
      }
    }
  }
`;

export const LiveContent = styled.article`
  width: 85%;

  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const LiveContentStatus = styled.span`
  font-size: 12px;
  line-height: 1.1em;
  color: ${colors.lightnerGrey};

  > strong {
    font-size: 16px;
    line-height: 1.1em;
    color: #b88746;
  }
`;

export const LiveContentTitle = styled.h3`
  letter-spacing: 1.36px;
  color: ${colors.lightnerGrey};
  font-size: 24px;
  line-height: 1.1em;
`;

export const LiveContentDescription = styled.p`
  color: ${colors.lightGrey};
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 16px;
`;

export const LiveContentPrice = styled.span`
  font-size: 1.6rem;

  > strong {
    font-size: 2rem;
  }
`;

export const LiveContentButton = styled(Link)`
  > button {
    background: ${colors.green} 0% 0% no-repeat padding-box;
  }
`;
