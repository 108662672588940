/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// // //
import { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ImageLogo } from '~/assets/icons';
import { Button, Input, InputMask, Select } from '~/components';
import PrivacyPolicy from '~/components/PrivacyAndTerms/PrivacyPolicy';
import UseTerms from '~/components/PrivacyAndTerms/UseTerms';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useStudent } from '~/hooks/Student';
import { useToast } from '~/hooks/Toast';
import {
  RegisterProfessionalRequestForm,
  SubmitHandler,
} from '~/models/Common';
import ProfessorService from '~/services/ProfessorService';
import UserService from '~/services/UserService';
import errorHandlerToToast from '~/utils/errorHandler';
import getValidationErrors from '~/utils/getValidationErrors';
import { useQuery } from '~/utils/query';
import { cast, removeMark } from '~/utils/yupHelpers';

import { Container } from './styles';

interface routeParams {
  publicname: string;
}

const Signup: React.FC = () => {
  const locationState = useLocation().state;
  const [occupation, setoccupation] = useState('0');

  const courseId = useQuery().get('course');
  const isPlan = useQuery().get('plan') === 'true';
  const liveId = useQuery().get('live');

  const formRef = useRef<FormHandles>(null);
  const { publicname } = useParams() as routeParams;

  const { addToast } = useToast();
  const { addStudent } = useStudent();
  const { signInFromSignUp, user } = useAuth();

  const [modalPrivacyIsOpen, setModalPrivacyIsOpen] = useState(false);
  const [modalUseTermsIsOpen, setModalUseTermsIsOpen] = useState(false);

  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );

  const { setLoading } = useLoading();

  const history = useHistory();

  const currentUrl = history.location.pathname;

  const baseUrl = `/${publicname}/payment?`;

  if (user && user.role === 'student') {
    if (courseId) {
      history.push(`${baseUrl}course=${courseId}`);
    } else if (isPlan) {
      history.push(`${baseUrl}plan=true`);
    } else if (liveId) {
      history.push(`${baseUrl}live=${liveId}`);
    }
  }

  const onchangeOccupation = (value: string): void => {
    setoccupation(value);
  };

  const handleOpenModalPrivacy = useCallback(() => {
    setModalPrivacyIsOpen(state => !state);
  }, []);

  const handleOpenModalUseTerms = useCallback(() => {
    setModalUseTermsIsOpen(state => !state);
  }, []);

  const handleSubmit: SubmitHandler<RegisterProfessionalRequestForm> = useCallback(
    async ({ cnpj, cpf, ...rest }) => {
      const data: RegisterProfessionalRequestForm = {
        cnpj: removeMark(cnpj),
        cpf: removeMark(cpf),
        ...rest,
      };

      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          cref: Yup.string().required('CREF obrigatório.'),
          name: Yup.string()
            .required('Nome obrigatório.')
            .matches(/^[A-zÀ-ú\s]+$/, 'Apenas caracteres alfabéticos!'),
          cnpj: Yup.number()
            .integer()
            .positive()
            .validCNPJ('É preciso um CNPJ válido')
            .required('CNPJ obrigatório.')
            .transform(cast),
          cpf: Yup.number()
            .integer()
            .positive()
            .validCPF('É preciso um CPF válido')
            .required('CPF obrigatório.')
            .transform(cast),
          email: Yup.string()
            .required('E-mail obrigatório.')
            .email('E-mail inválido.'),
          password: Yup.string().min(6, 'Minímo 6 carácteres.'),
          confirm: Yup.string()
            .required('Confirmar obrigatório.')
            .equals([data.password], 'Senha precisa ser igual.'),
          role: Yup.string().equals(['professional']),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        // colocar o comentario para desativar: salvar o cpf e email no banco do cadastro do professor
        const validateResponse = await UserService.validate({
          cpf: data.cpf,
          email: data.email,
        });

        if ('isAxiosError' in validateResponse) {
          const validData = validateResponse.response?.data;
          if (validData) {
            const onlyUniqueError = validData.filter(
              ({ validation }) => validation === 'unique',
            );
            const validationErrors: Yup.ValidationError[] = onlyUniqueError?.map(
              ({ message, field }) => {
                return new Yup.ValidationError(message, data[field], field);
              },
            );

            throw new Yup.ValidationError(validationErrors);
          }

          /** condicao de guarda */
          throw validateResponse;
        }

        const validateResponseProfCnpj = await ProfessorService.validate({
          cnpj: data.cnpj,
        });
        if ('isAxiosError' in validateResponseProfCnpj) {
          const validDataCnpj = validateResponseProfCnpj.response?.data;
          if (validDataCnpj) {
            const onlyUniqueErrorCnpj = validDataCnpj.filter(
              ({ validation }) => validation === 'unique',
            );
            const validationErrorsCnpj: Yup.ValidationError[] = onlyUniqueErrorCnpj?.map(
              ({ message, field }) => {
                return new Yup.ValidationError(message, data[field], field);
              },
            );

            throw new Yup.ValidationError(validationErrorsCnpj);
          }

          /** condicao de guarda */
          throw validateResponseProfCnpj;
        }

        addToast({
          type: 'info',
          title: 'Prossiga com o cadastro.',
        });
        setLoading(false);
        history.push('/page-customize', {
          data,
          plan: locationState,
          professionId: occupation.toString(),
        });
      } catch (error: any) {
        setLoading(false);
        const responseErr = errorHandlerToToast({ error, formRef });
        if (responseErr) addToast(responseErr);

        const errors = getValidationErrors(error);

        Object.values(errors).forEach((value: string) => {
          addToast({
            title: 'Verifique os dados inseridos',
            description: value,
            type: 'error',
          });
        });
      }
    },
    [addToast, history, setLoading, occupation],
  );

  const handleGetProfessions = async (): Promise<void> => {
    try {
      const response = await ProfessorService.getProfessions();
      setOptions(
        response.data?.map(item => {
          return {
            value: item.id.toString(),
            label: item.occupation,
          };
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetProfessions();
  }, []);

  const handleSubmitStudent: SubmitHandler<RegisterProfessionalRequestForm> = useCallback(
    async ({ cpf, ...rest }) => {
      const data: RegisterProfessionalRequestForm = {
        cpf: removeMark(cpf),
        ...rest,
      };

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório.'),
          cpf: Yup.number()
            .integer()
            .positive()
            .validCPF('É preciso um CPF válido')
            .required('CPF obrigatório.')
            .transform(cast),
          email: Yup.string()
            .required('E-mail obrigatório.')
            .email('E-mail inválido.'),
          password: Yup.string().min(6, 'Minímo 6 carácteres.'),
          confirm: Yup.string()
            .required('Confirmar obrigatório.')
            .matches(new RegExp(data.password), 'Senha precisa ser igual.'),
          role: Yup.string().equals(['student']),
        });

        setLoading(true);

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await UserService.signup(data);

        if (!response.data) {
          const responseObject: any = { ...response };
          setLoading(false);
          if (responseObject.response.status >= 400) {
            throw new Error(responseObject.response.data.message);
          }
        } else {
          const {
            data: { message, ...dataFromSignUp },
          } = response;

          if (dataFromSignUp.user.role === 'student') {
            addStudent(dataFromSignUp.user);
          }

          addToast({
            type: 'success',
            title: message,
            description: 'Prossiga com o cadastro',
          });

          signInFromSignUp(dataFromSignUp);

          setLoading(false);

          if (courseId) {
            history.push(`/${publicname}/signup-payments?course=${courseId}`);
          }

          if (isPlan) {
            history.push(`/${publicname}/signup-payments?plan=true`);
          }

          if (liveId) {
            history.push(`/${publicname}/signup-payments?live=${liveId}`);
          }
        }
      } catch (error: any) {
        setLoading(false);

        const responseErr = errorHandlerToToast({
          error,
          formRef,
          description: 'Cheque se as credenciais foram inseridas corretamente.',
        });
        if (responseErr) addToast(responseErr);
      }
    },
    [
      addStudent,
      addToast,
      courseId,
      history,
      isPlan,
      liveId,
      publicname,
      occupation,
      setLoading,
      signInFromSignUp,
    ],
  );

  return (
    <Container>
      <img src={ImageLogo} alt="" />
      {currentUrl === '/signup' && (
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input name="role" type="hidden" value="professional" />
          <Input name="cref" placeholder="Registro profissional" />
          <Input name="name" placeholder="Nome" />
          <Select
            name="professionId"
            options={options}
            value={occupation}
            setValue={setoccupation}
          />
          <InputMask
            mask=" 99. 999. 999/9999-99"
            name="cnpj"
            placeholder="CNPJ"
          />
          <InputMask mask="999.999.999-99" name="cpf" placeholder="CPF" />
          <Input name="email" type="email" placeholder="E-mail" />
          <Input name="password" type="password" placeholder="Senha" />
          <Input name="confirm" type="password" placeholder="Confirmar senha" />
          <p>
            Ao criar uma conta, você concorda com nossos{' '}
            <strong onClick={handleOpenModalUseTerms}>Termos de Uso</strong> e{' '}
            <strong onClick={handleOpenModalPrivacy}>
              Política de Privacidade
            </strong>
            .
          </p>
          <Button isOutline={false}>Continuar</Button>
          <h6>
            Já tem uma conta? <Link to="signin">Entre aqui.</Link>{' '}
          </h6>
        </Form>
      )}
      {currentUrl === `/${publicname}/signup` && (
        <Form onSubmit={handleSubmitStudent} ref={formRef}>
          <Input name="role" type="hidden" value="student" />
          <Input name="name" placeholder="Nome" />
          <InputMask mask="999.999.999-99" name="cpf" placeholder="CPF" />
          <Input name="email" type="email" placeholder="E-mail" />
          <Input name="password" type="password" placeholder="Senha" />
          <Input name="confirm" type="password" placeholder="Confirmar senha" />
          <p>
            Ao criar uma conta, você concorda com nossos{' '}
            <strong onClick={handleOpenModalUseTerms}>Termos de Uso</strong> e{' '}
            <strong onClick={handleOpenModalPrivacy}>
              Política de Privacidade
            </strong>
            .
          </p>
          <Button type="submit" isOutline={false}>
            Continuar cadastro
          </Button>
          <h6>
            Já tem uma conta?{' '}
            <Link
              to={{
                pathname: '/signin',
                state: { publicname, isPlan, courseId, liveId },
              }}
            >
              Entre aqui.
            </Link>{' '}
          </h6>
        </Form>
      )}
      <UseTerms
        modalIsOpen={modalUseTermsIsOpen}
        setModalIsOpen={setModalUseTermsIsOpen}
      />
      <PrivacyPolicy
        modalIsOpen={modalPrivacyIsOpen}
        setModalIsOpen={setModalPrivacyIsOpen}
      />
    </Container>
  );
};

export default Signup;
