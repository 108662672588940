import React, { createContext, useContext, useState } from 'react';

import { UpdateProfessionalResponse as Professional } from '../models/Common';

interface ProfessorContextData {
  addProfessor(professor: Professional): void;
  professor?: Professional;
}

const ProfessorContext = createContext<ProfessorContextData>({
  addProfessor: professor => {
    throw new Error('Add professor is not loaded');
  },
});

const ProfessorProvider: React.FC = ({ children }) => {
  const [professor, setProfessor] = useState<Professional>();

  const addProfessor = (professorData: Professional): void => {
    setProfessor(professorData);
  };

  return (
    <ProfessorContext.Provider value={{ addProfessor, professor }}>
      {children}
    </ProfessorContext.Provider>
  );
};

function useProfessor(): ProfessorContextData {
  const context = useContext(ProfessorContext);

  if (!context) {
    throw new Error('useProfessor must be used within a ProfessorProvider');
  }

  return context;
}

export { ProfessorProvider, useProfessor };
