import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button } from '../../pages/Common/Configuration/styles';
import { colors } from '../../styles/settings/colors';
import { breakpoints } from '../../styles/tools/breakpoints';

interface ButtonProps {
  active?: boolean;
}

export const CategoriesWrapper = styled.section`
  display: flex;
  gap: var(--gap-sm);
  margin-top: var(--gap-lg);
  margin-bottom: var(--gap-md);
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const CategoriesItem = styled(Link)`
  @media (max-width: ${breakpoints.small}) {
    width: 100%;
    max-width: 302px;
  }
`;

export const CategoriesItemButton = styled(Button)<ButtonProps>`
  border: 2px solid ${colors.primary};
  color: ${colors.primary};
  min-width: 242px;
  max-width: 242px;
  height: 42px;
  padding: 0;
  text-transform: capitalize;
  margin-top: 0;

  ${({ active }) =>
    active &&
    css`
      border: 2px solid ${colors.black} !important;
    `}

  ${({ active }) =>
    !active &&
    css`
      transition: 0.3s;

      &:hover {
        transition: 0.3s;
        background: ${colors.terciary};
      }
    `}

  @media (max-width: ${breakpoints.large}) {
    min-width: 200px;
  }
  @media (max-width: 1200px) {
    min-width: 160px;
  }
  @media (max-width: ${breakpoints.medium}) {
    min-width: 130px;
  }
  @media (max-width: ${breakpoints.small}) {
    max-width: 100%;
  }
`;
