/* eslint-disable no-warning-comments */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { Category, LivePrice, SlideWrapper, Title } from './styles';

type PropType = {
  imgSrc: string;
  title?: string;
  category?: string;
  price?: string;
  index: number;
  url?: string;
  onClick: () => void;
};

export const Thumb: React.FC<PropType> = props => {
  const { imgSrc, onClick, title, category, price } = props;

  return (
    <SlideWrapper className="embla-thumbs__slide" onClick={onClick}>
      <img
        className="embla-thumbs__slide__img"
        src={imgSrc}
        alt="Your alt text"
      />
      <Category>{category}</Category>
      <Title title={title}>{title}</Title>
      <LivePrice>
        {price
          ? new Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }).format(parseFloat(price))
          : ''}
      </LivePrice>
    </SlideWrapper>
  );
};
