import React, { createContext, useContext, useState } from 'react';

import { ProfessionalProfile } from '~/services/StudentService';

import { UserBase, UserStudent } from '../models/User';

interface StudentContextData {
  addStudent(student: UserStudent & UserBase): void;
  updateSelectedProfessional: (professional: ProfessionalProfile) => void;
  student: UserStudent & UserBase;
  selectedProfessional: ProfessionalProfile;
}

const StudentContext = createContext<StudentContextData>(
  {} as StudentContextData,
);

const prefix = '@SeuCoach';

const StudentProvider: React.FC = ({ children }) => {
  const [student, setStudent] = useState<UserStudent & UserBase>(
    {} as UserStudent & UserBase,
  );
  const [
    selectedProfessional,
    setSelectedProfessional,
  ] = useState<ProfessionalProfile>(() => {
    const data = localStorage.getItem(`${prefix}:selectedProfessional`);

    const professional = data ? JSON.parse(data) : ({} as ProfessionalProfile);

    return professional;
  });

  const addStudent = (studentData: UserStudent & UserBase): void => {
    setStudent(studentData);
  };

  const updateSelectedProfessional = (
    professional: ProfessionalProfile,
  ): void => {
    localStorage.setItem(
      `${prefix}:selectedProfessional`,
      JSON.stringify(professional),
    );
    setSelectedProfessional(professional);
  };

  return (
    <StudentContext.Provider
      value={{
        addStudent,
        updateSelectedProfessional,
        student,
        selectedProfessional,
      }}
    >
      {children}
    </StudentContext.Provider>
  );
};

function useStudent(): StudentContextData {
  const context = useContext(StudentContext);

  if (!context) {
    throw new Error('useStudent must be used within a StudentProvider');
  }

  return context;
}

export { StudentProvider, useStudent };
