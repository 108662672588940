import styled, { keyframes } from 'styled-components';

import { colors } from '~/styles/settings/colors';

const moveToDown = keyframes`
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

export const TableEl = styled.table`
  border-collapse: collapse;
  width: 100%;
  animation: ${moveToDown} 500ms;
`;

export const TableHeader = styled.thead`
  width: 100%;
  background-color: #b88746;
  border-radius: 5px 5px 0 0;
  color: white;
`;

export const TableHeaderCell = styled.th`
  padding: 1rem 2rem;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`;

export const TableHeaderCellContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const TableHeaderButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableHeaderButton = styled.button`
  width: 20px !important;
  height: 20px !important;
  max-width: 20px !important;
  max-height: 20px !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent;

  &:hover {
    background-color: #00000023;
  }
`;

export const TableBody = styled.tbody`
  tr:hover {
    background-color: #fff0dc;
  }

  tr {
    cursor: pointer;
    width: 100%;
  }
`;

export const TableRow = styled.tr`
  & + & {
    border-top: 1px solid #dcdcdc;
  }
`;

export const TableCell = styled.td`
  padding: 1rem;
  text-align: center;
`;

export const Wrapper = styled.div`
  overflow: auto;
  margin-top: 24px;
`;

export const TableFooter = styled.footer`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const PaginateWrapper = styled.div`
  margin-left: auto;
  width: min-content;

  .pagination {
    border: 1px solid #dcdcdc;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-around;
    color: ${colors.lightGrey};
  }

  .paginationLink,
  .paginationPrevious {
    border-right: 1px solid #dcdcdc;
  }

  .paginationLinkDisabled.paginationPrevious {
    display: none;
  }

  .paginationLink {
    border-left: 1px solid #dcdcdc;
  }

  .paginationItem {
    padding: 1rem;
  }

  .paginationItem + .paginationItem {
    border-left: none;
  }

  .paginationLinkDisabled {
    color: #9e9e9e;
    cursor: not-allowed;
  }

  .paginationLinkActive {
    color: #b88746;
  }
`;
