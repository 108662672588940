import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const RadioGroup = styled.div`
  display: flex;
  position: relative;
  max-width: 100px;

  label {
    color: ${colors.lightGrey};
    display: flex;
    align-items: center;
    font-size: 19px;
    margin: 10px 20px 20px 0;
    font-weight: 600;

    input {
      margin-right: 10px;
    }
  }
`;

export const InputRadio = styled.input`
  &[type='radio'] {
    position: relative;
    width: 26px;
    height: 26px;
    color: #000;
    &:checked:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: ${colors.primary} 0% 0% no-repeat padding-box;
      border-radius: 100%;
      left: 0;
      top: 0;
    }
  }
`;
