/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FiChevronLeft } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';

import { HeaderMySite, Paginate } from '~/components';
import { useProfessor } from '~/hooks/professor';
import { useToast } from '~/hooks/Toast';
import { PageOptionsRequest, PageOptionsResponse } from '~/models/Common';
import { Workouts as WorkoutsInterface } from '~/models/Workouts';
import ProfessorService from '~/services/ProfessorService';
import errorHandlerToToast from '~/utils/errorHandler';

import { Container, Content, Workout } from './styles';

import ViewWorkout from './ViewWorkout';

/** @todo verificar workout */

interface routeParams {
  publicname: string;
}

const Workouts: React.FC = () => {
  const [workouts, setWorkouts] = useState<
    PageOptionsResponse<WorkoutsInterface>
  >({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: 0,
  });
  const [selectedworkout, setSelectedWorkout] = useState<WorkoutsInterface>();
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const history = useHistory();
  const { publicname } = useParams<routeParams>();
  const { professor, addProfessor } = useProfessor();
  const { addToast } = useToast();

  const getProfessional = useCallback(async () => {
    try {
      const response = await ProfessorService.getProfessionalByPublicname(
        publicname,
        'workouts',
      );
      addProfessor(response.data);
    } catch (err) {
      const errToToast = errorHandlerToToast({ error: err });
      if (errToToast) addToast(errToToast);
    }
  }, [addProfessor, addToast, publicname]);

  const getWorkouts = useCallback(
    async (idProfessional: number): Promise<void> => {
      const response = await ProfessorService.getProfessionalWorkouts(
        idProfessional,
        pageOptions,
      );
      setWorkouts(response.data);
    },
    [pageOptions],
  );

  useEffect(() => {
    if (!professor?.public_name) {
      getProfessional();
    }
    if (professor?.public_name) {
      getWorkouts(professor.id);
    }
  }, [addProfessor, getProfessional, getWorkouts, professor, publicname]);

  const handleOpenModal = useCallback(
    workout => {
      setModalIsOpen(!modalIsOpen);
      setSelectedWorkout(workout);
    },
    [modalIsOpen],
  );

  const workoutsMemo = useMemo(
    () =>
      workouts.data?.map(workout => (
        <Workout key={workout.id}>
          <button type="button" onClick={() => handleOpenModal(workout)}>
            <img src={workout.image} alt="Capa da aula" />
          </button>
          <div onClick={() => handleOpenModal(workout)}>
            <span>{workout.category.description}</span>
            <h6>{workout.title}</h6>
            <p>{workout.description}</p>
          </div>
        </Workout>
      )),
    [handleOpenModal, workouts.data],
  );

  return professor ? (
    <>
      <HeaderMySite professional={professor} />
      <Container>
        <h1>
          <FiChevronLeft
            onClick={() => history.push(`/${professor.public_name}`)}
          />
          Aulas
        </h1>
        <Content>{workoutsMemo}</Content>
        <Paginate
          pageRangeDisplayed={pageOptions?.page ?? 1}
          pageCount={workouts.lastPage}
          {...{ setPageOptions }}
        />
      </Container>
      {selectedworkout && (
        <ViewWorkout
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          workout={selectedworkout}
          publicName={professor.public_name}
          planPrice={
            professor.amount_receivable ? professor.amount_receivable * 1.12 : 0
          }
        />
      )}
    </>
  ) : null;
};

export default Workouts;
