//
import { CSSProperties, useMemo, useState } from 'react';

import { useAuth } from '~/hooks/Auth';
import NewInteraction from '~/pages/Professor/Courses/CourseDetail/NewInteraction';
import NewPractice from '~/pages/Professor/Courses/CourseDetail/NewPractice';
import NewVideo from '~/pages/Professor/Courses/CourseDetail/NewVideo';
import NewCourse from '~/pages/Professor/Courses/MyCourses/NewCourse';
import NewWorkout from '~/pages/Professor/MyWorkouts/NewWorkout';

import {
  HeaderButton,
  HeaderContainer,
  HeaderTitle,
  HeaderWrapper,
} from './styles';

type HeaderTitleContent =
  | 'Cursos'
  | 'Prática'
  | 'Minhas Aulas'
  | 'Meus Cursos'
  | 'Interação ao vivo'
  | 'Ao Vivo'
  | 'Vídeos'
  | 'Vídeo'
  | 'Configurações'
  | 'Relatórios'
  | 'Cursos'
  | 'Interação ao vivo'
  | 'Aulas Gravadas';

interface HeaderProps {
  headerTitle: HeaderTitleContent;
  buttonText?: string;
  courseId?: number;
  setNeedUpdate?: (arg: boolean) => void;
  containerStyle?: CSSProperties;
}

const Header: React.FC<HeaderProps> = ({
  headerTitle,
  buttonText,
  courseId,
  setNeedUpdate,
  containerStyle = {},
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {
    user: { role },
  } = useAuth();

  const createBtn = useMemo((): JSX.Element | null => {
    if (role === 'professional') {
      const bundleUpdate = { modalIsOpen, setModalIsOpen, setNeedUpdate };

      switch (headerTitle) {
        case 'Aulas Gravadas':
          return <NewWorkout {...bundleUpdate} />;
        case 'Meus Cursos':
          return <NewCourse {...bundleUpdate} />;
        case 'Vídeos':
          return <NewVideo {...{ ...bundleUpdate, courseId }} />;
        case 'Prática':
          if (courseId) {
            return <NewPractice {...{ ...bundleUpdate, courseId }} />;
          }
          break;
        case 'Interação ao vivo':
          if (courseId) {
            return <NewInteraction {...{ ...bundleUpdate, courseId }} />;
          }
          break;
        case 'Ao Vivo':
          return <NewWorkout {...bundleUpdate} isNewLive />;
        //   return <NewWorkout {...bundleUpdate} />;

        default:
          break;
      }
    }
    return null;
  }, [courseId, headerTitle, modalIsOpen, role, setNeedUpdate]);

  return (
    <>
      <HeaderWrapper style={containerStyle}>
        <HeaderContainer>
          <HeaderTitle>{headerTitle}</HeaderTitle>
          {buttonText && (
            <HeaderButton
              isOutline={false}
              onClick={() => setModalIsOpen(!modalIsOpen)}
            >
              {buttonText}
            </HeaderButton>
          )}
        </HeaderContainer>
      </HeaderWrapper>
      {createBtn}
    </>
  );
};

export default Header;
