import { Link } from 'react-router-dom';

import { ImageDance, ImageLives } from '~/assets/images';
import { Button, Modal } from '~/components';
import { ModalContent } from '~/components/Modal/styles';
import { ModalLike } from '~/models/Common';
import CourseInterface from '~/models/Courses';
import { LiveIsFreeStatus, Lives } from '~/models/Lives';
import getMimeTypeByExtVideo from '~/utils/getMimeType';
import Price from '~/utils/Price';

import {
  Content,
  Image,
  LiveContent,
  LiveContentButton,
  LiveContentDescription,
  LiveContentPrice,
  LiveContentStatus,
  LiveContentTitle,
  Video,
} from './styles';

interface ViewCourseProps extends ModalLike {
  course?: CourseInterface;
  live?: Lives;
  handleCloseModal?: () => void;
  publicname?: string;
}

const ViewCourse: React.FC<ViewCourseProps> = ({
  modalIsOpen,
  setModalIsOpen,
  course,
  live,
  handleCloseModal,
  publicname,
}) => {
  return (
    <Modal {...{ setModalIsOpen, modalIsOpen }}>
      <ModalContent>
        {live && (
          <LiveContent>
            <LiveContent>
              <LiveContentStatus>
                AO VIVO -{' '}
                <strong>
                  {live.date} - {live.hour}
                </strong>
              </LiveContentStatus>
              <img
                src={ImageLives}
                alt="Live"
                style={{ width: '100%', height: 'auto' }}
              />
              <LiveContentTitle>{live.title}</LiveContentTitle>
              <LiveContentDescription>
                {live.description}
              </LiveContentDescription>
              {live.is_free === LiveIsFreeStatus.NO && live.price && (
                <>
                  <LiveContentPrice>
                    Valor: <strong>{Price.floatToCurrency(live?.price)}</strong>
                  </LiveContentPrice>
                  <LiveContentButton
                    to={`/${publicname}/signup?live=${live.id}`}
                  >
                    <Button>Comprar</Button>
                  </LiveContentButton>
                </>
              )}
              {live.is_free === LiveIsFreeStatus.YES && (
                <>
                  <LiveContentButton to={`/${publicname}/become-a-student`}>
                    <Button>Torne-se aluno</Button>
                  </LiveContentButton>
                </>
              )}
            </LiveContent>
          </LiveContent>
        )}
        {course && (
          <>
            <Content>
              <div>
                {course?.video ? (
                  <Video
                    onContextMenu={() => false}
                    controlsList="nodownload"
                    loop
                    controls
                    autoPlay
                    muted
                  >
                    <source
                      src={course.video}
                      type={getMimeTypeByExtVideo(
                        course.video.split('.').pop() as string,
                      )}
                    />
                  </Video>
                ) : (
                  <Image
                    src={course?.foto_path ?? ImageDance}
                    alt={course?.title}
                    title={course?.title}
                  />
                )}
              </div>
              <div>
                <h3>{course?.title}</h3>
                <h6>{Price.floatToCurrency(course?.price ?? 0)}</h6>
                <p>Aulas em vídeo, exercícios de prática e mais.</p>
              </div>
            </Content>
            <div>
              <Link to={`/${publicname}/signup?course=${course?.id}`}>
                <Button>Selecionar curso</Button>
              </Link>
            </div>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ViewCourse;
