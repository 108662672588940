import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const OrdenedList = styled.ol`
  counter-reset: item;
  color: ${colors.lightnerGrey};
  width: 100%;
  font-size: 1.4rem;
`;

export const ListItem = styled.li`
  display: block;
  &:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
  }
`;

export const OrdenedLetterList = styled.ol`
  counter-reset: item2;
  color: ${colors.lightnerGrey};
  width: 100%;
  font-size: 1.4rem;
  list-style-type: lower-alpha;
`;

export const ListLetterItem = styled.li`
  display: block;
  &:before {
    content: '(' counter(item2, lower-alpha) ') ';
    counter-increment: item2;
  }
`;

export const OrdenedUpperLetterList = styled.ol`
  counter-reset: item3;
  color: ${colors.lightnerGrey};
  width: 100%;
  font-size: 1.4rem;
  list-style-type: upper-alpha;
`;

export const UnorderedList = styled.ol`
  list-style-type: circle;
  padding-left: 3em;
`;
