import { FC } from 'react';

import * as M from './styles';

import { MenuMobileToggleProps } from './typings';

const MenuMobileToggle: FC<MenuMobileToggleProps> = ({
  handleActiveMenuMobile,
}) => {
  return (
    <M.MenuMobileToggle data-mobile="toggle" onClick={handleActiveMenuMobile}>
      <span />
    </M.MenuMobileToggle>
  );
};

export default MenuMobileToggle;
