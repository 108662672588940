import { AxiosPromise, AxiosResponse } from 'axios';
import api from './api';

interface CoproducerData {
  name: string;
  email: string;
  cell_phone: string;
  cpf: string;
  professional_id: string;
  created_at: string;
  updated_at: string;
  id: number;
  account_stripe: string;
}

export interface ProducerType {
  id: number;
  professional_id: number;
  name: string;
  email: string;
  cpf: string;
  cell_phone: string;
  is_active: number;
  account_stripe: string;
  created_at: string;
  updated_at: string;
}

export interface ProducerWithMeta {
  data: ProducerType[];
  lastPage: number;
  page: number;
  perPage: number;
  total: number;
}

export const Coproducer = {
  CreateCoproducer(data: any): Promise<AxiosResponse<CoproducerData>> {
    return api.post('/coproducer/create', data);
  },

  getAllProducers(
    professionalId: number,
    limit: number,
    page: number,
  ): AxiosPromise<ProducerWithMeta> {
    return api.get(`/coproducer/list/professional/${professionalId}`, {
      params: {
        limit,
        page,
      },
    });
  },

  updateCoproducer(producerId: number, data: any): AxiosPromise<any> {
    return api.put(`/coproducer/${producerId}/update`, data);
  },

  deleteCoproducer(producerId: number): AxiosPromise<void> {
    return api.delete(`/coproducer/${producerId}/delete`);
  },
};

export default Coproducer;
