import styled from 'styled-components';

import { Container } from '~/styles/objects/container';
import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

export const FooterWrapper = styled.footer`
  background: transparent linear-gradient(252deg, #2c2c2c 0%, #1c1c1c 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #3e3e3e2e;
  padding: var(--gap-md) 0;
  width: 100%;
  position: absolute;
  bottom: -222px;

  @media (max-width: ${breakpoints.small}) {
    position: static;
    text-align: center;
  }
`;

export const FooterContainer = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: var(--gap-md);
  }
`;

export const FooterContent = styled.section`
  display: flex;
  column-gap: var(--gap-md);

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: var(--gap-md);
  }
`;

export const FooterInfo = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-style: normal;
  gap: 5rem;

  img {
    margin-top: 1rem;
  }

  @media (max-width: ${breakpoints.small}) {
    align-items: center;
    justify-content: initial;
    row-gap: var(--gap-xs);
  }
`;

export const FooterInfoText = styled.p`
  color: #fff;
  max-width: 233px;
`;

export const FooterContacts = styled.address`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-style: normal;

  a {
    color: #fff;
    max-width: 233px;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      color: ${colors.terciary};
    }
  }

  @media (max-width: ${breakpoints.small}) {
    align-items: center;
    row-gap: var(--gap-sm);
  }
`;

export const FooterContactsTitle = styled.h3`
  color: ${colors.terciary};
  text-decoration: underline;
  font-size: 2.4rem;
`;

export const FooterContactsItem = styled.section`
  display: flex;
  align-items: center;
  column-gap: var(--gap-sm);
`;

export const FooterSocialMedias = styled.section`
  display: flex;
  column-gap: var(--gap-sm);
  align-items: center;
`;

export const FooterSocialMediasIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const FooterCopy = styled.p`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  color: #fff;

  > a {
    color: #fff;
    margin-left: 4px;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      color: ${colors.terciary};
    }
  }

  @media (max-width: ${breakpoints.small}) {
    text-align: center;
  }
`;
