import axios, { AxiosError, AxiosPromise } from 'axios';

import {
  ForgotPasswordFormData,
  MessageServer,
  PairForgetCode,
  RegisterRequest,
  RequiredFormAmountNumber,
  ResetPassword,
} from '~/models/Common';
import {
  ChangePasswordRequest,
  SignInResponse,
  User,
  UserCredentials,
  UserRole,
} from '~/models/User';

import api from './api';

type SignUpResponse = MessageServer & SignInResponse;

interface ChangePasswordResponse {
  Message: string;
}

interface UpdateUserArgs {
  userId: number;
  data: RequiredFormAmountNumber;
  type: UserRole;
  token?: string;
}

interface ForgotPasswordData {
  id: number;
}

interface ForgotPasswordResponse extends MessageServer {
  data: ForgotPasswordData;
}

interface ValidationUniqueFields {
  email: string;
  cpf: string;
  cnpj: string;
}

type ValidationRequest = Partial<ValidationUniqueFields>;

interface ValidationField {
  field: keyof ValidationUniqueFields;
  validation: 'integer' | 'max' | 'min' | 'unique' | 'email';
}

type ValidationMessage = MessageServer & ValidationField;

const UserSevice = {
  signup(data: RegisterRequest | FormData): AxiosPromise<SignUpResponse> {
    return api.post('/register', data);
  },

  async update({
    userId,
    data,
    type,
    token,
  }: UpdateUserArgs): Promise<AxiosPromise<User>> {
    const response = await api.put(`/${type}s/${userId}`, data, {
      headers: {
        Authorization: token
          ? `Bearer ${token}`
          : api.defaults.headers.Authorization,
      },
    });

    if (axios.isAxiosError(response)) {
      return Promise.reject(response);
    }

    return response;
  },

  updatePassword(
    role: UserRole,
    data: Omit<ChangePasswordRequest, 'confirm_password'>,
  ): AxiosPromise<ChangePasswordResponse> {
    return api.put(`/${role}s/`, data);
  },

  signin(
    data: UserCredentials,
  ): AxiosPromise<SignInResponse> | AxiosError<MessageServer> {
    return api.post('/user/authenticate', data);
  },

  forgotPassword(data: ForgotPasswordFormData): AxiosPromise<MessageServer> {
    return api.post('/auth/forgotpassword', data);
  },

  validateCode(data: PairForgetCode): AxiosPromise<ForgotPasswordResponse> {
    return api.post('/auth/validatecode', data);
  },

  resetPassword(data: ResetPassword): AxiosPromise<MessageServer> {
    return api.post('/auth/resetpassword', data);
  },

  validate(
    data: ValidationRequest,
  ): AxiosPromise<MessageServer> | AxiosError<ValidationMessage[]> {
    return api.post('/users/validate', data);
  },
};

export default UserSevice;
