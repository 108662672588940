import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const BasePrice = styled.span`
  font-size: '1.2rem';
  color: gray;
  font-weight: 'normal';
  display: 'block';
  width: '100%';
  margin-top: '8px';
  margin-bottom: '8px';
`;

export const Label = styled.label`
  margin-right: auto;
  color: ${colors.lightnerGrey};
  font-size: 1.8rem;
`;

export const DatetimeGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  input {
    color: ${colors.lightnerGrey};
    width: 100%;
    max-width: 373px;

    &::placeholder {
      color: ${colors.lightnerGrey};
    }
  }
`;

export const DatetimeFieldGroup = styled.div`
  flex: 1 1 150px;
  display: grid;
  grid-auto-flow: row;

  & + & {
    margin-left: 2rem;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 15px;

  h1 {
    color: ${colors.lightnerGrey};
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1.12px;
  }

  h2 {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 400;

    strong {
      color: #b88746;
    }
  }

  form {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    justify-items: center;
    align-content: center;
    width: 100%;

    > div {
      max-width: 373px;
      width: 100%;
      margin-top: 25px;
      border-color: #000;
      color: #fff;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  button {
    border: 2px solid #b88746;
    color: #b88746;
    font-weight: bold;
    margin-top: 10px;
    max-width: 390px;
    width: 100%;
  }
`;

export const CreateWorkout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 463px;
  width: 100%;

  label {
    cursor: pointer;
    margin-top: 42px;
    background: ${colors.gray} 0% 0% no-repeat padding-box;
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
    color: #ffffff;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 21px;
  }

  p {
    font-size: 12px;
    margin-top: 10px;
    width: 100%;
    color: gray;
  }

  form {
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    h6 {
      font-size: 19px;
      font-weight: 500;
      color: ${colors.lightnerGrey};
      width: 100%;
      margin-bottom: 10px;
    }

    > div {
      max-width: 100%;
      width: 100%;
      margin: 0;

      div,
      input {
        color: ${colors.lightnerGrey};
      }

      div {
        /* width: 100%; */
        border-color: #2c2c2c;
        svg: {
          width: 20px;
        }
      }
      input {
        width: 100%;
        max-width: 373px;
      }
    }
  }

  button {
    &:last-child {
      margin-top: 20px;
    }
  }
`;

export const Radio = styled.input`
  &[type='radio'] {
    position: relative;
    width: 26px;
    height: 26px;
    color: #000;
    &:checked:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: ${colors.primary} 0% 0% no-repeat padding-box;
      border-radius: 100%;
      left: 0;
      top: 0;
    }
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  position: relative;
  max-width: 100px;

  label {
    color: ${colors.lightGrey};
    background: transparent;
    box-shadow: none;
    display: flex;
    align-items: center;
    font-size: 19px;
    max-width: 100px;
    margin: 10px 20px 20px 0;

    input {
      margin-right: 10px;
    }
  }
`;

export const PreviewVideo = styled.video`
  width: 100%;
  max-width: 100%;
  max-height: 300px;
  position: relative;
`;

export const DescriptionInfo = styled.p`
  font-size: 1.5rem !important;
  color: white !important;
`;
