import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';
import { appearFromLeft } from '~/styles/tools/transitions';

import Button from '../Button';

export const Embla = styled.div`
  --slide-size: 100%;
  --slide-height: 60rem;
  padding-top: 9.5rem;
  flex-direction: column-reverse;
  display: flex;
  max-height: 850px;

  @media (min-width: ${breakpoints.small}) {
    margin: -95px 0 -0px -20px;
    --slide-height: 70rem;
    margin-bottom: 5rem;
  }

  @media (max-width: ${breakpoints.small}) {
    padding-top: 0;
    --slide-height: 30rem;
    margin-bottom: 0rem;
  }
`;

export const Viewport = styled.div`
  overflow: hidden;
  opacity: 0.7;

  @media (min-width: ${breakpoints.small}) {
    margin-left: 0%;
    left: 0;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 38%;
      width: 10%;
      height: 70rem;
      margin-bottom: 5rem;
      z-index: 2;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
`;

export const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem;
  z-index: 9999;

  @media (min-width: ${breakpoints.small}) {
    position: absolute;
    padding: 2rem 10rem;
  }
`;

export const Content2 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;

  @media (min-width: ${breakpoints.small}) {
    position: absolute;
    padding: 2rem 10rem;
  }

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
    z-index: 100;
  }
`;

export const Viewport2 = styled.div`
  overflow: hidden;
  z-index: 5;
`;

export const Container2 = styled.div`
  position: relative;
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
`;

export const Slide = styled.div`
  flex: 0 0 var(--slide-size);
  min-width: 0;
`;

export const Slide2 = styled.div`
  flex: 0 0 var(--slide-size);
  min-width: 0;
`;

export const SlideImage = styled.img`
  /* height: var(--slide-height); */
  width: 100%;
  height: 80%;
  object-fit: cover;
  object-position: 10% 10%;
  @media (max-width: 1450px) {
    height: 80%;
    width: 100vw;
  }
`;

export const Dots = styled.div`
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
  margin-top: -60px;

  .embla__dot {
    -webkit-appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
    margin-left: 0.75rem;

    &::after {
      background-color: #484848;
      border-radius: 1rem;
      width: 100%;
      height: 1rem;
      content: '';
    }

    &.embla__dot--selected::after {
      background: #eedc90;
    }
  }

  @media (min-width: ${breakpoints.small}) {
    top: 64rem;
  }
  @media (max-width: ${breakpoints.small}) {
    top: 0;
    display: none;
  }
`;

export const HeroContent = styled.section`
  animation: ${appearFromLeft} 1s;
  width: 40vw;

  > a {
    width: 100%;

    @media (max-width: ${breakpoints.small}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  > a > button {
    max-width: 380px;
    width: 100%;
    background: ${colors.green} 0% 0% no-repeat padding-box;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 2.2rem;
  }

  @media (max-width: ${breakpoints.small}) {
    width: 100vw;
    padding: 2rem;
    > a button {
      font-size: 1.6rem;
    }
  }
`;

export const HeroTitle = styled.h1`
  max-width: 90%;
  color: ${colors.terciary};
  text-shadow: 0px 3px 6px #00000070;
  font-weight: 500;
  font-size: 2.5rem;
  text-align: left;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${breakpoints.small}) {
    font-size: 2.2rem;
    width: 100%;
  }

  /* margin-bottom: var(--gap-xs); */
  margin-bottom: 0.8rem;
`;

export const HeroText = styled.p`
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
  text-shadow: 0px 3px 6px #0000008a;
  text-align: left;
  margin-bottom: var(--gap-md);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.8rem;
    width: 100%;
  }
`;

export const NavigateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${breakpoints.small}) {
    justify-content: center;
  }
`;

export const NavigateToLesson = styled(Button)`
  background: ${colors.primary} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px ${colors.gray};
`;
