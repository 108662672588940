export default function validateMediaType(
  type: string,
  fileName: string,
): void {
  const videoFormats = ['flv', 'mp4', 'm3u8', 'ts', '3gp', 'mov', 'avi', 'wmv'];
  const imageFormats = ['png', 'jpg', 'jpeg'];
  const fileExtension = fileName.split('.')[1];
  if (type === 'image') {
    if (videoFormats.includes(fileExtension))
      throw new Error('Formato de imagem inválido');
  }
  if (type === 'video') {
    if (imageFormats.includes(fileExtension))
      throw new Error('Formato de vídeo inválido');
  }
}
