import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import ConfigurationStudent from '~/pages/Student/Configuration';
import Courses from '~/pages/Student/Courses';
import MyWorkouts from '~/pages/Student/Workouts';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
}

const Route: React.FC<RouteProps> = ({ isPrivate = false, ...rest }) => {
  const { user } = useAuth();
  const role = user ? user.role : null;
  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return role === 'student' ? (
          <>
            <Route path="/student/my-workouts" exact component={MyWorkouts} />
            <Route path="/student/my-courses" exact component={Courses} />
            <Route
              path="/student/config"
              exact
              component={ConfigurationStudent}
            />

          </>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/signin' : '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
