import styled from 'styled-components';

import { Container } from '~/styles/objects/container';
import { appearFromRight } from '~/styles/tools/transitions';

export const MyCoursesContainer = styled(Container)`
  animation: ${appearFromRight} 1s;
`;

export const Footer = styled.footer`
  grid-column: 1 / -1;
`;
