import { ReactNode } from 'react';

import ReactModal from 'react-modal';

import { ModalLike } from '~/models/Common';

import { IconClose, ModalStyles } from './styles';

interface ModalProps extends ModalLike {
  children: ReactNode;
  withoutCloseButton?: boolean;
}

function Modal({
  children,
  modalIsOpen,
  setModalIsOpen,
  withoutCloseButton,
}: ModalProps): JSX.Element {
  return (
    <ReactModal
      isOpen={modalIsOpen}
      shouldCloseOnOverlayClick
      onRequestClose={() => {
        setModalIsOpen(false);
      }}
      ariaHideApp={false}
      style={ModalStyles}
    >
      {!withoutCloseButton && (
        <IconClose size={30} onClick={() => setModalIsOpen(false)} />
      )}
      {children}
    </ReactModal>
  );
}

Modal.defaultProps = {
  withoutCloseButton: false,
};

export default Modal;
