import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

import { Button } from '..';

export const PracticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  transition: background 0.3s;
  position: relative;
  width: 100%;

  @media (max-width: ${breakpoints.small}) {
    max-width: 300px;
  }
`;

export const PracticeContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--gap);
`;

export const PracticeDocument = styled.iframe`
  width: 100%;
  height: 240px;

  @media (max-width: ${breakpoints.small}) {
    height: 200px;
  }
`;

export const PracticeTitle = styled.h3`
  color: ${colors.black};
  font-size: 2.4rem;

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.8rem;
  }
`;

export const PracticeDescription = styled.p`
  color: ${colors.gray};
  font-size: 1.6rem;
  max-width: 393px;
`;

export const PracticeDocumentLink = styled.a`
  align-self: center;
  margin-top: var(--gap);
  width: 100%;
`;

export const PracticeDocumentButton = styled(Button)`
  background: ${colors.black} 0% 0% no-repeat padding-box;
  max-width: 100%;
  margin: 0 auto;

  @media (max-width: ${breakpoints.small}) {
    max-width: 309px;
  }
`;
