import styled, { keyframes } from 'styled-components';

export const MoveRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 0.92;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  position: fixed;
  z-index: 27;
  width: 95%;
  padding: 16px;
  bottom: 25px;
  left: 50%;
  margin-left: -47.5%;

  background: #2c2c2c;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  animation: ${MoveRight} 0.7s forwards;
`;

export const Text = styled.p`
  text-align: left;
  letter-spacing: 0.88px;
  color: #ffffff;
  opacity: 1;
  font-size: 14px;
`;
