import styled from 'styled-components';

interface LogoStylesProps {
  width?: number;
  height?: number;
}

export const LogoWrapper = styled.img<LogoStylesProps>`
  width: ${props => (props.width ? `${props.width}px` : `146px`)};
  height: ${props => (props.height ? `${props.height}px` : `auto`)};
`;
