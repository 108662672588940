import styled, { css } from 'styled-components';

import { colors } from '~/styles/settings/colors';

interface ContainerProps {
  contentVisible: boolean;
}

interface ContentProps {
  alignItems: string;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

export const DropdownButton = styled.button`
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border: 1px solid ${colors.primary};
  padding: 18px;
  border-radius: 60px;

  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.64px;

  p {
    margin-left: auto;
    color: ${colors.primary};
  }
`;

export const DropdownArrow = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  svg {
    transition: 300ms ease all;
  }

  ${props =>
    props.contentVisible &&
    css`
      svg {
        transform: rotate(-180deg);
      }
    `}
`;

export const DropdownContent = styled.div<ContentProps>`
  width: 100%;
  /* background-color: ${colors.primary}0d; */
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItems};

  button {
    width: 242px;
  }
`;

export const Icon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #00aa5b;
  margin-left: 9px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
  }
`;
