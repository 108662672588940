import { DropdownType, HandleDropdownArgs } from '~/models/Common';

interface HandleChange extends HandleDropdownArgs {
  setItem: (route: string) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface HandleChangeDropdownArgs {
  (arg0: HandleChange): void;
}

export const handleChangeDropdown: HandleChangeDropdownArgs = ({
  id,
  isOpen,
  setIsOpen,
  setItem,
  type,
}) => {
  setIsOpen(!isOpen);
  const typeName = DropdownType[type];
  const typeLowercase = typeName.toLowerCase();
  setItem(`${typeLowercase}_${id}`);
};
