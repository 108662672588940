import { useMemo } from 'react';

import { ImageDance } from '~/assets/images';
import { Button, Modal } from '~/components';
import { ModalLike } from '~/models/Common';
import { Workouts } from '~/models/Workouts';

import {
  InteractionContentButton,
  ModalContent,
  ViewWorkoutContent,
  ViewWorkoutDescription,
  ViewWorkoutLevel,
  ViewWorkoutTitle,
  ViewWorkoutVideo,
} from './styles';

interface ViewWorkoutProps extends ModalLike {
  workout?: Workouts;
}

const getCDNUrl = (url: string): string => {
  const regex = /\/videos\/(.*)$/;
  const match = url?.match(regex);

  const file = match && match[1];
  return `https://d26g8006nuvfz.cloudfront.net/videos/${file}`;
};
//

const ViewWorkout: React.FC<ViewWorkoutProps> = ({
  modalIsOpen,
  setModalIsOpen,
  workout,
}) => {
  const videoURL = getCDNUrl(workout?.path as string);

  const videoPoster = useMemo(() => {
    const { image } = workout as Workouts;
    return image || ImageDance;
  }, [workout]);

  return (
    <Modal {...{ modalIsOpen, setModalIsOpen }}>
      <ModalContent>
        {!workout?.url && workout?.is_converted && videoURL && (
          <ViewWorkoutVideo
            onContextMenu={() => false}
            controlsList="nodownload"
            src={videoURL}
            poster={videoPoster}
            controls
          />
        )}

        {workout?.url && (
          <img
            src={workout?.image}
            alt="Your alt text"
            style={{ width: '100%' }}
          />
        )}
        <ViewWorkoutContent>
          <ViewWorkoutLevel>
            {workout?.category?.description ?? ''}
          </ViewWorkoutLevel>
          <ViewWorkoutTitle>{workout?.title ?? ''}</ViewWorkoutTitle>
          <ViewWorkoutDescription>
            {workout?.description ?? ''}
          </ViewWorkoutDescription>
          {workout?.url && (
            <InteractionContentButton
              href={workout.url}
              rel="noreferrer noopener"
              target="_blank"
            >
              <Button>Link para a reunião</Button>
            </InteractionContentButton>
          )}
        </ViewWorkoutContent>
      </ModalContent>
    </Modal>
  );
};

export default ViewWorkout;
