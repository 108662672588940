import React, { useEffect, useState } from 'react';

import { ImageLives } from '~/assets/images';
import { Button, Modal } from '~/components';
import { ModalLike } from '~/models/Common';
import { LiveIsFreeStatus, Lives } from '~/models/Lives';
import Price from '~/utils/Price';

import * as L from './styles';

interface ViewLiveProps extends ModalLike {
  live: Lives;
  handleCloseModal?: () => void;
  publicname?: string;
}

const ViewLive: React.FC<ViewLiveProps> = ({
  modalIsOpen,
  handleCloseModal,
  publicname,
  setModalIsOpen,
  live,
}) => {
  const [price, setPrice] = useState<string>();

  useEffect(() => {
    if (live.price && live.price > 0) {
      const priceString = live.price.toString();
      const currency = Price.stringToCurrency(priceString);
      setPrice(currency);
    } else {
      setPrice('Gratuito');
    }
  }, [live.price]);

  /** @todo colocar imagem localmente pois poderá dar problema de cors */
  return (
    <Modal {...{ modalIsOpen, setModalIsOpen }}>
      <L.ModalContent>
        <L.LiveContent>
          <L.IconClose size={30} onClick={handleCloseModal} />
          <L.LiveContent>
            <L.LiveContentStatus>
              AO VIVO -{' '}
              <strong>
                {live.date.split('T')[0].split('-')[2]}/
                {live.date.split('T')[0].split('-')[1]} -{' '}
                {live.hour.split(':')[0]}:{live.hour.split(':')[1]}H
              </strong>
            </L.LiveContentStatus>
            <img
              src={ImageLives}
              alt="Live"
              style={{ width: '100%', height: 'auto' }}
            />
            <L.LiveContentTitle>{live.title}</L.LiveContentTitle>
            <L.LiveContentDescription>
              {live.description}
            </L.LiveContentDescription>
            {live.is_free === LiveIsFreeStatus.NO && live.price && (
              <>
                <L.LiveContentPrice>
                  Valor: <strong>{price}</strong>
                </L.LiveContentPrice>
                <L.LiveContentButton
                  to={`/${publicname}/signup?live=${live.id}`}
                >
                  <Button>Comprar</Button>
                </L.LiveContentButton>
              </>
            )}
            {live.is_free === LiveIsFreeStatus.YES && (
              <>
                <L.LiveContentButton to={`/${publicname}/become-a-student`}>
                  <Button>Torne-se aluno</Button>
                </L.LiveContentButton>
              </>
            )}
          </L.LiveContent>
        </L.LiveContent>
      </L.ModalContent>
    </Modal>
  );
};

export default ViewLive;
