import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const Interaction = styled.article`
  max-width: 393px;
  transition: background 0.3s;
  background: none;
  padding: 16px;
  position: relative;

  &:hover {
    cursor: pointer;
    background: ${colors.modalBackground} 0% 0% no-repeat padding-box;
  }

  @media (max-width: 768.9px) {
    max-width: 310px;

    img {
      max-width: 290px;
    }

    svg {
      margin-left: 240px;
    }
  }
`;

export const InteractionContent = styled.section`
  display: flex;
  flex-direction: column;
  /* row-gap: 8px; */
  padding-bottom: calc(var(--gap-xs) + 80px);
  position: relative;

  > span {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
    color: ${colors.lightGrey};
    letter-spacing: 0.52px;

    > strong {
      font-size: 13px;
      letter-spacing: 0.52px;
      color: #b88746;
    }
  }

  > h3 {
    color: ${colors.lightGrey};
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.8px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > p {
    color: ${colors.lightGrey};
    font-size: 16px;
    font-weight: 400;
    max-width: 393px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 768.9px) {
    button {
      margin-left: 0;
    }
  }
`;

export const InteractionContentFooter = styled.footer`
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

export const InteractionContentPrice = styled.p`
  display: inline-block;
  margin-bottom: var(--gap);
  font-size: 16px;
  font-weight: 600;
  color: #2c2c2c;

  strong {
    font-weight: 700;
  }
`;

export const InteractionContentButton = styled.a`
  /* align-self: center; */
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;

  > button {
    background-color: #2c2c2c;
    width: 100%;
    max-width: 100%;
    height: 44px;
    text-transform: none;
    padding: 0;
  }
`;
