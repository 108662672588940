import { FC, useMemo } from 'react';

import * as C from './styles';

import { CategoriesMenuProps } from './typings';

const CategoriesMenu: FC<CategoriesMenuProps> = ({
  options,
  query,
  handleClose,
}) => {
  const categoriesOptionsMemo = useMemo(() => {
    return options?.map(option => (
      <C.CategoriesItem
        key={option.category_id}
        to={option.to}
        title={option.label}
        onClick={() => handleClose(option.category_id)}
      >
        <C.CategoriesItemButton isOutline active={query === option.queryName}>
          {option.label}
        </C.CategoriesItemButton>
      </C.CategoriesItem>
    ));
  }, [handleClose, options, query]);

  return <C.CategoriesWrapper>{categoriesOptionsMemo}</C.CategoriesWrapper>;
};

export default CategoriesMenu;
