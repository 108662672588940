import React, { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router';

import { ImageDance, ImageLives } from '~/assets/images';
import {
  Button,
  DropdownMenu,
  Header,
  Paginate,
  Practices,
  Videos,
} from '~/components';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { DropdownType, PageOptionsRequest } from '~/models/Common';
import Courses, { CourseDetails } from '~/models/Courses';
import Interaction from '~/models/Interaction';
import PracticeModel from '~/models/Practice';
import { Grid } from '~/pages/Student/Courses/CourseDetail/styles';
import { cleanRequests } from '~/services/api';
import ProfessorService from '~/services/ProfessorService';
import { Container } from '~/styles/objects/container';
import { DropdownIcon } from '~/styles/objects/dropdown-icon';
import errorHandlerToToast from '~/utils/errorHandler';
import getMimeTypeByExtVideo from '~/utils/getMimeType';
import { handleChangeDropdown } from '~/utils/handleChangeDropdown';
import Price from '~/utils/Price';

import * as C from './styles';

import ViewVideo from './ViewVideo';

interface CoursesLocation {
  course: Courses;
}

const CourseDetail: React.FC = () => {
  const { setLoading } = useLoading();
  const { addToast } = useToast();
  const [practices, setPractices] = useState<PracticeModel[]>();
  const [lives, setLives] = useState<Interaction[]>();
  const [videoSelect, setVideoSelected] = useState<CourseDetails>();
  const [videoModal, setVideoModal] = useState(false);
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
    perpage: 4,
  });
  const { user } = useAuth();

  const {
    state: { course },
  } = useLocation<CoursesLocation>();

  const [videos, setVideos] = useState<CourseDetails[]>([]);
  const [needVideoUpdate, setVideoNeedUpdate] = useState(true);
  const [needPraticalUpdate, setNeedPraticalUpdate] = useState(true);

  const handleVideo = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await ProfessorService.getVideosByCourse(course.id);
      setVideos(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof Error) {
        addToast({
          title: err.message,
          type: 'error',
        });
      }
    }
  }, [addToast, course.id, setLoading]);

  const handlePractice = useCallback(async () => {
    if (needPraticalUpdate) {
      const { id: courseId } = course;
      const reponse = await ProfessorService.getPractice({ courseId });
      setPractices(reponse.data);
      setNeedPraticalUpdate(false);
    }
  }, [course, needPraticalUpdate]);
  const handleInteractions = useCallback(async () => {
    const { id: courseId } = course;
    const response = await ProfessorService.getInteractions({ courseId });
    setLives(response.data);
  }, [course]);
  const deleteInteractions = useCallback(
    async (interactionId: number) => {
      setLoading(true);
      try {
        const { id: courseId } = course;
        await ProfessorService.deleteInteractions({
          courseId,
          interactionId,
        });
        await handleInteractions();
      } catch (e) {
        // console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [course, handleInteractions, setLoading],
  );

  useEffect(() => {
    if (needPraticalUpdate) {
      handlePractice();
      handleInteractions();
      setNeedPraticalUpdate(false);
    }

    if (needVideoUpdate) {
      handleVideo();
      setVideoNeedUpdate(false);
    }
    return cleanRequests;
  }, [
    handleVideo,
    needVideoUpdate,
    handlePractice,
    needPraticalUpdate,
    handleInteractions,
  ]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSelected, setDropdownSelected] = useState<string>();

  /** @todo join deletes */
  const handleDeleteVideo = useCallback(
    async (idVideo: number) => {
      setLoading(true);

      try {
        if (user.role !== 'professional') {
          throw new Error('Você não é um professor');
        }
        const { id: idProfessional } = user.professional;
        await ProfessorService.deleteVideo({ idProfessional, idVideo });
        addToast({
          title: 'Video excluido com suceso',
          type: 'success',
        });
        setLoading(false);
        setVideoNeedUpdate(true);
      } catch (e) {
        const errorToToast = errorHandlerToToast({ error: e });
        if (errorToToast) addToast(errorToToast);
        setLoading(false);
      }
    },
    [addToast, setLoading, user],
  );

  const handleDeletePractice = useCallback(
    async (praticeId: number) => {
      const { id: courseId } = course;

      setLoading(true);
      try {
        await ProfessorService.deletePratice({ courseId, praticeId });
        setLoading(false);
        addToast({
          title: 'Arquivo excluido com suceso',
          type: 'success',
        });
        setNeedPraticalUpdate(true);
      } catch (e) {
        const errorToToast = errorHandlerToToast({ error: e });
        if (errorToToast) addToast(errorToToast);
        setLoading(false);
      }
    },
    [addToast, course, setLoading],
  );

  const handleVideoModal = (video: CourseDetails): void => {
    setVideoSelected(video);
    setVideoModal(true);
  };

  const status = course.status ? 'Ativo' : 'Inativo';

  return (
    <>
      <C.Presentation>
        <Container>
          <C.CourseDetails>
            {course.video ? (
              <C.CourseDetailsVideo
                onContextMenu={() => false}
                controlsList="nodownload"
                loop
                controls
                autoPlay
                muted
              >
                {/* @todo verificar o pop */}
                <source
                  src={course.video}
                  type={getMimeTypeByExtVideo(
                    course.video.split('.').pop() as string,
                  )}
                />
              </C.CourseDetailsVideo>
            ) : (
              <C.ImageContainer>
                <C.CourseDetailsImage
                  src={course.foto_path ?? ImageDance}
                  alt="Dance"
                />
              </C.ImageContainer>
            )}
            <C.CourseDetailsContent>
              <C.CourseDetailsStatus status={status}>
                {status}
              </C.CourseDetailsStatus>
              <C.CourseDetailsTitle>{course.title}</C.CourseDetailsTitle>
              <C.CourseDetailsPrice>
                Valor:{' '}
                <strong>{Price.floatToCurrency(course.price ?? 0)}</strong>
              </C.CourseDetailsPrice>
              <C.CourseDetailsPrice>
                Tipo de pagamento:{' '}
                {course.payment_type === 'charge'
                  ? 'Cobrança Única'
                  : 'Assinatura'}
              </C.CourseDetailsPrice>
              <C.CourseDetailsStudentsCount>
                {course.students ? `${course.students} alunos` : '0 alunos'}
              </C.CourseDetailsStudentsCount>
            </C.CourseDetailsContent>
          </C.CourseDetails>
        </Container>
      </C.Presentation>

      <Header
        headerTitle="Vídeos"
        buttonText="Novo vídeo"
        courseId={course.id}
        setNeedUpdate={setVideoNeedUpdate}
      />

      <Container>
        <Videos
          handleDeleteVideo={handleDeleteVideo}
          handleVideoModal={handleVideoModal}
          setVideoNeedUpdate={setVideoNeedUpdate}
          dropdownProps={{
            dropdownOpen,
            dropdownSelected,
            setDropdownOpen,
            setDropdownSelected,
          }}
          isProfessional
          videos={videos}
          course={course}
        />

        {videoSelect && (
          <ViewVideo
            courseVideoDetails={videoSelect}
            modalIsOpen={videoModal}
            setModalIsOpen={setVideoModal}
          />
        )}

        <C.Footer>
          <Paginate
            pageCount={pageOptions.page}
            pageRangeDisplayed={videos?.length}
            {...{ setPageOptions }}
          />
        </C.Footer>
      </Container>

      <Header
        headerTitle="Prática"
        buttonText="Nova Prática"
        courseId={course.id}
        setNeedUpdate={setNeedPraticalUpdate}
      />

      <Container>
        <Practices
          practices={practices ?? []}
          setPracticeNeedUpdate={setNeedPraticalUpdate}
          dropdownProps={{
            dropdownOpen,
            dropdownSelected,
            setDropdownOpen,
            setDropdownSelected,
          }}
          handleDeletePractice={handleDeletePractice}
          course={course}
          isProfessional
        />
      </Container>

      <Header
        headerTitle="Interação ao vivo"
        buttonText="Nova interação"
        courseId={course.id}
        setNeedUpdate={setNeedPraticalUpdate}
      />
      <Grid>
        {lives?.map(interaction => (
          <C.Interaction key={interaction.id}>
            <>
              <DropdownIcon
                size={24}
                color="#FFF"
                onClick={() =>
                  handleChangeDropdown({
                    id: interaction.id,
                    type: DropdownType.INTERACTION,
                    setIsOpen: setDropdownOpen,
                    setItem: setDropdownSelected,
                    isOpen: dropdownOpen,
                  })
                }
              />
              <DropdownMenu
                dropdownIsOpen={dropdownOpen}
                setDropdownIsOpen={setDropdownOpen}
                id={`interaction_${interaction.id}`}
                dropdownSelected={dropdownSelected}
                interaction={interaction}
                handleDelete={() => {
                  deleteInteractions(interaction.id);
                }}
              />
            </>
            <div>
              <span>
                Ao vivo -{' '}
                <strong>
                  {`${interaction.date.split('T')[0].split('-')[2]}/${
                    interaction.date.split('T')[0].split('-')[1]
                  } -${' '}
                  ${interaction.hour.split(':')[0]}:${
                    interaction.hour.split(':')[1]
                  }h`}
                </strong>
              </span>
              <img
                src={ImageLives}
                alt="Live"
                style={{ width: '100%', height: 'auto' }}
              />
              <h6>{interaction.title}</h6>
              <p>{interaction.description}</p>
              <a href={interaction.url} target="_blank" rel="noreferrer">
                <Button>Link para a reunião</Button>
              </a>
            </div>
          </C.Interaction>
        ))}
      </Grid>
    </>
  );
};

export default CourseDetail;
