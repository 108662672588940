import { useEffect, useState, useCallback, useRef } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Header, Table, Input, InputMask, Button } from '~/components';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { ISalesReport, ISortTableProps } from '~/models/Reports';
import ReportService from '~/services/ReportService';
import errorHandlerToToast from '~/utils/errorHandler';
import { chooseSortingFunction } from '~/utils/formatTable';
import { formatDate } from '~/utils/getTodayDate';
import getValidationErrors from '~/utils/getValidationErrors';

import { Container, FilterDiv, Label } from './styles';

interface IOnPageChangeProps {
  selected: number;
}

const Reports: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { setLoading } = useLoading();
  const formRef = useRef<FormHandles>(null);
  const [reportsData, setReportsData] = useState<ISalesReport[]>([]);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const getSalesData = async (): Promise<void> => {
      try {
        setLoading(true);
        if (user.role === 'professional') {
          const response = await ReportService.getSalesReports({
            professional_id: user.professional.id,
          });
          response.data.data && setReportsData(response.data.data);

          setPageCount(response.data.lastPage || 0);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        throw new Error('Erro ao carregar relatórios');
      }
    };
    getSalesData();
  }, [user, setLoading]);

  const onPageChange = useCallback(
    async ({ selected }: IOnPageChangeProps) => {
      try {
        setLoading(true);
        if (user.role === 'professional') {
          const data = formRef.current?.getData();

          const response = await ReportService.getSalesReports({
            professional_id: user.professional.id,
            page: selected,
            name: data?.name || '',
            initial_date: formatDate(data?.initial_date),
            final_date: formatDate(data?.final_date),
          });

          setReportsData(response?.data?.data || []);
          setPageCount(response?.data?.lastPage || 0);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        throw new Error('Erro ao carregar próxima página do relatório');
      }
    },
    [user, setLoading],
  );

  const sortTable = useCallback(
    ({ column, order }: ISortTableProps): void => {
      const sortFunction = chooseSortingFunction(column);
      const copiedArray = reportsData;
      const orderedArray = copiedArray.sort(sortFunction);
      if (order === 'asc') {
        setReportsData([...orderedArray]);
      }
      if (order === 'desc') {
        orderedArray.reverse();
        setReportsData([...orderedArray]);
      }
    },
    [reportsData, setReportsData],
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        if (data.initial_date) {
          // eslint-disable-next-line no-param-reassign
          data.initial_date = formatDate(data.initial_date);
          const isValid =
            new Date(data.initial_date).toString() !== 'Invalid Date';
          if (!isValid) {
            throw new Error('Data inválida');
          }
        }
        if (data.final_date) {
          // eslint-disable-next-line no-param-reassign
          data.final_date = formatDate(data.final_date);
          const isValid =
            new Date(data.final_date).toString() !== 'Invalid Date';
          if (!isValid) {
            throw new Error('Data inválida');
          }
        }

        const schema = Yup.object().shape({
          name: Yup.string(),
          initial_data: Yup.date(),
          final_data: Yup.date(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (user.role === 'professional') {
          const response = await ReportService.getSalesReports({
            professional_id: user.professional.id,
            name: data.name,
            initial_date: data.initial_date,
            final_date: data.final_date,
          });
          response.data.data && setReportsData(response.data.data);
          setPageCount(response.data.lastPage || 0);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        const responseErr = errorHandlerToToast({ error, formRef });
        if (responseErr) {
          addToast(responseErr);
        }
        const errors = getValidationErrors(error);

        Object.values(errors).forEach((value: string) => {
          addToast({
            title: 'Verifique os dados inseridos',
            description: value,
            type: 'error',
          });
        });
      }
    },
    [setLoading, addToast, user],
  );

  return (
    <>
      <Header headerTitle="Relatórios" />
      <Container>
        {!!reportsData?.length && (
          <>
            <FilterDiv>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Label>
                  Nome do aluno
                  <Input name="name" />
                </Label>
                <Label>
                  Data inicial:
                  <InputMask
                    name="initial_date"
                    type="tel"
                    placeholder="dd/mm/aaaa"
                    mask="99/99/9999"
                  />
                </Label>
                <Label>
                  Data final:
                  <InputMask
                    name="final_date"
                    type="tel"
                    placeholder="dd/mm/aaaa"
                    mask="99/99/9999"
                  />
                </Label>
              </Form>
              <Button
                type="button"
                active
                style={{ width: 200, height: 30 }}
                onClick={() => {
                  formRef.current?.submitForm();
                }}
              >
                Filtrar
              </Button>
            </FilterDiv>
            <Table
              reportData={reportsData}
              updateTable={onPageChange}
              pageCount={pageCount}
              sortTable={sortTable}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default Reports;
