import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--gap-xs);
`;

export const ViewWorkoutContent = styled.article`
  max-width: 797px;
  width: 100%;
`;

export const ViewWorkoutLevel = styled.span`
  letter-spacing: 0.68px;
  color: ${colors.lightGrey};
  text-transform: uppercase;
  margin-bottom: var(--gap);
  font-size: 1.6rem;
`;

export const ViewWorkoutTitle = styled.h3`
  letter-spacing: 1.36px;
  color: ${colors.lightnerGrey};
  font-size: 2.4rem;
  margin-bottom: var(--gap);

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.8rem;
  }
`;

export const ViewWorkoutDescription = styled.p`
  color: ${colors.lightGrey};
  font-size: 2rem;

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.6rem;
  }
`;

export const ViewWorkoutVideo = styled.video`
  height: 429px;
  max-width: 797px;
  width: 100%;
  margin-bottom: var(--gap);

  @media (max-width: 929px) {
    margin-top: var(--gap-sm);
  }

  @media (max-width: 768.9px) {
    max-height: 429px;
    height: 100%;
  }
`;

export const InteractionContentButton = styled.a`
  /* align-self: center; */
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;

  > button {
    background-color: #2c2c2c;
    width: 100%;
    max-width: 100%;
    height: 44px;
    text-transform: none;
    padding: 0;
  }
`;
