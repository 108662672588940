import { AxiosPromise } from 'axios';
import api, { getToken } from './api';

type CouponType = {
  name: string;
  percent_off: string;
  duration_days: string;
};

type ResponseCoupon = {
  id: number;
  name: string;
  percent_off: string;
  duration_days: string;
  active: boolean;
  professional_id: number;
  stripe_coupon_id: string;
  stripe_promotion_code_id: string;
  created_at: string;
  updated_at: string;
};

export const CouponServices = {
  CreateCoupon({
    name,
    percent_off,
    duration_days,
  }: CouponType): AxiosPromise<ResponseCoupon> {
    return api.post('/professional/create/coupon', {
      name,
      percent_off,
      duration_days,
    });
  },

  getCoupon({ professionalId }: any): AxiosPromise<any> {
    return api.get(`/coupon/${professionalId}`);
  },

  toggleCouponStatus({ couponId }: { couponId: number }): AxiosPromise<any> {
    return api.post(`/professional/coupons/${couponId}/toggle`);
  },
};
