import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const Label = styled.label`
  max-width: 463px;
  width: 100%;
  margin-top: 15px;
  color: ${colors.lightnerGrey};
  font-size: 21px;
  font-weight: 600;

  > div {
    display: flex;
    width: 100%;
    margin-top: 25px;
    border-color: #000;
    color: #fff;

    input {
      color: ${colors.lightnerGrey};
      width: 100%;

      &::placeholder {
        color: ${colors.lightnerGrey};
      }
    }
  }
`;
