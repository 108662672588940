import styled from 'styled-components';

export const ComplementaryText = styled.span`
  width: 100%;
  font-size: 1.2rem;
  color: gray;
  font-weight: normal;
  margin-top: 8px;
  text-align: left;
`;
