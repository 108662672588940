import { FiChevronLeft } from 'react-icons/fi';
import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const ModalContent = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 15px;

  h1 {
    color: ${colors.lightnerGrey};
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1.12px;
  }

  h2 {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 400;

    strong {
      color: #b88746;
    }
  }

  h6 {
    margin-top: 15px;
    color: ${colors.lightnerGrey};
    font-size: 18px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      max-width: 373px;
      width: 100%;
      margin-top: 25px;
      border-color: #000;
      color: #fff;

      input {
        color: ${colors.lightnerGrey};
        width: 100%;
        max-width: 373px;

        &::placeholder {
          color: ${colors.lightnerGrey};
        }
      }
    }
  }
`;

export const IconClose = styled(FiChevronLeft)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  color: ${colors.lightnerGrey};
  margin: 30px;

  transition: 180ms ease-in;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
  }
`;

export const Video = styled.video`
  height: 429px;
  max-width: 797px;
  width: 100%;

  @media (max-width: 929px) {
    margin-top: 30px;
  }

  @media (max-width: 768.9px) {
    max-height: 429px;
    height: 100%;
  }
`;
export const Content = styled.section`
  max-width: 797px;
  width: 100%;

  h6 {
    letter-spacing: 0.68px;
    color: ${colors.lightnerGrey};
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 400;
  }

  h1 {
    letter-spacing: 1.36px;
    color: ${colors.lightnerGrey};
    font-size: 34px;
    margin-top: 5px;
  }
  p {
    color: ${colors.lightGrey};
    font-size: 20px;
    font-weight: 400;
    margin-top: 12px;
  }
`;

export const LiveContent = styled.section`
  width: 85%;

  display: flex;
  flex-direction: column;
  /* row-gap: 16px; */
`;

export const LiveContentStatus = styled.span`
  font-size: 12px;
  line-height: 1.1em;
  color: ${colors.lightnerGrey};

  > strong {
    font-size: 16px;
    line-height: 1.1em;
    color: #b88746;
  }
`;

export const LiveContentTitle = styled.h3`
  letter-spacing: 1.36px;
  color: ${colors.lightnerGrey};
  font-size: 24px;
  line-height: 1.1em;
  margin-top: 4px;
`;

export const LiveContentDescription = styled.p`
  color: ${colors.lightGrey};
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 16px;
`;

export const LiveContentPrice = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 26px;

  > strong {
    font-weight: 700;
  }
`;

export const LiveContentButton = styled.a`
  width: 100%;
  max-width: 463px;
  > button {
    width: 100%;
    max-width: 463px;
  }
`;
