import styled from 'styled-components';

import { Button as ButtonBase } from '~/components';
import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

export const Header = styled.header`
  padding: var(--gap-sm) 20px;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SandwichMenuIcon = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  cursor: pointer;
  position: relative;

  span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: ${colors.lightGrey};
    border-radius: 3px;
    transition: all 0.3s ease-in-out;

    &:nth-child(1) {
      transform: ${({ isOpen }) =>
        isOpen ? 'translateY(7.5px) rotate(45deg)' : 'none'};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
    }

    &:nth-child(3) {
      transform: ${({ isOpen }) =>
        isOpen ? 'translateY(-7.5px) rotate(-45deg)' : 'none'};
    }

    @media (min-width: ${breakpoints.small}) {
      display: none;
    }
  }
`;

export const ButtonGroup = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  gap: var(--gap-sm);
  background-color: ${({ isOpen }) => (isOpen ? '#1a1a1a' : 'inherit')};
  z-index: 10000;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${breakpoints.small}) {
    position: absolute;
    top: 85px;
    left: 0;
    padding-bottom: 16px;
    flex-direction: column;
    width: 100%;

    > a {
      width: 100%;
      z-index: 1000;

      > button {
        width: 100%;
        margin: 0;
      }
    }
  }

  @media (min-width: ${breakpoints.small}) {
    display: flex;
  }
`;

export const Button = styled(ButtonBase)`
  line-height: 1.15em;
  /* margin: auto; */
  min-width: 309px;

  > strong {
    color: inherit;
    margin-left: 0.5rem;
    font-weight: 800;
  }

  @media (min-width: ${breakpoints.small}) and (max-width: 900px) {
    min-width: 240px;
  }

  @media (max-width: ${breakpoints.small}) {
    min-width: auto;
  }
`;
