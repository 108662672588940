import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import SwiperCore, {
  Autoplay,
  Lazy,
  Navigation,
  Pagination,
  Scrollbar,
  SwiperOptions,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import { ImageDance, ImageLives } from '~/assets/images';
import { Button, HeaderMySite } from '~/components';
import { useProfessor } from '~/hooks/professor';
import { useToast } from '~/hooks/Toast';
import {
  PageOptionsResponse,
  UpdateProfessionalResponse as Professional,
} from '~/models/Common';
import { Courses } from '~/models/Courses';
import { Lives } from '~/models/Lives';
import { Workouts } from '~/models/Workouts';
import { cleanRequests } from '~/services/api';
import LivesService from '~/services/LivesService';
import ProfessorService from '~/services/ProfessorService';
import errorHandlerToToast from '~/utils/errorHandler';
import getMimeTypeByExtVideo from '~/utils/getMimeType';
import Price from '~/utils/Price';

import {
  BoxSlider,
  Container,
  Course,
  Image,
  Live,
  LiveDescription,
  LiveFooter,
  LivePrice,
  LiveStatus,
  LiveTitle,
  MediaContainer,
  Video,
  Workout,
} from './styles';

import ViewCourse from './Courses/ViewCourse';

type SwiperBreakPoints = {
  [width: number]: SwiperOptions;
  [ratio: string]: SwiperOptions;
};

SwiperCore.use([Pagination, Navigation, Scrollbar, Autoplay, Lazy]);

interface routeParams {
  publicname: string;
}

interface state {
  cancelJuno: boolean;
}

const MySite: React.FC = () => {
  const [professional, setProfessional] = useState<Professional>();
  const [courses, setCourses] = useState<PageOptionsResponse<Courses>>();
  const [workouts, setWorkouts] = useState<PageOptionsResponse<Workouts>>();
  const [lives, setLives] = useState<PageOptionsResponse<Lives>>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<Courses>();
  const [selectedLive, setSelectedLive] = useState<Lives>();

  const { push } = useHistory();
  const { publicname } = useParams<routeParams>();
  const { addProfessor } = useProfessor();
  const { addToast } = useToast();
  const location = useLocation().state as state;
  const history = useHistory();

  const handleGetProfessional = useCallback(async (): Promise<void> => {
    try {
      const {
        data: professionalResponse,
      } = await ProfessorService.getProfessionalByPublicname(
        publicname,
        'mySite',
      );
      setProfessional(professionalResponse);
      addProfessor(professionalResponse);
      if (location?.cancelJuno) {
        push(`${publicname}/become-a-student`);
      }
    } catch (err: any) {
      const messageForToast = errorHandlerToToast({ error: err });
      if (messageForToast) addToast(messageForToast);
      push('/');
    }
  }, [addProfessor, addToast, publicname]);

  const handleGetCourses = useCallback(
    async (publicName: string): Promise<void> => {
      try {
        const response = await ProfessorService.getCourses(publicName);
        setCourses(response.data);
      } catch (err: any) {
        const errToToast = errorHandlerToToast(err);
        if (errToToast) addToast(errToToast);
      }
    },
    [addToast],
  );

  const handleGetWorkouts = useCallback(
    async (professionalId: number): Promise<void> => {
      try {
        const {
          data: { data: workoutsAndCourses, ...rest },
        } = await ProfessorService.getProfessionalWorkouts(professionalId);

        const onlyWorkouts = workoutsAndCourses.filter(
          ({ category }) => category.id,
        );

        setWorkouts({ data: onlyWorkouts, ...rest });
      } catch (err: any) {
        const errToToast = errorHandlerToToast(err);
        if (errToToast) addToast(errToToast);
      }
    },
    [addToast],
  );

  const handleGetLives = useCallback(
    async (publicName: string): Promise<void> => {
      try {
        const response = await LivesService.getLives(publicName);

        setLives(response.data);
      } catch (err: any) {
        const errToToast = errorHandlerToToast(err);
        if (errToToast) addToast(errToToast);
      }
    },
    [addToast],
  );

  const handleGoToBecomeStudent = useCallback((): void => {
    history.push(`/${publicname}/become-a-student`);
  }, [history, publicname]);

  const handleOpenModal = useCallback(
    course => {
      setModalIsOpen(!modalIsOpen);
      setSelectedCourse(course);
      setSelectedLive(undefined);
    },
    [modalIsOpen],
  );

  const handleOpenLiveModal = useCallback(
    live => {
      setModalIsOpen(!modalIsOpen);
      setSelectedLive({
        ...live,
        date: `${live.date.split('T')[0].split('-')[2]}/${
          live.date.split('T')[0].split('-')[1]
        }`,
        hour: `${live.hour.split(':')[0]}:${live.hour.split(':')[1]}h `,
      });
      setSelectedCourse(undefined);
    },
    [modalIsOpen],
  );

  const handleCloseModal = (): void => {
    setModalIsOpen(false);
    setSelectedCourse(undefined);
    setSelectedLive(undefined);
  };

  useEffect(() => {
    if (professional) {
      handleGetCourses(publicname);
      handleGetLives(publicname);
      handleGetWorkouts(professional?.id);
    } else {
      handleGetProfessional();
    }
    return cleanRequests;
  }, [
    handleGetCourses,
    handleGetLives,
    handleGetProfessional,
    handleGetWorkouts,
    professional,
    publicname,
  ]);

  const breakPoints: SwiperBreakPoints = {
    320: {
      width: 320,
      slidesPerView: 1,
      // spaceBetweenSlides: 5,
      spaceBetween: 5,
    },
    768: {
      width: 768,
      slidesPerView: 2,
      // spaceBetweenSlides: 5,
      spaceBetween: 5,
    },
    1280: {
      width: 1280,
      slidesPerView: 3,
    },
    1920: {
      width: 1920,
      slidesPerView: 4,
    },
  };

  const workoutsMemo = useMemo(
    () =>
      workouts?.data?.map(workout => {
        let myCategory: string;

        try {
          myCategory = workout.category.description;
        } catch (e) {
          myCategory = 'SEM CATEGORIA';
        }

        return (
          <SwiperSlide key={workout.id} onClick={handleGoToBecomeStudent}>
            <Workout>
              <button type="button">
                <img
                  style={{ height: '230px', width: 'auto' }}
                  src={workout.image ?? ImageDance}
                  alt="Workout"
                />
              </button>
              <div>
                <span>{myCategory}</span>
                <h6>{workout.title}</h6>
                <p>{workout.description}</p>
              </div>
            </Workout>
          </SwiperSlide>
        );
      }),
    [handleGoToBecomeStudent, workouts?.data],
  );

  const coursesMemo = useMemo(() => {
    return courses?.data
      ?.filter(course => Number(course.status) === 1)
      ?.map(course => (
        <SwiperSlide key={course.id} onClick={() => handleOpenModal(course)}>
          <Course status="Ativo">
            {course.video ? (
              <MediaContainer>
                <Video
                  onContextMenu={() => false}
                  controlsList="nodownload"
                  loop
                  controls
                  autoPlay
                  muted
                >
                  <source
                    src={course.video}
                    type={getMimeTypeByExtVideo(
                      course.video.split('.').pop() as string,
                    )}
                  />
                </Video>
              </MediaContainer>
            ) : (
              <MediaContainer>
                <Image
                  src={course?.foto_path ?? ImageDance}
                  alt={course?.title}
                  title={course?.title}
                />
              </MediaContainer>
            )}
            <span>Ativo</span>
            <h1>{course.title}</h1>
            <h3>
              Valor: <strong>{Price.floatToCurrency(course.price)}</strong>
            </h3>
            <h3>
              Tipo de pagamento:{' '}
              {course.payment_type === 'charge'
                ? 'Cobrança Única'
                : 'Assinatura'}
            </h3>
            {/* <h6>{course.students} Alunos</h6> */}
            <Link to={`/${publicname}/signup?course=${course.id}`}>
              <Button style={{ backgroundColor: '#57AB21' }}>Comprar</Button>
            </Link>
          </Course>
        </SwiperSlide>
      ));
  }, [courses?.data, handleOpenModal, publicname]);

  const livesMemo = useMemo(() => {
    return lives?.data?.map(live => (
      <SwiperSlide key={live.id} onClick={() => handleOpenLiveModal(live)}>
        <Live>
          <LiveStatus>
            Ao vivo -{' '}
            <strong>
              {live.date.split('T')[0].split('-')[2]}/
              {live.date.split('T')[0].split('-')[1]} -{' '}
              {live.hour.split(':')[0]}:{live.hour.split(':')[1]}H
            </strong>
          </LiveStatus>
          <img
            src={ImageLives}
            alt="Live"
            style={{ width: '100%', height: 'auto' }}
          />
          <LiveTitle>{live.title}</LiveTitle>
          <LiveDescription>{live.description}</LiveDescription>
          <LiveFooter>
            {!live.is_free && live.price && (
              <>
                <LivePrice>{Price.floatToCurrency(live.price)}</LivePrice>
                <Link to={`/${publicname}/signup?live=${live.id}`}>
                  <Button style={{ backgroundColor: '#57AB21' }}>
                    Comprar
                  </Button>
                </Link>
              </>
            )}
            {!!live.is_free && (
              <Link to={`/${publicname}/become-a-student`}>
                <Button style={{ backgroundColor: '#57AB21' }}>
                  Torne-se aluno
                </Button>
              </Link>
            )}
          </LiveFooter>
        </Live>
      </SwiperSlide>
    ));
  }, [handleOpenLiveModal, lives?.data, publicname]);

  return (
    <>
      {professional && <HeaderMySite professional={professional} />}
      <Container>
        <h1>Aulas</h1>
        <BoxSlider>
          {(workouts?.data?.length ?? 0) > 0 ? (
            <Swiper
              slidesPerView={4}
              spaceBetween={10}
              allowSlideNext
              allowSlidePrev
              draggable
              navigation
              breakpoints={breakPoints}
            >
              {workoutsMemo}
            </Swiper>
          ) : (
            <h2>Nenhuma aula encontrada</h2>
          )}
          <Link to={`/${publicname}/workouts`}>
            <Button>Ver todos</Button>
          </Link>
        </BoxSlider>
        <Link
          to={{
            pathname: `/${professional?.public_name}/become-a-student`,
            state: { professionalId: professional?.id },
          }}
        >
          <Button>Torne-se aluno</Button>
        </Link>
        <h1>Aulas Ao Vivo</h1>
        <BoxSlider>
          {(lives?.data?.length ?? 0) > 0 ? (
            <Swiper
              spaceBetween={10}
              slidesPerView={4}
              allowSlideNext
              allowSlidePrev
              draggable
              navigation
              lazy
              style={{ zIndex: 0 }}
              breakpoints={breakPoints}
            >
              {livesMemo}
            </Swiper>
          ) : (
            <h2>Nenhuma aula ao vivo encontrado.</h2>
          )}
          <Link to={`/${publicname}/lives`}>
            <Button>Ver todos</Button>
          </Link>
        </BoxSlider>
        <h1 style={{ marginTop: 70 }}>Módulos e Cursos</h1>
        <BoxSlider>
          {(courses?.data?.filter(course => Number(course.status) === 1)
            ?.length ?? 0) > 0 ? (
            <Swiper
              spaceBetween={10}
              slidesPerView={4}
              allowSlideNext
              allowSlidePrev
              draggable
              navigation
              lazy
              style={{ zIndex: 0 }}
              breakpoints={breakPoints}
            >
              {coursesMemo}
            </Swiper>
          ) : (
            <h2>Nenhum curso encontrado.</h2>
          )}
          <Link to={`/${publicname}/courses`}>
            <Button>Ver todos</Button>
          </Link>
        </BoxSlider>
      </Container>
      <ViewCourse
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        course={selectedCourse}
        live={selectedLive}
        handleCloseModal={handleCloseModal}
        publicname={publicname}
      />
    </>
  );
};

export default MySite;
