import { AxiosRequestConfig } from 'axios';

interface progressFunc {
  (percent: number): void;
}

interface handleProgressArgs {
  loaded: number;
  total: number;
  updateFunc: progressFunc;
}

const calculateProgress = ({
  loaded,
  total,
  updateFunc,
}: handleProgressArgs): void => {
  const ratio = loaded / total;
  const percent = Math.max(ratio * 100, 100);
  const resetWhenFully = percent % 100;
  updateFunc(resetWhenFully);
};

const progressConfigUpdate = (
  updateFunc: progressFunc,
  optionalConfig?: AxiosRequestConfig,
): AxiosRequestConfig => {
  return {
    onUploadProgress: ({ loaded, total }: ProgressEvent): void => {
      calculateProgress({ loaded, total, updateFunc });
    },
    ...optionalConfig,
  };
};

export const progressConfigUpdateJSON = (
  updateFunc: progressFunc,
  optionalConfig?: AxiosRequestConfig,
): AxiosRequestConfig => {
  const configWithHeader: AxiosRequestConfig = {
    headers: {
      'Context-Type': 'application/json',
    },
    ...optionalConfig,
  };
  return progressConfigUpdate(updateFunc, configWithHeader);
};

export const progressConfigUpdateFormData = (
  updateFunc: progressFunc,
  optionalConfig?: AxiosRequestConfig,
): AxiosRequestConfig => {
  const configWithHeader: AxiosRequestConfig = {
    headers: {
      'Context-Type': 'multipart/form-data',
    },
    ...optionalConfig,
  };
  return progressConfigUpdate(updateFunc, configWithHeader);
};
