import styled, { css } from 'styled-components';

interface DropdownProps {
  isSelected: boolean;
}

export const Dropdown = styled.div<DropdownProps>`
  display: none;
  ${props =>
    props.isSelected &&
    css`
      display: block;
    `}
  position: absolute;
  background: #fff;

  max-width: 173px;
  width: 100%;
  margin-left: 200px;
  margin-top: 25px;

  box-shadow: 0px 8px 15px #3e3e3e2e;
  border-radius: 3px;

  z-index: 10;

  @media (max-width: 768.9px) {
    margin-left: 100px;
  }
`;

export const List = styled.ul``;

export const Item = styled.li`
  display: flex;
  align-items: center;
  padding: 10px;
  color: #2c2c2c;
  letter-spacing: 0.64px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  transition: background 0.1s;

  > svg {
    margin-left: 0 !important;
    margin-right: 10px !important;
    position: relative !important;
    background: none !important;
  }

  &:hover {
    background: #aaaaaa;
  }
`;
