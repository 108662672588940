import { FC, useCallback, useMemo } from 'react';

import { ImageDance, ImageLives } from '~/assets/images';
import { Button } from '~/components';
import { DropdownType } from '~/models/Common';
import { DropdownIcon } from '~/styles/objects/dropdown-icon';
import { ItemsGrid } from '~/styles/objects/items-grid';
import { NotFoundItems } from '~/styles/objects/not-found';
import { handleChangeDropdown } from '~/utils/handleChangeDropdown';

import { DropdownMenu } from '..';

import * as W from './styles';

import { WorkoutsProps } from './typings';

const Workouts: FC<WorkoutsProps> = ({
  isProfessional,
  dropdownProps,
  handleOpenWorkoutModal,
  handleDeleteWorkout,
  workouts,
  getWorkouts,
  levelId,
}) => {
  const handleClick = useCallback(
    (idDropdown?: number): void => {
      if (idDropdown) {
        dropdownProps &&
          handleChangeDropdown({
            id: idDropdown,
            type: DropdownType.WORKOUT,
            isOpen: dropdownProps.dropdownOpen,
            setIsOpen: dropdownProps.setDropdownOpen,
            setItem: dropdownProps.setDropdownSelected,
          });
      }
    },
    [dropdownProps],
  );
  const formatDateLive = useCallback((date, hour) => {
    const new_date = new Date(date);
    const next_day = new Date(new_date);
    next_day.setDate(new_date.getDate() + 1);
    const day = next_day.getDate();
    const month = next_day.getMonth() + 1;
    // const year = new_date.getFullYear();
    return `${day < 10 ? `0${day}` : day}/${
      month < 10 ? `0${month}` : month
    } - ${hour?.slice(0, 5)}H`;
  }, []);

  const workoutsMemo = useMemo(() => {
    return workouts?.map(workout => (
      <W.Workout key={workout.id}>
        {isProfessional && (
          <>
            <DropdownIcon
              size={24}
              color="#FFF"
              onClick={() => handleClick(workout.id)}
            />
            <DropdownMenu
              dropdownIsOpen={dropdownProps?.dropdownOpen ?? false}
              setDropdownIsOpen={dropdownProps?.setDropdownOpen}
              id={`workout_${workout.id}`}
              dropdownSelected={dropdownProps?.dropdownSelected}
              getWorkouts={getWorkouts}
              workout={workout}
              handleDelete={() =>
                handleDeleteWorkout && handleDeleteWorkout(Number(workout.id))
              }
            />
          </>
        )}
        {!workout.url && (
          <>
            <W.ImageContainer>
              <W.WorkoutImage
                src={workout.image ?? ImageDance}
                alt="Miniatura da aula"
                onClick={() =>
                  handleOpenWorkoutModal && handleOpenWorkoutModal(workout)
                }
              />
            </W.ImageContainer>
            <W.WorkoutContent
              onClick={() =>
                handleOpenWorkoutModal && handleOpenWorkoutModal(workout)
              }
            >
              <span>{workout?.category?.description ?? ''}</span>
              <W.WorkoutTitle>{workout.title}</W.WorkoutTitle>
              <W.WorkoutDescription>{workout.description}</W.WorkoutDescription>
            </W.WorkoutContent>
          </>
        )}
        {workout.url && (
          <>
            <W.Interaction key={workout.id}>
              <W.InteractionLiveDate>
                Ao vivo -{' '}
                <strong>{formatDateLive(workout.date, workout.hour)}</strong>
              </W.InteractionLiveDate>
              <img
                src={ImageLives}
                alt="Live"
                style={{ width: '100%', height: 'auto' }}
              />
              <W.InteractionTitle>{workout.title}</W.InteractionTitle>
              <W.InteractionDescription>
                {workout.description}
              </W.InteractionDescription>
              {/* <strong>Valor: {workout.value}</strong> */}
              <W.InteractionMeetLink
                href={workout.url}
                target="_blank"
                rel="noreferrer"
              >
                <Button>Link para a reunião</Button>
              </W.InteractionMeetLink>
            </W.Interaction>
          </>
        )}
      </W.Workout>
    ));
  }, [
    dropdownProps,
    formatDateLive,
    getWorkouts,
    handleClick,
    handleDeleteWorkout,
    handleOpenWorkoutModal,
    isProfessional,
    levelId,
    workouts,
  ]);

  if (workouts?.length === 0)
    return <NotFoundItems>{/* Nenhuma aula encontrado. */}</NotFoundItems>;

  return <ItemsGrid style={{ padding: 0 }}>{workoutsMemo}</ItemsGrid>;
};

export default Workouts;
