import { createContext, useContext, useEffect, useState } from 'react';

import { merge } from 'lodash';
import * as Yup from 'yup';

import { SignInResponse, User, UserCredentials, UserData } from '~/models/User';
import api from '~/services/api';
import UserService from '~/services/UserService';

interface AuthState {
  token: string;
  user: UserData;
}

interface UpdateUserRequest {
  user: Partial<User>;
  redirect?: boolean;
}

interface AuthContextData {
  user: UserData;
  signInFromSignUp: (data: SignInResponse) => Promise<void>;
  updateUserAndBack: (state: UpdateUserRequest) => void;
  signin(credentials: UserCredentials): Promise<SignInResponse>;
  logout(): void;
  updateUserAvatar: (avatar: string) => void;
  publicName: string;
  handlePublicName: (value: string) => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  // const history = useHistory()

  const prefix = '@SeuCoach';
  const [publicName, setPublicName] = useState('');
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(`${prefix}:token`);
    const userString = localStorage.getItem(`${prefix}:user`);

    if (token && userString) {
      const user: UserData = JSON.parse(userString);
      api.defaults.headers.Authorization = `Bearer ${token}`;

      return { token, user };
    }
    return {} as AuthState;
  });

  const handlePublicName = (value: string): void => {
    const publicNameStoraged = localStorage.getItem(
      `${prefix}:professionalPublicName`,
    );

    if (value !== publicNameStoraged) {
      localStorage.setItem(`${prefix}:professionalPublicName`, value);
      setPublicName(value);
    }
  };

  useEffect(() => {
    const publicNameStoraged = localStorage.getItem(
      `${prefix}:professionalPublicName`,
    );
    if (publicNameStoraged) {
      setPublicName(publicNameStoraged);
    }
  }, []);

  useEffect(() => {
    const { user } = data;
    const lastDataStoraged = localStorage.getItem(`${prefix}:user`);
    const serializeData = JSON.stringify(user);

    if (serializeData && serializeData !== lastDataStoraged) {
      localStorage.setItem(`${prefix}:user`, serializeData);
    }
  }, [data]);

  const updateUserAndBack = ({
    user: userRequestNewData,
    redirect = true,
  }: UpdateUserRequest): void => {
    setData(({ token, user: userOld }) => ({
      token,
      user: merge(userOld, userRequestNewData),
    }));

    if (redirect) {
      window.history.go();
    }
  };

  const updateUserAvatar = (avatar: string): void => {
    setData(({ token, user: userOld }) => ({
      token,
      user: { ...userOld, avatar },
    }));
  };

  const signInFromSignUp = async (
    dataFromApi: SignInResponse,
  ): Promise<void> => {
    const {
      data: { token },
      user: { password: pass, ...user },
      public_name,
    } = dataFromApi;
    const schema = Yup.object().shape({
      [user.role]: Yup.object().shape({
        id: Yup.number().integer().positive(),
      }),
    });
    await schema.validate(user);

    localStorage.setItem(`${prefix}:token`, token);
    localStorage.setItem(`${prefix}:user`, JSON.stringify(user));
    api.defaults.headers.Authorization = `Bearer ${token}`;
    setData({ token, user });
  };

  const signin = async ({
    email,
    password,
    publicName: publicNameSignin,
  }: UserCredentials): Promise<SignInResponse> => {
    const { user } = data;

    if (user) logout();

    const response = await UserService.signin({
      email,
      password,
      // publicName:publicNameSignin
    });

    if ('isAxiosError' in response) {
      throw new Error(response.message);
    } else if (response.data.link) {
      return response.data;
    } else {
      signInFromSignUp(response.data);
    }
    return response.data;
  };

  const logout = (): void => {
    ['token', 'user', 'selectedProfessional'].forEach(key => {
      localStorage.removeItem(`${prefix}:${key}`);
    });
    setData({} as AuthState);
    api.defaults.headers.Authorization = '';
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signin,
        logout,
        updateUserAndBack,
        signInFromSignUp,
        updateUserAvatar,
        publicName,
        handlePublicName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
