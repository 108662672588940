import styled from 'styled-components';

import { Button } from '../../pages/Common/Configuration/styles';

import { colors } from '../settings/colors';

export const GreenButton = styled(Button)`
  background: ${colors.green} 0% 0% no-repeat padding-box;
  font-size: 1.8rem;
`;
