/* eslint-disable react/require-default-props */
import { Dispatch, ReactElement, SetStateAction, useCallback } from 'react';

import ReactPaginate from 'react-paginate';

import { PageOptionsRequest } from '~/models/Common';

import { Container } from './styles';

interface PaginateProps {
  pageRangeDisplayed: number;
  pageCount: number;
  setPageOptions: Dispatch<SetStateAction<PageOptionsRequest>>;
  noScroll?: boolean;
}

export interface HandlerPageChange {
  ({ selected }: { selected: number }): void;
}

const Paginate = ({
  setPageOptions,
  pageCount,
  pageRangeDisplayed,
  noScroll = false,
  ...props
}: PaginateProps): ReactElement => {
  const handlePage: HandlerPageChange = useCallback(
    ({ selected }) => {
      setPageOptions((prevState: PageOptionsRequest) => ({
        ...prevState,
        page: selected + 1,
      }));
      if (noScroll) {
        return;
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [setPageOptions, noScroll],
  );

  const nextClassName = pageCount === 0 ? 'disabled' : 'item control';

  return (
    <>
      {pageCount > 1 && (
        <Container>
          <ReactPaginate
            containerClassName="container"
            pageClassName="item"
            activeClassName="active"
            previousLabel="Anterior"
            nextLabel="Próximo"
            {...{ nextClassName, pageCount, pageRangeDisplayed }}
            previousClassName="item control"
            disabledClassName="disabled"
            breakClassName="item"
            onPageChange={handlePage}
            {...props}
            marginPagesDisplayed={2}
          />
        </Container>
      )}
    </>
  );
};

export default Paginate;
