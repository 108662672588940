import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    text-transform: uppercase;
  }

  h3 {
    font-weight: 500;
    margin-top: 10px;
  }

  a {
    width: 100%;
    max-width: 309px;
    background: #b88746;
    box-shadow: 0px 3px 6px #57575729;
    border-radius: 60px;
    text-decoration: none;
    text-align: center;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    padding: 12px;
    margin-top: 20px;
  }
`;
