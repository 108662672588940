import styled from 'styled-components';

import { Button as ButtonBase } from '~/components';
import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';
import { appearFromLeft } from '~/styles/tools/transitions';

export const Container = styled.div`
  flex: 1;
  display: flex;
  gap: 3rem;
  flex-direction: column;
  padding: 2rem;
`;

export const Hero = styled.main`
  position: relative;

  @media (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.large}) {
    height: 100vh;
  }

  @media (min-width: ${breakpoints.medium}) {
    height: auto;
  }

  @media (max-width: ${breakpoints.small}) {
    background-position: top;
  }
`;

export const HeroContent = styled.section`
  animation: ${appearFromLeft} 1s;

  > a {
    width: 100%;

    @media (max-width: ${breakpoints.small}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  > a > button {
    max-width: 380px;
    width: 100%;
    background: ${colors.green} 0% 0% no-repeat padding-box;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 2.2rem;
  }

  @media (max-width: ${breakpoints.small}) {
    > a button {
      font-size: 1.6rem;
    }
  }
`;

export const HeroTitle = styled.h1`
  max-width: 620px;
  color: ${colors.terciary};
  text-shadow: 0px 3px 6px #00000070;
  font-size: 3.6rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: var(--gap-xs);

  @media (max-width: ${breakpoints.small}) {
    font-size: 2.2rem;
  }

  bottom: 1.6rem;
`;

export const HeroText = styled.p`
  /* margin: 0 auto; */
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
  text-shadow: 0px 3px 6px #0000008a;
  text-align: left;
  margin-bottom: var(--gap-md);

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.6rem;
  }

  @media (min-width: ${breakpoints.small}) {
    max-width: 450px;
  }
`;

export const Button = styled(ButtonBase)`
  line-height: 1.15em;
  /* margin: auto; */
  min-width: 309px;

  > strong {
    color: inherit;
    margin-left: 0.5rem;
    font-weight: 800;
  }

  @media (min-width: ${breakpoints.small}) and (max-width: 900px) {
    min-width: 240px;
  }

  @media (max-width: ${breakpoints.small}) {
    min-width: auto;
  }
`;

export const NavigateToLesson = styled(Button)`
  background: ${colors.primary} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px ${colors.gray};
`;

export const LiveTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const RedDot = styled.div`
  width: 16px;
  height: 16px;
  background-color: red;
  border-radius: 50%;
`;
