import { useCallback, useEffect, useState } from 'react';

import { Route, Redirect, useHistory } from 'react-router-dom';

import { Footer, Navbar, CompleteRegistrationAdvise } from '~/components';
import { useAuth } from '~/hooks/Auth';
import { PaymentLayout } from '~/layout';
import ChooseNewPlan from './ChooseNewPlan';

import ConfigurationProfessor from './Configuration';
import CourseDetail from './Courses/CourseDetail';
import MyCourses from './Courses/MyCourses';
import MyWorkouts from './MyWorkouts';
import Reports from './Reports';

const Professor: React.FC = () => {
  const [isShowCompleteToast, setCompleteToast] = useState(false);
  const { user } = useAuth();
  const { pathname } = useHistory().location;

  const checkIfUserHaveCompleteRegistration = useCallback(() => {
    if (user) {
      const addressFields = Object.entries(user).filter(([propName]) =>
        /^address_(?!complement)/g.test(propName),
      );
      const addressRequired = addressFields.some(
        ([, adressValue]) => !adressValue,
      );
      const phoneRequired = !user.cell_phone;

      setCompleteToast(phoneRequired || addressRequired);
    }
  }, [user]);

  useEffect(() => {
    checkIfUserHaveCompleteRegistration();
  }, [checkIfUserHaveCompleteRegistration, user]);

  return (
    <>
      <div>
        <Navbar />
        <Route path="/professor/my-workouts" exact component={MyWorkouts} />
        <Route path="/professor/my-courses/" exact component={MyCourses} />
        <Route path="/professor/choose-new-plan" exact component={()=><PaymentLayout chooseNewPlan /> }  />
        <Route
          path="/professor/my-courses/:id"
          exact
          component={CourseDetail}
        />
        <Route
          path="/professor/config"
          exact
          component={ConfigurationProfessor}
        />
        <Route path="/professor/reports" exact component={Reports} />
        <Redirect
          from="/professor"
          to="/professor/my-workouts"
          exact
        />
        <Footer />
      </div>

      {isShowCompleteToast && pathname !== '/professor/config' && (
        <CompleteRegistrationAdvise />
      )}
    </>
  );
};

export default Professor;
