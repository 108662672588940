import { AxiosPromise } from 'axios';

import { PageOptionsRequest, PageOptionsResponse } from '~/models/Common';
import { Courses } from '~/models/Courses';

import api, { getToken } from './api';

const PER_PAGE_DEFAULT = process.env.REACT_APP_PER_PAGE_DEFAULT ?? 12;

export default {
  async getCourseById(courseId: number): Promise<Courses[]> {
    const response = await api.get(`/courses/${courseId}`, {
      cancelToken: getToken().token,
    });

    return response.data;
  },

  deleteCourseById(
    professionalId: number,
    courseId: number,
  ): Promise<Courses[]> {
    return api.delete(`/professionals/${professionalId}/courses/${courseId}`);
  },

  getStudentCourses({
    studentId,
    page = 1,
    perpage = PER_PAGE_DEFAULT,
  }: {
    studentId: number,
    page?: number,
    perpage?: number,
  }
  ): AxiosPromise<PageOptionsResponse<Courses>> {
    return api.get(`/students/${studentId}/courses`, {
      params: {
        page,
        perpage,
      },
      cancelToken: getToken().token,
    });
  },

  getSuggestionsCourses(
    { page = 1, perpage = PER_PAGE_DEFAULT }: {
      page?: number,
      perpage?: number
    }
  ): AxiosPromise<PageOptionsResponse<Courses>> {
    return api.get(`/courses/all/suggestions`, {
      params: {
        page,
        perpage,
      },
      cancelToken: getToken().token,
    });
  },
};
