import React from 'react';

import Modal from '~/components/Modal';
import { ModalContent } from '~/components/Modal/styles';

import {
  Address,
  ListItem,
  ListItemInline,
  ListOrdenatedByLetter,
  ListOrdenatedByLetterInline,
  ListTopic,
  ListTopicHeader,
  ListTopicItem,
  Paragraph,
  Subtitle,
  Title,
} from '../styles';

import Section from '../Section';

interface ModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: (state: boolean) => void;
}

const UseTerms: React.FC<ModalProps> = ({ modalIsOpen, setModalIsOpen }) => {
  return (
    <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}>
      <ModalContent>
        <Title>Termos de uso</Title>
        <Subtitle>Bem vindo à plataforma digital Seu.coach!</Subtitle>
        <Paragraph>
          Nossa plataforma oferece um espaço para treinadores e profissionais de
          Educação Física criarem ou divulgarem produtos digitais de forma
          simples e prática, possibilitando que as pessoas vivam de suas
          profissões e ensinem o que têm de melhor para o mundo todo. Estes
          Termos regem o uso da Plataforma e de todos os recursos, aplicativos,
          serviços, tecnologias e softwares disponibilizados aqui pela empresa
          Fityouself consultoria para atividades físicas, exceto quando
          esclarecemos expressamente que outros termos (e não estes) se aplicam.
        </Paragraph>
        <Paragraph>
          Ao acessar ou usar a Plataforma, você concorda em se vincular a estes
          Termos e em cumprir as suas regras. Você deve ler estes Termos de
          forma atenta e cuidadosa, pois eles contêm informações importantes
          sobre seus direitos e obrigações relativos ao acesso ou uso dos
          recursos ou Serviços da Plataforma Seu.coach.
        </Paragraph>
        <Section titleTopic="Visão geral">
          <ListOrdenatedByLetter>
            <ListItem>
              Estes Termos regulam o acesso ou uso de recursos contidos na
              Plataforma a partir de transações efetuadas na moeda Real (BRL)
              entre Usuários que, no seu cadastro na Plataforma, declarem
              domicílio no Brasil.
            </ListItem>
            <ListItem>
              A empresa com quem você contrata para usar a Plataforma pode
              variar de acordo com o seu país de domicílio. Ao aceitar estes
              Termos e usar a Plataforma nas condições descritas no parágrafo
              anterior, a empresa com quem você está contratando é a Fityourself
              Consultoria para atividades físicas EIRELI., pessoa jurídica de
              direito privado, inscrita no CNPJ/ME sob o nº. 32090167/0001-47,
              sediada em{' '}
              <Address>
                Rio de Janeiro, Estado do Rio de Janeiro, na Rua Barão de
                Mesquita, nº 850 - bloco B - 1009, Bairro Grajaú, CEP 20.540-004
              </Address>{' '}
              que atua na condição de intermediadora de negócios. Essa empresa
              pode ser denominada Fityourself ou simplesmente Seu.coach ao longo
              destes Termos.
            </ListItem>
            <ListItem>
              Se você ou sua empresa mudar o país de domicílio, o seu cadastro
              deverá ser atualizado, pois o seu acesso e uso da Plataforma
              poderá ser regidos por novos termos, de acordo com a política
              regional, moeda e mercado.
            </ListItem>
            <ListItem>
              Estes Termos constituem um contrato vinculante entre você, como
              Usuário, e a Fityourself, e regem seu acesso e uso:
              <ListOrdenatedByLetterInline>
                <ListItemInline>
                  do site seu.coach e seus subdomínios;
                </ListItemInline>
                <ListItemInline>
                  de quaisquer outros sites, interfaces ou aplicativos nos quais
                  a Fityourself disponibilize seus recursos, inclusive nossos
                  aplicativos de celular, tablet ou de outros dispositivos
                  eletrônicos; e
                </ListItemInline>
                <ListItemInline>
                  de todos os serviços relacionados aos recursos
                  disponibilizados.
                </ListItemInline>
              </ListOrdenatedByLetterInline>
            </ListItem>
            <ListItem>
              Ao aceitar estes Termos, você declara que conhece e concorda com o
              seu conteúdo e com as demais Políticas da Fityourself aplicáveis,
              inclusive com a Política de Privacidade. As Políticas da
              Plataforma Seu.coach são parte integrante destes Termos e se
              incorporam a eles por referência, ainda que sejam apresentadas em
              textos separados. Em caso de conflito, as regras destes Termos
              devem prevalecer sobre as condições estabelecidas em outras
              Políticas da Fityourself, exceto se houver previsões específicas
              estabelecendo que determinada Política prevalece. Estes Termos e
              suas atualizações também prevalecem sobre todas as propostas,
              entendimentos ou acordos anteriores, verbais ou escritos, que
              possam existir entre você e Seu.coach.
            </ListItem>
            <ListItem>
              Após você aceitar estes Termos, a Seu.coach concede a você
              automaticamente uma licença de uso não exclusiva da Plataforma. Os
              recursos contidos na Plataforma Seu.coach são licenciados no
              estado em que se encontram. Eles podem ser modificados,
              substituídos ou removidos da Plataforma pela Fityourself a
              qualquer momento, sem aviso prévio.
            </ListItem>
            <ListItem>
              O respeito às condições destes Termos é essencial para o uso
              legítimo dos recursos disponibilizados na Plataforma. Se você usar
              a Plataforma de modo incompatível com estes Termos, a Fityourself
              pode aplicar diferentes medidas, a qualquer tempo, com ou sem
              aviso prévio. Essas medidas podem incluir, sem prejuízo de outras,
              <ListOrdenatedByLetterInline>
                <ListItemInline>
                  a suspensão do acesso à sua conta,
                </ListItemInline>
                <ListItemInline>
                  o cancelamento do seu cadastro ou
                </ListItemInline>
                <ListItemInline>
                  o encerramento da sua licença de uso da Plataforma. O modo de
                  aplicação dessas e de outras medidas é detalhada ao longo
                  destes Termos.
                </ListItemInline>
              </ListOrdenatedByLetterInline>
            </ListItem>
            <ListItem>
              Ao aceitar estes Termos, você autoriza que a Fityourself reporte
              às autoridades competentes qualquer ato relacionado ao acesso ou
              uso da Plataforma que considere ter indícios de irregularidades ou
              ilegalidades. O Usuário reportado não pode exigir que a
              Fityourself pague qualquer tipo de indenização, por dano
              patrimonial ou extrapatrimonial, por consequência dessa
              comunicação.
            </ListItem>
            <ListItem>
              Em atenção a diretrizes expedidas por órgãos de alcance
              internacional, como o Office of Foreign Assets Control dos Estados
              Unidos, a United Kingdom Sanctions List ou a United Nations
              Security Council Sanctions List, a Fityourself não transaciona ou
              opera com Usuários localizados em determinados países ou regiões,
              em atendimento às normas e boas práticas globais voltadas à
              prevenção da lavagem de dinheiro, atos fraudulentos e
              financiamento de atividades ilícitas.
            </ListItem>
            <ListItem>
              A Fityourself se preocupa com sua privacidade e tem o compromisso
              de preservá-la. O tratamento de dados pessoais relacionados ao seu
              acesso e uso da Plataforma está descrito na nossa{' '}
              <em>política de privacidade</em>.
            </ListItem>
            <ListItem>
              Todo serviço de processamento de pagamento relacionado ao seu uso
              da Plataforma ou realizado por meio da Plataforma é prestado a
              você conforme estabelecido na Política de Pagamento.
            </ListItem>
            <ListItem>
              Ao usar os Serviços da Plataforma Seu.coach com relação a qualquer
              Produto, você se responsabiliza por identificar, compreender e
              cumprir todas as leis, regras e regulamentações aplicáveis. Isso
              pode incluir, entre outras, normas de natureza cível, normas sobre
              propriedade intelectual, privacidade, uso de dados pessoais, sobre
              o anúncio de seu Produto e sobre sua comercialização perante os
              Compradores. As normas aplicáveis também podem exigir providências
              de natureza regulatória, como obtenção de licenças, permissões ou
              autorizações por órgãos públicos, além de inscrições em registros
              profissionais e o respeito a regulamentações emitidas por eles.
              Regras sobre tributos ou contabilidade também podem incidir. Em
              caso de dúvidas sobre como as leis locais se aplicam aos seus
              negócios na Plataforma ou à sua relação com a Fityourself, procure
              a sua assessoria jurídica ou contábil.
            </ListItem>
            <ListItem>
              A Fityourself se reserva o direito de modificar estes Termos a
              qualquer tempo. Se estes Termos forem alterados, a Fityourself
              publicará na Plataforma os Termos revisados, e informará a data da
              última atualização no seu início (“versão atualizada em”). Também
              enviaremos a você, por e-mail, uma notificação sobre as alterações
              antes da data de início da sua vigência. Se você discordar da nova
              versão dos Termos, você pode encerrar este Contrato com efeito
              imediato. Se você não encerrar seu Contrato antes da data de
              vigência da nova versão dos Termos, seu acesso contínuo ou seu uso
              da Plataforma significará que você aceita as alterações.
            </ListItem>
            <ListItem>
              Se a Fityourself não exercer imediatamente algum direito que lhe
              seja cabível na forma da lei ou destes Termos, por qualquer
              motivo, ou mesmo se deixar de exercer algum direito em determinado
              caso, isso não significa que a Fityourself estará renunciando ao
              exercício desses direitos. A Fityourself pode exercer seus
              direitos a qualquer tempo, a seu critério, e se a mesma renunciar
              ao exercício de qualquer um de seus direitos individualmente isso
              não significa que estará renunciando ao exercício de seus direitos
              como um todo.
            </ListItem>
            <ListItem>
              As expressões iniciadas por letras maiúsculas nestes Termos ou nas
              Políticas da Fityourself têm significado próprio, conforme
              detalhado no Glossário disponível neste link. Para compreender em
              mais detalhes o conteúdo destes Termos e das Políticas da
              Fityourself, recomendamos que você consulte o significado dessas
              expressões no Glossário.
            </ListItem>
            <ListItem>
              O uso de exemplos nestes Termos, bem como o uso de expressões como
              “inclusive”, “incluindo” e outros termos semelhantes, não pode ser
              interpretado de modo a limitar a abrangência das regras que os
              utilizam, tendo sempre função exemplificativa e, portanto, não
              exaustiva.
            </ListItem>
            <ListItem>
              O idioma oficial destes Termos é o português brasileiro. Qualquer
              versão destes Termos em outro idioma é uma tradução fornecida por
              cortesia. Em caso de conflito, a versão em português deve
              prevalecer para as contratações.
            </ListItem>
          </ListOrdenatedByLetter>
        </Section>
        <ListTopic>
          <ListTopicItem>
            <ListTopicHeader>
              Os Serviços Prestados Pela Fityourself
            </ListTopicHeader>
            <ListTopic>
              <ListTopicItem>
                Escopo dos Serviços da Fityourself: A Plataforma Seu.coach
                disponibiliza um conjunto de recursos online que permitem aos
                seus Usuários consumir, criar, divulgar ou comercializar
                produtos que oferecem conteúdos em diversos formatos digitais
                (vídeo, áudio, texto etc.). Quando os Usuários realizam uma
                transação sobre um Produto mediante a Plataforma, eles celebram
                um contrato diretamente um com o outro. A Fityourself não é e
                não se torna parte, interveniente ou garantidora de qualquer
                relação entre os Usuários. A Fityourself não atua como agente ou
                distribuidor de qualquer Usuário.
              </ListTopicItem>
              <ListTopicItem>
                Relação entre os Usuários com a Fityourself: Ao utilizar a
                Plataforma como Produtor, Afiliado ou Comprador, você é um
                contratante de serviços de intermediação da Fityourself e não se
                torna empregado, colaborador, representante, agente, sócio,
                associado ou parceiro. Você não pode se apresentar como se
                tivesse vínculos desses tipos com a Fityourself ou a plataforma
                (por exemplo, você não pode se descrever nas redes sociais como
                “vendedor da Plataforma Seu.coach” ou “agente da Plataforma
                Seu.coach”). Você deve atuar exclusivamente em seu próprio nome
                e em seu próprio benefício, e não pode atuar em nome ou em
                benefício da Fityourself. Você tem liberdade de atuação e
                discricionariedade para divulgar e comercializar os Produtos dos
                quais você seja Produtor ou Afiliado, respeitadas as normas
                legais, as regras destes Termos e a Política de Uso Responsável
                da Fityourself, bem como para desempenhar suas atividades
                comerciais e estabelecer suas relações de trabalho como achar
                adequado.
              </ListTopicItem>
              <ListTopicItem>
                Titularidade dos Produtos: A condição de titular e fornecedora
                dos recursos disponibilizados pela Plataforma não significa que
                a Fityourself cria, elabora, controla, endossa ou fornece
                qualquer Produto. Os Produtores são integralmente responsáveis
                pelo conteúdo e pelas informações relativas aos seus Produtos,
                inclusive suas regras de oferta e uso. A Fityourself não se
                responsabiliza por quaisquer disputas ou danos, de qualquer
                natureza, que possam surgir do relacionamento entre seus
                Usuários, ou deles com terceiros. Em especial, a Fityourself não
                é responsável por qualquer pretensão que você possa ter por
                confiar em uma informação fornecida por um Produtor, por um
                Afiliado ou veiculada em um Produto.
              </ListTopicItem>
              <ListTopicItem>
                Inexistência de Promessas de Desempenho, Ganho ou Resultados
                decorrentes do Uso da Plataforma: A Fityourself não promete nem
                garante que você atingirá qualquer desempenho, ganho ou
                resultado com o uso da Plataforma ou com a aquisição de qualquer
                Produto. Além disso, nenhum Produtor ou Afiliado pode prometer
                desempenho, ganho ou resultado de qualquer natureza, seja
                decorrente do uso da Plataforma, de sua condição de Usuário, ou
                do uso ou comercialização dos Produtos. Assim, por exemplo,
                nenhum Produtor ou Afiliado pode prometer que o uso da
                Plataforma proporciona renda extra ao Usuário, nem anunciar que
                o uso de um Produto garante benefícios à saúde do Comprador.
              </ListTopicItem>
              <ListTopicItem>
                Conformidade dos Produtos com as Políticas da Fityourself: A
                Plataforma Seu.coach disponibiliza um espaço e recursos para
                quem deseja criar, divulgar ou comercializar um Produto de forma
                simples e prática, possibilitando que as pessoas vivam de suas
                paixões e ensinem o que têm de melhor para o mundo inteiro. Por
                isso, qualquer Produtor pode usar a Plataforma para criar,
                divulgar ou comercializar seus Produtos. Em linha com a
                legislação aplicável, a Fityourself não se obriga a fazer
                controle prévio de conteúdo (editorial ou de qualquer outra
                natureza) dos Produtos, nem a fazer curadoria de qualquer tipo
                dos Produtos. A Fityourself também não se obriga a fazer
                controle prévio da sua base de Usuários.
              </ListTopicItem>
              <ListTopicItem>
                Processos de Verificação conduzidos pela Fityourself: A
                Fityourself leva a integridade da comunidade que usa a
                Plataforma muito a sério, e por isso se reserva o direito de
                realizar verificações antes ou depois do cadastro dos Usuários,
                da oferta de um Produto ou da inserção de algum conteúdo na
                Plataforma, bem como o direito de exigir informações adicionais
                ou até mesmo mudanças a Produtos cadastrados na Plataforma.
                Quaisquer referências a um Usuário ou Produto sendo “analisado”
                (ou linguagem similar) apenas indica que o Usuário ou Produto se
                submete a um processo de verificação cadastral, e nada mais.
                Nenhuma dessas descrições significa endosso, certificação ou
                garantia da Fityourself sobre a qualidade do Produto. Essas
                descrições também não representam qualquer atestado de que o
                Usuário ou o Produto estão adequados ao disposto na legislação
                aplicável, nestes Termos ou nas Políticas da Fityourself.
                Lembramos que os Usuários são os responsáveis por cumprir todas
                as regras aplicáveis aos Produtos cadastrados e à sua
                comercialização, e não a Fityourself.
              </ListTopicItem>
              <ListTopicItem>
                Marketplace e Anúncio através de Serviços de Terceiros: Para
                ampliar a exposição dos Produtos, a Fityourself pode incluí-los
                em seu marketplace na Plataforma, de acordo com critérios
                estabelecidos pela própria. A Plataforma Seu.coach também pode
                mencionar ou promover os Produtos em suas comunicações, interna
                ou externa, bem como em outros sites, em aplicativos, em
                publicidade online e off-line, inclusive por meio de Serviços de
                Terceiros. Se qualquer dessas hipóteses ocorrer, a Fityourself
                preserva sua independência em relação aos Produtos e aos
                Produtores, e essa divulgação não significa que a Fityourself
                endossa ou concorda com o conteúdo dos Produtos. Além disso, os
                Produtores que tiverem seus Produtos divulgados não fazem jus a
                qualquer benefício, remuneração ou indenização por essa
                divulgação.
              </ListTopicItem>
              <ListTopicItem>
                Acesso à Internet e à Plataforma: Devido à natureza da Internet,
                a Fityourself não pode garantir que a Plataforma fique
                disponível e acessível ininterruptamente. Além disso, a
                Fityourself pode restringir a disponibilidade da Plataforma ou
                de certas áreas ou recursos a ela relacionados, se necessário,
                considerando os limites de capacidade, a segurança ou a
                integridade de seus servidores, bem como para realizar medidas
                de manutenção ou aprimoramento dos seus serviços. A Fityourself
                não pode ser responsabilizada pelo Usuário ou por qualquer
                terceiro em função do impedimento ou alteração na forma de
                acesso à Plataforma e aos Serviços. A Fityourself pode melhorar
                e alterar a Plataforma a qualquer tempo, seja para modificar,
                substituir ou remover Serviços existentes, ou para adicionar
                Serviços novos.
              </ListTopicItem>
              <ListTopicItem>
                Prestação de Serviços de Terceiros: A Plataforma pode conter
                links para sites ou recursos de terceiros. Esses Serviços de
                Terceiros não integram o escopo dos Serviços da Fityourself e
                não fazem parte da Plataforma. Por isso, eles estão sujeitos a
                termos e condições diferentes, inclusive com relação às práticas
                de privacidade. A Fityourself não é responsável pela
                disponibilidade ou pela precisão dos Serviços de Terceiros,
                tampouco pelo seu conteúdo, pelos produtos ou pelos serviços
                disponíveis nesses Serviços de Terceiros. Os links para esses
                Serviços de Terceiros não representam endosso ou concordância da
                Fityourself a seu respeito.
              </ListTopicItem>
              <ListTopicItem>
                Atendimento ao Cliente e Solução de Controvérsias entre os
                Usuários: A Plataforma Seu.coach dispõe de mecanismos de
                atendimento aos Usuários para facilitar a solução de problemas
                relativos aos Serviços da Fityourself. A Fityourself também
                dispõe de mecanismos destinados a facilitar a comunicação para
                solução de problemas e eventuais conflitos entre você e outro
                Usuário, funcionando como canal de diálogo em busca de solução
                consensual entre os Usuários. A Fityourself não disponibiliza
                serviço de atendimento ao consumidor para resolver problemas
                relativos aos Produtos, o que é de responsabilidade integral dos
                Produtores.
              </ListTopicItem>
            </ListTopic>
          </ListTopicItem>
          <ListTopicItem>
            <ListTopicHeader>
              Elegibilidade, cadastro e acesso à plataforma
            </ListTopicHeader>
            <ListTopic>
              <ListTopicItem>
                Elegibilidade dos Produtores, Colaboradores ou Afiliados: Para
                registrar uma conta na Plataforma como Produtor, Colaborador ou
                Afiliado, é necessário que você tenha pelo menos 18 anos de
                idade, ou seja emancipado, nos moldes da legislação aplicável.
                Ao aceitar estes Termos, você declara ser plenamente capaz para
                exercer todos os atos da vida civil. Se o cadastro na
                Fityourself for efetuado em nome de pessoa jurídica, você
                declara que tem poder e todas as autorizações necessárias para
                vinculá-la regularmente, inclusive para conceder à Fityourself
                todas as permissões e licenças referidas nestes Termos ou nas
                Políticas da Fityourself.
              </ListTopicItem>
              <ListTopicItem>
                Elegibilidade dos Compradores: A idade mínima para Compradores
                se cadastrarem na Plataforma é de 13 anos. Adolescentes entre 13
                e 18 anos precisam estar regularmente autorizados pelos pais ou
                responsáveis para tanto. O cadastro na Plataforma e a aceitação
                destes Termos pressupõe que essa autorização foi concedida. A
                Fityourself deve cancelar o cadastro de Usuário menor de 18 anos
                a pedido dos pais ou responsáveis, mas isso não gera direito a
                qualquer indenização ou reembolso em razão dos Produtos
                adquiridos pelo menor durante a vigência de seu cadastro.
              </ListTopicItem>
              <ListTopicItem>
                Usuários Restritos: A Fityourself pode recusar ou cancelar o
                cadastro de determinado Usuário se tomar conhecimento, por si ou
                por denúncia fundamentada de terceiro, da existência de
                condenação, mediante sentença transitada em julgado, por
                <ListOrdenatedByLetterInline>
                  <ListItemInline>
                    crimes hediondos, ou equiparados a crimes hediondos;
                  </ListItemInline>
                  <ListItemInline>
                    envolvimento em organizações criminosas, lavagem de
                    dinheiro, atos terroristas ou tráfico internacional de
                    pessoas; ou
                  </ListItemInline>
                  <ListItemInline>
                    crimes cometidos por meios eletrônicos ou mecanismos
                    cibernéticos.
                  </ListItemInline>
                </ListOrdenatedByLetterInline>
              </ListTopicItem>
              <ListTopicItem>
                Suspensão ou Encerramento da sua Conta: Se a Fityourself limitar
                seu acesso ou uso da Plataforma, suspender sua conta ou encerrar
                este Contrato, você não poderá registrar uma nova conta na
                Plataforma, nem acessar ou usar a Plataforma através da conta de
                outro Usuário.
              </ListTopicItem>
              <ListTopicItem>
                Cadastro: Para usar a Plataforma, você se obriga a preencher
                adequadamente e com informações corretas todos os dados
                solicitados pela na página de cadastramento. A Fityourself pode
                usar quaisquer meios legalmente admitidos para lhe identificar,
                assim como pode requerer de você a qualquer momento dados
                adicionais para complementar aqueles já fornecidos. Se você
                enviar informações inverídicas, incorretas ou incompletas, o seu
                cadastro na Plataforma pode ser suspenso ou cancelado. A
                Fityourself pode recusar seu cadastro ou cancelá-lo se entender
                que há indícios de que você está usando ou tende a utilizar a
                Plataforma em desacordo com estes Termos.
              </ListTopicItem>
              <ListTopicItem>
                Exatidão e Veracidade dos Dados: Você é o único responsável por
                cadastrar dados verdadeiros, exatos e atualizados, e responde
                pelas consequências dos dados ou informações inverídicos,
                incompletos ou incorretos que fornecer no cadastro ou depois
                dele. Confira sempre as informações fornecidas à Plataforma
                antes de concluir o seu cadastro.
              </ListTopicItem>
              <ListTopicItem>
                Senha de Acesso e Atividades da Conta: Você é o único
                responsável por seu login, por sua senha, e por qualquer
                atividade conduzida por meio de sua conta na Plataforma. A
                Fityourself não é responsável por quaisquer danos, patrimoniais
                ou extrapatrimoniais, resultantes do uso indevido da sua conta
                por terceiros. É imprescindível que você mantenha a
                confidencialidade e a segurança das suas credenciais de acesso à
                sua conta na Plataforma Seu.coach, que são pessoais e
                intransferíveis. Não divulgue ou de qualquer maneira compartilhe
                essas credenciais a terceiros. Nenhum empregado, colaborador,
                representante, agente ou qualquer pessoa vinculada direta ou
                indiretamente à Fityourself está autorizada a solicitar que você
                compartilhe sua senha de acesso. Você também não deve
                compartilhar suas credenciais de acesso com Produtores ou
                Afiliados, pois as atividades que esses Usuários desempenham não
                dependem do compartilhamento desse tipo de informação. Você deve
                notificar a Fityourself imediatamente se você tomar conhecimento
                ou suspeitar que suas credenciais de acesso foram extraviadas,
                furtadas, apropriadas indevidamente por terceiros, tiveram sua
                confidencialidade comprometida ou foram de qualquer forma
                utilizadas sem sua autorização.
              </ListTopicItem>
              <ListTopicItem>
                Dados Bancários: Após realizar a primeira venda, os Produtores
                ou Afiliados devem fornecer dados bancários, indicando conta
                corrente de sua titularidade e no mesmo domicílio de seu
                cadastro para receber o valor das transações efetivadas por meio
                da Plataforma. O cadastro ou o repasse de valores devidos aos
                Produtores ou Afiliados para conta bancária de terceiros não é
                permitido em nenhuma circunstância e a nenhum pretexto. Também é
                proibido o cadastro ou repasse de valores para conta bancária de
                mesma titularidade, mas situada fora do domicílio do cadastro do
                Produtor ou Afiliado.
              </ListTopicItem>
              <ListTopicItem>
                Tratamento dos Dados: A Política de Privacidade da Fityourself
                rege o tratamento dos dados pessoais que você fornece durante o
                uso e o acesso da Plataforma.
              </ListTopicItem>
            </ListTopic>
            <ListTopicItem>
              <ListTopicHeader>
                Seus principais compromissos com a fityourself e com os demais
                usuários
              </ListTopicHeader>
              <ListTopic>
                <ListTopicItem>
                  Obrigações Gerais dos Usuários da Plataforma: Você deve
                  acessar a Plataforma ou usar os Serviços da Plataforma
                  Seu.coach apenas para fins lícitos. Você deve preservar a
                  reputação da Fityourself e evitar qualquer prática realizada
                  por você (ou por terceiros relacionados a você) que possa,
                  direta ou indiretamente, desabonar a Fityourself, seus
                  Serviços, seus empregados, colaboradores, representantes,
                  agentes, sócios ou parceiros.
                </ListTopicItem>
                <ListTopicItem>
                  Direitos de Propriedade Intelectual da Fityourself: As
                  informações contidas na Plataforma, bem como as marcas, nomes
                  empresariais, nomes de domínio, programas, bancos de dados,
                  redes, arquivos, mídias em geral (áudio, texto, vídeo etc.) e
                  qualquer outra propriedade intelectual relacionada aos
                  Serviços ou contida na Plataforma são de titularidade
                  exclusiva da Fityourself, ou foram regularmente cedidas ou
                  licenciadas à Fityourself. Esses elementos são protegidos
                  pelas leis e tratados internacionais de propriedade
                  intelectual. É proibido copiar, distribuir, usar ou publicar
                  total ou parcialmente qualquer material, qualquer item da
                  Plataforma ou qualquer Produto ofertado na Plataforma sem
                  prévia autorização por escrito da Fityourself.
                </ListTopicItem>
                <ListTopicItem>
                  Relacionamento dos Usuários entre si e com Terceiros: Você se
                  compromete a manter um relacionamento saudável e harmonioso
                  com os demais Usuários e com terceiros a respeito do uso da
                  Plataforma. Você não pode agredir, caluniar, injuriar ou
                  difamar outros Usuários ou terceiros, inclusive os empregados,
                  colaboradores, representantes, agentes, sócios ou parceiros da
                  Fityourself com os quais você tenha contato. Se a Fityourself
                  constatar que você agrediu, assediou, discriminou ou de
                  qualquer outra forma lesionou direitos dessas pessoas, da
                  Fityourself ou de outros Usuários, a Fityourself pode, a seu
                  critério, tomar uma série de medidas previstas nestes Termos,
                  que podem incluir a suspensão da sua licença de uso da
                  Plataforma e o encerramento da prestação dos Serviços para
                  você, excluindo-o da Plataforma, além de buscar
                  concomitantemente a reparação de quaisquer danos, patrimoniais
                  ou extrapatrimoniais, que você causar.
                </ListTopicItem>
                <ListTopicItem>
                  Indenizações à Fityourself: Ao aceitar estes Termos, você se
                  obriga a manter a Fityourself indene e a reparar quaisquer
                  danos que você causar à Fityourself por ação ou omissão que
                  seja imputável a você. Essa obrigação de evitar e reparar
                  danos inclui danos patrimoniais e extrapatrimoniais, além de
                  todas as despesas incorridas pela Fityourself em decorrência
                  de condutas imputáveis a você, como despesas com ações
                  judiciais e com honorários de advogados e peritos, decorrentes
                  dos Produtos comercializados. Você também se obriga a manter
                  indene e a reparar os danos que você causar aos sócios da
                  Fityourself, aos seus controladores, às suas sociedades
                  controladas ou coligadas, aos seus administradores, diretores,
                  gerentes, empregados, agentes, colaboradores, representantes e
                  procuradores.
                </ListTopicItem>
                <ListTopicItem>
                  Uso Adequado da Plataforma e dos Serviços da Fityourself: A
                  Plataforma Seu.coach oferece um ambiente seguro para que
                  qualquer pessoa possa criar, divulgar, comercializar ou
                  comprar um Produto e, assim, iniciar o seu próprio negócio e
                  adquirir novas habilidades. Desse modo, e sem prejuízo de
                  outras regras previstas nestes Termos ou nas Políticas da
                  Fityourself:
                  <ListOrdenatedByLetterInline>
                    <ListItemInline>
                      você não pode violar ou tentar violar quaisquer medidas de
                      segurança da Plataforma, nem tirar proveito de eventual
                      inconsistência de sistemas da Fityourself;
                    </ListItemInline>
                    <ListItemInline>
                      você não pode alterar os dados de qualquer Produto depois
                      que a Fityourself concluir o processo de verificação
                      cadastral, inclusive seu título, descritivo, condições de
                      compra e conteúdo;
                    </ListItemInline>
                    <ListItemInline>
                      você não pode induzir terceiros a acreditar, por ação ou
                      omissão sua, dentro ou fora da Plataforma, que você é
                      empregado, colaborador, prestador de serviço,
                      representante ou que mantém com a Fityourself qualquer
                      outro vínculo além de Usuário;
                    </ListItemInline>
                    <ListItemInline>
                      você não pode manipular preços de Produtos;
                    </ListItemInline>
                    <ListItemInline>
                      você não pode interferir nas transações realizadas entre
                      outros Usuários;
                    </ListItemInline>
                    <ListItemInline>
                      você não pode adotar quaisquer práticas de divulgação,
                      captação de informação ou publicidade que sejam
                      indesejadas, massivas, repetitivas, fora de contexto,
                      enganosas, inapropriadas, caluniosas ou ilegais, para
                      promover qualquer Produto cadastrado na Plataforma, como
                      spam, flooding, adware, malware e outras técnicas nocivas;
                    </ListItemInline>
                    <ListItemInline>
                      você não pode declarar nem sugerir que a Plataforma é um
                      meio de obter rendimentos fácil, rápido, ou que não requer
                      trabalho ou esforço;
                    </ListItemInline>
                    <ListItemInline>
                      você não pode comprar qualquer Produto com seu próprio
                      link de Afiliado;
                    </ListItemInline>
                    <ListItemInline>
                      você não pode usar link de Afiliados de seu próprio
                      Produto em sua página de produto;
                    </ListItemInline>
                    <ListItemInline>
                      você não pode substituir o link de Afiliado constante de
                      uma página de produto em benefício próprio ou de
                      terceiros;
                    </ListItemInline>
                    <ListItemInline>
                      você não pode utilizar qualquer sistema para o envio de
                      requisições de acesso e utilização dos Serviços que
                      supere, em um dado período de tempo, o que seria
                      normalmente possível responder, levando ao impedimento de
                      acesso, deteriorando ou de qualquer forma alterando a
                      experiência de utilização da Plataforma e de seus
                      conteúdos; e
                    </ListItemInline>
                    <ListItemInline>
                      você se obriga a não utilizar qualquer sistema
                      automatizado, inclusive robôs, spiders, scripts ou offline
                      readers, que acessem os Serviços e venham a utilizá-los de
                      forma contrária ao previsto nestes Termos ou na legislação
                      em vigor.
                    </ListItemInline>
                  </ListOrdenatedByLetterInline>
                </ListTopicItem>
              </ListTopic>
            </ListTopicItem>
            <ListTopicItem>
              <ListTopicHeader>
                O uso dos recursos e os conteúdos que podem ser comercializados
                mediante a plataforma
              </ListTopicHeader>
              <ListTopic>
                <ListTopicItem>
                  Os Recursos da Plataforma: A Fityourself oferece uma
                  plataforma totalmente integrada e com soluções para escalar
                  qualquer negócio virtual, desde a hospedagem até a entrega
                  digital do Produto, incluindo o pagamento global e seguro das
                  transações entre os Usuários. O objetivo é que o Produtor
                  consiga focar em desenvolver produtos de qualidade e criar
                  relacionamentos mais próximos com seus Compradores. Para isso,
                  além de recursos para criação, divulgação, entrega e pagamento
                  pelo Produto, a Fityourself também disponibiliza recursos que
                  auxiliam as vendas de forma simplificada, cabendo aos
                  Produtores escolherem a quais desejam integrar seus Produtos.
                </ListTopicItem>
                <ListTopicItem>
                  Conteúdo do Produtor: Ao usar qualquer recurso da Plataforma
                  para criar, divulgar ou comercializar um Produto, o Produtor
                  declara ser o titular exclusivo de todos os direitos de
                  propriedade intelectual relativos ao Produto e ao seu
                  conteúdo, ou estar regularmente autorizado pelos titulares
                  desses direitos para fazê-lo. O Produtor também garante que o
                  Produto não viola quaisquer direitos de propriedade
                  intelectual de terceiros, inclusive de imagem ou direitos
                  autorais, sendo o Produto obra original do Produtor, ou tendo
                  o Produtor recebido as licenças ou cessões de direitos
                  pertinentes dos titulares de direitos sobre o conteúdo
                  integrante do Produto. Além disso, o Produtor deve ter todos
                  os direitos para conceder à Fityourself as licenças
                  necessárias para a Fityourself desempenhar suas atividades
                  relativas ao uso da Plataforma com relação ao Produto, na
                  forma destes Termos e das Políticas da Fityourself.
                </ListTopicItem>
                <ListTopicItem>
                  Exclusividade dos Produtos criados, divulgados ou
                  comercializados mediante a Plataforma: Ao usar qualquer
                  recurso da Plataforma para criar, divulgar ou comercializar um
                  Produto, o Produtor declara ter o direito de comercializar o
                  Produto em caráter de exclusividade, e assume a obrigação de
                  comercializá-lo pela Plataforma com exclusividade. Assim, o
                  Produtor não pode autorizar terceiros a explorar qualquer
                  Produto concorrentemente, nem deixar de tomar medidas para
                  impedir que terceiros os explorem em concorrência com a
                  Fityourself.
                </ListTopicItem>
                <ListTopicItem>
                  Uso Responsável da Plataforma: O propósito da Fityourself é
                  possibilitar que as pessoas empreendam e se eduquem com mais
                  facilidade e, assim, se desenvolvam tanto na área profissional
                  quanto na pessoal. Para atingir esse objetivo, a Plataforma
                  Seu.coach adota alguns valores e princípios, que devem ser
                  observados por todos seus Usuários: o respeito ao próximo, aos
                  Usuários e às leis locais é um deles, assim como a proteção à
                  liberdade de expressão e a neutralidade e isonomia das redes
                  de Internet. Por isso, a Fityourself não aceita conteúdos que
                  violem essas diretrizes. Ao aceitar estes Termos, você se
                  obriga a respeitar a política de uso responsável da Plataforma
                  Seu.coach. Em termos gerais, você não pode usar a Plataforma
                  para criar, divulgar ou comercializar Produtos de formato ou
                  conteúdo
                  <ListOrdenatedByLetterInline>
                    <ListItemInline>
                      que violem a legislação aplicável ou direitos de
                      terceiros;
                    </ListItemInline>
                    <ListItemInline>
                      que incitem violência, ódio, discriminação, ou que possam
                      causar prejuízo aos Usuários ou à Fityourself;
                    </ListItemInline>
                    <ListItemInline>
                      que prejudiquem a experiência dos Usuários na Plataforma;
                      ou
                    </ListItemInline>
                    <ListItemInline>
                      que estejam em desacordo com o modelo de negócios da
                      Hotmart ou que sejam contrários aos valores da empresa.
                    </ListItemInline>
                  </ListOrdenatedByLetterInline>
                </ListTopicItem>
                <ListTopicItem>
                  Conteúdo Disponibilizado ao Comprador: Ao adquirir um Produto,
                  o Comprador está adquirindo o direito de acesso ao seu
                  conteúdo, nos termos estabelecidos na página de produto. A
                  compra do Produto não concede ao Comprador direitos de
                  propriedade intelectual sobre o Produto. O Comprador não está
                  autorizado a comercializar ou ceder a terceiros o Produto, no
                  todo ou em parte, ainda que a título gratuito. O Produtor ou a
                  Fityourself podem cessar de forma temporária ou definitiva o
                  acesso do Comprador ao Produto, inclusive por bloqueio de
                  acesso ao Produto ou bloqueio de Usuário, se for verificada a
                  inobservância destes Termos ou a suspeita de fraude. Uma
                  fraude pode ser caracterizada pelo fornecimento ou
                  compartilhamento de senha, assim como por outras condutas,
                  como o download, transmissão, retransmissão ou armazenamento
                  não autorizado do Produto.
                </ListTopicItem>
              </ListTopic>
            </ListTopicItem>
          </ListTopicItem>
          <ListTopicItem>
            <ListTopicHeader>
              As principais regras aplicáveis aos produtores
            </ListTopicHeader>
            <ListTopic>
              <ListTopicItem>
                Exclusividade: Todos os Produtos devem ser comercializados na
                Plataforma em caráter exclusivo. É vedado ao Produtor exibir,
                divulgar ou comercializar qualquer Produto, total ou
                parcialmente, por outras plataformas, meios ou suportes, online
                ou offline, a qualquer título, de maneira onerosa ou gratuita.
              </ListTopicItem>
              <ListTopicItem>
                Proibição de Uso de Marca ou Associação Inadequada da
                Fityourself: Você não pode usar qualquer marca, logo ou nome
                comercial da Fityourself para divulgar qualquer Produto, e nem
                indicar direta ou indiretamente que a Fityourself se associou,
                aprovou ou certificou o Produto. Você deve usar e acessar a
                Plataforma apenas nos limites necessários para usar os Serviços
                da Fityourself na criação, divulgação ou comercialização do
                Produto.
              </ListTopicItem>
              <ListTopicItem>
                Principais Licenças que o Produtor concede à Fityourself: Ao
                cadastrar um Produto na Plataforma, o Produtor automaticamente
                concede à Fityourself licença de uso de todos os direitos de
                propriedade intelectual relativos ao Produto, inclusive direitos
                autorais, de modo irrevogável, irretratável, sublicenciável,
                isenta de royalties ou de qualquer outra remuneração. Essa
                licença é concedida de forma não exclusiva, sendo válida em
                todos os territórios do mundo, para todos os fins e efeitos
                legais, e para todas as modalidades de exploração, com o
                objetivo de a Fityourself usar, reproduzir, processar, adaptar,
                modificar, traduzir, incluir legendas, publicar, transmitir,
                exibir ou distribuir o Produto em qualquer mídia ou suporte, por
                qualquer meio de distribuição, conhecido ou a ser desenvolvido,
                dentro e fora da Plataforma. Essa licença também permite que a
                Fityourself disponibilize o Produto a terceiros, Usuários ou
                não, que mantenham com a Fityourself parceria, contrato ou outro
                arranjo para fins de marketing, que podem pressupor o uso,
                divulgação, promoção, publicidade, comercialização, venda,
                distribuição, transmissão ou publicação do Produto. A integração
                dos Serviços da Fityourself a qualquer Produto não significa que
                o Produto ou os direitos de propriedade intelectual a ele
                associados passam a ser de titularidade da Fityourself. O
                Produtor continua sendo titular de todos os direitos e
                obrigações relacionados ao Produto criado, divulgado ou
                comercializado na Plataforma.
              </ListTopicItem>
              <ListTopicItem>
                Proteção de Direitos relativos ao Produto: O objetivo da
                Fityourself é criar um espaço em que o Produtor possa
                compartilhar suas habilidades e conhecimentos com o mundo e
                gerar renda com isso. Por isso, é importante para a Fityourself
                que os direitos dos Produtores sobre os Produtos que cadastram
                na Plataforma sejam respeitados. Para tanto, ao cadastrar
                qualquer Produto na Plataforma, o Produtor cede automaticamente
                à Fityourself o direito de tomar quaisquer medidas, judiciais ou
                extrajudiciais, para a Fityourself mover as pretensões que
                entender aplicáveis para proteger os direitos de propriedade
                intelectual relativos ao Produto, patrimoniais ou morais, bem
                como cede os direitos de exigir e receber qualquer indenização
                decorrente da sua violação. Isso inclui todos os poderes
                necessários para que a Fityourself tome as medidas que entender
                cabíveis, aos seus custos, como por exemplo mover ação judicial,
                ingressar como parte em ação judicial movida por terceiros,
                realizar acordos, interpor recursos, dar e receber quitação, bem
                como todos os demais poderes habitualmente exigíveis para o
                exercício e a proteção de direitos em juízo.
              </ListTopicItem>
              <ListTopicItem>
                Dados Pessoais dos Usuários: O Produtor deve tratar os dados
                pessoais dos Usuários aos quais tiver acesso em decorrência dos
                Serviços da Fityourself em total respeito à Política de
                Privacidade e às leis aplicáveis, incluindo a Lei Geral de
                Proteção de Dados (Lei Federal nº 13.709/18). Se o Produtor
                solicitar dados pessoais aos Usuários fora da Plataforma, o
                Produtor deve assegurar a existência de uma base legal válida
                para tratar esses dados. Isso inclui a obrigação de obter o
                consentimento expresso desses Usuários, nos termos da legislação
                aplicável. O Produtor deve informar imediatamente à Fityourself
                qualquer incidente de segurança ocorrido ou que possa ter
                ocorrido no tratamento de dados pessoais dos Usuários. O
                Produtor deve excluir todos os dados e informações de terceiros
                que tenha recebido ou armazenado em virtude do uso da Plataforma
                e de seus recursos dentro de prazo de razoável e adequado,
                respondendo integralmente pela observância da legislação de
                proteção de dados pessoais aplicável.
              </ListTopicItem>
              <ListTopicItem>
                Transparência e Dever de Informação: Além das informações de
                cadastro, dos dados bancários e dos demais dados da conta, o
                Produtor deve fornecer à Fityourself e a terceiros, Usuários ou
                não, todas as informações razoavelmente esperadas relativas aos
                Produtos. Essas informações devem ser prestadas de maneira
                completa, inequívoca, objetiva, transparente e atualizada,
                dentro e fora da Plataforma, notadamente quanto
                <ListOrdenatedByLetterInline>
                  <ListItemInline>
                    ao assunto, composição, descrição, objetivo,
                    características, qualidade e quantidade do Produto;
                  </ListItemInline>
                  <ListItemInline>
                    ao preço, formas de pagamento, condições de parcelamento e
                    eventuais promoções e descontos;
                  </ListItemInline>
                  <ListItemInline>
                    aos prazos e formas de exercício de garantia ou direito de
                    arrependimento;
                  </ListItemInline>
                  <ListItemInline>
                    às formas, prazos, validade e períodos de entrega e acesso;
                  </ListItemInline>
                  <ListItemInline>
                    às atualizações e versões, se aplicável;
                  </ListItemInline>
                  <ListItemInline>
                    aos requisitos obrigatórios e certificação, se aplicável;
                  </ListItemInline>
                  <ListItemInline>
                    aos deveres que devem ser cumpridos pelos Usuários e limites
                    aos seus direitos;
                  </ListItemInline>
                  <ListItemInline>
                    às regras de aceitação e comissão dos Afiliados (como
                    percentual e possibilidade de recebimento pela primeira
                    indicação, pela última indicação ou divisão da comissão para
                    a primeira e última indicações);
                  </ListItemInline>
                  <ListItemInline>
                    a qualquer outra informação relevante ou decorrente de
                    obrigação legal sobre o aproveitamento e a comercialização
                    do Produto.
                  </ListItemInline>
                </ListOrdenatedByLetterInline>
                <ListTopic>
                  <ListTopicItem>
                    Disclaimers: Além de eventuais advertências exigíveis em
                    razão de leis, regulamentos ou convenções que disponham
                    sobre a publicidade e anúncios de produtos, a Fityourself
                    pode sugerir ou requerer a inserção de advertências para
                    determinadas categorias de produtos, ou para alguns produtos
                    específicos, no intuito de garantir maior transparência
                    sobre os riscos e as expectativas de entrega de Produtos
                    cadastrados na Plataforma.
                  </ListTopicItem>
                </ListTopic>
              </ListTopicItem>
              <ListTopicItem>
                Produtos Duplicados: O Produtor não pode cadastrar o mesmo
                Produtos em duplicidade, comercializando-o mediante dois ou mais
                cadastros diferentes, sem que seus respectivos anúncios ofereçam
                meios de pagamentos distintos entre si.
              </ListTopicItem>
              <ListTopicItem>
                Responsabilidade por Terceiros Relacionados: Os Produtores são
                integral e solidariamente responsáveis por ações ou omissões
                praticadas por Terceiros Relacionados. São considerados
                Terceiros Relacionados quaisquer pessoas, naturais ou jurídicas,
                que estejam direta ou indiretamente relacionadas a um Produto,
                como especialistas, atores, personagens ou garotos-propaganda.
                Ao cadastrar um Produto na Plataforma, os Produtores garantem
                que os Terceiros Relacionados conhecem, concordam e se obrigam a
                cumprir estes Termos e as Políticas da Fityourself, pois eles se
                aplicam a essas pessoas. Os Produtores se responsabilizam
                integralmente por obter e manter eficazes, durante todo o
                período de vida do Produto na Plataforma, os direitos para a
                exploração da imagem e dos demais direitos de personalidade dos
                Terceiros Relacionados, na extensão necessária para a
                comercialização do Produto. Na hipótese de um Terceiro
                Relacionado ser um menor de 18 anos, o Produtor deve garantir
                que tem as respectivas autorizações dos responsáveis e órgãos
                competentes por emitir permissões nesse sentido. A participação
                de menores de 18 anos em textos, imagens ou sons contidos nos
                Produtos deve ser muito restrita, somente quando essencial para
                a apresentação do tema proposto, e o Produtor deve cumprir e se
                responsabilizar pelo cumprimento de todas as normas aplicáveis
                sobre o uso de trabalho infantil, além do estabelecido nestes
                Termos. A Fityourself pode aplicar medidas perante qualquer
                Usuário ou Produto, inclusive removê-los da Plataforma, com base
                em ações ou omissões de Terceiros Relacionados que violem estes
                Termos.
              </ListTopicItem>
              <ListTopicItem>
                Os Coprodutores: Qualquer Produtor pode cadastrar Usuários da
                Plataforma como Coprodutores de determinado Produto, desde que
                esses sejam plenamente capazes, autorizando cada Coprodutor a
                praticar atos relativos a determinado Produto sem que seja
                necessário o compartilhamento das credenciais de acesso do
                Produtor à Plataforma. Ao efetuar esse cadastro, cabe ao
                Produtor definir o conjunto de atos facultados a cada
                Coprodutor, bem como os percentuais a que cada Coprodutor faz
                jus pela venda de determinado Produto. Os Produtores são
                integral e solidariamente responsáveis perante terceiros e
                perante a Fityourself pelas atividades desempenhadas pelos
                Coprodutores relativas aos seus Produtos. Da mesma maneira, os
                Coprodutores são integral e solidariamente responsáveis perante
                terceiros e perante a Fityourself pelas atividades desempenhadas
                pelos Produtores relativas aos Produtos dos quais são
                cadastrados como Coprodutores. Ao aceitar estes Termos, os
                Produtores isentam a Fityourself de responsabilidade por
                quaisquer danos, patrimoniais ou extrapatrimoniais, decorrentes
                de ações ou omissões praticadas pelos Coprodutores, e os
                Coprodutores isentam a Fityourself de responsabilidade por
                quaisquer danos, patrimoniais ou extrapatrimoniais, decorrentes
                de ações ou omissões praticadas pelos Produtores.
              </ListTopicItem>
              <ListTopicItem>
                Colaboradores dos Produtores: Qualquer Produtor pode convidar
                Usuários da Plataforma para serem seus Colaboradores, desde que
                esses sejam plenamente capazes, podendo o Colaborador fazer a
                gestão da conta do Produtor, sem que seja necessário o
                compartilhamento das credenciais de acesso do Produtor à
                Plataforma. O Colaborador não faz jus a nenhum pagamento a ser
                efetuado pela Fityourself, cabendo aos Produtores e aos
                Colaboradores estabelecerem entre si as condições das suas
                relações. Os Produtores são integral e solidariamente
                responsáveis perante terceiros e perante a Fityourself pelas
                atividades desempenhadas pelos Colaboradores em sua conta ou
                relativas aos seus Produtos. Ao aceitar estes Termos, os
                Produtores isentam a Fityourself de responsabilidade por
                quaisquer danos, patrimoniais ou extrapatrimoniais, decorrentes
                de ações ou omissões praticadas pelos Colaboradores.
              </ListTopicItem>
              <ListTopicItem>
                Obrigações perante os Compradores: Lembramos que, quando os
                Usuários fazem qualquer transação sobre um Produto mediante a
                Plataforma, eles celebram um contrato diretamente um com o
                outro. Assim, entre outros aspectos, o Produtor é responsável
                <ListOrdenatedByLetterInline>
                  <ListItemInline>
                    por garantir que o Comprador se beneficie do Produto tal
                    como lhe foi ofertado no momento da compra;
                  </ListItemInline>
                  <ListItemInline>
                    pela entrega do Produto ao Comprador, especialmente se o uso
                    for disponibilizado por meio de ferramentas externas à
                    Plataforma;
                  </ListItemInline>
                  <ListItemInline>
                    por solucionar quaisquer problemas que ocorram com o Produto
                    após a entrega ao Comprador, em especial os problemas
                    técnicos;
                  </ListItemInline>
                  <ListItemInline>
                    por fornecer suporte adequado ao Comprador; e
                  </ListItemInline>
                  <ListItemInline>
                    por responder aos eventuais contatos da equipe de suporte da
                    Fityourself para viabilizar o atendimento adequado aos
                    Usuários. Em especial, o Produtor deve respeitar os prazos
                    de exercício de direito de arrependimento e de garantia
                    exigidos por lei, se aplicáveis, bem como qualquer prazo
                    adicional que oferecer aos Compradores, obrigando-se a
                    aceitar eventual solicitação de cancelamento da venda e
                    reembolso por meio da Plataforma ou de seus canais de
                    atendimento e de suporte. Para tanto, o Produtor desde já
                    autoriza a Plataforma Seu.coach a realizar a dedução dos
                    valores de reembolso devidos pelo Produtor a qualquer
                    Comprador. A Fityourself pode deduzir esses valores dos
                    créditos existentes ou futuros na conta do Produtor.
                  </ListItemInline>
                </ListOrdenatedByLetterInline>
              </ListTopicItem>
              <ListTopicItem>
                Funcionalidade Trial: O Produtor pode disponibilizar o Produto
                por meio da funcionalidade trial, um período de utilização não
                vinculativo, a título de teste, pelo Comprador que pode variar
                de um a 30 dias, de acordo com a escolha do Produtor. O Trial
                tem a finalidade de permitir que novos Compradores experimentem
                o Produto. A qualificação ao Trial, seu uso e tempo de duração
                devem ser determinados pelo Produtor a seu critério. O Produtor
                pode limitar a qualificação para evitar abusos ao Trial, desde
                que o faça em conformidade com estes Termos. O Produtor deve
                cobrar do Comprador o valor do Produto ao final do período de
                Trial estabelecido pelo Produtor, a não ser que o Comprador
                cancele o uso do Trial antes do final desse período.
                <ListTopic>
                  <ListTopicItem>
                    Nos Produtos de assinatura, os Produtores podem optar pela
                    funcionalidade Trial no modelo gratuito, a seu exclusivo
                    critério. A diferença entre essas modalidades é que o
                    Comprador, ao decidir continuar com o Produto adquirido na
                    modalidade teste, deverá pagar pelo período urilizado, o que
                    não ocorre no modelo gratuito.
                  </ListTopicItem>
                </ListTopic>
              </ListTopicItem>
            </ListTopic>
          </ListTopicItem>
          <ListTopicItem>
            <ListTopicHeader>
              As principais regras sobre o programa de afiliados
            </ListTopicHeader>
            <ListTopic>
              <ListTopicItem>
                Programa de Afiliados: O Programa de Afiliados da Fityourself
                permite que os Afiliados comercializem um Produto mediante
                remuneração equivalente a um percentual do valor de cada venda
                que o Afiliado promover. Compete ao Produtor decidir se deseja
                participar do Programa de Afiliados.
                <ListTopic>
                  <ListTopicItem>
                    Responsabilidade pelos Afiliados: O Produtor é integralmente
                    responsável pela atuação dos Afiliados por ocasião da
                    promoção das vendas de seu Produto. Se qualquer Afiliado
                    adotar condutas inadequadas, ou praticar ações ou omissões
                    contrárias a estes Termos ou às Políticas da Fityourself, a
                    mesma pode aplicar quaisquer das medidas previstas na Seção
                    10 destes Termos, de maneira isolada ou cumulativa, em
                    desfavor do Afiliado, do Produtor, do seu Produto ou de
                    todos. São consideradas condutas inadequadas dos Afiliados,
                    entre outras, os seguintes exemplos:
                    <ListOrdenatedByLetterInline>
                      <ListItemInline>
                        a mudança da narrativa de vendas do Produto;
                      </ListItemInline>
                      <ListItemInline>
                        a prestação de informações falsas, incompletas ou
                        imprecisas sobre quaisquer características do Produto;
                      </ListItemInline>
                      <ListItemInline>
                        a prática de spam ou qualquer prática que a Fityourself
                        entender nociva ao mercado de afiliação ou ao Programa
                        de Afiliados.
                      </ListItemInline>
                    </ListOrdenatedByLetterInline>
                  </ListTopicItem>
                  <ListTopicItem>
                    Práticas Vedadas aos Produtores: Qualquer repasse de
                    remuneração aos Afiliados deve se dar exclusivamente por
                    meio da Plataforma. O Produtor não pode criar outros
                    mecanismos de pagamento de comissão, compensação,
                    bonificação, pagamento direto ou remuneração a qualquer
                    título realizados fora da Plataforma. O Produtor também não
                    pode exigir como requisitos para aceitar candidatos ou
                    mantê-los como Afiliados que
                    <ListOrdenatedByLetterInline>
                      <ListItemInline>atinjam metas,</ListItemInline>
                      <ListItemInline>
                        comprem previamente qualquer Produto ou
                      </ListItemInline>
                      <ListItemInline>
                        paguem qualquer valor ao Produtor.
                      </ListItemInline>
                    </ListOrdenatedByLetterInline>
                  </ListTopicItem>
                  <ListTopicItem>
                    Comunicação: O Produtor deve comunicar ao Afiliado ou à sua
                    rede de Afiliados, com antecedência mínima de 30 dias, todas
                    as condições de afiliação e qualquer mudança que fizer sobre
                    as regras aplicáveis à afiliação, como por exemplo a sua
                    interrupção ou mudanças nos percentuais de comissão.
                  </ListTopicItem>
                </ListTopic>
              </ListTopicItem>
              <ListTopicItem>
                Regras de Afiliação: Ao aderir ao Programa de Afiliados, o
                Produtor deve observar as determinações mínimas estabelecidas
                nestes Termos, sendo de atribuição exclusiva do Produtor:
                <ListOrdenatedByLetterInline>
                  <ListItemInline>
                    determinar o percentual devido ao Afiliado pela venda de
                    cada Produto;
                  </ListItemInline>
                  <ListItemInline>
                    estabelecer os critérios de participação do Programa de
                    Afiliados para seus Produtos;
                  </ListItemInline>
                  <ListItemInline>
                    aceitar ou recusar os candidatos inscritos, conforme
                    critérios previamente estabelecidos;
                  </ListItemInline>
                  <ListItemInline>
                    definir as formas e limitações de divulgação do Produto; e
                  </ListItemInline>
                  <ListItemInline>
                    fazer a gestão de sua rede de Afiliados.
                  </ListItemInline>
                </ListOrdenatedByLetterInline>
                A Fityourself se reserva o direito de limitar o número de
                Afiliados de determinado Produto ou Produtor, bem como o direito
                de acrescentar deveres e obrigações aos Produtores e Afiliados
                adicionais aos previstos nestes Termos, a qualquer tempo, com ou
                sem aviso prévio. Na hipótese de conflito entre as regras
                estabelecidas pelos Produtores e as condições destes Termos,
                estes últimos devem sempre prevalecer.
              </ListTopicItem>
              <ListTopicItem>
                Processo de Afiliação: O Afiliado deve apresentar candidatura
                para afiliação a determinado Produto específico. O Produtor pode
                aceitá-la ou recusá-la com base em seus critérios previamente
                definidos. Ao submeter a sua candidatura, o Afiliado concorda
                com todas as regras estabelecidas pelo Produtor, inclusive com
                relação aos valores e às regras de divisão da comissão pela
                venda do Produto, que são determinadas exclusivamente pelo
                Produtor, sem a participação da Fityourself. O Afiliado deve
                levar em conta todas as condições, gerais e específicas, da
                Fityourself e do Produtor, antes de decidir se engajar na
                promoção de um determinado Produto.
              </ListTopicItem>
              <ListTopicItem>
                Condição de Afiliado: A afiliação a um Produto não estabelece
                qualquer relação entre o Afiliado e a Fityourself além daquela
                de Usuário. O Afiliado não pode praticar qualquer ação ou
                omissão que, direta ou indiretamente, o identifique com a
                Fityourself ou possa sugerir vínculo de qualquer outra natureza,
                seja pelo uso indevido da marca ou de qualquer outra propriedade
                intelectual da Fityourself, seja pela descrição de cargos ou
                relação profissional, ou por qualquer outro meio. Por exemplo, o
                Afiliado não pode criar perfis em redes sociais, de cunho
                pessoal ou profissional, se apresentando como “vendedor da
                Fityourself” ou “agente da Fityourself”.
              </ListTopicItem>
              <ListTopicItem>
                Divulgação e Comercialização dos Produtos: O Afiliado é
                responsável por reproduzir ao público para o qual divulgar o
                Produto todas as características do Produto e as condições de
                venda inseridas pelo Produtor na Plataforma. O Afiliado assume
                perante o Comprador responsabilidade solidária com o Produtor
                pelo cumprimento integral das condições de venda.
              </ListTopicItem>
              <ListTopicItem>
                Sub-Afiliação ou Marketing Multinível: O Afiliado não pode criar
                ou praticar, direta ou indiretamente, qualquer forma de revenda
                ou sub-afiliação, de marketing multinível, ou dinâmicas
                similares.
              </ListTopicItem>
            </ListTopic>
          </ListTopicItem>
          <ListTopicItem>
            <ListTopicHeader>
              A experiência de compra do comprador
            </ListTopicHeader>
            <ListTopic>
              <ListTopicItem>
                Responsabilidade pela Experiência do Comprador: A Fityourself
                acredita que aprender e ensinar são atividades que podem e devem
                ser democratizadas. Por isso, os Serviços da Fityourself podem
                ser utilizados por qualquer Produtor. A Fityourself também preza
                pela autonomia das pessoas, acreditando que seus Usuários devem
                ter o poder de decisão sobre quais conteúdos desejam adquirir.
                Por isso, a Fityourself não se obriga a fazer controle prévio
                (editorial ou de qualquer outra natureza) ou curadoria dos
                Produtos. A Fityourself pode fazer verificações sobre o cadastro
                dos Produtos, mas não é capaz de determinar se os Produtos
                observam todas as determinações legais, se são adequados para os
                fins a que se propõem, nem é capaz de determinar a veracidade, a
                exatidão e a completude das informações prestadas pelos
                Produtores. Ao adquirir algum Produto, o Comprador deve
                verificar a adequação do Produto e do Produtor aos fins buscados
                por ocasião da compra, bem como a veracidade das demais
                informações e características prestadas em relação ao Produto.
                Além disso, o Comprador assume voluntariamente todo e qualquer
                risco associado à aquisição do Produto. Isso inclui o risco de
                ser exposto a assunto que considere ofensivo, indecente ou
                censurável, ou de que o conhecimento veiculado possa resultar em
                efeitos danosos ou adversos, físicos ou mentais, pelos quais o
                Comprador assume total responsabilidade.
              </ListTopicItem>
              <ListTopicItem>
                Diligência sobre as Informações Prestadas: O Comprador é
                integralmente responsável por todas as informações que prestar e
                pelas declarações que fizer aos Produtores ou a qualquer outra
                pessoa por ocasião do uso da Plataforma, em razão da compra ou
                em decorrência do uso de qualquer Produto.
              </ListTopicItem>
            </ListTopic>
          </ListTopicItem>
          <ListTopicItem>
            <ListTopicHeader>A remuneração da fityourself</ListTopicHeader>
            <ListTopic>
              <ListTopicItem>
                Tarifas do Produtor: O uso pelo Produtor dos recursos que a
                Plataforma disponibiliza está sujeito ao pagamento das Tarifas
                de Licença e de Intermediação, que são cobradas por ocasião da
                venda de cada Produto, no momento em que a transação é aprovada,
                e deduzidas do valor final cobrado pelo Produtor de cada
                Comprador. Essas tarifas, seus respectivos meios de pagamento,
                podem ser alteradas a qualquer tempo, a critério da Fityourself,
                mediante notificação prévia.
              </ListTopicItem>
              <ListTopicItem>
                Tarifas do Afiliado e Comprador: Os Compradores e Afiliados
                podem acessar e usar os serviços da Plataforma Seu.coach
                gratuitamente. No entanto, a Fityourself pode cobrar valores
                decorrentes de operações financeiras realizadas por esses
                Usuários, como a antecipação de recebíveis solicitada por
                Afiliado, ou o parcelamento ou uso de diferentes meios de
                pagamento por Comprador.
              </ListTopicItem>
            </ListTopic>
          </ListTopicItem>
          <ListTopicItem>
            <ListTopicHeader>
              Limites de responsabilidade da fityourself
            </ListTopicHeader>
            <ListTopic>
              <ListTopicItem>
                Limites da Responsabilidade da Fityourself: Lembramos que, ao
                usar a Plataforma como Produtor, Afiliado ou Comprador, você é
                um contratante dos serviços de intermediação prestados pela
                Fityourself. A Fityourself não cria, elabora, controla, endossa
                ou fornece qualquer Produto para você. Os Produtores são os
                responsáveis pelo conteúdo e pelas informações relativas aos
                seus Produtos. Ao aceitar estes Termos e usar a Plataforma, você
                o faz voluntariamente, por sua conta e risco. Por isso,
                excetuadas as hipóteses de responsabilidade determinadas por
                lei, a Fityourself não responde por danos de qualquer natureza
                decorrentes do acesso ou uso da Plataforma, sejam patrimoniais
                ou extrapatrimoniais, diretos ou indiretos, prejuízos efetivos
                ou lucros cessantes. A Fityourself não responde, a título
                exemplificativo e não exaustivo, por:
                <ListOrdenatedByLetterInline>
                  <ListItemInline>
                    danos decorrentes da inadequação dos Produtos aos fins a que
                    se destinam;
                  </ListItemInline>
                  <ListItemInline>
                    danos decorrentes de defeitos ou vícios de qualidade ou
                    quantidade dos Produtos;
                  </ListItemInline>
                  <ListItemInline>
                    danos decorrentes de riscos relativos ao uso de Produto,
                    inclusive potencial nocividade ou periculosidade;
                  </ListItemInline>
                  <ListItemInline>
                    reclamações decorrentes de insatisfações pela baixa
                    qualidade dos Produtos;
                  </ListItemInline>
                  <ListItemInline>
                    danos decorrentes de erro, incompletude, inverdade ou
                    inadequação de informações prestadas na divulgação de
                    Produto mediante recursos disponibilizados pela Plataforma;
                  </ListItemInline>
                  <ListItemInline>
                    danos decorrentes do descumprimento, pelos Usuários, dos
                    seus deveres legais ou contratuais perante outros Usuários;
                  </ListItemInline>
                  <ListItemInline>
                    danos decorrentes de erro, incompletude, inverdade ou
                    inadequação de informações prestadas durante o processamento
                    de transações de pagamento;
                  </ListItemInline>
                  <ListItemInline>
                    danos decorrentes dos Serviços de Terceiros;
                  </ListItemInline>
                  <ListItemInline>
                    danos resultantes dos serviços de terceiros no processamento
                    de conversão e saque de moeda;
                  </ListItemInline>
                  <ListItemInline>
                    danos decorrentes do uso indevido da Plataforma por
                    terceiros, sejam Produtores, Afiliados ou Compradores, a que
                    título for, especialmente em caso de divulgação em outras
                    plataformas de Produtos previamente adquiridos;
                  </ListItemInline>
                  <ListItemInline>
                    danos decorrentes dos materiais, informações ou conteúdos
                    publicados em sites de terceiros, tampouco pela
                    disponibilidade destes sites, competindo ao Usuário usar seu
                    próprio discernimento, precaução e senso comum ao acessar
                    sites de terceiros, devendo conferir as respectivas
                    políticas de privacidade e os termos de uso aplicáveis;
                  </ListItemInline>
                  <ListItemInline>
                    danos decorrentes de dificuldades técnicas ou falhas nos
                    sistemas, servidores ou na internet; ou
                  </ListItemInline>
                  <ListItemInline>
                    danos decorrentes de ataques de vírus ao equipamento do
                    Usuário ocorridos durante o uso da Plataforma, ou como
                    consequência da transferência de dados, arquivos, imagens,
                    textos ou áudio.
                  </ListItemInline>
                </ListOrdenatedByLetterInline>
              </ListTopicItem>
              <ListTopicItem>
                Exclusão da Fityourself do Polo Passivo de Eventual Disputa: Ao
                aceitar estes Termos, você desde já concorda com a exclusão da
                Fityourself do polo passivo de qualquer tipo de reclamação ou
                processo, judicial ou extrajudicial, que você iniciar contra
                outros Usuários ou terceiros a respeito do uso da Plataforma.
                Essa exclusão também se aplica em favor dos sócios da
                Fityourself, dos seus controladores, das suas sociedades
                controladas ou coligadas, dos seus administradores, diretores,
                gerentes, empregados, agentes, colaboradores, representantes e
                procuradores.
              </ListTopicItem>
            </ListTopic>
          </ListTopicItem>
          <ListTopicItem>
            <ListTopicHeader>
              Infrações aos termos e às políticas da fityourself
            </ListTopicHeader>
            <ListTopic>
              <ListTopicItem>
                Infrações aos Termos: Se você deixar de observar qualquer
                condição destes Termos ou de qualquer Política da Fityourself,
                total ou parcialmente, a Fityourself pode aplicar diferentes
                medidas, a seu critério, de forma isolada ou cumulativa, com ou
                sem prévio aviso, a qualquer tempo e pelo período que lhe
                aprouver. Entre essas medidas, sem prejuízo de outras não
                previstas nestes Termos, a Fityourself pode aplicar:
                <ListOrdenatedByLetterInline>
                  <ListItemInline>advertência;</ListItemInline>
                  <ListItemInline>
                    limitação, remoção ou encerramento de acesso ao Produto;
                  </ListItemInline>
                  <ListItemInline>
                    retirada ou diminuição de visibilidade do Produto do
                    marketplace;
                  </ListItemInline>
                  <ListItemInline>
                    limitação de acesso, suspensão ou desligamento do Programa
                    de Afiliados;
                  </ListItemInline>
                  <ListItemInline>
                    limitação de acesso, suspensão ou encerramento de qualquer
                    benefício ou categoria especial oferecida pela Plataforma
                    relacionada ao Produtor, à sua conta, ou a determinado
                    Produto;
                  </ListItemInline>
                  <ListItemInline>
                    rebaixamento de categoria de Usuário;
                  </ListItemInline>
                  <ListItemInline>
                    limitação, suspensão ou encerramento de acesso a alguma
                    funcionalidade especial da Plataforma;
                  </ListItemInline>
                  <ListItemInline>remoção da página do Produto;</ListItemInline>
                  <ListItemInline>
                    limitação de acesso, suspensão, bloqueio ou remoção de
                    Produto, conta ou Usuário; ou
                  </ListItemInline>
                  <ListItemInline>encerramento deste Contrato.</ListItemInline>
                </ListOrdenatedByLetterInline>
              </ListTopicItem>
              <ListTopicItem>
                Medidas Preventivas: A Fityourself pode, a seu critério, adotar
                medidas preventivas para averiguar possíveis infrações aos
                Termos ou às Políticas da Fityourself, ou se entender ser
                necessário para proteger interesses próprios ou de terceiros.
                Essas medidas podem durar o tempo necessário para que eventual
                correção ou verificação seja feita, ou até a Fityourself decidir
                pelo encerramento ou não dos Serviços da Fityourself, ou pela
                aplicação de outras medidas. As medidas preventivas podem
                resultar na suspensão temporária dos Serviços da Fityourself a
                determinado Produto ou Usuário. Nessa hipótese, durante todo o
                período de suspensão, a Fityourself pode alterar ou retirar o
                acesso total ou parcial de algumas funcionalidades da conta do
                Usuário.
              </ListTopicItem>
              <ListTopicItem>
                Outras Medidas Judiciais ou Extrajudiciais: A Fityourself pode
                adotar quaisquer outras medidas, judiciais ou extrajudiciais,
                que entender cabíveis, em nome próprio ou mediante terceiros
                legitimados, sem prejuízo da adoção isolada ou cumulativa das
                outras mencionadas nos itens anteriores.
              </ListTopicItem>
            </ListTopic>
          </ListTopicItem>
          <ListTopicItem>
            <ListTopicHeader>
              Suspensão ou encerramento dos serviços da fityourself
            </ListTopicHeader>
            <ListTopic>
              <ListTopicItem>
                Suspensão ou Encerramento dos Serviços da Fityourself: Os
                valores e princípios da Fityourself são amparados no respeito ao
                próximo e à liberdade, e estamos sempre empenhados em facilitar
                o empreendedorismo, o conhecimento e a educação para que mais
                pessoas atinjam desenvolvimento pessoal e profissional. Por
                isso, a Fityourself se reserva o direito de tomar diversas ações
                destinadas a proteger a Plataforma ou nossa comunidade, mediante
                requerimento justificado de terceiro ou por iniciativa própria.
                Essas medidas podem incluir suspender ou interromper a prestação
                de Serviços, encerrar a licença de uso concedida a Usuário,
                tornar Produto indisponível, removê-lo da Plataforma, bem como
                quaisquer outras medidas previstas ou não na Seção 10 destes
                Termos. A Fityourself pode decidir tomar essas e outras medidas
                a qualquer tempo, com ou sem aviso prévio, de modo isolado ou
                cumulativo. Qualquer decisão da Fityourself a esse respeito
                ocorrerá a seu exclusivo critério, por motivos que podem
                incluir:
                <ListOrdenatedByLetterInline>
                  <ListItemInline>
                    a inobservância destes Termos ou das Políticas da
                    Fityourself;
                  </ListItemInline>
                  <ListItemInline>
                    a prática de ato que contrarie de alguma maneira os valores
                    ou princípios da Fityourself;
                  </ListItemInline>
                  <ListItemInline>
                    qualquer conduta, praticada dentro ou fora da Plataforma,
                    que possa impactar negativamente a Fityourself, ou que possa
                    levar a empresa ou seus negócios a descrédito público,
                    desonra, escândalo ou ridículo.
                  </ListItemInline>
                </ListOrdenatedByLetterInline>
                O fato de qualquer Produto ou Usuário ter sido habilitado
                anteriormente durante o processo de verificação cadastral não
                impede a Fityourself de tomar as medidas que julgar adequadas,
                quando entender cabível. Ao aceitar estes Termos, você reconhece
                que não tem direito a indenização de qualquer dano, patrimonial
                ou extrapatrimonial, se a Fityourself adotar qualquer medida
                baseada em lei, nestes Termos ou nas Políticas da Fityourself,
                inclusive se você tiver seu cadastro ou qualquer Produto
                recusado, se tiver o acesso à sua conta suspenso ou
                interrompido, se a Fityourself encerrar a licença conferida a
                você para uso da Plataforma, ou se você tiver um Produto
                removido da Plataforma ou tornado indisponível.
              </ListTopicItem>
              <ListTopicItem>
                Encerramento Imotivado: Ao aceitar este Contrato, a Fityourself
                lhe concede automaticamente uma licença de uso não exclusiva da
                Plataforma, por prazo indeterminado. Desse modo, a Fityourself
                pode encerrar este Contrato e a respectiva licença de uso a
                qualquer momento, a critério próprio, mediante o envio de
                notificação com 30 dias de antecedência para o endereço de
                e-mail que você registrar na sua conta.
              </ListTopicItem>
              <ListTopicItem>
                Encerramento Motivado: A Fityourself pode encerrar este Contrato
                imediatamente, sem aviso prévio,
                <ListOrdenatedByLetterInline>
                  <ListItemInline>
                    se você violar estes Termos ou quaisquer condições das
                    Políticas da Fityourself;
                  </ListItemInline>
                  <ListItemInline>para prevenir fraudes;</ListItemInline>
                  <ListItemInline>
                    para impedir a ocorrência ou o agravamento de danos a si ou
                    a terceiros; ou
                  </ListItemInline>
                  <ListItemInline>
                    em razão de estrito cumprimento de dever legal, por decisão
                    judicial ou determinação de órgão governamental ou agência
                    regulatória.
                  </ListItemInline>
                </ListOrdenatedByLetterInline>
                <ListTopic>
                  <ListTopicItem>
                    Encerramento Imediato por Condutas Inadequadas: A
                    Fityourself leva a integridade da comunidade que usa a
                    Plataforma muito a sério. Assim, a Fityourself pode aplicar
                    quaisquer das medidas previstas na Seção 10 destes Termos,
                    ou, se entender necessário, pode inclusive encerrar este
                    Contrato imediatamente, sem aviso prévio,
                    <ListOrdenatedByLetterInline>
                      <ListItemInline>
                        se você receber repetidamente avaliações ou comentários
                        negativos;
                      </ListItemInline>
                      <ListItemInline>
                        se a Fityourself tomar ciência de reclamações sobre
                        conduta inapropriada sua praticada de maneira reiterada
                        ou contumaz;
                      </ListItemInline>
                      <ListItemInline>
                        se o seu Produto ou se você, como Produtor, receber um
                        volume de reclamações nos canais de atendimento, de
                        solicitações de cancelamento ou de estorno que a
                        Fityourself entender incompatível com a qualidade que se
                        espera de Usuários ou Produtos cadastrados na
                        Plataforma;
                      </ListItemInline>
                      <ListItemInline>
                        se você, como Produtor ou Afiliado, deixar de prestar
                        suporte aos Compradores; (e) se você se recusar a
                        corrigir, incluir ou excluir informação relevante sobre
                        o Produto ou sobre você mesmo na Plataforma;
                      </ListItemInline>
                      <ListItemInline>
                        se os índices de reclamações e contestações referentes
                        às transações de pagamento da sua conta forem elevados;
                      </ListItemInline>
                      <ListItemInline>
                        se você fornecer informações incompletas,
                        desatualizadas, incorretas, fraudulentas ou imprecisas
                        em seu processo de cadastro, sobre o Produto ou para
                        receber atendimento pela Fityourself;
                      </ListItemInline>
                      <ListItemInline>
                        se você fizer algo nocivo e a Fityourself entender que o
                        encerramento do Contrato seja medida necessária, em
                        bases razoáveis, para proteger a saúde, a segurança, a
                        reputação, a honra e os direitos da Fityourself , de
                        seus empregados, colaboradores, investidores, sócios,
                        administradores, dos seus Usuários ou de terceiros
                        afetados por ação ou omissão que você praticar.
                      </ListItemInline>
                    </ListOrdenatedByLetterInline>
                  </ListTopicItem>
                </ListTopic>
              </ListTopicItem>
              <ListTopicItem>
                Efeitos do Encerramento: Se a Fityourself encerrar este
                Contrato, interrompendo a prestação dos Serviços, em qualquer
                modalidade e por qualquer razão, você deve observar as condições
                a seguir:
                <ListTopic>
                  <ListTopicItem>
                    Acesso à Conta Fityourself: Se este Contrato for encerrado,
                    você não terá direito a uma restauração da sua conta na
                    Plataforma, nem à manutenção dos seus Produtos na
                    Plataforma. Você também não poderá registrar uma nova conta
                    na Plataforma, nem acessar ou usar a Plataforma através da
                    conta de outro Usuário.
                  </ListTopicItem>
                  <ListTopicItem>
                    Fornecimento de Dados: A Fityourself não é obrigada a
                    fornecer aos Usuários dados adicionais aos quais eles já
                    tenham acesso em decorrência do uso regular da Plataforma,
                    em observância da Política de Privacidade. O Produtor,
                    Colaborador ou Afiliado deve observar todas as regras da
                    Política de Privacidade a respeito da exclusão de dados
                    pessoais dos Usuários sob sua responsabilidade em caso de
                    encerramento deste Contrato.
                  </ListTopicItem>
                  <ListTopicItem>
                    Produtos de Assinatura: A Fityourself pode, a seu critério e
                    pelo tempo que lhe aprouver, continuar processando os
                    pagamentos para os produtos de assinatura, que são os
                    produtos em que o pagamento é feito de forma periódica e
                    sucessiva por meio de débitos na fatura de cartão de crédito
                    do Comprador. Em caso de migração dos Compradores de um
                    Produto para outra modalidade de pagamento, ou para outra
                    plataforma, o Produtor deve providenciar essa migração,
                    dentro do prazo que lhe for conferido, com os dados dos
                    Usuários aos quais tiver acesso por ocasião do uso da
                    Plataforma. A Fityourself não fornecerá qualquer dado
                    adicional àqueles já disponibilizados ao Produtor para que a
                    migração seja feita.
                  </ListTopicItem>
                  <ListTopicItem>
                    Resgate de Saldo da Conta Fityourself: Se houver saldo na
                    conta do Usuário no momento do encerramento do Contrato, os
                    valores devem ser transferidos para a conta bancária
                    indicada no cadastro em até 30 dias, após a solicitação de
                    transferência.
                  </ListTopicItem>
                  <ListTopicItem>
                    Reembolso a Compradores Afetados: Se o encerramento do
                    Contrato de um Produtor afetar o acesso de Compradores a um
                    determinado Produto, a Fityourself somente se obriga a
                    reembolsar os Compradores até o limite do saldo existente na
                    conta de pagamento do Produtor.
                  </ListTopicItem>
                  <ListTopicItem>
                    Retenção de Valores pela Fityourself: Se a Fityourself
                    encerrar o Contrato, por qualquer motivo, com um Produtor ou
                    Afiliado, a mesma pode reter um montante do saldo do
                    Produtor ou Afiliado na Plataforma por período de até 180
                    dias contados a partir da última transação realizada. Essa
                    retenção deve ter como finalidade assegurar os direitos de
                    regresso e compensação da Fityourself por quaisquer despesas
                    que ela suportar e que sejam de responsabilidade dos
                    Produtores ou Afiliados. Após esse período, a Fityourself
                    deve liberar o saldo na conta bancária de cadastro do
                    Produtor ou Afiliado.
                  </ListTopicItem>
                  <ListTopicItem>
                    Suporte aos Usuários após o Encerramento: O suporte ao
                    Produtor e o respectivo acesso ao gerente de conta, caso
                    existente, serão automaticamente encerrados com o
                    encerramento do Contrato. Havendo necessidade, os Usuários
                    interessados devem endereçar quaisquer dúvidas ou problemas
                    remanescentes ou decorrentes do encerramento ao serviço de
                    suporte ao cliente da Fityourself.
                  </ListTopicItem>
                  <ListTopicItem>
                    Acordos Específicos com a Fityourself: Se você tiver este
                    Contrato encerrado e tiver algum outro acordo específico
                    pactuado com a Fityourself, este outro acordo também será
                    encerrado imediatamente.
                  </ListTopicItem>
                </ListTopic>
              </ListTopicItem>
              <ListTopicItem>
                Cláusulas Sobreviventes ao Encerramento: As Seções 5, 6, 7 e 9
                destes Termos devem sobreviver a qualquer encerramento deste
                Contrato.
              </ListTopicItem>
            </ListTopic>
          </ListTopicItem>
          <ListTopicItem>
            <ListTopicHeader>
              Mecanismos de denúncias e solução de disputas
            </ListTopicHeader>
            <ListTopic>
              <ListTopicItem>
                Mecanismos de Denúncias: A Fityourself tem um canal de contato
                para que quaisquer interessados possam reportar violação de
                direitos e outras irregularidades na Plataforma, inclusive
                infrações a estes Termos e às Políticas da Fityourself. Essas
                denúncias são apuradas com rigor, e podem resultar em
                consequências diversas aos envolvidos. Por isso, é muito
                importante que o canal de denúncias seja utilizado com ética. Em
                caso de dúvida sobre a ocorrência de infração, recomendamos que
                busque orientação jurídica antes de enviar qualquer notificação,
                além de avaliar se existem formas de provar o que pretende
                informar.
              </ListTopicItem>
              <ListTopicItem>
                Denúncias sobre violação a direitos de propriedade intelectual:
                Se a sua denúncia for relativa a violação de direitos de
                propriedade intelectual, você deve seguir todas as orientações
                do canal de denúncias, incluindo o compartilhamento de
                informações capazes de provar os fatos denunciados, em especial:
                <ListOrdenatedByLetterInline>
                  <ListItemInline>
                    suas informações de contato, para que possamos nos comunicar
                    com você;
                  </ListItemInline>
                  <ListItemInline>
                    informações sobre o conteúdo denunciado, com o detalhamento
                    do ocorrido e os documentos demonstrativos dos fatos
                    narrados, no que for possível;
                  </ListItemInline>
                  <ListItemInline>
                    informações sobre a titularidade dos direitos de propriedade
                    intelectual que possam ter sido violados; e
                  </ListItemInline>
                  <ListItemInline>
                    declaração de ausência de autorização de uso de propriedade
                    intelectual, assinada por você em via física ou eletrônica.
                  </ListItemInline>
                </ListOrdenatedByLetterInline>
                <ListTopic>
                  <ListTopicItem>
                    A denúncia deve ser enviada pela pessoa cujos direitos de
                    propriedade intelectual foram violados, ou por representante
                    munido de procuração com poderes para tanto. A Fityourself
                    não aceita denúncias enviadas por terceiros.
                  </ListTopicItem>
                  <ListTopicItem>
                    Ao encaminhar uma denúncia, o denunciante declara estar
                    ciente e concordar que a Fityourself compartilhará suas
                    informações com o denunciado, e que o denunciado poderá
                    contatar o denunciante para esclarecer os fatos e dirimir a
                    questão.
                  </ListTopicItem>
                  <ListTopicItem>
                    No prazo de 10 dias contados do encaminhamento da denúncia
                    pela Fityourself, o denunciado pode apresentar a sua defesa,
                    informando os motivos que desqualificam a denúncia
                    apresentada e comprovando a titularidade do direito
                    supostamente violado. O denunciado deve enviar:
                    <ListOrdenatedByLetterInline>
                      <ListItemInline>informações de contato;</ListItemInline>
                      <ListItemInline>
                        informações sobre o conteúdo denunciado;
                      </ListItemInline>
                      <ListItemInline>
                        informações sobre a titularidade dos direitos de
                        propriedade intelectual sobre o Produto denunciado,
                        incluindo a documentação comprobatória pertinente; e
                      </ListItemInline>
                      <ListItemInline>
                        declaração de discordância sobre a denúncia de violação
                        a direitos de propriedade intelectual,
                        responsabilizando-se por quaisquer danos causados pela
                        continuidade da comercialização ou do acesso ao Produto
                        objeto da denúncia.
                      </ListItemInline>
                    </ListOrdenatedByLetterInline>
                  </ListTopicItem>
                </ListTopic>
              </ListTopicItem>
              <ListTopicItem>
                Denúncias sobre divulgação de cenas de nudez ou atos sexuais de
                caráter privado: Se a sua denúncia for relativa a violação da
                intimidade decorrente da divulgação, sem autorização de seus
                participantes, de imagens, de vídeos ou de outros materiais
                contendo cenas de nudez ou de atos sexuais de caráter privado, a
                Fityourself promoverá a indisponibilização desse conteúdo, no
                âmbito e nos limites técnicos do seu serviço, de forma
                diligente, em até 10 dias corridos da data do recebimento pela
                Fityourself de todas as informações necessárias para que possa
                tomar essa providência. Para isso, você deve indicar
                precisamente:
                <ListOrdenatedByLetter>
                  <ListItemInline>
                    a URL onde está o material objeto da denúncia; e
                  </ListItemInline>
                  <ListItemInline>
                    os meios que possibilitem à Fityourself identificar a vítima
                    no material denunciado, conforme sua descrição. É possível
                    denunciar apenas conteúdo que envolva você, um familiar ou
                    outra pessoa de quem você seja o representante legal, com as
                    comprovações de vínculo ou parentesco. Materiais que
                    envolvam outras pessoas devem ser denunciados por elas, seus
                    familiares ou responsáveis.
                  </ListItemInline>
                </ListOrdenatedByLetter>
              </ListTopicItem>
              <ListTopicItem>
                Providências a serem adotadas pela Fityourself: Qualquer
                denúncia deve ser encaminhada contendo todas as informações
                exigidas nos itens anteriores. A Fityourself não iniciará
                qualquer processo de verificação se verificar a ausência de
                qualquer daquelas informações.
              </ListTopicItem>
              <ListTopicItem>
                Vedação ao anonimato: A Fityourself não dará prosseguimento a
                denúncias anônimas.
              </ListTopicItem>
              <ListTopicItem>
                Responsabilidade do denunciante: O envio intencional de
                denúncias falsas ou enganosas pode levar à aplicação de qualquer
                das medidas previstas na Seção 10 destes Termos, inclusive o
                bloqueio definitivo de conta, se o denunciante for Usuário da
                Plataforma, além da sua responsabilidade por danos, de acordo
                com a legislação aplicável. Esclarecemos que denunciantes de
                má-fé podem ser investigados pelas autoridades competentes e que
                Fityourself cooperará com as investigações oficiais nesse
                sentido. O denunciante responde integralmente por quaisquer
                danos que causar ao denunciado, à Fityourself ou a terceiros em
                razão de denúncias infundadas, principalmente nos casos em que a
                Fityourself, diante da denúncia, e a seu exclusivo critério,
                bloquear preventiva ou definitivamente a comercialização ou o
                acesso a um Produto.
              </ListTopicItem>
              <ListTopicItem>
                Processo de Apuração de Denúncias: Se a Fityourself entender que
                uma denúncia não tem fundamentos ou elementos de prova
                suficientes para iniciar processo interno de apuração, a
                Fityourself pode arquivar a denúncia e encerrar a ocorrência
                reportada. Se a Fityourself entender que a apuração dos fatos é
                necessária, ela deve encaminhar a denúncia ao denunciado ou ao
                responsável pelo Produto denunciado, sem prejuízo de a
                Fityourself adotar, preventivamente e a seu exclusivo critério,
                quaisquer das medidas previstas na Seção 10 destes Termos, de
                modo isolado ou cumulativo. O denunciado pode responder a
                denúncia em até 10 dias contados do seu encaminhamento pela
                Fityourself.
                <ListTopic>
                  <ListTopicItem>
                    A Fityourself pode iniciar procedimento de investigação
                    interna, a qualquer tempo, sempre que entender necessário à
                    apuração dos fatos denunciados, sobretudo diante
                    <ListOrdenatedByLetterInline>
                      <ListItemInline>
                        de reclamações cujo esclarecimento seja capaz de
                        solucionar a potencial disputa entre os Usuários,
                      </ListItemInline>
                      <ListItemInline>
                        de fatos que não tenham sido narrados de forma clara,
                        mas que tragam indícios de irregularidades praticadas
                        pelo denunciado ou
                      </ListItemInline>
                      <ListItemInline>
                        de denúncias com inconsistências ou indícios indicativos
                        de má-fé, fraude ou dolo do denunciante. Os resultados
                        das investigações internas conduzidas pela Fityourself
                        poderão ser compartilhados com as autoridades
                        competentes, sempre que exigido por lei ou necessário.
                        Se o compartilhamento não for obrigatório por lei, cabe
                        exclusivamente à Fityourself definir se irá divulgar ou
                        não quaisquer resultados de sua apuração.
                      </ListItemInline>
                    </ListOrdenatedByLetterInline>
                  </ListTopicItem>
                </ListTopic>
              </ListTopicItem>
              <ListTopicItem>
                Avaliação das Denúncias pela Fityourself: Se o denunciado não
                responder a denúncia em 10 dias, a Fityourself pode aplicar, em
                caráter definitivo, quaisquer das medidas previstas na Seção 10
                destes Termos, de modo isolado ou cumulativo, se entender
                cabíveis, dando ciência ao denunciante e comunicando as medidas
                ao Usuário afetado. A Fityourself também pode aplicar quaisquer
                das medidas previstas na Seção 10 destes Termos, de modo isolado
                ou cumulativo, se, diante da resposta do denunciado, concluir
                que houve infração aos Termos, violação de direitos ou outras
                irregularidades praticadas na Plataforma. Se a Fityourself, por
                si ou por terceiros por ela determinados, entender que a questão
                denunciada é razoavelmente controversa, a mesma pode instaurar
                um procedimento de solução de disputas entre os Usuários.
              </ListTopicItem>
              <ListTopicItem>
                Solução de Disputas entre Usuários: Os Serviços da Fityourself
                são prestados para conectar os Usuários entre si. As disputas
                que surgem dessas relações devem ser resolvidas entre os
                Usuários diretamente, inclusive aquelas relativas à qualidade,
                às garantias e aos aspectos técnicos dos Produtos. Com o intuito
                de facilitar o diálogo para solução de disputas a Fityourself
                oferece serviço, através do contato@seu.coach, aos Usuários, sem
                custo, e que atende por meio do e-mail. Uma vez acionado, o
                serviço de suporte pode entrar em contato com os Usuários
                envolvidos numa disputa. Você concorda que deve aplicar seus
                melhores esforços para responder aos chamados do serviço de
                suporte, com foco na solução do problema, no menor tempo
                possível e dentro dos prazos estabelecidos pela Fityourself. Se
                não for possível a composição entre os Usuários acerca de uma
                disputa, a Fityourself pode (mas não se obriga a) encerrar a
                disputa mediante decisão própria, pautada na boa-fé e em
                parâmetros de equidade, considerando as regras destes Termos e
                das Políticas da Fityourself. A Fityourself pode delegar a
                solução de qualquer disputa a terceiros por ela determinados,
                inclusive mecanismos de autorregulamentação. A decisão da
                Fityourself a respeito da disputa, ou de quem ela designar, deve
                ser acatada de modo integral e irrecorrível pelos Usuários
                envolvidos.
              </ListTopicItem>
            </ListTopic>
          </ListTopicItem>
          <ListTopicItem>
            <ListTopicHeader>Disposições finais</ListTopicHeader>
            <ListTopic>
              <ListTopicItem>
                Foro e Lei Aplicável: O foro da Comarca da capital/RJ fica
                eleito como o único competente, com exclusão de qualquer outro,
                por mais privilegiado que seja, para dirimir quaisquer
                controvérsias referentes a estes Termos e às Políticas da
                Fityourself. Estes Termos e as Políticas da Fityourself são
                regidos pelas leis do Brasil, com exclusão de qualquer outra, a
                eles não se aplicando a Convenção das Nações Unidas sobre
                Contratos de Compra e Venda Internacional de Mercadorias.
              </ListTopicItem>
              <ListTopicItem>
                Dúvidas: O site da seu.coach é a sua fonte primária com relação
                a esses Termos, às Políticas da Fityourself, aos Serviços ou a
                qualquer programa, funcionalidade, recurso ou questões em geral
                relacionados à Plataforma. Nele você pode encontrar, inclusive,
                muito material educativo gratuito, além de dicas e guias de
                melhores práticas. Nenhum terceiro tem o direito ou autorização
                da Fityourself para falar em nome dela, e todas as práticas
                estabelecidas ou sugeridas por essas pessoas devem ser
                consideradas não oficiais. Se as informações contidas no site
                não forem suficientes para esclarecer suas dúvidas, entre em
                contato através deste.
              </ListTopicItem>
            </ListTopic>
          </ListTopicItem>
        </ListTopic>
      </ModalContent>
    </Modal>
  );
};

export default UseTerms;
