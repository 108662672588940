import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';

interface LoadingContextData {
  setLoading: (state: boolean) => void;
  setPecent: (percent: number) => void;
  readonly isLoading: boolean;
  readonly percent: number;
}

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData,
);

interface LoadingProviderProps {
  children: ReactNode;
}

function LoadingProvider({ children }: LoadingProviderProps): ReactElement {
  const [isLoading, setLoading] = useState(false);
  const [percent, setPecent] = useState(0);

  return (
    <LoadingContext.Provider
      value={{ isLoading, percent, setLoading, setPecent }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading problems');
  }

  return context;
}

export { useLoading, LoadingProvider };
