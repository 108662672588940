import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 15px;

  h1 {
    color: ${colors.lightGrey};
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1.12px;
  }

  h2 {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 400;

    strong {
      color: #b88746;
    }
  }

  h6 {
    margin-top: 15px;
    color: ${colors.lightGrey};
    font-size: 18px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      max-width: 373px;
      width: 100%;
      margin-top: 25px;
      border-color: #000;
      color: #fff;

      input {
        color: ${colors.lightGrey};
        width: 100%;
        max-width: 373px;

        &::placeholder {
          color: ${colors.lightGrey};
        }
      }
    }
  }
`;

export const CreateInteraction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 463px;
  width: 100%;

  > button {
    margin-top: 42px;
    background: ${colors.lightGrey} 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #57575729;
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
  }

  form {
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    h6 {
      font-size: 19px;
      font-weight: 500;
      color: ${colors.lightGrey};
      width: 100%;
      margin-bottom: 10px;
    }

    > div input {
      color: ${colors.lightGrey};
      width: 100%;
      max-width: 373px;
      /* min-width: 186.5px; */

      ::-webkit-calendar-picker-indicator {
        filter: invert(0);
      }
    }

    > div {
      max-width: 100%;
      width: 100%;
      margin: 0;

      @media (max-width: 380px) {
        display: flex;
        flex-wrap: wrap;
      }

      div {
        color: ${colors.lightGrey};
        width: 100%;
        border-color: ${colors.lightGrey};
        svg: {
          width: 20px;
        }
      }
    }
  }

  button {
    &:last-child {
      margin-top: 20px;
    }
  }
`;
