import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};

  align-items: center;

  background: transparent;
  width: 100%;
  padding-bottom: 18px;
  padding-left: 20px;
  margin-bottom: 25px;

  border: 0;
  border-bottom: 2px solid #fff !important;
  color: #fff;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}
  ${props =>
    props.isFocused &&
    css`
      border-color: #fdf5a6;
    `}
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    background: transparent;
    width: 100%;
    border: transparent;
    color: #fff;

    &::placeholder {
      color: #fff;
      font-weight: bold;

      ${props =>
        props.isFocused &&
        css`
          color: #fdf5a6;
        `}
    }
  }
`;

export const Prefix = styled.span`
  font-weight: 500;
  color: #fff;
  margin-right: 10px;
`;

export const Error = styled(Tooltip)`
  height: 20px;
  svg {
    margin-right: 0;
    margin-left: 15px;
  }
`;
