import { useState } from 'react';

import { Link } from 'react-router-dom';

import * as H from './styles';

import Logo from '../Logo';

export const SandwichMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <H.Header>
      <H.Nav>
        <Logo />
        <H.SandwichMenuIcon
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          isOpen={isMenuOpen}
        >
          <span />
          <span />
          <span />
        </H.SandwichMenuIcon>
        <H.ButtonGroup isOpen={isMenuOpen}>
          <Link to="signup/become-a-professional">
            <H.Button className="signupbutton" isOutline={false}>
              Criar Conta
            </H.Button>
          </Link>
          <Link to="signin">
            <H.Button isOutline>Entrar</H.Button>
          </Link>
        </H.ButtonGroup>
      </H.Nav>
    </H.Header>
  );
};
