import styled, { keyframes } from 'styled-components';

import { ImageBackground } from '~/assets/images';
import { Input } from '~/components';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const InputUpperCase = styled(Input)`
  text-transform: uppercase;
`;

export const LinkPayment = styled.button`
  background: #00aa1c 0% 0% no-repeat padding-box;
  color: white;
  max-width: 400px;
  width: 100%;
  padding: 1rem;
  border-radius: 60px;

  font-size: 2rem;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`;

export const ButtonLink = styled.button`
  background: transparent;
  max-width: 400px !important;
  width: 100%;
  padding: 1rem !important;
  border-radius: 60px;

  font-size: 2rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonBack = styled.button`
  position: fixed;
  top: 5rem;
  left: 5rem;
  width: 4rem !important;
  height: 4rem !important;
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    background-color: #00000020;
  }

  @media (max-width: 768.9px) {
    position: absolute;

    top: 1rem;
    left: 1rem;
  }
`;

export const Container = styled.div`
  background: url(${ImageBackground}) 0% / cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-top: 64px;
  padding-bottom: 128px;

  img {
    width: 145px;
    height: 68px;
  }

  h1 {
    letter-spacing: 1.28px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 700;
    margin-top: 40px;
  }

  h2 {
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-top: 16px;
    font-weight: 400;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    max-width: 420px;
    width: 100%;
    animation: ${appearFromRight} 1s;
    input {
      width: 100%;
    }
  }

  p {
    margin-top: 20px;
    margin-left: 25px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    max-width: 364px;

    strong {
      color: #fdf5a6;
      cursor: pointer;
    }
  }

  button {
    margin-top: 20px;
    max-width: 420px;
    width: 100%;
    height: auto;
    padding: 14px;
  }

  @media (max-width: 768.9px) {
    width: 100%;
    height: 100%;
    padding: 10px 20px;

    p {
      margin-left: 0;
    }
  }

  @media (max-width: 768.9px) and (max-height: 673.9px) {
    width: 100%;
    height: 100%;
  }
`;

export const PaymentBox = styled.div`
  display: flex;
  max-width: 1024px;
  width: 100%;
  margin-top: 60px;
  justify-content: center;
  padding: 5px;

  @media (max-width: 768.9px) {
    max-width: 90%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
  }
`;

export const PaymentData = styled.div`
  max-width: 560px;
  width: 100%;
  padding-right: 20px;

  form {
    margin: 0 auto;
    label {
      color: #fff;
      font-size: 21px;
      font-weight: 600;

      input {
        padding-top: 20px;
      }
    }

    > div {
      display: flex;
      label {
        max-width: 25%;

        &:first-child {
          max-width: 75%;
          padding-right: 55px;
        }
      }
    }
  }
  @media (max-width: 768.9px) {
    width: 100%;

    form {
      max-width: 100%;
      label {
        width: 100%;
        max-width: 100%;
        > div {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
`;

export const PaymentInfo = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  // width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 24px;

  > h1 {
    margin-top: 15px;
    letter-spacing: 1.35px;
    color: #b88746;
    text-transform: uppercase;
    font-size: 27px;
    font-weight: 700;
  }

  p {
    letter-spacing: 0.88px;
    color: #2c2c2c !important;
    font-size: 22px;
    font-weight: 400;
    margin-top: 20px;

    strong {
      cursor: default;
      letter-spacing: 0.88px;
      color: #2c2c2c;
      font-size: 22px;
      font-weight: 700;
      margin-right: 10px;
    }
  }

  span {
    letter-spacing: -0.36px;
    color: #565656;
    font-size: 33px;
    font-weight: 700;

    strong {
      letter-spacing: -0.74px;
      color: #141414;
      font-weight: 700;
      font-size: 67px;
    }
  }
  @media (max-width: 768.9px) {
    height: auto;

    > h1 {
      text-align: center;
    }

    span {
      font-size: 21px;

      strong {
        font-size: 34px;
      }
    }
  }
`;

export const List = styled.ul`
  margin-top: 40px;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  letter-spacing: -0.19px;
  color: #2c2c2c;
  margin-bottom: 25px;
  max-width: 100%;
  width: 100%;
  font-size: 15px;

  img {
    width: 13px;
    height: 9px;
    margin-right: 10px;
  }

  svg {
    margin-right: 10px;
  }

  @media (max-width: 768.9px) {
    font-size: 15px;
  }
`;

export const DescountText = styled.li`
  width: 100%;
  text-align: center;
`;
export const DescountTextTwo = styled.h1`
  width: 100%;
  text-align: center;
`;

export const DescountItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  h1 {
    text-align: center;
    margin-top: 0px;
    color: black;
    font-size: 20px;
  }
  input {
    color: black !important;
    background-color: white;
    border: 2px solid green;
    padding: 10px;
    border-radius: 20px;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
