import { FC, useMemo } from 'react';

import { ImageDance } from '~/assets/images';
import { DropdownType } from '~/models/Common';
import { DropdownIcon } from '~/styles/objects/dropdown-icon';
import { ItemsGrid } from '~/styles/objects/items-grid';
import { NotFoundItems } from '~/styles/objects/not-found';
import getMimeTypeByExtVideo from '~/utils/getMimeType';
import { handleChangeDropdown } from '~/utils/handleChangeDropdown';

import { DropdownMenu } from '..';

import * as V from './styles';

import { VideoProps } from './typings';

const Video: FC<VideoProps> = ({
  videos,
  course,
  dropdownProps,
  handleDeleteVideo,
  setVideoNeedUpdate,
  handleVideoModal,
  isProfessional,
}) => {
  const videosMemo = useMemo(
    () =>
      videos?.map(video => (
        <V.VideoWrapper key={video.id}>
          {isProfessional && (
            <>
              <DropdownIcon
                size={24}
                color="#FFF"
                onClick={() =>
                  dropdownProps &&
                  handleChangeDropdown({
                    id: video.id,
                    type: DropdownType.VIDEO,
                    isOpen: dropdownProps.dropdownOpen,
                    setIsOpen: dropdownProps.setDropdownOpen,
                    setItem: dropdownProps.setDropdownSelected,
                  })
                }
              />

              <DropdownMenu
                setNeedUpdate={setVideoNeedUpdate}
                handleDelete={() => handleDeleteVideo(video.id)}
                dropdownIsOpen={dropdownProps.dropdownOpen}
                setDropdownIsOpen={dropdownProps.setDropdownOpen}
                id={`video_${video.id.toString()}`}
                dropdownSelected={dropdownProps.dropdownSelected}
                video={{
                  description: video.description ?? '',
                  id: video.id,
                  title: video.title,
                  video: video.path,
                  image: video.image,
                }}
                course={course}
              />
            </>
          )}
          <V.VideosContent onClick={() => handleVideoModal(video)}>
            {/* {course?.video ? (
              <V.VideosField
                onContextMenu={() => false}
                controlsList="nodownload"
                loop
                controls
                autoPlay
                muted
              >
                <source
                  src={`${process.env.REACT_APP_BASE_VIDEO}${video.id}`}
                  type={getMimeTypeByExtVideo(
                    course?.video.split('.').pop() as string,
                  )}
                />
              </V.VideosField>
            ) : (
              <V.VideosImage src={video?.image ?? ImageDance} alt="Dance" />
            )} */}
            {video?.image ? (
              <V.MediaContainer>
                <V.VideosImage src={video?.image ?? ImageDance} alt="Dance" />
              </V.MediaContainer>
            ) : (
              <V.MediaContainer>
                <V.VideosField
                  onContextMenu={() => false}
                  controlsList="nodownload"
                  loop
                  controls
                  autoPlay
                  muted
                >
                  <source
                    src={video.path}
                    type={getMimeTypeByExtVideo(
                      video?.name.split('.').pop() as string,
                    )}
                  />
                </V.VideosField>
              </V.MediaContainer>
            )}
            <V.VideosContent>
              <V.VideosTitle>{video.title}</V.VideosTitle>
              <V.VideosDescription>{video.description}</V.VideosDescription>
            </V.VideosContent>
          </V.VideosContent>
        </V.VideoWrapper>
      )),
    [
      course,
      dropdownProps,
      handleDeleteVideo,
      handleVideoModal,
      isProfessional,
      setVideoNeedUpdate,
      videos,
    ],
  );

  if (videos?.length === 0)
    return <NotFoundItems>{/* Nenhum vídeo encontrado. */}</NotFoundItems>;

  return <ItemsGrid>{videosMemo}</ItemsGrid>;
};

export default Video;
