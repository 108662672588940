//
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

//
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { serialize } from 'object-to-formdata';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Button,
  ButtonBlack,
  ButtonLink,
  Input,
  InputCurrency,
  InputLink,
  InputVideos,
  Modal,
  ModalRedirect,
  Select,
  Textarea,
} from '~/components';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import {
  Categories,
  MediaProps,
  MediaType,
  ModalLike,
  SubmitHandler,
  VideoError,
} from '~/models/Common';
import { LiveIsFreeStatus, Lives } from '~/models/Lives';
import { Workouts } from '~/models/Workouts';
import LivesService from '~/services/LivesService';
import ProfessorService from '~/services/ProfessorService';
import { ComplementaryText } from '~/styles/objects/complementary-text';
import errorHandlerToToast from '~/utils/errorHandler';
import getCurrentTime from '~/utils/getCurrentTime';
import getTodayDate from '~/utils/getTodayDate';
import getValidationErrors from '~/utils/getValidationErrors';
import handleState from '~/utils/handleColorButtonBlack';
import handleChangeFile from '~/utils/handleFile';
import Price from '~/utils/Price';
import { useQuery } from '~/utils/query';
import validateMediaType from '~/utils/validateMediaType';

import {
  BasePrice,
  ButtonGroup,
  CreateWorkout,
  DatetimeFieldGroup,
  DatetimeGroup,
  DescriptionInfo,
  Label,
  ModalContent,
  PreviewVideo,
  Radio,
  RadioGroup,
} from './style';

interface NewWorkoutProps extends ModalLike {
  loading?: boolean;
  readonly workout?: Workouts;
  readonly live?: Lives;
  isNewLive?: boolean;
}

interface NewWorkoutCommonCreate {
  treining_category_id: number;
  title: string;
  description: string;
}

interface NewLiveCreate extends NewWorkoutCommonCreate {
  path: string;
  live_hour: string;
  price?: number;
  original_price?: number;
  is_free: number;
}

interface NewLiveFormCreate extends Omit<NewLiveCreate, 'original_price'> {
  original_price: string;
}

type VideoType = 'mov' | 'mp4' | 'mkv' | 'avi';

interface NewWorkoutCreate extends NewWorkoutCommonCreate {
  level: string;
  video?: File;
  image?: File;
  link?: string;
  ext_file?: VideoType;
}

type NewWokoutMedia = Partial<Record<MediaType, Partial<MediaProps>>>;

const NewWorkout: React.FC<NewWorkoutProps> = ({
  modalIsOpen,
  setModalIsOpen,
  workout,
  live,
  isNewLive = false,
}) => {
  const formRef = useRef<FormHandles>(null);
  const BASE_PRICE = 29.9;
  const { user, logout } = useAuth();
  const queryParam = useQuery().get('level') as string | null;

  if (user.role !== 'professional') {
    logout();
    throw new Error('Acesso não permitido');
  }
  const userId = user.professional.id;

  const { addToast } = useToast();
  const { setLoading, setPecent } = useLoading();

  const [newWorkout, setNewWorkout] = useState<Workouts | null>(null);

  const [showLinkInput, setShowLinkInput] = useState(false);

  const [amountReceivable, setAmountReceivable] = useState<Price>(
    new Price({ basePrice: BASE_PRICE }),
  );
  const [workoutPrice, setWorkoutPrice] = useState<string>();
  const { push } = useHistory();
  const [mediaManager, setMediaManager] = useState<NewWokoutMedia>();

  const [videoErr, setVideoErr] = useState<VideoError>({
    isCoverErr: false,
    isVideoErr: false,
  });

  const [hasValue, setHasValue] = useState(false);
  const [videoExtension, setVideoExtension] = useState('mp4');

  const [myWorkout, setMyWorkout] = useState<Workouts | null>(null);
  const [myLive, setMyLive] = useState<Lives | null>(null);

  const [modalRedirectVisible, setModalRedirectVisible] = useState(false);
  const [professioncategories, setprofessionscategories] = useState<
    Categories[]
  >([]);

  useEffect(() => {
    const chargeInCurrency = amountReceivable.getChargeInCurrency();
    setWorkoutPrice(chargeInCurrency);
  }, [amountReceivable]);

  useEffect(() => {
    if (workout) {
      setNewWorkout({
        ...workout,
      });

      if (!workout.id) throw new Error('Workout dont have id');

      setMediaManager({
        image: {
          blob: workout.image,
        },
        video: {
          blob: workout.path,
        },
      });

      setMyWorkout(workout);
    } else if (live) {
      setHasValue(live.is_free !== LiveIsFreeStatus.YES);
      setMyLive({
        ...live,
        date: live.date.split('T')[0],
      });
      if (live.price) {
        const price = new Price({ price: live.price, basePrice: BASE_PRICE });
        setAmountReceivable(price);
      }
    }
  }, [workout, live, BASE_PRICE]);

  useEffect(() => {
    if (workout?.price) {
      const price = new Price({ price: workout.price, basePrice: BASE_PRICE });
      setAmountReceivable(price);
    }
  }, [workout?.price]);

  const handleWorkoutPriceChange = (currency: string): void => {
    let currencyToPrice: Price;
    try {
      currencyToPrice = Price.fromCurrency({
        price: currency,
        basePrice: BASE_PRICE,
      });
    } catch (e) {
      currencyToPrice = new Price({ basePrice: BASE_PRICE });
    }
    setAmountReceivable(currencyToPrice);
  };

  const handleNewWorkout = (category: Categories): void => {
    setNewWorkout({
      category,
      title: '',
      description: '',
    });
  };

  const handProfessionCategories = async (): Promise<void> => {
    try {
      const response = await ProfessorService.getProfessionCategories();
      setprofessionscategories(response.data.professionscategories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handProfessionCategories();
  }, []);

  const handleUploadMedia = useCallback(
    (type: MediaType, e: ChangeEvent<HTMLInputElement>) => {
      const newFile = handleChangeFile(e);
      try {
        if (newFile) {
          validateMediaType(type.toString(), newFile.file.name);
          setMediaManager({
            ...mediaManager,
            [type]: {
              blob: newFile.name,
              file: newFile.file,
            },
          });
        }
      } catch (error: any) {
        const responseErr = errorHandlerToToast({ error, formRef });
        if (responseErr) {
          addToast(responseErr);
        }
      }
    },
    [mediaManager, addToast],
  );

  const handleValue = (value: boolean): void => {
    setHasValue(value);
  };

  const handleSubmit: SubmitHandler<NewWorkoutCreate> = useCallback(
    async preData => {
      setLoading(true);

      try {
        // const treining_category_id = workout
        //   ? workout.category.id
        //    : 0

        const treining_category_id = newWorkout?.category.id ?? 0;

        const data: NewWorkoutCreate = {
          ...preData,
          treining_category_id,
          ext_file: videoExtension as VideoType,
        };

        data.image = mediaManager?.image?.file ?? undefined;
        data.video = mediaManager?.video?.file ?? undefined;

        if (data.link) data.video = undefined;

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: workout
            ? Yup.string()
            : Yup.string().required('Título obrigatório'),
          description: workout
            ? Yup.string()
            : Yup.string().required('Descrição obrigatória.'),
          video:
            workout || showLinkInput
              ? Yup.mixed()
              : Yup.mixed().required('Vídeo obrigatório'),
          image: Yup.mixed(),
          treining_category_id: Yup.number(),
          link: showLinkInput
            ? Yup.string().url().required('Link obrigatório')
            : Yup.string().url().nullable(),
          ext_file:
            workout || showLinkInput
              ? Yup.mixed()
              : Yup.mixed().oneOf(['mov', 'mp4', 'mkv', 'avi']),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        const newData = serialize<NewWorkoutCreate>(data);

        if (!userId) throw new Error('Problema no userId');
        const response: any =
          workout && workout.id
            ? await ProfessorService.videoUploadEditTraining(
                { professionalId: userId, videoId: workout.id },
                newData,
                setPecent,
              )
            : await ProfessorService.videoUploadTraining(
                userId,
                newData,
                setPecent,
              );

        setModalIsOpen(false);

        setLoading(false);

        if (response?.response?.status > 300) {
          addToast({
            title: response.response.data.message,
            type: 'error',
          });
          throw new Error(response.response.data.message);
        }

        addToast({
          type: 'success',
          title: response.data?.message,
        });
        if (newWorkout?.description) {
          push(`/professor/my-workouts?level=${newWorkout.description}`);
        }
      } catch (error) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const {
            video: isVideoErr,
            imagem: isImageErr,
            ...errors
          } = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          setVideoErr({
            isCoverErr: !!isImageErr,
            isVideoErr: !!isVideoErr,
          });
        } else if (error instanceof Error) {
          const responseErr = errorHandlerToToast({
            error,
          });
          if (responseErr) addToast(responseErr);
        }
      }
    },
    [
      addToast,
      mediaManager?.image?.file,
      mediaManager?.video?.file,
      newWorkout?.description,
      push,
      setLoading,
      setModalIsOpen,
      setPecent,
      userId,
      workout,
      showLinkInput,
      videoExtension,
    ],
  );

  const handleSubmitLive: SubmitHandler<NewLiveFormCreate> = useCallback(
    async ({ original_price, ...preData }) => {
      setLoading(true);
      try {
        const data: NewLiveCreate = {
          ...preData,
          treining_category_id: newWorkout?.category.id ?? 0,
          is_free: !hasValue ? 1 : 0,
          original_price: original_price
            ? Price.currencyToFloat(original_price)
            : 0,
          price:
            hasValue && original_price
              ? amountReceivable.getChargeInFloat()
              : undefined,
        };

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          url: Yup.string().url().required('Link obrigatório'),
          date: Yup.string().required('Data obrigatória'),
          hour: Yup.string().required('Hora obrigatório'),
          title: Yup.string().required('Título obrigatório'),
          description: Yup.string()
            .required('Descrição obrigatória.')
            .max(6000, 'Máximo 6000 caracteres'),
          price: Yup.number().when('is_free', {
            is: 0,
            then: Yup.number().positive().required(),
          }),
          original_price: Yup.number().when('price', {
            is: (val: number) => val !== undefined,
            then: Yup.number().positive().required(),
          }),
          is_free: Yup.number(),
          treining_category_id: Yup.number().positive().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const newData = serialize(data);

        if (!userId) throw new Error('Problema no userId');

        if (live) {
          await LivesService.putUpdateLive(
            { professionalId: userId, liveId: live.id },
            newData,
            setPecent,
          );
        } else {
          await LivesService.postLives(userId, newData, setPecent);
        }

        setModalIsOpen(false);

        const redirectLevel = newWorkout?.category.description;

        push(`/professor/my-workouts?level=${redirectLevel}`);

        addToast({
          type: 'success',
          title: 'Aula ao vivo criada com sucesso!',
        });
      } catch (error: any) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          const responseErr = errorHandlerToToast({
            error: new Error(
              'Erro ao criar live. Verifique os dados inseridos.',
            ),
            formRef,
          });
          if (responseErr) addToast(responseErr);
        } else {
          const responseErr = errorHandlerToToast({ error, formRef });
          if (responseErr) addToast(responseErr);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      addToast,
      amountReceivable,
      hasValue,
      live,
      push,
      setLoading,
      setModalIsOpen,
      setPecent,
      userId,
      newWorkout,
    ],
  );

  useEffect(() => {
    if (!workout) {
      setNewWorkout(null);
      setMediaManager(undefined);
    }
  }, [modalIsOpen, workout]);

  return (
    <>
      <Modal {...{ modalIsOpen, setModalIsOpen }}>
        <ModalContent>
          {!isNewLive && !newWorkout && !myWorkout && !myLive && (
            <>
              <h1>Nova Aula</h1>
              <ButtonGroup>
                {professioncategories?.map(item => (
                  <Button
                    isOutline
                    key={item.id.toString()}
                    onClick={() => handleNewWorkout(item)}
                  >
                    {item.description}
                  </Button>
                ))}
              </ButtonGroup>
            </>
          )}
        </ModalContent>
      </Modal>
      {((newWorkout && !isNewLive) ||
        (myWorkout && myWorkout.category.id === 1)) && (
        <Modal {...{ modalIsOpen, setModalIsOpen }}>
          <ModalContent>
            <>
              <h2>
                {workout ? 'Editar' : 'Nova'} {' Aula> '}
                <strong>{newWorkout?.category?.description}</strong>
              </h2>
              <CreateWorkout>
                {mediaManager?.video && (
                  <PreviewVideo src={mediaManager.video.blob} controls />
                )}
                <Form
                  initialData={workout}
                  ref={formRef}
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {!showLinkInput && (
                    <>
                      <InputVideos
                        name="video"
                        formRef={formRef}
                        setFileFunction={(type, newFile) => {
                          setMediaManager({
                            ...mediaManager,
                            [type]: {
                              blob: newFile.name,
                              file: newFile.file,
                            },
                          });
                        }}
                        stateUpload={handleState({
                          hasValue: mediaManager?.video?.blob !== undefined,
                          error: videoErr.isVideoErr,
                        })}
                        showLinkInput={showLinkInput}
                        setShowLinkInput={setShowLinkInput}
                      >
                        {mediaManager?.video?.blob
                          ? 'Vídeo carregado'
                          : 'Carregar vídeo'}
                      </InputVideos>
                      <ButtonLink setShowLinkInput={setShowLinkInput} />
                    </>
                  )}
                  {showLinkInput && (
                    <InputLink setShowLinkInput={setShowLinkInput} />
                  )}

                  <Select
                    name="ext_file"
                    options={[
                      {
                        label: 'MOV',
                        value: 'mov',
                      },
                      {
                        label: 'MP4',
                        value: 'mp4',
                      },
                      {
                        label: 'MKV',
                        value: 'mkv',
                      },
                      {
                        label: 'AVI',
                        value: 'avi',
                      },
                    ]}
                    value={videoExtension}
                    setValue={setVideoExtension}
                    color="#000"
                  />

                  <label htmlFor="image">
                    <ButtonBlack
                      stateUpload={handleState({
                        hasValue: mediaManager?.image?.blob !== undefined,
                        error: videoErr.isCoverErr,
                      })}
                    >
                      {mediaManager?.image?.blob
                        ? 'Capa carregada'
                        : 'Carregar capa'}
                    </ButtonBlack>
                    <input
                      type="file"
                      id="image"
                      accept="image/jpeg,image/png"
                      hidden
                      onChange={handleUploadMedia.bind(this, 'image')}
                    />
                  </label>
                  <p>Dê preferência para imagens horizontais na capa.</p>

                  <h6>Título:</h6>
                  <Input name="title" />
                  <h6>Descrição:</h6>
                  <Textarea name="description" />
                  <Button>Salvar</Button>
                </Form>
              </CreateWorkout>
            </>
          </ModalContent>
        </Modal>
      )}
      {(isNewLive || live) && (
        <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}>
          <ModalContent>
            <>
              <h1>Nova Aula</h1>
              <ButtonGroup>
                {professioncategories?.map(item => (
                  <Button
                    isOutline
                    key={item.id.toString()}
                    onClick={() => handleNewWorkout(item)}
                  >
                    {item?.description}
                  </Button>
                ))}
              </ButtonGroup>
            </>
          </ModalContent>
        </Modal>
      )}

      {((newWorkout && isNewLive) ||
        (myWorkout && myWorkout.category.id === 1)) && (
        <Modal {...{ modalIsOpen, setModalIsOpen }}>
          <ModalContent>
            <h2>
              {workout ? 'Editar' : 'Nova'} {' Aula > '}
              <strong>{newWorkout?.category.description}</strong>
            </h2>
            <CreateWorkout>
              <Form
                initialData={{
                  ...live,
                  date: live?.date.split('T')[0] || getTodayDate(),
                  hour: live?.hour || getCurrentTime(),
                  price: amountReceivable.toCurrencyFormat(),
                }}
                ref={formRef}
                onSubmit={handleSubmitLive}
              >
                <Label htmlFor="url">Link da aula:</Label>
                <Input id="url" name="url" type="url" />

                <DatetimeGroup>
                  <DatetimeFieldGroup>
                    <Label htmlFor="date">Data:</Label>
                    <Input
                      id="date"
                      name="date"
                      type="date"
                      min="2020-12-31"
                      max="9999-12-31"
                    />
                  </DatetimeFieldGroup>
                  <DatetimeFieldGroup>
                    <Label htmlFor="hour">Hora:</Label>
                    <Input id="hour" name="hour" type="time" />
                  </DatetimeFieldGroup>
                </DatetimeGroup>

                <Label>Título:</Label>
                <Input name="title" />

                <Label>Descrição:</Label>
                <Textarea name="description" />
                <DescriptionInfo>
                  <strong>Obs:</strong> *Utilize esse campo para inserir o
                  código de acesso da aula, fornecido pela plataforma de lives
                  (zoom, google meet, skype ou de sua preferência)
                </DescriptionInfo>

                <h6>Você deseja cobrar pela aula ao vivo?</h6>
                <RadioGroup>
                  <label htmlFor="yes">
                    <Radio
                      name="paid"
                      id="yes"
                      type="radio"
                      checked={hasValue}
                      onChange={() => handleValue(true)}
                    />
                    Sim
                  </label>
                  <label htmlFor="no">
                    <Radio
                      name="paid"
                      id="no"
                      type="radio"
                      checked={!hasValue}
                      onChange={() => handleValue(false)}
                    />
                    Não
                  </label>
                </RadioGroup>
                {hasValue && (
                  <>
                    <h6>Quanto você quer receber?</h6>
                    <InputCurrency
                      name="original_price"
                      callbackFunction={handleWorkoutPriceChange}
                      defaultValue={Price.decimalUSToBr(BASE_PRICE)}
                      min={BASE_PRICE}
                    />
                    <BasePrice>
                      Valor mínimo de {Price.floatToCurrency(BASE_PRICE)}.
                    </BasePrice>
                    <h6>Valor:</h6>
                    <Input name="price" value={workoutPrice} disabled />
                    <ComplementaryText style={{ marginTop: 0 }}>
                      Acrescentados {Price.CHARGE_PERCENT - 4.5}% da plataforma
                      + 4,5% de encargos financeiros + R$: 0.39 centavos de
                      tarifas.
                    </ComplementaryText>
                  </>
                )}
                <Button
                  type="button"
                  onClick={() => {
                    formRef.current?.submitForm();
                  }}
                >
                  Salvar
                </Button>
              </Form>
            </CreateWorkout>
          </ModalContent>
        </Modal>
      )}
      <ModalRedirect
        modalIsOpen={modalRedirectVisible}
        setModalIsOpen={setModalRedirectVisible}
      />
    </>
  );
};

export default NewWorkout;
