import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { serialize } from 'object-to-formdata';
import * as Yup from 'yup';

import { Button, ButtonBlack, Input, Modal } from '~/components';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { ModalLike, SubmitHandler } from '~/models/Common';
import Practice from '~/models/Practice';
import ProfessorService from '~/services/ProfessorService';
import errorHandlerToToast from '~/utils/errorHandler';
import handleState from '~/utils/handleColorButtonBlack';
import handleChangeFile from '~/utils/handleFile';

import { CreateVideo, ModalContent } from './styles';

interface NewPracticeProps extends ModalLike {
  practice?: Practice;
  courseId: number;
  setNeedUpdate?: (arg: boolean) => void;
}
interface FileForm {
  file: File;
}
interface PracticeText {
  title: string;
  description: string;
}
type PracticeFormData = Partial<FileForm> & PracticeText;
const NewPractice: React.FC<NewPracticeProps> = ({
  modalIsOpen,
  setModalIsOpen,
  practice,
  courseId,
  setNeedUpdate,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { setLoading, setPecent } = useLoading();
  const [fileState, setFileState] = useState<FileForm | null>(null);

  useEffect(() => {
    if (!modalIsOpen) {
      setFileState(null);
      formRef.current?.reset();
    }
  }, [modalIsOpen]);

  const handleSubmit: SubmitHandler<PracticeText> = useCallback(
    async preData => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Título obrigatório'),
          description: Yup.string().required('Descrição obrigatória.'),
        });

        await schema.validate(preData, {
          abortEarly: false,
        });
        const data: PracticeFormData = {
          ...preData,
          ...fileState,
        };

        const formData = serialize<PracticeFormData>(data);

        practice?.id
          ? await ProfessorService.updatePratice({
              courseId,
              praticeId: practice.id,
              data: formData,
              setPercent: setPecent,
            })
          : await ProfessorService.createPratice({
              data: formData,
              courseId,
              setPercent: setPecent,
            });

        if (setNeedUpdate) setNeedUpdate(true);

        setLoading(false);

        setModalIsOpen(false);

        addToast({ title: 'Arquivo enviado com sucesso!', type: 'success' });
      } catch (error) {
        setLoading(false);
        setModalIsOpen(false);
        const toastProps = errorHandlerToToast({ error });
        if (toastProps) addToast(toastProps);
      }
    },
    [
      addToast,
      courseId,
      fileState,
      practice,
      setLoading,
      setModalIsOpen,
      setNeedUpdate,
      setPecent,
    ],
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    const newFile = handleChangeFile(e);
    if (newFile) {
      setFileState({
        file: newFile.file,
      });
    }
  }, []);

  return (
    <Modal {...{ setModalIsOpen, modalIsOpen }}>
      <ModalContent>
        <h2>{practice ? 'Editar arquivo' : 'Novo arquivo'}</h2>
        <CreateVideo>
          <Form initialData={practice} ref={formRef} onSubmit={handleSubmit}>
            <h6>Título:</h6>
            <Input name="title" />
            <h6>Descrição:</h6>
            <Input name="description" />
            <label htmlFor="file">
              <ButtonBlack
                stateUpload={handleState({
                  hasValue:
                    fileState?.file !== undefined ||
                    practice?.file !== undefined,
                  error: false,
                })}
              >
                {fileState?.file || practice?.file
                  ? 'Documento carregado'
                  : 'Carregar documento'}
              </ButtonBlack>
              <input
                type="file"
                id="file"
                accept="
                  application/pdf,
                  .csv,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                  application/vnd.ms-excel,
                  .doc,
                  .docx,
                  application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                "
                onChange={handleChange.bind(this)}
                hidden
              />
            </label>
            <Button type="submit">Salvar</Button>
          </Form>
        </CreateVideo>
      </ModalContent>
    </Modal>
  );
};

export default NewPractice;
