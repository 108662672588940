enum getTranslation {
  period = 'Data de entrada',
  type_payment = 'Tipo',
  name = 'Aluno (a)',
  status = 'Status',
  original_price = 'Valor a receber',
  payment_date = 'Data de Pagamento',
  paid = 'Pago',
  canceled = 'Cancelado',
  training = 'Aula',
  course = 'Curso',
  live = 'Ao Vivo',
}
export const translateTable = (cell: string): string => {
  return getTranslation[cell as keyof typeof getTranslation];
};

export const formatTableCell = (cell: string[]): string => {
  const [key, value] = cell;
  switch (key) {
    case 'type_payment':
    case 'status':
      return translateTable(value);

    case 'original_price':
      return value
        ? `R$${Number(value).toFixed(2).replace('.', ',')}`
        : 'Gratuito';

    case 'payment_date':
      // return formatDate(value, 'invert');
      // return value;

      return `${value.split('T')[0].split('-')[2]}/${
        value.split('T')[0].split('-')[1]
      }/${value.split('T')[0].split('-')[0]}`;
    case 'period':
      return `${value.split('T')[0].split('-')[2]}/${
        value.split('T')[0].split('-')[1]
      }/${value.split('T')[0].split('-')[0]}`;
    default:
      return value || 'N/A';
  }
};

export const chooseSortingFunction = (column: string): any => {
  const alphabeticSort = (a: any, b: any): number => {
    if (a[column].toLowerCase() < b[column].toLowerCase()) {
      return -1;
    }
    if (a[column].toLowerCase() > b[column].toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const numericSort = (a: any, b: any): number => {
    if (Number(a[column]) < Number(b[column])) {
      return -1;
    }
    if (Number(a[column]) > Number(b[column])) {
      return 1;
    }
    return 0;
  };

  const dateSort = (a: any, b: any): number => {
    return new Date(a[column]).getTime() - new Date(b[column]).getTime();
  };

  switch (column) {
    case 'type_payment':
    case 'status':
    case 'name':
      return alphabeticSort;

    case 'original_price':
      return numericSort;

    case 'payment_date':
    case 'period':
      return dateSort;

    default:
      return null;
  }
};
