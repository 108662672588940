import styled from 'styled-components';

import { ImageGoldenBg } from '~/assets/icons';
import Button from '~/components/Button';
import { Container } from '~/styles/objects/container';
import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

export const HeaderWrapper = styled.header`
  background: url(${ImageGoldenBg}) 0% / cover;
  padding: var(--gap-sm) 0;
  margin-top: 32px;
`;

export const HeaderContainer = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: var(--gap-sm);

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
    row-gap: var(--gap-xs);
  }
`;

export const HeaderTitle = styled.h1`
  font-size: 3.6rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.44px;

  @media (max-width: ${breakpoints.small}) {
    font-size: 2.4rem;
    text-align: center;
  }
`;

export const HeaderButton = styled(Button)`
  background: ${colors.black};

  @media (max-width: ${breakpoints.small}) {
    max-width: 290px;
  }
`;
