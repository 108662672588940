import { ClipLoader as ClipLoaderBase } from 'react-spinners';
import styled from 'styled-components';

export const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClipLoader = styled(ClipLoaderBase)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
`;
