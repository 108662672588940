import { ClipLoader, Center } from './styles';

interface LoadingProps {
  loading: boolean;
  percent?: number;
}

const Loading: React.FC<LoadingProps> = ({ loading, percent }) => {
  return (
    <>
      {loading && (
        <div
          style={{
            position: 'fixed',
            width: 'calc(100vw - 15px)',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0,0,0, 0.7)',
            zIndex: 1999,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <Center>
              <ClipLoader color="#FDF5A6" loading={loading} size={120} />
            </Center>
            <div
              style={{ color: '#FDF5A6', fontSize: 14, textAlign: 'center' }}
            >
              {percent && percent > 0 && percent < 100
                ? `${percent?.toFixed(0)}%`
                : ''}
              {percent && percent > 99 ? (
                <>
                  <p style={{ marginTop: 20 }}>
                    Aguarde a compressão do vídeo.
                  </p>
                  <br />
                  <p>Isso pode levar algum tempo.</p>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
