import { FiMoreVertical } from 'react-icons/fi';
import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

export const DropdownIcon = styled(FiMoreVertical)`
  position: absolute;
  right: 24px;
  margin-top: 5px;
  border-radius: 50%;
  background: ${colors.black} 0% 0% no-repeat padding-box;
  opacity: 0.67;
  cursor: pointer;
  z-index: 30;

  @media (max-width: ${breakpoints.small}) {
    right: 12px;
  }
`;
