import { FiChevronLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--gap);
`;

export const IconClose = styled(FiChevronLeft)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  color: #2c2c2c;
  margin: 30px;

  transition: 180ms ease-in;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
  }
`;

export const LiveContent = styled.article`
  width: 85%;

  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const LiveContentStatus = styled.span`
  font-size: 12px;
  line-height: 1.1em;
  color: #2c2c2c;

  > strong {
    font-size: 16px;
    line-height: 1.1em;
    color: #b88746;
  }
`;

export const LiveContentTitle = styled.h3`
  letter-spacing: 1.36px;
  color: #2c2c2c;
  font-size: 24px;
  line-height: 1.1em;
`;

export const LiveContentDescription = styled.p`
  color: #636363;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 16px;
`;

export const LiveContentPrice = styled.span`
  font-size: 1.6rem;

  > strong {
    font-size: 2rem;
  }
`;

export const LiveContentButton = styled(Link)`
  > button {
    background: ${colors.green} 0% 0% no-repeat padding-box;
  }
`;
