import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Container } from '~/styles/objects/container';
import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

export const NavbarWrapper = styled.header`
  background: #000;
  box-shadow: 0px 8px 15px #3e3e3e2e;
  opacity: 0.95;
  padding-top: var(--gap-xs);
  position: relative;
  z-index: 28;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--gap-xs);
  padding-left: var(--gap-lg);
  padding-right: var(--gap-lg);

  @media (max-width: ${breakpoints.small}) {
    padding-left: 0;
    padding-right: 0;

    img {
      display: none;
    }
  }
`;

export const MobileSelectedProfessionalContainer = styled.div`
  display: none;
  @media (max-width: ${breakpoints.small}) {
    display: flex;
    align-items: center;
    gap: 1rem;
    img {
      display: block;
    }
  }
`;

export const NavbarContent = styled.nav`
  display: flex;
  @media (max-width: ${breakpoints.small}) {
    display: none;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  column-gap: var(--gap-md);

  &:first-child::after {
    content: '';
    display: inline-block;
    width: 2px;
    background: #ffffff;
    opacity: 0.2;
  }

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
    justify-content: center;

    &:first-child::after {
      display: none;
    }
  }
`;
export const NavItem = styled.li`
  display: flex;
  align-items: center;
  gap: 4rem;
  margin-right: 0;
  cursor: pointer;

  > div {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s;
  }

  &:hover {
    div {
      visibility: visible;
      opacity: 1;
    }
  }

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
    margin: 0;
    margin-bottom: 20px;
  }
`;

export const NavItemLink = styled(NavLink)`
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  transition: 0.3s;

  &:hover {
    color: ${colors.terciary};
    transition: 0.3s;
  }

  @media (max-width: ${breakpoints.medium}) {
    font-size: 1.6rem;
  }
`;

export const UserItem = styled(NavItem)`
  transition: 0.3s;
  gap: 0;

  border-left: 2px solid #fff;
  padding-left: var(--gap-md);

  > svg {
    color: #fff;
    margin-left: var(--gap);

    @media (max-width: ${breakpoints.small}) {
      margin: 0;
      display: flex;
    }
  }

  &:hover {
    transition: 0.3s;

    > span,
    > svg {
      color: ${colors.terciary};
    }
  }
`;

export const UserItemNickname = styled.span`
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  transition: 0.3s;

  @media (max-width: ${breakpoints.small}) {
    margin-bottom: var(--gap);
  }
`;

export const UserImageWrapper = styled.span`
  display: flex;
  justify-content: center;
  width: 54px;
  height: 54px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: var(--gap-xs);

  @media (max-width: ${breakpoints.small}) {
    margin: 0;
  }
`;

export const UserImage = styled.img`
  width: auto;
  height: 100%;
  border-radius: 50%;
`;

export const DropdownWorkout = styled.div`
  display: flex;
  background: #2c2c2c 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #3e3e3e2e;
  border-radius: 3px;
  max-width: 160px;
  width: 100%;
  // height: 266px;
  right: 4px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 64px;
  z-index: 30;

  ul {
    flex-direction: column;

    li {
      max-width: 218px;
      width: 100%;
      margin: 0;
      justify-content: center;
    }

    li a,
    li span {
      text-align: center;
      font-size: 16px;
      padding: 16px;
      margin: 0;
    }
  }

  ul:first-child::after {
    display: none;
  }

  @media (max-width: 768.9px) {
    margin-top: 30px !important;
    margin-left: 0px;
  }
`;

export const Dropdown = styled.div`
  background: #2c2c2c 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #3e3e3e2e;
  border-radius: 3px;
  max-width: 200px;
  width: 100%;
  height: auto;
  top: 64px;
  right: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 30;

  ul {
    flex-direction: column;

    li {
      max-width: 218px;
      width: 100%;
      margin: 0;
      justify-content: center;
    }

    li a,
    li span {
      text-align: center;
      font-size: 16px;
      padding: 16px;
      margin: 0;
    }
  }

  ul:first-child {
    border: 0;
  }

  @media (max-width: 768.9px) {
    margin-top: 340px !important;
    margin-left: 0px;
  }
`;

export const DropdownWorkoutStudent = styled.div`
  display: flex;
  background: #2c2c2c 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #3e3e3e2e;
  border-radius: 3px;
  max-width: 160px;
  width: 100%;
  height: auto;
  top: 64px;
  margin-left: -44px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 30;

  ul {
    flex-direction: column;

    li {
      max-width: 218px;
      width: 100%;
      margin: 0;
      justify-content: center;
    }

    li a {
      text-align: center;
      font-size: 16px;
      padding: 16px;
      margin: 0;
    }
  }

  ul:first-child::after {
    display: none;
  }

  @media (max-width: 768.9px) {
    margin-top: 30px !important;
    margin-left: 0px;
  }
`;

export const DropdownStudent = styled.div`
  background: #2c2c2c 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #3e3e3e2e;
  border-radius: 3px;
  max-width: 218px;
  width: 100%;
  height: 120px;
  top: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 30;

  ul {
    flex-direction: column;

    li {
      max-width: 218px;
      width: 100%;
      margin: 0;
      justify-content: center;
    }

    li a,
    li span {
      text-align: center;
      font-size: 16px;
      padding: 16px;
      margin: 0;
    }
  }

  ul:first-child {
    border: 0;
  }

  @media (max-width: 768.9px) {
    margin-top: 230px !important;
    margin-left: 0px;
  }
`;

export const SelectedProfessorContainer = styled.div`
  background-color: #2b2b2b;
  display: flex;
  align-items: center;
  gap: var(--gap-xs);

  padding: var(--gap-xs) var(--gap-lg);

  @media (max-width: 768.9px) {
    display: none;
  }
`;

export const ImgContainer = styled.div`
  width: 55px;
  height: 55px;

  img {
    width: 100%;
    object-fit: contain;
    border-radius: 50%;
  }

  @media (max-width: ${breakpoints.small}) {
    width: 40px;
    height: 40px;
  }
`;

export const ProfessionalInfoContainer = styled.div`
  h4 {
    font-size: 2rem;
  }

  p {
    color: #ebd98e;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
  }

  @media (max-width: ${breakpoints.small}) {
    h4 {
      font-size: 1.75rem;
    }
  }
`;
