import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const Container = styled.div`
  width: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #fff;

  > h1 {
    width: 100%;
    color: #e9543f !important;
    font-size: 16px !important;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  > h2 {
    width: 100%;
    color: ${colors.lightGrey};
    font-size: 16px !important;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    text-transform: none !important;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  > h3 {
    width: 100%;
    color: ${colors.lightGrey};
    font-size: 10px !important;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
    text-transform: none !important;
  }

  > label {
    width: 100%;
    color: ${colors.lightGrey};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.12px;
    box-shadow: none !important;
    background: none !important;
    margin-top: 0 !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  > div {
    width: 100% !important;
    max-width: none !important;
  }

  input {
    width: 100%;
    max-width: none !important;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    color: ${colors.lightnerGrey};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.12px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: underline;
    margin-top: 0 !important;
  }
`;
