import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 100%;
  color: ${colors.lightGrey};

  button {
    margin: 1rem;
  }

  h1 {
    color: ${colors.lightnerGrey};
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1.12px;
    margin: 30px 0;
    text-align: center;
    align-self: center;
  }

  .modal-content {
    display: flex;
    flex-direction: row;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768.9px) {
    /* padding: 0; */
    max-width: 80%;
    h1 {
      text-align: center;
    }
  }
  @media (max-width: 362.9px) {
    /* padding: 0; */
    max-width: 76%;
  }
`;

export const Boxmodal = styled.div`
  display: flex;
  flex-direction: row;
`;
