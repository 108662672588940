import Modal from '~/components/Modal';
import { ModalContent } from '~/components/Modal/styles';

import { Paragraph, Subtitle, Title } from '../styles';
import {
  ListItem,
  ListLetterItem,
  OrdenedLetterList,
  OrdenedList,
  OrdenedUpperLetterList,
  UnorderedList,
} from './styles';

interface ModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: (state: boolean) => void;
}

const PrivacyPolicy: React.FC<ModalProps> = ({
  modalIsOpen,
  setModalIsOpen,
}) => {
  return (
    <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}>
      <ModalContent>
        <Title>Políticas de privacidade</Title>
        <Subtitle>
          A Plataforma Seu Coach se preocupa com sua privacidade e tem o
          compromisso de preservá-la.
        </Subtitle>
        <Paragraph>
          O tratamento de dados pessoais relacionados ao seu acesso e uso da
          Plataforma está descrito nesta Política de Privacidade. Você deve ler
          de forma atenta e cuidadosa as condições a seguir, pois elas contêm
          informações importantes sobre acesso ou uso da Plataforma.
        </Paragraph>
        <Paragraph>
          Você pode ter acesso a algumas funcionalidades da Plataforma sem a
          necessidade de criar uma conta. Se você quiser criar uma conta, a
          Plataforma Seu Coach precisa tratar alguns dados pessoais seus, pois
          não é possível oferecer certas funcionalidades da Plataforma sem ter
          acesso a esses dados pessoais. Além disso, a empresa Seu Coach pode
          coletar dados pessoais de visitantes da Plataforma para determinadas
          finalidades. Por isso, se você não concordar com esta Política de
          Privacidade, você deve cessar o seu acesso ou uso da Plataforma.
        </Paragraph>
        <Paragraph>
          Esta Política pode ser modificada, substituída ou removida a qualquer
          momento, sem aviso prévio. Se você não concordar com uma nova versão
          desta Política, você deve cessar o uso da sua conta na plataforma Seu
          Coach. Esta Política e suas respectivas atualizações prevalecem sobre
          quaisquer propostas, contratos, entendimentos anteriores e acordos,
          verbais ou escritos, que possam existir entre você e a a plataforma
          Seu Coach, especialmente versando sobre privacidade ou dados pessoais.
        </Paragraph>

        <Title>VISÃO GERAL</Title>
        <OrdenedUpperLetterList>
          <li>
            Esta Política de Privacidade estabelece as regras sobre
            <OrdenedLetterList>
              <ListLetterItem>
                como e para quais finalidades seus dados pessoais podem ser
                tratados pela empresa Seu Coach,
              </ListLetterItem>
              <ListLetterItem>
                com quem esses dados podem ser compartilhados, e
              </ListLetterItem>
              <ListLetterItem>
                como os Usuários podem gerenciar seus dados pessoais. Ela se
                aplica ao uso ou acesso à Plataforma mediante o site seu.coach e
                seus subdomínios, bem como mediante outros sites, interfaces ou
                aplicativos nos quais a plataforma Seu Coach disponibilize seus
                recursos, inclusive aplicativos de celular, tablet ou de outros
                dispositivos eletrônicos.
              </ListLetterItem>
            </OrdenedLetterList>
          </li>
          <br />
          <li>
            Sobre as regras:
            <OrdenedList>
              <ListItem>
                <b>Quais são os dados tratados pela plataforma Seu.coach</b>
                <br />
                Dados Pessoais. Os Dados Pessoais tratados podem variar a
                depender do tipo de interação do Usuário com a Plataforma. Esses
                dados pessoais podem ser coletados diretamente do Usuário,
                obtidos através de terceiros ou coletados automaticamente. Os
                dados pessoais podem envolver:
                <OrdenedLetterList>
                  <ListLetterItem>
                    informações cadastrais, como e-mail, nome completo,
                    endereço, telefone, celular, CPF, RG, CNH, foto de perfil e
                    passaporte; e
                  </ListLetterItem>
                  <ListLetterItem>
                    informações bancárias, como nome da instituição financeira
                    na qual o Usuário possui conta bancária, número da conta,
                    tipo de conta, agência e dados do cartão de crédito,
                    incluindo nome do titular, validade e código CVV.
                  </ListLetterItem>
                </OrdenedLetterList>
                <OrdenedList>
                  <ListItem>
                    A plataforma Seu Coach pode coletar dados pessoais
                    diretamente de você, relativos a informações cadastrais e
                    informações bancárias.
                    <OrdenedList>
                      <ListItem>
                        Interação “offline”. A plataforma Seu Coach pode coletar
                        dados pessoais quando você interage por outros meios que
                        não seja o acesso à Plataforma, como, por exemplo,
                        quando você participa de um evento organizado pela
                        Empresa Seu Coach, seja este vinculado ou não ao produto
                        (plataforma digital Seu Coach) ou um evento organizado
                        por um terceiro cuja atividade esteja contratualmente
                        vinculada à Empresa Seu Coach.
                      </ListItem>
                      <ListItem>
                        Campos opcionais. A Plataforma disponibiliza certos
                        campos de preenchimento opcional. Esses campos estão
                        destacados como opcionais e têm a finalidade de melhorar
                        a personalização do conteúdo disponibilizado ao Usuário.
                      </ListItem>
                      <ListItem>
                        Dados públicos. A plataforma Seu Coach considera alguns
                        dados pessoais como públicos, porque podem ser vistos
                        por qualquer pessoa, dentro e fora da Plataforma,
                        inclusive se essa pessoa não tiver uma conta na
                        Plataforma. São considerados dados públicos as
                        informações que você inserir nos campos destacados com o
                        aviso “dados públicos”. Você, outras pessoas que usam a
                        Plataforma e a própria empresa Seu Coach podem fornecer
                        acesso ou enviar dados públicos para qualquer pessoa
                        dentro e fora da Plataforma. É possível também
                        visualizar, acessar, compartilhar novamente ou baixar
                        dados públicos por meio de ferramentas de terceiros,
                        como mecanismos de pesquisa, APIs e mídia offline, ou
                        por meio de aplicativos, sites e outros Serviços de
                        Terceiros.
                      </ListItem>
                    </OrdenedList>
                  </ListItem>
                  <ListItem>
                    Dados pessoais obtidos de terceiros. A empresa Seu Coach
                    pode ter acesso a determinados dados pessoais por intermédio
                    de terceiros, sejam eles informações cadastrais ou
                    bancárias. Isso acontece, por exemplo, quando, em razão de
                    cumprimento de obrigação legal ou regulatória, a plataforma
                    Seu Coach precisa consultar bancos de dados mantidos por
                    terceiros que viabilizem a análise de restrições
                    creditícias, análises antifraude e demais análises de gestão
                    de risco integrados à Plataforma.
                    <OrdenedList>
                      <ListItem>
                        A empresa Seu Coach também poderá obter informações
                        cadastrais por intermédio de terceiros quando você
                        vincula, se conecta ou faz login na Plataforma por um
                        Serviço de Terceiro (por exemplo, Facebook).
                      </ListItem>
                      <ListItem>
                        A Empresa Seu Coach pode também ter acesso a informações
                        cadastrais por meio de terceiros para inscrição em
                        eventos, pesquisas, campanhas publicitárias e demais
                        atividades organizadas por esses terceiros, cuja
                        atividade esteja contratualmente vinculada à Plataforma
                        digital Seu Coach.
                      </ListItem>
                    </OrdenedList>
                  </ListItem>
                  <ListItem>
                    Os Dados Pessoais coletados automaticamente ao acessar a
                    Plataforma, são:
                    <OrdenedList>
                      <ListItem>
                        Informações de transação de pagamento: instrumento de
                        pagamento usado; data e hora da transação; valor do
                        pagamento; data de vencimento do instrumento de
                        pagamento; código postal de cobrança; informações do
                        IBAN, endereço e outros detalhes relacionados à
                        transação de pagamento.
                      </ListItem>
                      <ListItem>
                        Informações de acesso ou uso: páginas e conteúdo que
                        você visualiza; buscas por anúncios; frequência de
                        utilização da Plataforma; informação sobre o navegador;
                        dados de conexão; histórico de compras e histórico de
                        navegação.
                      </ListItem>
                      <ListItem>
                        Dados de log e informações do dispositivo: detalhes
                        sobre como você acessou e usou a Plataforma (incluindo
                        links acessados); endereço de IP; datas e horários de
                        acesso; informações de hardware e software; informações
                        do dispositivo; informações de eventos do dispositivo;
                        identificadores exclusivos; dados de falha; dados de
                        cookies e as páginas que você visualizou ou interagiu
                        antes e depois de acessar ou usar a Plataforma.
                      </ListItem>
                      <ListItem>
                        Cookies, pixel e tecnologias semelhantes.
                      </ListItem>
                    </OrdenedList>
                  </ListItem>
                </OrdenedList>
              </ListItem>
              <br />
              <ListItem>
                <b>Quais as finalidades de tratamento dos dados pessoais</b>
                <br />
                <OrdenedList>
                  <ListItem>
                    A plataforma Seu Coach utiliza os dados pessoais para as
                    seguintes finalidades:
                    <OrdenedList>
                      <ListItem>
                        Fornecer e desenvolver a Plataforma:
                        <UnorderedList>
                          <li>
                            Vincular sua conta na Seu Coach com redes sociais
                            terceiras
                          </li>
                          <li>
                            Personalizar a Plataforma de acordo com a
                            experiência de navegação
                          </li>
                          <li>
                            Realizar análises e estudos relacionados ao
                            comportamento, interesse e demografia
                          </li>
                          <li>Melhorar a Plataforma</li>
                          <li>Arquivar histórico de compra</li>
                          <li>Atualizar cadastro</li>
                          <li>
                            Enviar mensagens, atualizações e notificações da
                            conta
                          </li>
                          <li>Realizar atividades de suporte ao Usuário</li>
                          <li>
                            Permitir que o Usuário publique seus conteúdos
                          </li>
                          <li>
                            Divulgar o conteúdo do Usuário pelos meios
                            disponíveis na Plataforma
                          </li>
                        </UnorderedList>
                      </ListItem>
                      <ListItem>
                        Fornecer e otimizar as atividades de publicidade e
                        marketing
                        <UnorderedList>
                          <li>Realizar cadastro em eventos</li>
                          <li>Enviar newsletter</li>
                          <li>
                            Enviar comunicações via e-mail, SMS ou gravações
                            telefônicas
                          </li>
                          <li>Enviar materiais educativos ou e-books</li>
                          <li>
                            Realizar campanhas de mídia com foco em publicidade
                            e promoções
                          </li>
                          <li>
                            Enviar, manualmente, listas com os veículos de mídia
                            (Facebook e Google)
                          </li>
                          <li>
                            Enviar comunicação, tais como e-mail marketing,
                            remarketing e outras campanhas de publicidade nos
                            canais de mídia
                          </li>
                          <li>
                            Analisar o comportamento de navegação para
                            otimização da Plataforma e oferta de produtos e
                            serviços
                          </li>
                        </UnorderedList>
                      </ListItem>
                      <ListItem>
                        Viabilizar os Serviços do gateway de pagamento
                        <UnorderedList>
                          <li>
                            Realizar comunicação ligada ao pagamento, como, por
                            exemplo, envio de e-mails transacionais
                          </li>
                          <li>
                            Participar do arranjo com as bandeiras de cartão
                          </li>
                          <li>Emitir moeda eletrônica</li>
                          <li>Credenciar e emitir instrumento de pagamento</li>
                          <li>Gerir conta de pagamento</li>
                          <li>Converter moeda física em moeda eletrônica</li>
                          <li>Executar remessa de fundos</li>
                        </UnorderedList>
                      </ListItem>
                      <ListItem>Manter um ambiente seguro</ListItem>
                      <UnorderedList>
                        <li>Realizar autenticação junto à Plataforma</li>
                        <li>
                          Verificar autenticidade dos dados e documentos
                          fornecidos, inclusive mediante tratamento e
                          verificação cruzada
                        </li>
                        <li>Enviar comunicados</li>
                        <li>
                          Realizar atividades de análise de prevenção a fraudes,
                          incidentes de segurança e abusos na Plataforma
                        </li>
                        <li>Verificação de cadastro</li>
                        <li>Verificação de conteúdo</li>
                      </UnorderedList>
                    </OrdenedList>
                  </ListItem>
                  <br />
                  <ListItem>
                    Finalidades do Tratamento dos Dados Pessoais de Visitante. A
                    plataforma Seu Coach pode coletar dados pessoais de
                    visitantes da Plataforma para as seguintes finalidades:
                    <UnorderedList>
                      <li>
                        Campanhas de mídia com foco em publicidade e promoções
                      </li>
                      <li>
                        Enviar comunicação, tais como e-mail marketing,
                        remarketing e outras campanhas de publicidade nos canais
                        de mídia
                      </li>
                      <li>
                        Analisar seu comportamento de navegação na Plataforma
                      </li>
                      <li>Realizar cadastro em eventos</li>
                      <li>Enviar newsletter</li>
                      <li>
                        Enviar comunicações via e-mail, SMS ou gravações
                        telefônicas
                      </li>
                      <li>Enviar materiais educativos ou e-books</li>
                    </UnorderedList>
                  </ListItem>
                </OrdenedList>
              </ListItem>
              <br />
              <ListItem>
                <b>Quais as bases legais de tratamento</b>
                <br />
                <OrdenedList>
                  <ListItem>
                    O tratamento de dados pessoais realizado pela Seu Coach é
                    sempre fundamentado em uma base legal prevista nas
                    legislações aplicáveis. As bases legais utilizadas pela
                    plataforma digital Seu Coach podem variar a depender do
                    contexto de tratamento. A plataforma Seu Coach utiliza as
                    seguintes bases:
                    <OrdenedList>
                      <ListItem>
                        Consentimento. Quando o Usuário manifestamente aceita o
                        tratamento de dados pessoais, como, por exemplo, quando
                        concorda com o recebimento de newsletters, comunicados
                        publicitários ou pesquisas. Esse consentimento pode ser
                        revogado a qualquer momento pelo Usuário.
                      </ListItem>
                      <ListItem>
                        Cumprimento de obrigação legal ou regulatória. Quando a
                        plataforma Seu Coach é obrigada a realizar o tratamento
                        de dados pessoais em razão de determinação legal ou
                        regulatória, como, por exemplo, quando a plataforma Seu
                        Coach se utiliza de ferramentas de terceiros para
                        realizar avaliações creditícias dos Usuários. Nesse
                        cenário, poderá existir decisões automatizadas para
                        análise da possibilidade de antecipação de valores na
                        Plataforma.
                      </ListItem>
                      <ListItem>
                        Execução do Contrato ou procedimentos relacionados ao
                        Contrato. Quando a plataforma Seu Coach é obrigada a
                        realizar o tratamento de dados pessoais em razão dos
                        Contrato aplicável entre a plataforma Seu Coach e o
                        Usuário.
                      </ListItem>
                      <ListItem>
                        Exercício regular de direitos em processo judicial,
                        administrativo ou arbitral. Quando houver a necessidade
                        de tratamento de dados pessoais em razão de ação
                        judicial, procedimento administrativo ou arbitral.
                      </ListItem>
                      <ListItem>
                        Legítimo interesse. Quando a seu Coach decide prosseguir
                        com o tratamento dos dados pessoais após análise
                        criteriosa envolvendo a avaliação da finalidade e
                        necessidade do tratamento ao Usuário, a expectativa do
                        Usuário quanto ao tratamento dos dados pessoais e a
                        elaboração da documentação legal necessária. Essa base
                        legal é utilizada quando, por exemplo, a plataforma Seu
                        Coach envia uma newsletter relacionada aos interesses do
                        Usuário, considerando a sua interação com a Plataforma.
                        Nesse caso, a plataforma Seu.coach sempre viabiliza ao
                        Usuário a opção de cancelar o recebimento dessas
                        comunicações.
                      </ListItem>
                    </OrdenedList>
                  </ListItem>
                </OrdenedList>
              </ListItem>
              <br />
              <ListItem>
                <b>Quais períodos de retenção dos dados pessoais</b>
                <br />
                <OrdenedList>
                  <ListItem>
                    Os dados pessoais são tratados pela empresa Seu Coach até
                    que pelo menos uma das hipóteses abaixo descritas seja
                    atingida. São elas:
                    <UnorderedList>
                      <li>Alcance da finalidade de tratamento</li>
                      <li>
                        Os dados pessoais deixarem de ser necessários ou
                        pertinentes ao alcance da finalidade específica almejada
                      </li>
                      <li>
                        Término do Contrato com o Usuário, seja qual for o
                        motivo
                      </li>
                      <li>
                        Exercício do direito de revogação do consentimento pelo
                        Usuário, nas hipóteses que o tratamento de dados
                        pessoais foi realizado com fundamento nessa base legal
                      </li>
                      <li>
                        Eventual penalização aplicada pelo órgão responsável por
                        zelar, implementar e fiscalizar o cumprimento da
                        legislação aplicável, ou por demais órgãos competentes
                      </li>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    Conservação dos Dados Pessoais. Em determinadas situações, a
                    Seu Coach e os terceiros parceiros da plataforma podem
                    conservar residualmente os dados pessoais mesmo após as
                    hipóteses de término de tratamento dos dados pessoais, em
                    virtude de cumprimento de obrigação legal ou regulatória, ou
                    ainda em circunstâncias em que haja uma finalidade legítima
                    da empresa Seu Coach ou desses terceiros. Nesses casos, os
                    dados pessoais serão armazenados em local seguro com acesso
                    restrito e pelo tempo necessário ao cumprimento da obrigação
                    legal ou regulatória, ou ao término da finalidade.
                  </ListItem>
                </OrdenedList>
              </ListItem>
              <br />
              <ListItem>
                <b>Compartilhamento de dados pessoais</b>
                <br />
                <OrdenedList>
                  <ListItem>
                    A Seu Coach pode compartilhar os dados pessoais com
                    terceiros. Esses terceiros celebram contrato por escrito com
                    a empresa Seu Coach ou sua plataforma e estão cientes das
                    suas responsabilidades em cada etapa do tratamento,
                    principalmente do dever de implementar medidas de segurança
                    que atendam às exigências do contrato e das legislações
                    aplicáveis. O compartilhamento pode ocorrer, por exemplo,
                    nas situações descritas abaixo.
                    <OrdenedList>
                      <ListItem>
                        Consentimento do Usuário. Quando você dá seu
                        consentimento, compartilhamos suas informações conforme
                        descrito no momento do consentimento – como, por
                        exemplo, ao autorizar o envio de dados pessoais a
                        terceiros para viabilizar participação em eventos.
                      </ListItem>
                      <ListItem>
                        Cumprimento da lei, prevenção de danos e segurança.
                        Quando há necessidade de compartilhamento de dados
                        pessoais com terceiros a fim de
                        <OrdenedLetterList>
                          <ListLetterItem>
                            prevenir fraudes (como, por exemplo, análises de
                            risco de lavagem de dinheiro), o que pode envolver
                            decisão automatizada;
                          </ListLetterItem>
                          <ListLetterItem>
                            verificar lista de Usuários com restrições;
                          </ListLetterItem>
                          <ListLetterItem>
                            autenticar documentação encaminhada;
                          </ListLetterItem>
                          <ListLetterItem>
                            possibilitar atendimento de suporte;
                          </ListLetterItem>
                          <ListLetterItem>
                            evitar atividades ilegais;
                          </ListLetterItem>
                          <ListLetterItem>
                            proceder acerca de disputas entre Usuários;
                          </ListLetterItem>
                          <ListLetterItem>
                            proceder acerca de investigações internas relativas
                            a qualquer conduta que possa violar as regras dos
                            Termos ou das Políticas da empresa Seu Coach.
                          </ListLetterItem>
                        </OrdenedLetterList>
                      </ListItem>
                      <ListItem>
                        Os dados pessoais, incluindo o número do cartão, serão
                        compartilhados com a bandeira do cartão de crédito e com
                        a instituição de pagamento parceira da plataforma Seu
                        Coach. Esse compartilhamento tem como finalidade
                        processar o pagamento, disponibilizar os serviços de
                        cartão, prevenir, detectar e investigar eventuais
                        fraudes ou listas de restrições, facilitação em
                        resolução de disputas envolvendo reembolso.
                      </ListItem>
                      <ListItem>
                        Em determinados casos, se necessário, a Seu Coach pode
                        fornecer os dados coletados aos órgãos e entidades que
                        intervenham na investigação ou na resolução de disputas
                        entre Usuários, ou entre Usuários e terceiros, tais como
                        órgãos do Poder Judiciário, tribunais arbitrais ou
                        autoridades administrativas competentes.
                      </ListItem>
                      <ListItem>
                        O compartilhamento dos dados pessoais pode ocorrer entre
                        as empresas controladoras, controladas, coligadas ou sob
                        controle comum da plataforma Seu Coach, para viabilizar
                        os Serviços.
                      </ListItem>
                      <ListItem>
                        A plataforma Seu Coach também pode compartilhar os seus
                        dados pessoais com terceiros envolvidos nas atividades
                        de assessoria financeira, contábil, jurídica, atividades
                        sem fins lucrativos e potenciais investidores, bem como
                        terceiros envolvidos na prestação de backup de dados,
                        provedores de serviços de computação em nuvem ou para
                        realizar estudos analíticos.
                      </ListItem>
                    </OrdenedList>
                  </ListItem>
                </OrdenedList>
              </ListItem>
              <br />
              <ListItem>
                <b>Integracões e terceiros parceiros</b>
                <br />A empresa Seu Coach, em determinados casos, pode vincular
                serviços de terceiros à Plataforma por meio de application
                programming interface (APIs), como, por exemplo, a API do
                YouTube. Estes serviços de terceiros não integram o escopo dos
                serviços da plataforma Seu Coach e não fazem parte da Mesma,
                estando sujeitos a termos e condições diferentes, descritos
                diretamente por cada fabricante ou responsável. A Seu Coach não
                é responsável por estes serviços e, quando você interage com
                eles, está fornecendo-lhes seus dados pessoais diretamente.
              </ListItem>
              <br />
              <ListItem>
                <b>Transferências Internacionais</b>
                <br />
                <OrdenedList>
                  <ListItem>
                    A plataforma Seu Coach e seus terceiros parceiros podem
                    transferir os seus dados pessoais a países diferentes
                    daquele onde você está localizado. Nesse caso, a
                    transferência internacional dos dados pessoais será
                    realizada para um país que proporcione grau de proteção de
                    dados pessoais adequado. A Seu Coach e seus terceiros
                    parceiros adotam as medidas exigidas por lei para assegurar
                    a proteção desses dados pessoais, bem como que a
                    transferência seja realizada de acordo com um dos mecanismos
                    de transferência previstos na legislação aplicável.
                  </ListItem>
                </OrdenedList>
              </ListItem>
              <br />
              <ListItem>
                <b>Direitos com relações aos dados</b>
                <OrdenedList>
                  <ListItem>
                    Você pode encaminhar uma solicitação relacionada aos seus
                    direitos previstos na legislação aplicável. Esses direitos
                    podem incluir o pedido de confirmação de tratamento de dados
                    pessoais, acesso aos dados pessoais, retificação dos dados
                    pessoais e eliminação de dados pessoais,
                    <UnorderedList>
                      <li>
                        Para falar com o nosso responsável pela proteção de
                        dados pessoais, entre em contato através do atendimento
                        no site (www.seu.coach).
                      </li>
                      <li>
                        Sempre que a plataforma Seu Coach estiver atuando em
                        nome de terceiros, e o Usuário decidir buscar junto ao
                        nosso atendimento um de seus direitos, poderemos
                        atender, em certas circunstâncias, a essa solicitação,
                        caso aplicável.
                      </li>
                      <li>
                        Se você desejar cancelar o envio de newsletters,
                        e-mails, telefonemas ou SMS publicitários, poderá
                        fazê-lo através do link fornecido nas próprias
                        comunicações ou pelo atendimento no site.
                      </li>
                    </UnorderedList>
                  </ListItem>
                </OrdenedList>
              </ListItem>
              <br />
              <ListItem>
                <b>Segurança</b>
                <br />
                <OrdenedList>
                  <ListItem>
                    A plataforma Seu Coach emprega medidas de segurança,
                    técnicas e organizacionais necessárias para proteger os
                    dados pessoais. Os esforços visam mitigar riscos de
                    destruição, perda acidental ou ilegal, alteração, divulgação
                    ou acesso não autorizado ou qualquer outra forma de
                    tratamento ilegal ou não autorizado ou não adequado. Para
                    isso, a empresa Seu Coach conta com controles rígidos de
                    acesso restrito, baseados no menor privilégio, aos sistemas
                    de tecnologia da informação para proteger os dados pessoais
                    contra eventual incidente de segurança.
                  </ListItem>
                </OrdenedList>
              </ListItem>
              <br />
              <ListItem>
                <b>Tratamento de dados pessoais de menores</b>
                <br />
                <OrdenedList>
                  <ListItem>
                    A Plataforma digital Seu Coach não coleta nem solicita
                    conscientemente dados pessoais de menores de 14 anos. Se
                    tomarmos conhecimento de que dados pessoais de um menor de
                    14 anos foram coletados, cancelaremos a conta criada na
                    Plataforma e excluiremos os dados pessoais, apenas mantendo
                    dados pessoais que tenham como objetivo evitar nova
                    tentativa de cadastro.
                  </ListItem>
                </OrdenedList>
              </ListItem>
            </OrdenedList>
          </li>
        </OrdenedUpperLetterList>
      </ModalContent>
    </Modal>
  );
};

export default PrivacyPolicy;
