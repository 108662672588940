import { isCep } from 'validator-brazil';

import { ViaCepAddress } from '~/models/User';
import ViaCep from '~/services/ViaCepService';

import NotFoundCepError from './NotFoundCepError';
import { removeMark } from './yupHelpers';

export const getCEP = async (
  cepWithMark: string,
  lastCepSearched?: string,
): Promise<ViaCepAddress | never> => {
  if (isCep(cepWithMark) && lastCepSearched !== cepWithMark) {
    const onlyNumbers = removeMark(cepWithMark);
    const {
      data: {
        bairro: address_neighborhood,
        localidade: address_city,
        logradouro: address_street,
        uf: address_state,
        erro,
      },
    } = await ViaCep.callData(onlyNumbers);

    if (erro) {
      throw new NotFoundCepError(cepWithMark);
    }

    return {
      address_city,
      address_complement: '',
      address_neighborhood,
      address_street,
      address_state,
    };
  }

  throw new Error('CEP inválido');
};
