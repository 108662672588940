import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

import { TitleTopic } from './Section/styles';

export const Address = styled.address`
  display: inline;
`;

export const Title = styled.h1`
  color: ${colors.lightnerGrey};
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: 1.12px;
  text-transform: uppercase;
`;

export const Subtitle = styled.h2`
  margin-bottom: 1.4rem;
  font-size: 1.8rem;
  text-align: center;
`;

export const ListTopic = styled.ol`
  list-style: none inside;
  margin-right: auto;
  counter-reset: topic;
  margin-top: 1.4rem;
  font-size: 1.4rem;
  color: ${colors.lightGrey};
`;

export const ListTopicHeader = styled(TitleTopic)`
  font-size: 1.4rem;
  font-weight: 400;
  display: inline;
  margin-top: 0;
`;

export const Paragraph = styled.p`
  text-align: left;
  font-size: 1.4rem;
  color: ${colors.lightGrey};

  & + & {
    margin-top: 1.5rem;
  }
`;

export const ListOrdenatedByLetter = styled.ol`
  list-style-type: upper-alpha;
  font-size: 1.4rem;
  color: ${colors.lightGrey};
  list-style-position: inside;
`;

export const ListOrdenatedByLetterInline = styled.ol`
  counter-reset: lowcase;
  list-style-type: none;
  list-style-position: inside;
  display: inline;
`;

export const ListItem = styled.li`
  & + & {
    margin-top: 1rem;
  }
`;

export const ListTopicItem = styled(ListItem)`
  ::before {
    content: counters(topic, '.') ' ';
    counter-increment: topic;
  }
`;

export const ListItemInline = styled.li`
  display: inline;
  counter-increment: lowcase;

  ::before {
    content: ' (' counter(lowcase, lower-alpha) ') ';
  }
`;

export const ListItemInlineTitle = styled.h3`
  font-size: 1.4rem;
`;
