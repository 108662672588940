import {
  ImageWhatsapp,
  ImageEmail,
  ImageInstagram,
  ImageFacebook,
  ImageYoutube,
} from '~/assets/icons';
import { WhatsAppUrl } from '~/components';

//
import * as F from './styles';

import Logo from '../Logo';

const Footer: React.FC = () => {
  return (
    <F.FooterWrapper>
      <F.FooterContainer>
        <F.FooterContent>
          <F.FooterInfo>
            <Logo width={256} />
            <F.FooterInfoText>
              Seu Coach, sua plataforma digital.
            </F.FooterInfoText>
          </F.FooterInfo>
          <F.FooterContacts>
            <F.FooterContactsTitle>Fale conosco</F.FooterContactsTitle>
            <F.FooterContactsItem>
              <img src={ImageWhatsapp} alt="Logo do WhatsApp" />
              <span>
                <WhatsAppUrl ddd={21} cell_phone={994118964} />
              </span>
            </F.FooterContactsItem>
            <F.FooterContactsItem>
              <img src={ImageEmail} alt="" />
              <a href="mailto:contato@seu.coach">contato@seu.coach</a>
            </F.FooterContactsItem>
            <F.FooterSocialMedias>
              <a
                href="https://www.instagram.com/seu.coachoficial/?igshid=YmMyMTA2M2Y%3D"
                target="_blank"
                rel="noreferrer"
              >
                <F.FooterSocialMediasIcon src={ImageInstagram} alt="" />
              </a>
              <a
                href="https://m.facebook.com/SeuCoach-105073425097124/"
                target="_blank"
                rel="noreferrer"
              >
                <F.FooterSocialMediasIcon src={ImageFacebook} alt="" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCFqpDnfhsuB0IXjMQReJU_g"
                target="_blank"
                rel="noreferrer"
              >
                <F.FooterSocialMediasIcon src={ImageYoutube} alt="" />
              </a>
            </F.FooterSocialMedias>
          </F.FooterContacts>
        </F.FooterContent>
      </F.FooterContainer>
    </F.FooterWrapper>
  );
};

export default Footer;
