import React, { useCallback, useEffect } from 'react';

import { AxiosError } from 'axios';
import { Route, Switch } from 'react-router-dom';

import { Loading } from '~/components';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { PaymentLayout } from '~/layout';
import BecomeAProfessional from '~/pages/Guest/BecomeAProfessional';
import Home from '~/pages/Guest/Home';
import MySite from '~/pages/Guest/MySite';
import Courses from '~/pages/Guest/MySite/Courses';
import Lives from '~/pages/Guest/MySite/Lives';
import Workouts from '~/pages/Guest/MySite/Workouts';
import PageCustomize from '~/pages/Guest/PageCustomize';
import Signin from '~/pages/Guest/Signin';
import Signup from '~/pages/Guest/Signup';
import SignupPaymentData from '~/pages/Guest/Signup/SignupPaymentData';
import Professor from '~/pages/Professor';
import CourseList from '~/pages/Student/CourseList';
import CourseListAll from '~/pages/Student/CourseListAll';
import ProfessionalProfiles from '~/pages/Student/ProfessionalProfiles';
import api from '~/services/api';

import ProfessorRoute from './ProfessorRoute';
import StudentRoute from './StudentRoute';

const Routes: React.FC = () => {
  const { isLoading, percent } = useLoading();
  const { addToast } = useToast();
  const { logout, user } = useAuth();

  const interceptorUnauthorized = useCallback(() => {
    api.interceptors.response.use(
      response => response,
      err => {
        const error: AxiosError = err;
        if (error.response?.status === 401) {
          if (user) {
            logout();
            addToast({
              title: error.message,
              type: 'error',
            });
          }
        }
        return err;
      },
    );
  }, [addToast, logout, user]);

  useEffect(() => {
    interceptorUnauthorized();
  }, [interceptorUnauthorized]);

  return (
    <>
      <Loading loading={isLoading} percent={percent} />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route
          path="/professional-profiles"
          exact
          component={ProfessionalProfiles}
        />
        <Route path="/course-list" exact component={CourseList} />
        <Route path="/course-list/all" exact component={CourseListAll} />
        <Route path="/signup" exact component={Signup} />
        <Route path="/signin" exact component={Signin} />
        <Route path="/page-customize" exact component={PageCustomize} />
        <Route path="/:publicname" exact component={MySite} />
        <Route path="/:publicname/workouts" exact component={Workouts} />
        <Route path="/:publicname/courses" exact component={Courses} />
        <Route path="/:publicname/lives" exact component={Lives} />
        <Route
          path="/:publicname/become-a-student"
          exact
          component={() => <PaymentLayout becomeAStudent />}
        />
        <Route
          path="/signup/become-a-professional"
          exact
          component={BecomeAProfessional}
        />
        <Route path="/:publicname/signup" exact component={Signup} />
        <Route
          path="/:publicname/signup-payments"
          component={SignupPaymentData}
        />
        <Route
          path="/:publicname/payment"
          exact
          component={() => <PaymentLayout becomeAStudent={false} />}
        />
        <ProfessorRoute path="/professor" component={Professor} />
        <StudentRoute path="/student" />
      </Switch>
    </>
  );
};
export default Routes;
