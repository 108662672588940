import { FC, useCallback, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { ImageDance } from '~/assets/images';
import { DropdownType } from '~/models/Common';
import { Courses as ICourses } from '~/models/Courses';
import { DropdownIcon } from '~/styles/objects/dropdown-icon';
import { ItemsGrid } from '~/styles/objects/items-grid';
import { NotFoundItems } from '~/styles/objects/not-found';
import getMimeTypeByExtVideo from '~/utils/getMimeType';
import { handleChangeDropdown } from '~/utils/handleChangeDropdown';
import Price from '~/utils/Price';

import * as C from './styles';

import { DropdownMenu } from '..';

import { CoursesProps } from './typings';

const Courses: FC<CoursesProps> = ({
  courses,
  handleDeleteCourse,
  isProfessional,
  dropdownProps,
  displayBuyButton,
  withCategories,
  handleSelectCourse,
}) => {
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCourseLinkAction = useCallback(
    (course: ICourses) => {
      if (isProfessional) {
        history.push({
          pathname: `/professor/my-courses/${course.id.toString()}`,
          state: { course },
        });
      } else {
        handleSelectCourse
          ? handleSelectCourse(course)
          : history.push({
              pathname: `/student/my-courses/${course.id.toString()}`,
              state: { course },
            });
      }
    },
    [handleSelectCourse, history, isProfessional],
  );

  const coursesMemo = useMemo(() => {
    if (courses) {
      return courses?.map(course => (
        <C.Course
          key={course.id}
          displayBuyButton={displayBuyButton}
          onClick={() => {
            if (!isModalOpen) handleCourseLinkAction(course);
          }}
        >
          {isProfessional && (
            <>
              <DropdownIcon
                size={24}
                color="#FFF"
                onClick={e => {
                  e.stopPropagation();
                  dropdownProps &&
                    handleChangeDropdown({
                      id: course.id,
                      type: DropdownType.COURSE,
                      isOpen: dropdownProps?.dropdownOpen,
                      setIsOpen: dropdownProps?.setDropdownOpen,
                      setItem: dropdownProps?.setDropdownSelected,
                    });
                }}
              />
              <DropdownMenu
                dropdownIsOpen={dropdownProps?.dropdownOpen ?? false}
                handleDelete={() =>
                  handleDeleteCourse && handleDeleteCourse(course.id)
                }
                setDropdownIsOpen={dropdownProps?.setDropdownOpen}
                id={`course_${course.id.toString()}`}
                dropdownSelected={dropdownProps?.dropdownSelected}
                course={course}
                setNeedUpdate={dropdownProps?.setNeedUpdate}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </>
          )}
          {course.video ? (
            <C.MediaContainer>
              <C.CourseVideo
                onContextMenu={() => false}
                controlsList="nodownload"
                loop
                autoPlay
                muted
              >
                <source
                  src={course.video}
                  type={getMimeTypeByExtVideo(
                    course.video.split('.').pop() as string,
                  )}
                />
              </C.CourseVideo>
            </C.MediaContainer>
          ) : (
            <C.MediaContainer>
              <C.CourseImage
                src={course.foto_path || ImageDance}
                alt={course.title}
                title={course.title}
              />
            </C.MediaContainer>
          )}
          <C.CourseLink onClick={() => handleCourseLinkAction(course)}>
            <C.CourseContent
              onClick={() => handleSelectCourse && handleSelectCourse(course)}
            >
              <C.CourseStatus status={course.status ? 'Ativo' : 'Inativo'}>
                {course.status ? 'Ativo' : 'Inativo'}
              </C.CourseStatus>
              <C.CourseTitle>{course.title}</C.CourseTitle>
              <C.CoursePrice>
                Valor:{' '}
                <strong>{Price.floatToCurrency(course.price ?? 0)}</strong>
              </C.CoursePrice>
              <C.CoursePrice>
                Tipo de pagamento:{' '}
                {course.payment_type === 'charge'
                  ? 'Cobrança Única'
                  : 'Assinatura'}
              </C.CoursePrice>
              <C.CourseStudentsCount>
                {course.students ?? 0} Alunos
              </C.CourseStudentsCount>
            </C.CourseContent>
            {displayBuyButton && <C.CourseBuyButton>Comprar</C.CourseBuyButton>}
          </C.CourseLink>
        </C.Course>
      ));
    }
    return {};
  }, [
    courses,
    displayBuyButton,
    dropdownProps,
    handleCourseLinkAction,
    handleDeleteCourse,
    handleSelectCourse,
    isProfessional,
    isModalOpen,
  ]);

  if (!courses || courses?.length === 0)
    return (
      <NotFoundItems withCategories={withCategories}>
        {/* Nenhum curso encontrado. */}
      </NotFoundItems>
    );

  return <ItemsGrid withCategories={withCategories}>{coursesMemo}</ItemsGrid>;
};

export default Courses;
