import { Categories, PageOptionsResponse } from './Common';

export enum LiveIsFreeStatus {
  NO = 0,
  YES = 1,
}

export interface Lives {
  id: number;
  title: string;
  description: string;
  is_free: LiveIsFreeStatus;
  price?: number;
  date: string;
  hour: string;
  url: string;
  payment_url?: string;
  treining_category_id: number;
  category: Categories;
}

export type LivesData = PageOptionsResponse<Lives>;
