import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { MaskType } from '~/models/Common';

export const creditCardMask = (rawValue: string): (string | RegExp)[] => {
  const numbers = rawValue.match(/\d/g);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.join('')?.length;
  }
  if (numberLength < 16) {
    return [
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ];
  }
  return [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];
};

export function convertMaskToInputMask(mask: string): MaskType {
  const arr = mask.split('');
  const arrRegExp = arr?.map(x => (/9/.test(x) ? /[0-9]/ : x));
  return arrRegExp;
}

export const currencyMask = createNumberMask({
  decimalSymbol: ',',
  thousandsSeparatorSymbol: '.',
  prefix: 'R$ ',
  allowDecimal: true,
  requireDecimal: true,
  decimalLimit: 2,
});
