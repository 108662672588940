import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const Label = styled.label`
  font-size: 19px;
  font-weight: 500;
  color: ${colors.lightGrey};
  width: 100%;
  margin-bottom: 10px;
  margin-top: 15px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 15px;

  h1 {
    color: ${colors.lightGrey};
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1.12px;
  }

  h2 {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 400;

    strong {
      color: #b88746;
    }
  }

  h6 {
    margin-top: 15px;
    color: ${colors.lightGrey};
    font-size: 18px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    W > div {
      display: flex;
      max-width: 373px;
      width: 100%;
      margin-top: 25px;
      border-color: #000;
      color: #fff;

      input {
        color: ${colors.lightGrey};
        width: 100%;
        max-width: 373px;

        &::placeholder {
          color: ${colors.lightGrey};
        }
      }
    }

    p {
      font-size: 12px;
      margin-top: -20px;
      margin-bottom: 20px;
      width: 100%;
      color: gray;
    }
  }
`;

export const CreateVideo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 463px;
  width: 100%;

  form {
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    h6 {
      font-size: 19px;
      font-weight: 500;
      color: ${colors.lightGrey};
      width: 100%;
      margin-bottom: 10px;
    }

    > div input {
      color: ${colors.lightGrey};
      width: 100%;
      max-width: 373px;
    }

    > div {
      max-width: 100%;
      width: 100%;
      margin: 0;
      div {
        color: ${colors.lightGrey};
        /* width: 100%; */
        border-color: #2c2c2c;
        padding-right: 0px;
        svg: {
          width: 20px;
        }
      }
    }

    .wrapperButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 65px;
      margin-top: 30px;
      margin-bottom: 20px;
      cursor: pointer;

      + .wrapperButton {
        margin-top: -15px;
      }
    }
  }
`;
