import React, { useState, CSSProperties } from 'react';

import { IoIosArrowDown, IoIosCheckmark } from 'react-icons/io';
import { IoChatbubbleSharp } from 'react-icons/io5';

import {
  Container,
  DropdownButton,
  DropdownContent,
  Icon,
  DropdownArrow,
} from './styles';

interface IProps {
  title: string;
  alignItems?: string;
  showIcon?: boolean;
  showNotification?: boolean;
  containerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
  contentVisible: boolean;
  setContentVisible: (isVisible: boolean) => void;
}

const ToggleMenu: React.FC<IProps> = ({
  title,
  alignItems = 'flex-start',
  showIcon = false,
  showNotification = false,
  children,
  containerStyle,
  contentStyle,
  setModal = null,
  contentVisible,
  setContentVisible,
}) => {
  return (
    <Container>
      <DropdownButton
        style={containerStyle}
        type="button"
        onClick={() => setContentVisible(!contentVisible)}
      >
        <p>{title}</p>
        {showIcon && (
          <Icon>
            <IoIosCheckmark size={26} />
          </Icon>
        )}
        {showNotification && (
          <Icon style={{ backgroundColor: '#FF9A00' }}>
            <IoChatbubbleSharp size={10} />
          </Icon>
        )}
        <DropdownArrow contentVisible={contentVisible}>
          <IoIosArrowDown className="arrowdown" size={20} />
        </DropdownArrow>
      </DropdownButton>
      {contentVisible && (
        <DropdownContent style={contentStyle} alignItems={alignItems}>
          {children}
        </DropdownContent>
      )}
    </Container>
  );
};

export default ToggleMenu;
