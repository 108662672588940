import styled, { css } from 'styled-components';

import { breakpoints } from '../tools/breakpoints';

interface NotFoundProps {
  withCategories?: boolean;
}

export const NotFoundItems = styled.h2<NotFoundProps>`
  ${props =>
    props.withCategories
      ? css`
          padding: 0 0 var(--gap-lg) 0;

          @media (min-width: ${breakpoints.large}) {
            padding: 0 0 var(--gap-xl) 0;
          }
        `
      : css`
          padding: var(--gap-lg) 0;

          @media (min-width: ${breakpoints.large}) {
            padding: var(--gap-xl) 0;
          }
        `}
`;
