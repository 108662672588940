import { FiChevronLeft } from 'react-icons/fi';
import styled from 'styled-components';

import { Button } from '~/components';
import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

export const ModalContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--gap-xs);
  padding: var(--gap-xs);
`;

export const IconClose = styled(FiChevronLeft)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  color: ${colors.black};
  padding: var(--gap);
  box-sizing: content-box;

  transition: 180ms ease-in;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
  }
`;

export const BuyCourseWrapper = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-sm);

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
  }
`;

export const BuyCourseImage = styled.img`
  max-width: 300px;

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
  }
`;

export const BuyCourseInfo = styled.section``;

export const BuyCourseTitle = styled.h3`
  letter-spacing: 1.52px;
  color: ${colors.black};
  font-size: 2.4rem;
  line-height: 1.1em;
  margin-bottom: var(--gap-xs);

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.8rem;
  }
`;

export const BuyCoursePrice = styled.span`
  display: inline-block;
  color: ${colors.primary};
  font-size: 2.4rem;
  line-height: 1.1em;
  font-weight: 700;
  margin-bottom: var(--gap-xs);

  @media (max-width: ${breakpoints.small}) {
    font-size: 2rem;
  }
`;

export const BuyCourseDescription = styled.p`
  font-size: 1.6rem;
  letter-spacing: 0.88px;
  color: ${colors.black};
  max-width: 427px;

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.4rem;
  }
`;

export const ButtonGreen = styled(Button)`
  margin-top: var(--gap-sm);
  background: ${colors.green} 0% 0% no-repeat padding-box;
  font-size: 1.8rem;
`;

export const BuyCourseVideo = styled.video`
  width: 300px;
  height: 250px;

  @media (max-width: ${breakpoints.small}) {
    height: 320px;
  }
`;
