import React, {
  CSSProperties,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useField } from '@unform/core';
import clamp from 'lodash.clamp';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import Price from '~/utils/Price';

import { Container, Error, Prefix } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: CSSProperties;
  icon?: React.ComponentType<IconBaseProps>;
  prefix?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  callbackFunction?: (value: string) => void;
}

const InputCurrency: React.FC<InputProps> = ({
  name,
  containerStyle,
  icon: Icon,
  prefix = 'R$ ',
  placeholder = '00,00',
  min = 0,
  max = 999999,
  callbackFunction,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null); // HTMLInputElement - vai dar ao inputRef as propriedades de um input

  const [isFocused, setIsFocused] = useState(false); // Se esta com foco
  const [isFilled, setIsFilled] = useState(false); // Se esta preenchido

  const { fieldName, defaultValue, error, registerField } = useField(name);

  /** eh possivel incorporar essa funcao a ~/utils/Price.ts */
  const formatValue = useCallback((initialValue: string): string => {
    const value = initialValue.replace(/[^0-9]+/g, '');
    let formatedValue = value;
    // Remover o 0 a esquerda se o tamanho da string for maior que 4
    if (formatedValue?.length >= 4 && formatedValue[0] === '0') {
      formatedValue = formatedValue.slice(1, formatedValue?.length);
    }
    // Adicionar o 0 a esquerda se o tamanho da string for menor que 4
    if (formatedValue?.length < 4) {
      formatedValue = `0${formatedValue}`;
    }
    // Adicionar a vírgula que separa os decimais
    formatedValue = `${formatedValue.slice(
      0,
      formatedValue?.length - 2,
    )},${formatedValue.slice(formatedValue?.length - 2)}`;
    // Adicionar o ponto que separa os milhares
    if (formatedValue?.length > 6) {
      formatedValue =
        formatedValue
          .slice(0, formatedValue?.length - 3)
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
        formatedValue.slice(formatedValue?.length - 3);
    }
    return formatedValue;
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    // Verifica se o inputRef tem um valor/value. Se tiver preenchido = true. Se tiver vazio = false. !! Tranforma o value em booleano.
    setIsFilled(!!inputRef.current?.value); // inputRef pega o valor direto do Input. document.querySelector('input') e etc.

    // Setar valor mínimo e máximo
    const input = inputRef.current;
    if (input) {
      const valueString = input.value;
      let currency: number;
      try {
        currency = Price.currencyToFloat(valueString);
      } catch (e) {
        currency = 0.0;
      }
      if (currency < min || currency > max) {
        const price = clamp(currency, min, max);
        const priceString = price.toFixed(2).toString();
        const priceFormated = formatValue(priceString);
        input.value = priceFormated;

        if (callbackFunction) {
          callbackFunction(priceFormated);
        }
      }
    }
  }, [callbackFunction, formatValue, max, min]);

  const handleInput = useCallback(() => {
    const input = inputRef.current;
    if (input) {
      const { value } = input;

      const formatedValue = formatValue(value);
      input.value = formatedValue;
      if (callbackFunction) {
        callbackFunction(formatedValue);
      }
    }
  }, [callbackFunction, formatValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      style={containerStyle}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
    >
      <Prefix>{prefix}</Prefix>
      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue || '00,00'}
        type="tel"
        ref={inputRef}
        placeholder={placeholder}
        min={min}
        max={max}
        onChange={() => {
          handleInput();
        }}
        {...rest}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default InputCurrency;
