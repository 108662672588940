import { useCallback, useRef } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Button, Input, Modal, Textarea } from '~/components';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { ModalLike, SubmitHandler } from '~/models/Common';
import Interaction from '~/models/Interaction';
import api from '~/services/api';
import errorHandlerToToast from '~/utils/errorHandler';
import getCurrentTime from '~/utils/getCurrentTime';
import getTodayDate from '~/utils/getTodayDate';

import { CreateInteraction, ModalContent } from './styles';

interface NewInteractionProps extends ModalLike {
  interaction?: Interaction;
  setNeedUpdate?: (arg: boolean) => void;
  courseId: number;
}

const NewInteraction: React.FC<NewInteractionProps> = ({
  modalIsOpen,
  setModalIsOpen,
  interaction,
  setNeedUpdate,
  courseId,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { setLoading } = useLoading();
  const handleSubmit: SubmitHandler<any> = useCallback(
    async data => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Título obrigatório'),
          description: Yup.string().required('Descrição obrigatória.'),
          url: Yup.string().required('Link da reunião obrigatório.'),
          date: Yup.string().required('Data da reunião obrigatório.'),
          hour: Yup.string().required('Hora da reunião obrigatório.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        // const newDate = date
        //   .replace(/\//g, '')
        //   .replace(/(\d{2})(\d{2})(\d{4})/g, '$3-$2-$1');

        const inputData = {
          ...data,
          // date: newDate,
        };
        await api.post(`/courses/${courseId}/lives`, inputData);
        if (setNeedUpdate) setNeedUpdate(true);
        setModalIsOpen(false);
        setLoading(false);
        addToast({
          title: 'Nova interação cadastrada com sucesso !',
          type: 'success',
        });
      } catch (error) {
        setLoading(false);
        setModalIsOpen(false);
        const responseErr = errorHandlerToToast({ error, formRef });
        if (responseErr) addToast(responseErr);
      }
    },
    [addToast, setLoading, setModalIsOpen, setNeedUpdate, courseId],
  );

  return (
    <Modal {...{ modalIsOpen, setModalIsOpen }}>
      <ModalContent>
        <h2>{interaction ? 'Editar' : 'Nova'} interação ao vivo</h2>
        <CreateInteraction>
          <Form
            initialData={
              interaction
                ? { ...interaction, date: interaction.date.split('T')[0] }
                : { date: getTodayDate(), hour: getCurrentTime() }
            }
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <h6>Título:</h6>
            <Input name="title" />
            <h6>Descrição:</h6>
            <Textarea name="description" rows={4} />
            <h6>Link da reunião:</h6>
            <Input name="url" />
            <div>
              <div style={{ marginRight: 15 }}>
                <h6>Data:</h6>
                <Input name="date" type="date" min={getTodayDate()} />
              </div>
              <div>
                <h6>Hora:</h6>
                <Input id="hour" name="hour" type="time" />
              </div>
            </div>
            <Button>Salvar</Button>
          </Form>
        </CreateInteraction>
      </ModalContent>
    </Modal>
  );
};

export default NewInteraction;
