import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import BecomeAStudent from '~/pages/Guest/BecomeAStudent';
import Payment from '~/pages/Guest/Payment';
import ChooseNewPlan from '~/pages/Professor/ChooseNewPlan';
//
interface IPaymentLayout {
  becomeAStudent?: boolean;
  chooseNewPlan?: boolean;
}

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

const PaymentLayout: React.FC<IPaymentLayout> = ({
  children,
  becomeAStudent,
  chooseNewPlan,
}) => {
  const stripePromise = loadStripe(STRIPE_KEY as string);

  if (becomeAStudent) {
    return (
      <Elements stripe={stripePromise}>
        <BecomeAStudent />
      </Elements>
    );
  }

  if (chooseNewPlan) {
    return (
      <Elements stripe={stripePromise}>
        <ChooseNewPlan />
      </Elements>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <Payment />
    </Elements>
  );
};

export default PaymentLayout;
