import { AuthProvider } from './Auth';
import { LoadingProvider } from './Loading';
import { ProfessorProvider } from './professor';
import { StudentProvider } from './Student';
import { ToastProvider } from './Toast';

const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <LoadingProvider>
        <ToastProvider>
          <ProfessorProvider>
            <StudentProvider>{children}</StudentProvider>
          </ProfessorProvider>
        </ToastProvider>
      </LoadingProvider>
    </AuthProvider>
  );
};

export default AppProvider;
