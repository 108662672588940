import { BrowserRouter as Router } from 'react-router-dom';

import { Reset } from './styles/generic/reset';
import { Gaps } from './styles/tools/gaps';

import AppProvider from './hooks';
import { LoadingProvider } from './hooks/Loading';
import Routes from './routes';

import '~/utils/yupCustomValidators';

const App: React.FC = () => (
  <>
    <AppProvider>
      <LoadingProvider>
        <Router>
          <Routes />
        </Router>
      </LoadingProvider>
    </AppProvider>
    <Gaps />
    <Reset />
  </>
);

export default App;
