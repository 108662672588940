export default function getMimeTypeByExtVideo(ext: string): string {
  switch (ext) {
    case 'flv':
      return 'video/x-flv';
    case 'm3u8':
      return 'application/x-mpegURL';
    case 'ts':
      return 'video/MP2T';
    case '3gp':
      return 'video/3gpp';
    case 'mov':
      return 'video/quicktime';
    case 'avi':
      return 'video/x-msvideo';
    case 'wmv':
      return 'video/x-ms-wmv';

    case 'mp4':
    default:
      return 'video/mp4';
  }
}
