import { Button, Modal } from '~/components';
import { ModalLike } from '~/models/Common';
import { LiveIsFreeStatus, Lives } from '~/models/Lives';
import { Workouts } from '~/models/Workouts';
import Price from '~/utils/Price';

import {
  Content,
  LiveContent,
  LiveContentButton,
  LiveContentDescription,
  LiveContentPrice,
  LiveContentStatus,
  LiveContentTitle,
  ModalContent,
  Video,
} from './styles';

interface ViewWorkoutProps extends ModalLike {
  workout?: Workouts;
  live?: Lives;
}

const getCDNUrl = (url: string): string => {
  const regex = /\/videos\/(.*)$/;
  const match = url?.match(regex);

  const file = match && match[1];

  return `https://d26g8006nuvfz.cloudfront.net/videos/${file}`;
};
//
const ViewWorkout: React.FC<ViewWorkoutProps> = ({
  modalIsOpen,
  setModalIsOpen,
  live,
  workout,
}) => {
  const videoURL = getCDNUrl(workout?.path as string);

  return (
    <Modal {...{ modalIsOpen, setModalIsOpen }}>
      <ModalContent>
        {live ? (
          <LiveContent>
            <LiveContentStatus>
              AO VIVO -{' '}
              <strong>
                {live.date} - {live.hour}
              </strong>
            </LiveContentStatus>
            <LiveContentTitle>{live.title}</LiveContentTitle>
            <LiveContentDescription>{live.description}</LiveContentDescription>
            {live.is_free === LiveIsFreeStatus.NO && live.price && (
              <LiveContentPrice>
                Valor:{' '}
                <strong>{Price.floatToCurrency(live?.price ?? 0)}</strong>
              </LiveContentPrice>
            )}
            <LiveContentButton
              href={live.url}
              rel="noreferrer noopener"
              target="_blank"
            >
              <Button>Link para a reunião</Button>
            </LiveContentButton>
          </LiveContent>
        ) : (
          <>
            {/* <Video
              onContextMenu={() => false}
              controlsList="nodownload"
              src={workout?.path}
              controls
            /> */}

            {workout?.is_converted && videoURL && (
              <Video
                onContextMenu={() => false}
                controlsList="nodownload"
                src={videoURL}
                controls
              />
            )}

            <Content>
              <h6>{workout?.category.description}</h6>
              <h1>{workout?.title}</h1>
              <p>{workout?.description}</p>
            </Content>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ViewWorkout;
