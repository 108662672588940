import { useCallback, useEffect, useState } from 'react';

import { Courses as CoursesComponent, Header, Paginate } from '~/components';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { StudentLayout } from '~/layout';
import { PageOptionsRequest, PageOptionsResponse } from '~/models/Common';
import CourseProps, { Courses as CourseInterface } from '~/models/Courses';
import { cleanRequests } from '~/services/api';
import CourseService from '~/services/CourseService';
import { Container } from '~/styles/objects/container';

import BuyCourse from './BuyCourse';

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<
    PageOptionsResponse<CourseInterface>
  >();
  const [coursesSuggests, setCoursesSuggests] = useState<
    PageOptionsResponse<CourseInterface>
  >();
  const [selectedCourse, setSelectedCourse] = useState<CourseProps>(
    {} as CourseProps,
  );
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { user } = useAuth();
  const { setLoading } = useLoading();

  const serializedCourses = courses && courses.data;

  const getStudentCourses = useCallback(
    async (userId: number, options) => {
      setLoading(true);
      const response = await CourseService.getStudentCourses({
        studentId: userId,
        ...options,
      });
      setCourses(response.data);
      setLoading(false);
    },
    [setLoading],
  );

  const handleSelectCourse = useCallback(course => {
    setSelectedCourse(course);
    setModalIsOpen(true);
  }, []);

  const handleSuggestsCourses = useCallback(async () => {
    setLoading(true);
    const response = await CourseService.getSuggestionsCourses(pageOptions);
    setCoursesSuggests(response.data);
    setLoading(false);
  }, [pageOptions, setLoading]);

  useEffect(() => {
    getStudentCourses(user.id, pageOptions);
    return cleanRequests;
  }, [getStudentCourses, pageOptions, user.id]);

  useEffect(() => {
    handleSuggestsCourses();
    return cleanRequests;
  }, [handleSuggestsCourses, pageOptions]);

  return (
    <StudentLayout>
      <Header headerTitle="Cursos" />

      <Container>
        <CoursesComponent courses={serializedCourses ?? []} />

        <Paginate
          pageCount={courses?.lastPage as number}
          pageRangeDisplayed={courses?.data?.length ?? 0}
          {...{ setPageOptions }}
        />
      </Container>

      <Header headerTitle="Cursos" />

      <Container>
        <CoursesComponent
          courses={coursesSuggests ? coursesSuggests.data : []}
          handleSelectCourse={handleSelectCourse}
          displayBuyButton
        />

        <Paginate
          pageCount={coursesSuggests?.lastPage as number}
          pageRangeDisplayed={courses?.data?.length ?? 0}
          {...{ setPageOptions }}
        />
      </Container>

      <BuyCourse
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        course={selectedCourse}
      />
    </StudentLayout>
  );
};

export default Courses;
