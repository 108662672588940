import { FC, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { ImageLives } from '~/assets/images';
import { DropdownType } from '~/models/Common';
import { DropdownIcon } from '~/styles/objects/dropdown-icon';
import { ItemsGrid } from '~/styles/objects/items-grid';
import { NotFoundItems } from '~/styles/objects/not-found';
import { handleChangeDropdown } from '~/utils/handleChangeDropdown';
import Price from '~/utils/Price';

import { Button, DropdownMenu } from '..';

import * as L from './styles';

import { LivesProps } from './typings';

const Lives: FC<LivesProps> = ({
  handleDeleteLive,
  isProfessional,
  liveIsPaid,
  lives,
  dropdownProps,
  getLives,
  gridPadding,
  handleOpenLiveModal,
  publicname,
}) => {
  const livesMemo = useMemo(() => {
    if (lives) {
      return lives?.map(live => (
        <L.Interaction key={live.id}>
          {isProfessional && (
            <>
              <DropdownIcon
                size={24}
                color="#FFF"
                style={{ zIndex: 5 }}
                onClick={() =>
                  dropdownProps &&
                  handleChangeDropdown({
                    id: live.id,
                    type: DropdownType.LIVE,
                    setIsOpen: dropdownProps.setDropdownOpen,
                    setItem: dropdownProps.setDropdownSelected,
                    isOpen: dropdownProps.dropdownOpen,
                  })
                }
              />
              <DropdownMenu
                dropdownIsOpen={dropdownProps?.dropdownOpen ?? false}
                setDropdownIsOpen={dropdownProps?.setDropdownOpen}
                id={`live_${live.id}`}
                dropdownSelected={dropdownProps?.dropdownSelected}
                handleDelete={() =>
                  handleDeleteLive && handleDeleteLive(live.id)
                }
                live={live}
              />
            </>
          )}
          <L.InteractionContent
            onClick={() => handleOpenLiveModal && handleOpenLiveModal(live)}
          >
            <span>
              Ao vivo -{' '}
              <strong>
                {live.date.split('T')[0].split('-')[2]}/
                {live.date.split('T')[0].split('-')[1]} -{' '}
                {live.hour.split(':')[0]}:{live.hour.split(':')[1]}H
              </strong>
            </span>
            <img
              src={ImageLives}
              alt="Live"
              style={{ width: '100%', height: 'auto' }}
            />
            <h3>{live.title}</h3>
            <p>{live.description}</p>
            <L.InteractionContentFooter>
              {!live.is_free && live.price && (
                <>
                  <L.InteractionContentPrice>
                    Valor: <strong>{Price.floatToCurrency(live.price)}</strong>
                  </L.InteractionContentPrice>
                  {!!publicname && (
                    <Link to={`/${publicname}/signup?live=${live.id}`}>
                      <Button style={{ backgroundColor: '#57AB21' }}>
                        Comprar
                      </Button>
                    </Link>
                  )}
                </>
              )}
              {!!live.is_free && (
                <>
                  {publicname && (
                    <Link to={`/${publicname}/become-a-student`}>
                      <Button style={{ backgroundColor: '#57AB21' }}>
                        Torne-se aluno
                      </Button>
                    </Link>
                  )}
                </>
              )}

              {(isProfessional || liveIsPaid) && (
                <L.InteractionContentButton
                  href={live.url}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <Button>Link para a reunião</Button>
                </L.InteractionContentButton>
              )}
            </L.InteractionContentFooter>
          </L.InteractionContent>
        </L.Interaction>
      ));
    }
    return {};
  }, [
    dropdownProps,
    handleDeleteLive,
    handleOpenLiveModal,
    isProfessional,
    liveIsPaid,
    lives,
    publicname,
  ]);

  if (!lives || lives?.length === 0) return <NotFoundItems> </NotFoundItems>;
  // <NotFoundItems>Nenhuma aula ao vivo encontrado.</NotFoundItems>;

  return <ItemsGrid style={{ padding: gridPadding }}>{livesMemo}</ItemsGrid>;
};

export default Lives;
