import styled from 'styled-components';

import { breakpoints } from '~/styles/tools/breakpoints';

import { Container } from '../../../styles/objects/container';

export const WorkoutContainer = styled(Container)`
  #showonmobile {
    display: none;
  }

  @media (max-width: ${breakpoints.small}) {
    #showonmobile {
      display: flex;
    }
    #hideonmobile {
      display: none;
    }
  }
`;

export const NoCategoryWorkoutTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;
