import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Configuration } from '~/components';
import { useAuth } from '~/hooks/Auth';
import StudentLayout from '~/layout/StudentLayout';
import StudentService from '~/services/StudentService';
import Price from '~/utils/Price';

import {
  CancelButton,
  Plan,
  PlanLabel,
  PlanTitle,
  PlansWarning,
} from './styles';
import { useToast } from '~/hooks/Toast';
import LivesService from '~/services/LivesService';

interface PlanData {
  price: number;
  id: number;
  status: string;
  professional: any;
  created_at: string;
  updated_at: string;
}

const ConfigurationStudent: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [planData, setPlanData] = useState<PlanData[]>([]);

  useEffect(() => {
    const getPlanData = async (): Promise<void> => {
      try {
        let student_id = 0;
        if (user.role === 'student') student_id = user.student.id;
        const response = await StudentService.getPlan(student_id);
        setPlanData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getPlanData();
  }, [user]);

  const handleCancelPlan = useCallback(
    async (id, name) => {
      if (
        window.confirm(
          `Tem certeza que deseja cancelar o plano?
          Ao efetuar o cancelamento, seu acesso ao conteúdo do treinador ${name} será removido imediatamente e você não receberá mais cobranças.`,
        )
      ) {
        let student_id = 0;
        if (user.role === 'student') student_id = user.student.id;

        await StudentService.cancelPlan(student_id, id);

        addToast({
          type: 'success',
          title: 'Plano cancelado com sucesso!',
        });

        history.push('/professional-profiles');
      }
    },
    [user],
  );

  const ChangePaymentMethod = async (): Promise<void> => {
    try {
      const {
        data: { url },
      } = await LivesService.changePaymentMethod();

      window.location.href = url;
    } catch (error) {
      console.error('Erro ao mudar o método de pagamento:', error);
    }
  };

  return (
    <StudentLayout>
      <Configuration>
        {planData && (
          <Plan>
            <PlanTitle>Planos</PlanTitle>

            {planData?.map(plan => {
              const paymentDay = new Date(plan.created_at);
              const paymentNextDay = new Date(paymentDay);
              paymentNextDay.setDate(paymentDay.getDate() + 1);
              return (
                <PlanLabel key={plan.id}>
                  <span>
                    <strong>Dia de vencimento:</strong>
                    {paymentNextDay.getDay()}
                  </span>
                  <span>
                    <strong>Valor do Plano:</strong>
                    {Price.floatToCurrency(plan.price ?? 0)}
                  </span>
                  <span>
                    <strong>Treinador:</strong>
                    {plan.professional.nick_name}
                  </span>
                  <CancelButton
                    onClick={() => {
                      handleCancelPlan(plan.id, plan.professional.nick_name);
                    }}
                  >
                    Cancelar Plano
                  </CancelButton>
                  <CancelButton
                    style={{
                      background: '#b88746 0% 0% no-repeat padding-box',
                      color: 'white',
                    }}
                    type="button"
                    onClick={ChangePaymentMethod}
                  >
                    Mudar cartão
                  </CancelButton>
                </PlanLabel>
              );
            })}
            <PlansWarning>
              Você pode cancelar o seu plano até 1 dia antes do vencimento.
            </PlansWarning>
          </Plan>
        )}
      </Configuration>
    </StudentLayout>
  );
};

export default ConfigurationStudent;
