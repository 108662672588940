import styled from 'styled-components';

import { breakpoints } from '~/styles/tools/breakpoints';
import { appearFromLeft } from '~/styles/tools/transitions';

import { Container } from '../../../styles/objects/container';

export const MyWorkoutsContainer = styled(Container)`
  animation: ${appearFromLeft} 1s;

  flex-direction: column;

  #showonmobile {
    display: none;
  }

  @media (max-width: ${breakpoints.small}) {
    #showonmobile {
      display: flex;
    }
    #hideonmobile {
      display: none;
    }
  }
`;

export const NoCategoryWorkoutTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const MyWorkoutsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap-sm);
  justify-content: center;
  margin-bottom: var(--gap-md);

  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
`;

export const Workout = styled.div`
  padding: var(--gap-xs);
  cursor: pointer;
  transition: background 0.3s;
  box-sizing: content-box;
  margin: 0 auto;

  svg {
    position: absolute;
    margin-left: 370px;
    margin-top: 5px;
    border-radius: 50%;
    background: #2c2c2c 0% 0% no-repeat padding-box;
    opacity: 0.67;
    margin-right: 15px;
  }

  &:hover,
  &:focus {
    transition: 0.3s;
    background: #fff0dc 0% 0% no-repeat padding-box;
  }

  @media (min-width: 1612px) {
    width: 320px;
  }

  @media (min-width: ${breakpoints.large}) and (max-width: 1612px) {
    width: 265px;
  }

  @media (min-width: 1120px) and (max-width: ${breakpoints.large}) {
    width: 289px;
  }

  @media (min-width: ${breakpoints.medium}) and (max-width: 1120px) {
    width: 222px;
  }

  @media (max-width: ${breakpoints.medium}) {
    width: 300px;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: 0;

    &:hover,
    &:focus {
      background: none;
    }
  }
`;

export const WorkoutImage = styled.img`
  width: 100%;

  @media (min-width: 1612px) {
    height: 213px;
  }

  @media (min-width: ${breakpoints.large}) and (max-width: 1612px) {
    height: 176px;
  }

  @media (min-width: 1120px) and (max-width: ${breakpoints.large}) {
    height: 192px;
  }

  @media (min-width: ${breakpoints.medium}) and (max-width: 1120px) {
    height: 148px;
  }

  @media (max-width: ${breakpoints.medium}) {
    height: 200px;
  }
`;

export const NotWorkouts = styled.h1`
  max-width: 100%;
  width: 100%;
  padding-bottom: 150px;
`;

export const Interaction = styled.article`
  max-width: 393px;
  transition: background 0.3s;
  background: none;
  padding: 16px;
  position: relative;

  &:hover {
    cursor: pointer;
    background: #fff0dc 0% 0% no-repeat padding-box;
  }

  > svg {
    position: absolute;
    right: 2px;
    top: 16px;
    border-radius: 50%;
    background: #2c2c2c 0% 0% no-repeat padding-box;
    opacity: 0.67;
    margin-right: 15px;
    cursor: pointer;
  }

  @media (max-width: 768.9px) {
    max-width: 310px;

    img {
      max-width: 290px;
    }

    svg {
      margin-left: 240px;
    }
  }
`;

export const InteractionContent = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-bottom: calc(var(--gap-xs) + 80px);
  position: relative;

  > span {
    text-transform: uppercase;
    font-size: 12px;
    color: #2c2c2c;

    > strong {
      font-size: 16px;
      letter-spacing: 0.52px;
      color: #b88746;
    }
  }

  > h3 {
    color: #2c2c2c;
    font-size: 24px;
    font-weight: bold;
  }

  > p {
    color: #636363;
    font-size: 16px;
    line-height: 1.5em;
    max-width: 393px;
  }

  @media (max-width: 768.9px) {
    button {
      margin-left: 0;
    }
  }
`;

export const InteractionContentFooter = styled.footer`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const InteractionContentPrice = styled.strong`
  display: inline-block;
  margin-bottom: var(--gap);
`;

export const InteractionContentButton = styled.a`
  align-self: center;
  width: 100%;

  > button {
    background: #2c2c2c 0% 0% no-repeat padding-box;
    width: 100%;
    max-width: 100%;
  }
`;
