export default class NotFoundCepError extends Error {
  cepTried: string;

  constructor(
    cepTried: string,
    message = `Infelizmente o CEP ${cepTried} não foi achado.`,
  ) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundCepError);
    }

    this.cepTried = cepTried;
    this.name = 'NotFoundCepError';
  }
}
