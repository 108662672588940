function checkTime(i: number): string {
  if (i < 10) {
    return `0${i}`;
  }
  return i.toString();
}

export default function getCurrentTime(): string {
  const today = new Date();
  const hour = today.getHours();
  const minute = today.getMinutes();
  // add a zero in front of numbers<10
  const hh = checkTime(hour);
  const mm = checkTime(minute);
  const currentTime = `${hh}:${mm}`;
  return currentTime;
}
