import { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { ImageHome, ImageLives, ImageDance } from '~/assets/images';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { StudentLayout } from '~/layout';
import { Workouts, WorkoutsData } from '~/models/Workouts';
import { cleanRequests } from '~/services/api';
import WorkoutsService from '~/services/WorkoutsService';
import errorHandlerToToast from '~/utils/errorHandler';

import * as S from './styles';

import ViewWorkout from '../Workouts/ViewWorkout';

export const images: string[] = [ImageHome, ImageHome, ImageHome, ImageHome];

interface RouteParam {
  state: {
    professionalId: number;
    categoryId?: number;
  };
}

const CourseListAll: React.FC = () => {
  const location = useLocation() as RouteParam;
  const [workouts, setWorkouts] = useState<WorkoutsData>({} as WorkoutsData);
  const [workout, setWorkout] = useState<Workouts>({} as Workouts);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { user } = useAuth();
  const { setLoading } = useLoading();
  const { addToast } = useToast();

  const getStudentWorkouts = useCallback(async () => {
    try {
      setLoading(true);
      if (user.role === 'student') {
        const response = await WorkoutsService.getStudentWorkoutsByProfessional(
          {
            studentId: user.student.id,
            categoryId: location.state?.categoryId as number,
            perpage: 10,
            professionalId: location.state.professionalId,
          },
        );
        // precisa refatorar
        const responseAux = await WorkoutsService.getStudentWorkoutsByProfessional(
          {
            studentId: user.student.id,
            categoryId: location.state?.categoryId as number,
            perpage: response.data.total,
            professionalId: location.state.professionalId,
          },
        );
        setWorkouts(responseAux.data);
      } else {
        throw new Error('User must be a student');
      }
    } catch (error: any) {
      const responseErr = errorHandlerToToast({
        error,
        description: 'Não foi possível carregar as aulas gravadas.',
      });

      if (responseErr) addToast(responseErr);
    } finally {
      setLoading(false);
    }
  }, [addToast, setLoading, user]);

  function handModal(value: Workouts): void {
    setWorkout(value);
    setModalIsOpen(true);
  }

  useEffect(() => {
    getStudentWorkouts();
    return cleanRequests;
  }, [getStudentWorkouts]);

  return (
    <StudentLayout>
      <S.Container>
        <S.ListHeader>
          <S.ListTitle>Aulas gravadas</S.ListTitle>
        </S.ListHeader>
        <S.ListBody>
          {workouts &&
            workouts.total > 0 &&
            workouts.data?.map(workoutAux => {
              const defaultImage = workoutAux?.url ? ImageLives : ImageDance;

              return (
                <S.ListCard
                  key={workoutAux.id}
                  onClick={() => {
                    handModal(workoutAux);
                  }}
                >
                  <S.CardImage
                    src={workoutAux?.image || defaultImage}
                    alt="Card Image"
                  />
                  <S.CardCategory>
                    {workoutAux.category.description}
                  </S.CardCategory>
                  <S.CardTitle>{workoutAux.title}</S.CardTitle>
                </S.ListCard>
              );
            })}
        </S.ListBody>
        <ViewWorkout
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          workout={workout}
        />
      </S.Container>
    </StudentLayout>
  );
};

export default CourseListAll;
