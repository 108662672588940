import { Container, Text } from './styles';

interface PreviewLinkProps {
  publicName: string;
}

const PreviewLink: React.FC<PreviewLinkProps> = ({ publicName }) => {
  const { href: url } = new URL(publicName, process.env.REACT_APP_API_BASE_URL);

  return (
    <Container>
      Link personalizado:
      <Text>{url}</Text>
    </Container>
  );
};

export default PreviewLink;
