import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Button, Footer, Modal, Navbar } from '~/components';
import { useAuth } from '~/hooks/Auth';
import { IMigrationPayment } from '~/models/MigrationPayment';
import StudentService from '~/services/StudentService';
import getTodayDate, { formatDate } from '~/utils/getTodayDate';

import { Boxmodal, ModalContent } from './styles';

interface StudentLayoutProps {
  children: React.ReactNode;
}

const StudentLayout: React.FC<StudentLayoutProps> = ({
  children,
}: StudentLayoutProps) => {
  const { publicName } = useAuth();
  const { push } = useHistory();
  const [isCancelJunoSignature, setisCancelJunoSignature] = useState(false);
  const today = getTodayDate();
  const [
    isMigrationPaymentModalOpen,
    setIsMigrationPaymentModalOpen,
  ] = useState(false);
  const [migrationPayment, setMigrationPayment] = useState<IMigrationPayment>();

  const handleMigrationPayment: () => void = async () => {
    try {
      const response = await StudentService.migrationPayment();

      if (response.data.status === 200) {
        setMigrationPayment(response.data);
        setIsMigrationPaymentModalOpen(true);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleICancelationJunoSignature: () => void = async () => {
    try {
      setisCancelJunoSignature(true);
      const response = await StudentService.cancelationJunoSignature();
      if (
        response.data.responseJuno.status === 'CANCELED' ||
        response.data.responseJuno.status === 'INACTIVE'
      ) {
        push(`/${response.data.profesionalPublicName}`, {
          cancelJuno: true,
        });
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setisCancelJunoSignature(false);
      setIsMigrationPaymentModalOpen(false);
    }
  };

  useEffect(() => {
    handleMigrationPayment();
  }, [publicName]);

  return (
    <>
      <Navbar />
      {children}
      <Footer />
      <Modal
        modalIsOpen={isMigrationPaymentModalOpen}
        setModalIsOpen={setIsMigrationPaymentModalOpen}
        withoutCloseButton
      >
        <ModalContent>
          <h1>Aviso Importante</h1>
          {migrationPayment?.signatureJuno.status === 'CANCELED' && (
            <div>
              Sua assinatura está cancelada. Clique em continuar para fazer uma
              nova assinatura
              <Boxmodal>
                <Button
                  onClick={handleICancelationJunoSignature}
                  disabled={isCancelJunoSignature}
                >
                  {isCancelJunoSignature ? 'Carregando' : 'Continuar'}
                </Button>
                <Button
                  onClick={() => setIsMigrationPaymentModalOpen(false)}
                  disabled={isCancelJunoSignature}
                >
                  Fechar
                </Button>
              </Boxmodal>
            </div>
          )}
          {migrationPayment?.signatureJuno.status === 'ACTIVE' && (
            <div>
              Para melhor atende-lo nós estamos trocando a nossa plataforma de
              pagamento.
              <br />A sua mensalidade vence dia:{' '}
              <b>
                {formatDate(
                  migrationPayment?.signatureJuno.nextBillingDate ?? '',
                  'invert',
                )}
              </b>
              . Acesse o seu perfil na data:{' '}
              <b>
                {formatDate(migrationPayment?.migrationDate ?? '', 'invert')}
              </b>
              . Para fazer o pagamento da sua assinatura na nossa nova
              plataforma.
              <Button
                onClick={() => setIsMigrationPaymentModalOpen(false)}
                disabled={isCancelJunoSignature}
              >
                Fechar
              </Button>
              {today === migrationPayment?.migrationDate && (
                <Boxmodal>
                  <Button
                    onClick={handleICancelationJunoSignature}
                    disabled={isCancelJunoSignature}
                  >
                    {isCancelJunoSignature ? 'Carregando' : 'Continuar'}
                  </Button>
                  <Button
                    onClick={() => setIsMigrationPaymentModalOpen(false)}
                    disabled={isCancelJunoSignature}
                  >
                    Fechar
                  </Button>
                </Boxmodal>
              )}
            </div>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default StudentLayout;
