import { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useLoading } from '~/hooks/Loading';
import { useStudent } from '~/hooks/Student';
import { StudentLayout } from '~/layout';
import { cleanRequests } from '~/services/api';
import StudentService, { ProfessionalProfile } from '~/services/StudentService';

import * as S from './styles';

const ProfessionalProfiles: React.FC = () => {
  const history = useHistory();

  const { updateSelectedProfessional } = useStudent();

  const [professionalProfiles, setProfessionalProfiles] = useState<
    ProfessionalProfile[]
  >([]);
  const { isLoading, setLoading } = useLoading();

  const getProfessionalProfiles = useCallback(async () => {
    setLoading(true);
    const response = await StudentService.getProfessionalProfiles();
    setProfessionalProfiles(response?.data?.payment);
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    getProfessionalProfiles();
    return cleanRequests;
  }, [getProfessionalProfiles]);

  function capitalizeFirstLetters(inputString: string): string {
    return inputString.replace(/\b\w{3,}\b/g, function (match) {
      return match.charAt(0).toUpperCase() + match.slice(1);
    });
  }

  return (
    <StudentLayout>
      <S.Container>
        <S.Title>
          {professionalProfiles?.length > 0
            ? 'O que deseja assistir?'
            : 'Assine algum profissional para assistir as aulas'}
        </S.Title>

        <S.Profiles>
          {professionalProfiles?.length > 0 &&
            professionalProfiles?.map(professionalProfile => (
              <S.Card
                key={professionalProfile.id}
                onClick={() => {
                  updateSelectedProfessional(professionalProfile);
                  history.push('/course-list', {
                    professionalId: professionalProfile.professional_id,
                  });
                }}
              >
                <S.Image
                  src={professionalProfile.professional.user.avatar}
                  alt={`Image Profile ${professionalProfile.professional.nick_name}`}
                />
                <S.ProfileName>
                  {capitalizeFirstLetters(
                    professionalProfile.professional.nick_name,
                  )}
                </S.ProfileName>
              </S.Card>
            ))}
        </S.Profiles>
      </S.Container>
    </StudentLayout>
  );
};

export default ProfessionalProfiles;
