import { useTransition } from 'react-spring';

import { ToastMessage } from '~/hooks/Toast';

import { Container } from './styles';

import Toast from './Toast';

interface ToastContainerProps {
  messages: ToastMessage[];
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  const messagesWithTransition = useTransition(messages, {
    keys: message => message.id,
    from: { right: '-120%', opacity: '0' },
    enter: { right: '0%', opacity: '1' },
    leave: { right: '-120%', opacity: '0' },
  });

  return (
    <Container>
      {messagesWithTransition((style, message) => (
        <Toast key={message.id} {...{ message, style }} />
      ))}
    </Container>
  );
};

export default ToastContainer;
