import styled, { keyframes } from 'styled-components';

import { ImageBackground } from '~/assets/images';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  background: url(${ImageBackground}) 0% / cover;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-bottom: var(--gap-lg);

  img {
    width: 198px;
    height: 121px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    max-width: 420px;
    width: 100%;

    animation: ${appearFromLeft} 1s;

    input {
      width: 100%;
    }
  }

  button {
    margin-top: var(--gap-xs);
    max-width: 420px;
    width: 100%;
    height: auto;
    padding: 14px;
  }

  h6 {
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin-top: 15px;
  }

  h6 a {
    color: #fdf5a6;
  }

  @media (max-width: 768.9px) {
    padding: 10px 20px;
  }
`;

export const ForgetPasswordLink = styled.button`
  display: block;
  background: none;
  margin-top: var(--gap-xs);
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  max-width: 364px;
  color: #fdf5a6;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: underline;

  &:hover {
    transition: color 0.3s;
    color: #fff;
  }
`;
