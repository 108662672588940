import { useHistory } from 'react-router-dom';

import { PageOptionsResponse } from '~/models/Common';

import * as S from './styles';

import { Carousel } from './carrousel';

interface Data {
  id?: number;
  image?: string;
  url?: string;
  description?: string;
  category?: {
    description: string;
  };
  title?: string;
}

interface Props<T extends Data> {
  data: PageOptionsResponse<T>;
  title: any;
  // title: string | Element;
  professionalId: number;
  categoryId?: number;
}

export const ListVideoGroup: <T extends Data>(
  props: Props<T>,
) => React.ReactElement<Props<T>> = ({
  data,
  title,
  professionalId,
  categoryId,
}) => {
  const navigate = useHistory();
  return (
    <S.ListGroup>
      <S.ListHeader>
        <S.ListTitle>{title}</S.ListTitle>
        {data.total > 5 && (
          <S.ListLink
            onClick={() => {
              navigate.push('/course-list/all', { professionalId, categoryId });
            }}
          >
            Ver todos
          </S.ListLink>
        )}
      </S.ListHeader>
      <S.ListBody>
        <Carousel data={data.data} />
      </S.ListBody>
    </S.ListGroup>
  );
};
