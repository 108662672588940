import styled from 'styled-components';

import { Container } from '~/styles/objects/container';
import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

export const Workout = styled.article`
  padding: var(--gap-xs);
  cursor: pointer;
  transition: background 0.3s;
  box-sizing: content-box;
  margin: 0 auto;
  position: relative;
  width: 100%;
  overflow: hidden;

  &:hover,
  &:focus {
    transition: 0.3s;
    background: ${colors.modalBackground} 0% 0% no-repeat padding-box;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: 0;
    max-width: 300px;

    &:hover,
    &:focus {
      background: none;
    }
  }

  span {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.52px;
    color: ${colors.lightGrey};
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white;

  @media (max-width: ${breakpoints.small}) {
    height: 200px;
  }
`;

export const WorkoutImage = styled.img`
  width: auto;
  height: 100%;
`;

export const WorkoutContent = styled.article``;

export const WorkoutTitle = styled.h3`
  color: ${colors.lightnerGrey};
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.8px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WorkoutDescription = styled.p`
  color: ${colors.lightGrey};
  font-size: 1.6rem;
  max-width: 393px;
  font-weight: 400;
  letter-spacing: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LivesContainer = styled(Container)`
  padding: var(--gap-lg) 0;
`;

export const LivesContent = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap-sm);
  justify-content: center;
  margin-bottom: var(--gap-md);

  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    x-width: 100%;
  }
`;

export const Interaction = styled.div`
  width: 100%;
  transition: background 0.3s;
`;

export const InteractionLiveDate = styled.span`
  text-transform: uppercase;
  font-size: 1.4rem;
  color: ${colors.black};

  > strong {
    letter-spacing: 0.52px;
    color: ${colors.primary};
  }
`;

export const InteractionTitle = styled.h3`
  color: ${colors.black};
  font-size: 2rem;
`;

export const InteractionDescription = styled.p`
  color: ${colors.gray};
  font-size: 1.6rem;
`;

export const InteractionMeetLink = styled.a`
  display: flex;
  justify-content: center;
  > button {
    background: ${colors.black} 0% 0% no-repeat padding-box;
    margin-top: var(--gap-xs);

    @media (max-width: ${breakpoints.small}) {
      margin-left: 0;
    }
  }
`;

export const NotFoundWorkout = styled.h2`
  color: ${colors.black};
`;
