import styled from 'styled-components';

import { ImageHome, ImageHomeMobile } from '~/assets/images';
import { Button as ButtonBase } from '~/components';
import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';
import {
  appearFromLeft,
  appearOpacity,
  upFade,
} from '~/styles/tools/transitions';

interface BenefitImageProps {
  image?: string;
}

export const Button = styled(ButtonBase)`
  line-height: 1.15em;
  /* margin: auto; */
  min-width: 309px;

  > strong {
    color: inherit;
    margin-left: 0.5rem;
    font-weight: 800;
  }

  @media (min-width: ${breakpoints.small}) and (max-width: 900px) {
    min-width: 240px;
  }

  @media (max-width: ${breakpoints.small}) {
    min-width: auto;
  }
`;

export const ButtonCreateAccount = styled(Button)`
  background: ${colors.green} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px ${colors.gray};
`;

export const Hero = styled.main`
  position: relative;

  /* background: url(${ImageHome}) 0% / cover; */

  @media (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.large}) {
    height: 100vh;
  }

  @media (min-width: ${breakpoints.medium}) {
    height: auto;
  }

  @media (max-width: ${breakpoints.small}) {
    /* background: url(${ImageHomeMobile}) 0% / cover; */
    background-position: top;
  }
`;

export const HeroContent = styled.section`
  animation: ${appearFromLeft} 1s;

  > a {
    width: 100%;

    @media (max-width: ${breakpoints.small}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  > a > button {
    max-width: 380px;
    width: 100%;
    background: ${colors.green} 0% 0% no-repeat padding-box;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 2.2rem;
  }

  @media (max-width: ${breakpoints.small}) {
    > a button {
      font-size: 1.6rem;
    }
  }
`;

export const HeroTitle = styled.h1`
  max-width: 620px;
  color: ${colors.terciary};
  text-shadow: 0px 3px 6px #00000070;
  font-size: 3.6rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: var(--gap-xs);

  @media (max-width: 1450px) {
    font-size: 2.2rem;
  }

  bottom: 1.6rem;
`;

export const HeroText = styled.p`
  /* margin: 0 auto; */
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
  text-shadow: 0px 3px 6px #0000008a;
  text-align: left;
  margin-bottom: var(--gap-md);

  @media (max-width: 1450px) {
    font-size: 1.6rem;
  }

  @media (min-width: ${breakpoints.small}) {
    max-width: 450px;
  }
`;

export const Presentation = styled.section`
  background: #1a1a1a;
  padding: var(--gap-lg) 0;
  animation: ${appearFromLeft} 1s;

  > a > button {
    width: 420px;
    margin-top: var(--gap-md);
    font-weight: bold;
  }

  @media (min-width: ${breakpoints.large}) {
    padding: var(--gap-xl) 0;
  }
  /* background: ${colors.black}; */
`;

export const PresentationTitle = styled.h2`
  font-size: 3.6rem;
  line-height: 1.5em;
  color: ${colors.primary};
  font-weight: 600;
  max-width: 746px;
  text-align: center;
  align-self: center;

  @media (max-width: ${breakpoints.small}) {
    font-size: 2.4rem;
  }
`;

export const PresentationText = styled.p`
  margin-top: var(--gap-xs);
  color: #f4f4f4;
  font-size: 2rem;
  margin-bottom: var(--gap-lg);
  align-self: center;

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: var(--gap-md);
  }
`;

export const PresentationVideo = styled.video`
  max-width: 979px;
  width: 100%;
  align-self: center;
  margin-bottom: var(--gap-md);
`;

export const Benefits = styled.section`
  height: 100%;
  flex: 1;
  background: transparent linear-gradient(255deg, #b88746 0%, #5c4423 100%) 0%
    0% no-repeat padding-box;

  padding: var(--gap-lg) 0;
`;

export const BenefitsTitle = styled.h2`
  font-size: 3.6rem;
  line-height: 1.15em;
  letter-spacing: 1.76px;
  color: #ffffff;
  text-transform: uppercase;
  align-self: center;
  margin-bottom: var(--gap-sm);

  @media (max-width: ${breakpoints.small}) {
    text-align: center;
    font-size: 2.4rem;
  }

  @media (min-width: ${breakpoints.large}) {
    margin-bottom: var(--gap-lg);
  }
`;

export const BenefitsContent = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  animation: ${appearFromLeft} 1s;

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
    align-items: center;
    row-gap: var(--gap-xs);
  }
`;

export const BenefitsItem = styled.article<BenefitImageProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 441px;
  height: 441px;
  opacity: 0.9;
  border-radius: 8px;

  &::before {
    content: '';
    background-image: url(${({ image }) => image});
    background-position: 0%;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.8;
    transition: opacity 500ms ease-in-out;
  }

  &:hover::before {
    opacity: 0.3;
  }

  &:hover {
    z-index: 1000;
    > h3,
    > article,
    > article > p {
      animation-name: ${upFade};
      animation-duration: 500ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }

    > article {
      display: flex;
      flex-direction: column;
      row-gap: var(--gap);
    }
  }

  @media (max-width: ${breakpoints.small}) {
    width: 324px;
    height: 324px;
  }
`;

export const BenefitsItemTitle = styled.h3`
  color: #fff;
  font-size: 2.7rem;
  line-height: 3.3rem;
  text-transform: uppercase;
  margin-bottom: var(--gap);
  font-weight: 500;
  max-width: 300px;
  text-align: center;
  animation: ${appearOpacity} 500ms ease-in-out;

  > strong {
    font-weight: bold;
    color: inherit;
  }

  @media (max-width: ${breakpoints.small}) {
    font-size: 2.4rem;
    max-width: 240px;
  }
`;

export const BenefitsItemContent = styled.article`
  max-width: 350px;
  opacity: 0;
  display: none;
  animation: ${appearOpacity} 0.5s;

  > a {
    margin-top: var(--gap);
    display: inline-block;
    width: 100%;
  }

  > a > button {
    cursor: pointer;
  }

  @media (max-width: ${breakpoints.small}) {
    max-width: 324px;
  }
`;

export const BenefitsItemText = styled.p`
  font-size: 1.5rem;
  line-height: 2rem;
  color: #fff;
  text-align: justify;
  animation: ${appearOpacity} 0.5s;
  opacity: 0;
`;
