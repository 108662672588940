import styled from 'styled-components';

import { breakpoints } from '~/styles/tools/breakpoints';

export const Container = styled.div`
  flex: 1;
  display: flex;
  gap: 3rem;
  flex-direction: column;
  padding: 4rem 20px;

  @media screen and (max-width: 412px) {
    font-size: 60%;
  }

  @media (min-width: ${breakpoints.small}) {
    padding: 5rem;
  }
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ListTitle = styled.h3`
  color: #f4f4f4;
`;

export const ListLink = styled.a`
  color: #f4f4f4;
`;

export const ListContent = styled.div`
  display: flex;
`;

export const ListBody = styled.div`
  gap: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
`;

export const ListCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  @media screen and (max-width: 412px) {
    gap: 0;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
export const CardCategory = styled.span`
  color: #f4f4f4;
  word-wrap: break-word;
`;
export const CardTitle = styled.h4`
  color: #f4f4f4;
  word-wrap: break-word;
`;
