import { Modal } from '~/components';
import { ModalLike } from '~/models/Common';
import { CourseDetails } from '~/models/Courses';

import { Content, ModalContent, Video } from './styles';

interface ViewVideoProps extends ModalLike {
  courseVideoDetails: CourseDetails;
}

const ViewVideo: React.FC<ViewVideoProps> = ({
  modalIsOpen,
  setModalIsOpen,
  courseVideoDetails,
}) => (
  <Modal {...{ modalIsOpen, setModalIsOpen }}>
    <ModalContent>
      <Video
        onContextMenu={() => false}
        controlsList="nodownload"
        src={courseVideoDetails.path}
        controls
      />
      <Content>
        <h1>{courseVideoDetails.title}</h1>
        <p>{courseVideoDetails.description}</p>
      </Content>
    </ModalContent>
  </Modal>
);

export default ViewVideo;
