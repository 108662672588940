import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 45px 120px 120px;
  margin-left: 120px;

  button {
    margin-top: 25px;
    max-height: 65px;
    max-width: 463px;
    padding: 28px 0;
  }

  @media (max-width: 768.9px) {
    margin: 0;
    padding: 40px;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 1.12px;
  color: #2c2c2c;
  text-transform: uppercase;

  @media (max-width: 768.9px) {
    text-align: center;
  }
`;
export const Link = styled.a`
  text-decoration: none;
`;

export const FilterDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
    display: flex;
    justify-content: space-around;

    @media (max-width: 625px) {
      flex-direction: column;
    }
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: ${colors.lightGrey};
  font-size: 2.1rem;
  font-weight: 600;
  margin-right: 1.6rem;

  > div {
    border-color: ${colors.lightGrey};
    color: ${colors.lightGrey};
    padding-top: 10px;
    padding-left: 5px;

    > input {
      color: ${colors.lightGrey};
      font-size: 21px;
    }
  }
`;
