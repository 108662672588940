import { ReactNode } from 'react';

import { Container, TitleTopic } from './styles';

interface SectionProps {
  titleTopic: string;
  children: ReactNode;
}

export default function Section({
  children,
  titleTopic,
}: SectionProps): JSX.Element {
  return (
    <Container>
      <TitleTopic>{titleTopic}</TitleTopic>
      {children}
    </Container>
  );
}
