import { useCallback, useEffect } from 'react';

import { EmblaOptionsType } from 'embla-carousel-react';
import { Link, useHistory } from 'react-router-dom';

import {
  ImageBusiness,
  ImageCarousel_1,
  ImageCarousel_2,
  ImageCarousel_3,
  ImageCarousel_4,
  ImagePhysiotherapist,
  ImageSchool,
  ImageTrainer,
  ImageYogaTeacher,
} from '~/assets/images';
import EmblaCarousel from '~/components/Carrousel';
import Footer from '~/components/Footer';
import { SandwichMenu } from '~/components/SandwichMenu';
import { useAuth } from '~/hooks/Auth';
import { useToast } from '~/hooks/Toast';
import ProfessorService from '~/services/ProfessorService';
import { Container } from '~/styles/objects/container';
import { useQuery } from '~/utils/query';

import * as H from './styles';

export const images: string[] = [
  ImageCarousel_1,
  ImageCarousel_2,
  ImageCarousel_3,
  ImageCarousel_4,
];
const OPTIONS: EmblaOptionsType = {};
const Home: React.FC = () => {
  const history = useHistory();

  const { user, logout } = useAuth();
  const isStatus = useQuery().get('status');
  const { addToast } = useToast();
  const btnCreateAccount = (isYours: boolean): JSX.Element => (
    <Link to="signup/become-a-professional">
      <H.ButtonCreateAccount isOutline={false}>
        Criar {isYours ? `sua ` : ''}conta gratuita
      </H.ButtonCreateAccount>
    </Link>
  );

  const handleCheckAccountStripe = useCallback(async () => {
    try {
      if (user) {
        if (user.role === 'student') {
          history.push(`/professional-profiles`);
          return;
        }

        if (user.role === 'professional') {
          //  const id = user.role === 'professional' ? user.professional.id : -1;
          const response = await ProfessorService.checkAccountStripe({
            userId: user.id,
          });

          if (
            response.data.message ===
            'Você precisa concluir seu cadastro na stripe'
          ) {
            logout();
          }
          if (isStatus === 'success') {
            addToast({
              title: 'Professor cadastrado no plano',
              description: '',
              type: 'info',
            });
          }
          if (isStatus === 'fail') {
            addToast({
              title: 'Erro ao cadastrar o professor no plano',
              description: '',
              type: 'info',
            });
          }
        }
      }
    } catch (error) {
      console.log(`erro na handleCheckAccountStripe ${error}`);
    }
  }, [addToast, isStatus, logout, user]);
  useEffect(() => {
    handleCheckAccountStripe();
  }, [handleCheckAccountStripe]);

  return (
    <>
      <H.Hero>
        <SandwichMenu />
        <EmblaCarousel
          images={images}
          slides={Array.from(Array(5).keys())}
          options={OPTIONS}
        >
          <H.HeroContent>
            <H.HeroTitle>
              Já pensou em vender seus serviços e cursos pela internet?
            </H.HeroTitle>
            <H.HeroText>
              Nossa plataforma vai ajudar você. Comece hoje o seu negócio.
            </H.HeroText>
            {btnCreateAccount(true)}
          </H.HeroContent>
        </EmblaCarousel>
      </H.Hero>

      <H.Presentation>
        <Container>
          <H.PresentationTitle>
            Aprenda como gerar conteúdo online e vender na sua nova plataforma.
          </H.PresentationTitle>
          <H.PresentationText>
            Assista ao vídeo e comece agora o seu negócio.
          </H.PresentationText>
          <H.PresentationVideo
            onContextMenu={() => false}
            controlsList="nodownload"
            loop
            controls
            poster={`${process.env.PUBLIC_URL}/posterVideo.png`}
          >
            <source
              src={`${process.env.PUBLIC_URL}/videos/home.mp4`}
              type="video/mp4"
            />
          </H.PresentationVideo>
          <a
            style={{ margin: '0 auto' }}
            href="https://wa.me/+5521994118964"
            target="BLANK"
          >
            <H.ButtonCreateAccount isOutline={false}>
              Entre em contato para consultoria gratuita
            </H.ButtonCreateAccount>
          </a>
        </Container>
      </H.Presentation>

      <H.Benefits>
        <Container>
          <H.BenefitsTitle>Benefícios e vantagens</H.BenefitsTitle>
          <H.BenefitsContent>
            <H.BenefitsItem image={ImagePhysiotherapist}>
              <H.BenefitsItemTitle>
                Tenha um <strong>Site Grátis</strong>
              </H.BenefitsItemTitle>
              <H.BenefitsItemContent>
                <H.BenefitsItemText>
                  Ter um canal próprio aumenta a credibilidade do seu trabalho
                  gera para você a autoridade de um profissional conectado às
                  mais modernas tecnologias do mercado.
                </H.BenefitsItemText>
                <H.BenefitsItemText>
                  Nossa empresa entende que através de uma plataforma digital
                  eficiente, gratuita e simplificada ao usuário, podemos
                  realizar a inclusão digital de milhares de professores.
                </H.BenefitsItemText>
                {btnCreateAccount(false)}
              </H.BenefitsItemContent>
            </H.BenefitsItem>
            <H.BenefitsItem image={ImageSchool}>
              <H.BenefitsItemTitle>
                Venda suas <strong>aulas e cursos</strong>
              </H.BenefitsItemTitle>
              <H.BenefitsItemContent>
                <H.BenefitsItemText>
                  Com o objetivo de oferecer aos profissionais a oportunidade de
                  desenvolver seus trabalhos no meio digital, nossa equipe criou
                  uma ferramenta completa e essencial, capaz de integrar todas
                  as funcionalidades necessárias para criação, divulgação e
                  venda de aulas e cursos.
                </H.BenefitsItemText>
                <H.BenefitsItemText>
                  A venda das suas aulas usa o método de recorrência mensal.
                </H.BenefitsItemText>
                <H.BenefitsItemText>
                  Já na venda dos cursos, o profissional separa os produtos por
                  tema e faz suas vendas individualmente curso a curso. Usando a
                  venda direta ou por recorrência mensal.
                </H.BenefitsItemText>
                {btnCreateAccount(false)}
              </H.BenefitsItemContent>
            </H.BenefitsItem>
            <H.BenefitsItem image={ImageYogaTeacher}>
              <H.BenefitsItemTitle>
                Venda <strong>Ao vivo</strong>
              </H.BenefitsItemTitle>
              <H.BenefitsItemContent>
                <H.BenefitsItemText>
                  A sua plataforma digital ainda possibilita a relação direta
                  com seus clientes, através da interação ao vivo. Onde você
                  pode organizar aulas especiais, reuniões dos seus cursos ou
                  dar consultoria individual.
                </H.BenefitsItemText>
                {btnCreateAccount(false)}
              </H.BenefitsItemContent>
            </H.BenefitsItem>
            <H.BenefitsItem image={ImageBusiness}>
              <H.BenefitsItemTitle>
                Escolha <strong>Quanto</strong> você quer{' '}
                <strong>Ganhar</strong>
              </H.BenefitsItemTitle>
              <H.BenefitsItemContent>
                <H.BenefitsItemText>
                  Cada profissional tem a sua estratégia de preço. Na sua
                  plataforma digital, você escolhe o valor da mensalidade para
                  seus clientes do programa de aulas, além de poder devinde o
                  quanto quer ganhar por seus cursos, aulas especiais e
                  consultorias.
                </H.BenefitsItemText>
                {btnCreateAccount(false)}
              </H.BenefitsItemContent>
            </H.BenefitsItem>
            <H.BenefitsItem image={ImageTrainer}>
              <H.BenefitsItemTitle>
                Seu dinheiro <strong>Rápido e fácil</strong>
              </H.BenefitsItemTitle>
              <H.BenefitsItemContent>
                <H.BenefitsItemText>
                  Nossa plataforma de pagamento é uma das melhores do mercado,
                  possibilitando o direcionamento rápido e fácil dos pagamentos
                  para sua conta.
                </H.BenefitsItemText>
                {btnCreateAccount(false)}
              </H.BenefitsItemContent>
            </H.BenefitsItem>
          </H.BenefitsContent>
        </Container>
      </H.Benefits>

      <Footer />
    </>
  );
};

export default Home;
