/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// linha 235 navbar fixo estudante
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FaExchangeAlt } from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ImageUserPlaceholder } from '~/assets/icons';
import { useAuth } from '~/hooks/Auth';
import { useStudent } from '~/hooks/Student';
import { useToast } from '~/hooks/Toast';
import { MenuMobileItem } from '~/models/Common';

import { Logo, MenuMobile, MenuMobileToggle } from '..';

import { QueryProps } from '~/pages/Professor/MyWorkouts';
import StudentService, { ProfessionalProfile } from '~/services/StudentService';
import errorHandlerToToast from '~/utils/errorHandler';

import * as N from './styles';

const Navbar: React.FC = () => {
  const dropdownContainer = useRef<HTMLDivElement>(null);
  const { push, location } = useHistory();
  const { pathname } = useLocation();
  const { selectedProfessional, updateSelectedProfessional } = useStudent();

  const [, currentPath] = pathname.split('/');
  const { user, logout, publicName: studentPublicName } = useAuth();
  const [publicName, setPublicName] = useState<string>();
  const [nickName, setNickname] = useState<string>(user.name);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [avatarUser, setAvatarUser] = useState<string>();
  const [menuMobileStatus, setMenuMobileStatus] = useState(false);
  const [categoriesMenuOptions, setcategoriesMenuOptions] = useState<
    QueryProps[]
  >([]);
  const { addToast } = useToast();

  const handleGetcategoriesMenuOptions = async (): Promise<void> => {
    try {
      const response = await StudentService.getProfessionsStudentAssociatedProfessional(
        studentPublicName,
      );

      setcategoriesMenuOptions(
        response.data?.map(item => {
          return {
            label: item.description,
            queryName: item.description,
            to: `my-workouts?level=${item.description}`,
            category_id: item.id,
          };
        }),
      );
    } catch (error: any) {
      console.log(error);
      const messageToToast = errorHandlerToToast({ error });
      if (messageToToast) addToast(messageToToast);
    }
  };

  useEffect(() => {
    // handleGetcategoriesMenuOptions();

    if (user.role !== 'professional') {
      handleGetcategoriesMenuOptions();
    }
  }, [studentPublicName?.length !== 0]);

  const navBarOptions: MenuMobileItem[] = useMemo(() => {
    if (currentPath === 'professional-profiles') {
      return [];
    }
    return user.role === 'professional'
      ? [
          {
            label: 'Minhas aulas',
            to: '/professor/my-workouts',
          },
          {
            label: 'Meus cursos',
            to: '/professor/my-courses',
          },
          {
            label: 'Meu site',
            to: `/${publicName}`,
          },
          {
            label: 'Configurações',
            to: '/professor/config',
          },
          {
            label: 'Relatórios',
            to: '/professor/reports',
          },
        ]
      : [
          {
            label: 'Professores',
            to: '/professional-profiles',
          },
          {
            label: 'Configurações',
            to: '/student/config',
          },
        ];
  }, [user, currentPath, publicName]);

  const UserItemElement = (): JSX.Element =>
    user.role === 'professional' ? (
      <N.UserItem onClick={handleDropdownOpened}>
        <N.UserImageWrapper>
          <N.UserImage src={avatarUser} alt="User" />
        </N.UserImageWrapper>
        <N.UserItemNickname>{nickName}</N.UserItemNickname>
        <FiChevronDown size={20} />
      </N.UserItem>
    ) : (
      <N.UserItem onClick={handleDropdownOpened}>
        <N.UserItemNickname>{nickName}</N.UserItemNickname>
        <FiChevronDown size={20} />
      </N.UserItem>
    );

  const handleDropdownOpened = useCallback(() => {
    setDropdownOpen(!dropdownOpen);
  }, [dropdownOpen]);

  const handleLogout = useCallback(() => {
    logout();
    updateSelectedProfessional({} as ProfessionalProfile);
    push('/signin');
  }, [logout, push]);

  const handleToggleMenuMobile = useCallback(() => {
    setMenuMobileStatus(!menuMobileStatus);
  }, [menuMobileStatus]);

  useEffect(() => {
    if (user.role === 'professional') {
      setPublicName(user.professional.public_name);
      setNickname(user.professional.nick_name);
      setAvatarUser(user.avatar ?? ImageUserPlaceholder);
    }
  }, [user]);

  useEffect(() => {
    const pageClickEvent = (e: MouseEvent): void => {
      if (!dropdownContainer.current?.contains(e.target as Node)) {
        setDropdownOpen(prevState => !prevState);
      }
    };

    if (dropdownOpen) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [dropdownContainer, dropdownOpen, setDropdownOpen]);

  useEffect(() => {
    setMenuMobileStatus(false);
  }, [location.pathname]);

  useEffect(() => {
    const menuToggle = document.querySelector('[data-mobile="toggle"]');

    menuMobileStatus
      ? menuToggle?.classList.add('active')
      : menuToggle?.classList.remove('active');
  }, [menuMobileStatus]);

  return (
    <N.NavbarWrapper>
      <N.NavbarContainer>
        <Logo width={146} />
        <N.MobileSelectedProfessionalContainer>
          {selectedProfessional?.professional?.user?.avatar ? (
            <>
              <N.ImgContainer>
                <img
                  src={selectedProfessional?.professional?.user?.avatar}
                  alt="Professional avatar"
                />
              </N.ImgContainer>

              <N.ProfessionalInfoContainer>
                <h4>{selectedProfessional?.professional?.nick_name}</h4>
              </N.ProfessionalInfoContainer>
            </>
          ) : (
            <Logo width={146} />
          )}
        </N.MobileSelectedProfessionalContainer>

        {currentPath === 'professor' && (
          <>
            <N.NavbarContent>
              <N.NavMenu>
                <N.NavItem>
                  <N.NavItemLink
                    to="/professor/my-workouts?level=funcional"
                    exact
                    activeStyle={{ color: '#FDF5A6' }}
                  >
                    Minhas aulas
                  </N.NavItemLink>
                </N.NavItem>
                <N.NavItem>
                  <N.NavItemLink
                    to="/professor/my-courses"
                    exact
                    activeStyle={{ color: '#FDF5A6' }}
                  >
                    Meus cursos
                  </N.NavItemLink>
                </N.NavItem>
              </N.NavMenu>
              <N.NavMenu>
                <UserItemElement />
                {dropdownOpen && (
                  <N.DropdownWorkout ref={dropdownContainer}>
                    <N.NavMenu>
                      <N.NavItem>
                        <N.NavItemLink to={`/${publicName}`}>
                          Meu site
                        </N.NavItemLink>
                      </N.NavItem>
                      <N.NavItem>
                        <N.NavItemLink to="/professor/config">
                          Configurações
                        </N.NavItemLink>
                      </N.NavItem>
                      <N.NavItem>
                        <N.NavItemLink to="/professor/reports">
                          Relatórios
                        </N.NavItemLink>
                      </N.NavItem>
                      <N.NavItem onClick={handleLogout}>
                        <N.NavItemLink as="span">Sair</N.NavItemLink>
                      </N.NavItem>
                    </N.NavMenu>
                  </N.DropdownWorkout>
                )}
              </N.NavMenu>
            </N.NavbarContent>
            <MenuMobileToggle handleActiveMenuMobile={handleToggleMenuMobile} />
          </>
        )}
        {currentPath === 'professional-profiles' && (
          <>
            <N.NavbarContent>
              <N.NavMenu>
                <UserItemElement />
                {dropdownOpen && (
                  <N.DropdownWorkout ref={dropdownContainer}>
                    <N.NavMenu>
                      <N.NavItem onClick={handleLogout}>
                        <N.NavItemLink as="span">Sair</N.NavItemLink>
                      </N.NavItem>
                    </N.NavMenu>
                  </N.DropdownWorkout>
                )}
              </N.NavMenu>
            </N.NavbarContent>
            <MenuMobileToggle handleActiveMenuMobile={handleToggleMenuMobile} />
          </>
        )}
        {(currentPath === 'student' || currentPath === 'course-list') && (
          <>
            <N.NavbarContent>
              <N.NavMenu>
                <N.NavItem>
                  <N.NavItemLink
                    to="/professional-profiles"
                    exact
                    activeStyle={{ color: '#FDF5A6' }}
                  >
                    Professores
                  </N.NavItemLink>

                  <N.DropdownWorkoutStudent>
                    <N.NavMenu>
                      {categoriesMenuOptions?.map(item => (
                        <N.NavItem>
                          <N.NavItemLink
                            as={Link}
                            to={item.to}
                            key={item.category_id}
                          >
                            {item.label}
                          </N.NavItemLink>
                        </N.NavItem>
                      ))}
                    </N.NavMenu>
                  </N.DropdownWorkoutStudent>
                </N.NavItem>
              </N.NavMenu>

              <N.NavMenu>
                <UserItemElement />
                {dropdownOpen && (
                  <N.DropdownStudent ref={dropdownContainer}>
                    <N.NavMenu>
                      <N.NavItem>
                        <N.NavItemLink to={{ pathname: '/student/config' }}>
                          Configurações
                        </N.NavItemLink>
                      </N.NavItem>
                      <N.NavItem onClick={handleLogout}>
                        <N.NavItemLink as="span">Sair</N.NavItemLink>
                      </N.NavItem>
                    </N.NavMenu>
                  </N.DropdownStudent>
                )}
              </N.NavMenu>
            </N.NavbarContent>

            <MenuMobileToggle handleActiveMenuMobile={handleToggleMenuMobile} />
          </>
        )}

        <MenuMobile
          items={navBarOptions}
          menuMobileStatus={menuMobileStatus}
          setMenuMobileStatus={setMenuMobileStatus}
        >
          <N.NavItem onClick={handleLogout}>
            <N.NavItemLink as="span">Sair</N.NavItemLink>
          </N.NavItem>
        </MenuMobile>
      </N.NavbarContainer>

      {currentPath === 'course-list' && (
        <N.SelectedProfessorContainer>
          <N.ImgContainer>
            <img
              src={selectedProfessional?.professional?.user?.avatar}
              alt="Professional avatar"
            />
          </N.ImgContainer>

          <N.ProfessionalInfoContainer>
            <h4>{selectedProfessional?.professional?.nick_name}</h4>
            <p onClick={() => push('/professional-profiles')}>
              <FaExchangeAlt />
              Trocar de curso
            </p>
          </N.ProfessionalInfoContainer>
        </N.SelectedProfessorContainer>
      )}
    </N.NavbarWrapper>
  );
};

export default Navbar;
