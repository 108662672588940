import { InputHTMLAttributes } from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';

import { Input } from '~/components';

import 'react-day-picker/lib/style.css';

interface DatePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

function DatePicker({ name }: DatePickerProps): JSX.Element {
  return (
    <DayPickerInput
      component={(props: DatePickerProps) => <Input {...props} {...{ name }} />}
    />
  );
}

export default DatePicker;
