import styled, { css } from 'styled-components';

import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

import { GreenButton } from '../../styles/themes/green-button';

interface CourseProps {
  status: string;
}

export const Course = styled.article<{ displayBuyButton?: boolean }>`
  width: 100%;
  border-radius: 3px;
  padding: var(--gap-xs);
  cursor: pointer;
  transition: background 0.3s;
  position: relative;
  width: 100%;

  ${props =>
    props.displayBuyButton &&
    css`
      padding-bottom: calc(var(--gap) + 64px);
    `}

  &:hover {
    background: #fff0dc 0% 0% no-repeat padding-box;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: 0;
    max-width: 300px;

    &:hover,
    &:focus {
      background: none;
    }
  }
`;

export const MediaContainer = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white;

  @media (max-width: ${breakpoints.small}) {
    height: 200px;
  }
`;

export const CourseImage = styled.img`
  width: auto;
  height: 100%;
`;

export const CourseVideo = styled.video`
  width: auto;
  height: 100%;
`;

export const CourseLink = styled.button`
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  padding: 10px;
`;

export const CourseContent = styled.article`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const CourseStatus = styled.span<CourseProps>`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${colors.green};
  text-transform: uppercase;
  letter-spacing: 0.52px;

  ${props =>
    props.status === 'Inativo' &&
    css`
      color: ${colors.red};
    `}
`;

export const CourseTitle = styled.h3`
  letter-spacing: 0.8px;
  color: ${colors.black} !important;
  font-size: 1.8rem;
  margin-bottom: var(--gap);
`;

export const CoursePrice = styled.span`
  letter-spacing: 0px;
  color: ${colors.black};
  font-size: 1.6rem;
  margin-bottom: var(--gap);
`;

export const CourseStudentsCount = styled.span`
  color: ${colors.gray};
  font-size: 1.2rem;
  font-weight: 400;
`;

export const CourseBuyButton = styled(GreenButton)`
  position: absolute;
  bottom: 0;
  max-width: 92%;

  @media (max-width: ${breakpoints.small}) {
    max-width: 309px;
    width: 100%;
    margin: var(--gap-xs) auto 0 auto;
    position: initial;
  }
`;
