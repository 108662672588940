import { isCep, isCpf, isCnpj } from 'validator-brazil';
import * as Yup from 'yup';

import { adjustSize, regExpPublicName } from './yupHelpers';

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'validPublicName',
  (
    message:
      | string
      | undefined = 'Não é possível utilizar estes caracteres no nome',
  ) => {
    return Yup.string().matches(regExpPublicName, {
      message,
    });
  },
);

Yup.addMethod<Yup.NumberSchema>(Yup.number, 'validCPF', (message: string) => {
  const tool = Yup.number();
  return tool.test({
    message,
    test: value => {
      if (tool.isType(value) && value) {
        const cpf = value.toString();
        const cpfPadding = adjustSize(cpf, 11);
        return isCpf(cpfPadding);
      }

      return false;
    },
  });
});

Yup.addMethod<Yup.NumberSchema>(Yup.number, 'validCEP', (message: string) => {
  const tool = Yup.number();
  return tool.test({
    message,
    test: value => {
      const isValueType = tool.isType(value);

      if (!(isValueType || value)) {
        return true;
      }

      if (isValueType && value) {
        const cep = value.toString();
        const cepPadding = adjustSize(cep, 8);
        return isCep(cepPadding);
      }

      return false;
    },
  });
});

Yup.addMethod<Yup.NumberSchema>(Yup.number, 'validCNPJ', (message: string) => {
  const tool = Yup.number();
  return tool.test({
    message,
    test: value => {
      if (tool.isType(value) && value) {
        const cnpj = value.toString();
        const cnpjPadding = adjustSize(cnpj, 14);
        return isCnpj(cnpjPadding);
      }

      return false;
    },
  });
});
