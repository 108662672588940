import { useEffect, useState } from 'react';

import { BiCheckSquare, BiCheckbox } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import SwiperCore, {
  Autoplay,
  Lazy,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper';

import 'swiper/swiper-bundle.css';

import { ImageLogo } from '~/assets/icons';
import { Button } from '~/components';
import { useToast } from '~/hooks/Toast';
import { IProfessionalPlansAdhesion } from '~/models/ProfessionalPlansAdhesion';
import ProfessorService from '~/services/ProfessorService';

import { Container, Item, List, PlanBox } from './styles';

SwiperCore.use([Pagination, Navigation, Scrollbar, Autoplay, Lazy]);

interface routeParams {
  publicname: string;
}

const BecomeAProfessional: React.FC = () => {
  const [plans, setPlans] = useState<IProfessionalPlansAdhesion[]>([]);
  const { addToast } = useToast();
  const [currentPlan, setcurrentPlan] = useState<IProfessionalPlansAdhesion>();
  const [currency, setCurrency] = useState<string>();

  const handlerGetProfessionalPlansAdhesion = async (): Promise<void> => {
    try {
      const response = await ProfessorService.getallProfessionalPlansAdhesion();
      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handlerGetProfessionalPlansAdhesion();
  }, []);

  useEffect(() => {
    if (currentPlan && currentPlan.amount) {
      setCurrency(currentPlan.amount.toFixed(2).replace('.', ','));
    }
  }, [currentPlan]);

  useEffect(() => {
    const freePlan = plans.filter(plan => plan.name === 'Gratuito')[0];
    setcurrentPlan(freePlan);
  }, [plans]);

  return (
    <Container>
      <img src={ImageLogo} alt="Logo" />
      <h1>Torne-se Professor</h1>
      <h6>Selecione seu plano.</h6>
      <h3>Planos de adesão</h3>
      <PlanBox>
        <List>
          {plans?.map(plan => {
            return (
              <Item onClick={() => setcurrentPlan(plan)}>
                {plan === currentPlan ? (
                  <BiCheckSquare size={20} />
                ) : (
                  <BiCheckbox size={20} />
                )}
                Plano {plan.name} - Até {plan.number_videos} vídeos.
              </Item>
            );
          })}
        </List>

        <div>
          <h5>
            {currentPlan?.amount ? (
              <>
                {'R$ '}
                <strong>{currency}</strong>
                /mês
              </>
            ) : (
              'Gratuito'
            )}
          </h5>
          <Link
            to={{
              pathname: '/signup',
              state: {
                planId: currentPlan?.id,
                planAmount: currentPlan?.amount,
                planName: currentPlan?.name,
                numberVideos: currentPlan?.number_videos,
              },
            }}
          >
            <Button>Selecionar</Button>
          </Link>
        </div>
      </PlanBox>
      {/* <h3>Cursos</h3>
      <CourseBox>
        {courses?.data?.length > 0 ? (
          <Swiper
            spaceBetween={57}
            slidesPerView={2}
            allowSlideNext
            allowSlidePrev
            draggable
            navigation
            lazy
            breakpoints={breakPoints}
          >
            {coursesMemo}
          </Swiper>
        ) : (
          <h2>Nenhum curso encontrado.</h2>
        )}
      </CourseBox> */}
    </Container>
  );
};

export default BecomeAProfessional;
