import styled, { css } from 'styled-components';

import { ImageBackground } from '~/assets/images';
import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

interface CourseProps {
  status?: string;
}

export const Footer = styled.footer`
  grid-column: 1 / -1;
`;

export const Presentation = styled.header`
  background: url(${ImageBackground}) 0% / cover;
  padding: var(--gap-md) 0;
`;

export const CourseDetails = styled.article`
  display: flex;
  gap: var(--gap-sm);

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CourseDetailsContent = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap);

  @media (max-width: ${breakpoints.small}) {
    align-items: center;
  }
`;

export const CourseDetailsStatus = styled.span<CourseProps>`
  align-self: flex-start;
  letter-spacing: 0.88px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  background: ${colors.green} 0% 0% no-repeat padding-box;
  border-radius: 50px;
  padding: calc(var(--gap) / 2) var(--gap-xs);

  ${props =>
    props.status === 'Inativo' &&
    css`
      background: ${colors.red} 0% 0% no-repeat padding-box;
    `}

  @media (max-width: ${breakpoints.small}) {
    text-align: center;
    align-self: center;
    font-size: 1.2rem;
  }
`;

export const CourseDetailsPrice = styled.span`
  font-size: 2rem;
  color: white;
  margin-bottom: var(--gap);

  > strong {
    font-size: 2.4rem;
    font-weight: 600;
    color: white;
  }

  @media (max-width: ${breakpoints.small}) {
    text-align: center;
    font-size: 1.8rem;
  }
`;

export const CourseDetailsStudentsCount = styled.span`
  font-size: 1.8rem;
  color: white;

  @media (max-width: ${breakpoints.small}) {
    text-align: center;
    font-size: 1.2rem;
  }
`;

export const CourseDetailsTitle = styled.h2`
  letter-spacing: 1.52px;
  font-size: 3.6rem;
  color: white;

  @media (max-width: ${breakpoints.small}) {
    text-align: center;
    font-size: 2.4rem;
  }
`;

export const ImageContainer = styled.div`
  width: auto;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background-color: white;
`;

export const CourseDetailsImage = styled.img`
  width: auto;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
`;

export const CourseDetailsVideo = styled.video`
  width: 300px;
  height: 250px;

  @media (max-width: ${breakpoints.small}) {
    height: 320px;
  }
`;

export const Interaction = styled.div`
  max-width: 393px;
  transition: background 0.3s;
  background: none;
  padding: 16px;
  position: relative;

  &:hover {
    cursor: pointer;
    background: #fff0dc 0% 0% no-repeat padding-box;
  }

  > svg {
    position: absolute;
    margin-left: 370px;
    margin-top: 5px;
    border-radius: 50%;
    background: #2c2c2c 0% 0% no-repeat padding-box;
    opacity: 0.67;
    margin-right: 15px;
    cursor: pointer;
  }

  div {
    span {
      text-transform: uppercase;
      font-size: 13px;
      color: #2c2c2c;

      strong {
        letter-spacing: 0.52px;
        color: #b88746;
      }
    }

    h6 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: bold;
    }

    p {
      color: #636363;
      font-size: 16px;
      max-width: 393px;
    }

    button {
      background: #2c2c2c 0% 0% no-repeat padding-box;
      margin-top: 15px;
      margin-left: 20px;
    }
  }

  @media (max-width: 768.9px) {
    max-width: 310px;

    img {
      max-width: 290px;
    }

    svg {
      margin-left: 240px;
    }

    div {
      button {
        margin-left: 0;
      }
    }
  }
`;
