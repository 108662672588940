import { FC, useMemo } from 'react';

import ReactModal from 'react-modal';

import * as M from './styles';

import { MenuMobileProps } from './typings';

const MenuMobile: FC<MenuMobileProps> = ({
  items,

  menuMobileStatus,
  setMenuMobileStatus,
  children,
}) => {
  const menuMobileItemsMemo = useMemo(
    () =>
      items?.map(({ ...itemProps }) => (
        <M.MenuMobileItem
          exact
          activeStyle={{ color: '#FDF5A6' }}
          key={itemProps.label}
          {...itemProps}
        >
          {itemProps.label}
        </M.MenuMobileItem>
      )),
    [items],
  );

  return (
    <ReactModal
      isOpen={menuMobileStatus}
      shouldCloseOnOverlayClick
      onRequestClose={() => {
        setMenuMobileStatus(false);
      }}
      ariaHideApp={false}
      style={{ ...M.MenuMobileStyles }}
    >
      {menuMobileItemsMemo}
      {children}
    </ReactModal>
  );
};

export default MenuMobile;
