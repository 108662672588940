import styled from 'styled-components';

import { Container } from '~/styles/objects/container';
import { colors } from '~/styles/settings/colors';

import { breakpoints } from '../../../../styles/tools/breakpoints';

export const LivesContainer = styled(Container)`
  padding: var(--gap-xl) 0;

  > button {
    background: ${colors.green} 0% 0% no-repeat padding-box;
    margin-top: 50px;
    margin-bottom: 80px;
    max-width: 507px;
    padding: 24px;
    border-radius: 60px;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: var(--gap-lg) 0;
  }
`;

export const LivesTitle = styled.h2`
  font-size: 42px;
  font-weight: bold;

  text-align: center;
  align-self: center;
  letter-spacing: 8.4px;
  text-transform: uppercase;

  display: flex;
  align-items: center;

  > svg {
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 422px);
  column-gap: 10px;
  row-gap: 30px;
  justify-content: center;
  margin-bottom: 25px;

  @media (max-width: 768.9px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 100%;
  }
`;

export const Workout = styled.div`
  max-width: 422px;
  padding: 16px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #fff0dc 0% 0% no-repeat padding-box;
  }

  img {
    max-width: 393px;
    width: 100%;
    height: 212px;
  }

  svg {
    position: absolute;
    margin-left: 370px;
    margin-top: 5px;
    border-radius: 50%;
    background: #2c2c2c 0% 0% no-repeat padding-box;
    opacity: 0.67;
    margin-right: 15px;
  }

  div {
    span {
      text-transform: uppercase;
      font-size: 13px;
      color: #2c2c2c;
    }

    h6 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: bold;
    }

    p {
      color: #636363;
      font-size: 16px;
      max-width: 393px;
    }
  }

  @media (max-width: 768.9px) {
    max-width: 310px;

    img {
      max-width: 290px;
    }

    svg {
      margin-left: 270px;
    }
  }
`;
