// 8 multiples gaps

import { createGlobalStyle } from 'styled-components';

export const Gaps = createGlobalStyle`
  :root {
    --gap: .8rem; // 8px
    --gap-xs: 1.6rem; // 16px
    --gap-sm: 2.4rem; // 24px
    --gap-md: 3.2rem; // 32px
    --gap-lg: 6.4rem; // 64px
    --gap-xl: 12.8rem; // 128px
  }
`;
