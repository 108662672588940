import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { serialize } from 'object-to-formdata';
import * as Yup from 'yup';

import {
  Button,
  ButtonBlack,
  ButtonLink,
  Input,
  InputLink,
  InputVideos,
  Modal,
  ModalRedirect,
  Textarea,
} from '~/components';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { ModalLike, SubmitHandler, VideoError } from '~/models/Common';
import Video from '~/models/Video';
import { cleanRequests } from '~/services/api';
import ProfessorService from '~/services/ProfessorService';
import errorHandlerToToast from '~/utils/errorHandler';
import getValidationErrors from '~/utils/getValidationErrors';
import handleState from '~/utils/handleColorButtonBlack';
import handleChangeFile, { handleTypeFile } from '~/utils/handleFile';

import { CreateVideo, Label, ModalContent } from './styles';

interface NewVideoProps extends ModalLike {
  video?: Video;
  courseId?: number;
  setNeedUpdate?: (arg: boolean) => void;
}

interface VideoForm {
  video: File | undefined;
  image: File;
}

interface VideoFormData extends VideoForm {
  title: string;
  description: string;
  link?: string;
}

const NewVideo: React.FC<NewVideoProps> = ({
  courseId,
  modalIsOpen,
  setModalIsOpen,
  setNeedUpdate,
  video,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { setLoading, setPecent } = useLoading();
  const [videoState, setVideoState] = useState<Partial<VideoForm>>({});
  const [videoErr, setVideoErr] = useState<VideoError>({
    isCoverErr: false,
    isVideoErr: false,
  });
  const [modalRedirectVisible, setModalRedirectVisible] = useState(false);
  const [showLinkInput, setShowLinkInput] = useState(false);

  /** @todo colocar o new need update aqui */

  useEffect(() => {
    if (!modalIsOpen) {
      setVideoState({});
      setVideoErr({ isCoverErr: false, isVideoErr: false });
      // formRef.current?.reset();
    }
    return cleanRequests;
  }, [modalIsOpen]);

  const handleSubmit: SubmitHandler<VideoFormData> = useCallback(
    async preData => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const data: VideoFormData = {
          ...preData,
          ...videoState,
        };

        if (data.link) data.video = undefined;

        const schema = Yup.object().shape({
          title: video
            ? Yup.mixed()
            : Yup.string().required('Título obrigatório'),
          description: video
            ? Yup.mixed()
            : Yup.string().required('Descrição obrigatória.'),
          video: video || showLinkInput ? Yup.mixed() : Yup.mixed().required(),
          image: video ? Yup.mixed() : Yup.mixed().required(),
          link: showLinkInput
            ? Yup.string().url().required('Link obrigatório')
            : Yup.string().url().nullable(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = serialize<VideoFormData>(data);

        if (courseId === undefined) throw new Error('courseId problem');

        const response: any = video?.id
          ? await ProfessorService.videoUploadEditCourse(
              { videoId: video.id, courseId },
              formData,
              setPecent,
            )
          : await ProfessorService.videoUploadCourse(
              courseId,
              formData,
              setPecent,
            );

        if (response?.response?.status > 300) {
          addToast({
            title: response.response.data.message,
            type: 'error',
          });
          throw new Error(response.response.data.message);
        }

        setModalIsOpen(false);
        if (setNeedUpdate) setNeedUpdate(true);

        setLoading(false);

        addToast({ title: 'Video enviado com sucesso!', type: 'success' });
      } catch (error) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const {
            video: isVideoErr,
            image: isImageErr,
            ...errors
          } = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          setVideoErr({
            isCoverErr: !!isImageErr,
            isVideoErr: !!isVideoErr,
          });
        } else if (error instanceof Error) {
          setModalIsOpen(false);

          addToast({
            title: error.message,
            type: 'error',
          });
        }
      }
    },
    [
      addToast,
      courseId,
      setLoading,
      setModalIsOpen,
      setNeedUpdate,
      setPecent,
      video,
      videoState,
      showLinkInput,
    ],
  );

  const handleChange = useCallback(
    (type: handleTypeFile, e: ChangeEvent<HTMLInputElement>): void => {
      const newFile = handleChangeFile(e);
      try {
        if (newFile) {
          setVideoState({
            ...videoState,
            [type]: newFile.file,
          });
        }
      } catch (error) {
        const responseErr = errorHandlerToToast({ error, formRef });
        if (responseErr) {
          addToast(responseErr);
        }
      }
    },
    [videoState, addToast],
  );

  return (
    <>
      <Modal {...{ setModalIsOpen, modalIsOpen }}>
        <ModalContent>
          <h2>{video ? 'Editar vídeo' : 'Novo vídeo'}</h2>
          <CreateVideo>
            <Form
              initialData={video}
              ref={formRef}
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <Label htmlFor="title">Título:</Label>
              <Input id="title" name="title" />
              <Label htmlFor="description">Descrição:</Label>
              <Textarea id="description" name="description" />
              {!showLinkInput && (
                <>
                  <InputVideos
                    name="video"
                    className="wrapperButton"
                    formRef={formRef}
                    stateUpload={handleState({
                      hasValue:
                        videoState.video !== undefined ||
                        video?.video !== undefined,
                      error: videoErr.isVideoErr,
                    })}
                    setFileFunction={(type, newFile) => {
                      setVideoState({
                        ...videoState,
                        [type]: newFile.file,
                      });
                    }}
                    showLinkInput={showLinkInput}
                    setShowLinkInput={setShowLinkInput}
                  >
                    {videoState.video || video?.video
                      ? 'Vídeo carregado'
                      : 'Carregar vídeo'}
                  </InputVideos>
                  <ButtonLink setShowLinkInput={setShowLinkInput} />
                </>
              )}
              {showLinkInput && (
                <InputLink setShowLinkInput={setShowLinkInput} />
              )}
              <label className="wrapperButton" htmlFor="image">
                <ButtonBlack
                  stateUpload={handleState({
                    hasValue:
                      videoState.image !== undefined ||
                      video?.image !== undefined,
                    error: videoErr.isCoverErr,
                  })}
                >
                  {videoState.image || video?.image
                    ? 'Capa carregada'
                    : 'Carregar capa'}
                </ButtonBlack>
                <input
                  type="file"
                  id="image"
                  accept="image/jpeg,image/png"
                  hidden
                  onChange={handleChange.bind(this, 'image')}
                />
              </label>
              <p>Dê preferência para imagens horizontais na capa.</p>
              <Button type="submit">Salvar</Button>
            </Form>
          </CreateVideo>
        </ModalContent>
      </Modal>
      <ModalRedirect
        modalIsOpen={modalRedirectVisible}
        setModalIsOpen={setModalRedirectVisible}
      />
    </>
  );
};

export default NewVideo;
