//

import { useCallback, useEffect, useState } from 'react';

import {
  CategoriesMenu,
  Header,
  Paginate,
  ToggleMenu,
  Workouts,
} from '~/components';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { StudentLayout } from '~/layout';
import { PageOptionsRequest } from '~/models/Common';
import { Workouts as IWorkouts, WorkoutsData } from '~/models/Workouts';
import { QueryProps } from '~/pages/Professor/MyWorkouts';
import { cleanRequests } from '~/services/api';
import StudentService from '~/services/StudentService';
import WorkoutsService from '~/services/WorkoutsService';
import errorHandlerToToast from '~/utils/errorHandler';
import { useQuery } from '~/utils/query';

import {
  WorkoutContainer as Container,
  NoCategoryWorkoutTitle,
} from './styles';

import ViewWorkout from './ViewWorkout';

export interface routeParams {
  publicname: string;
}

const MyWorkouts: React.FC = () => {
  const query = useQuery().get('level') as string | null;
  const { publicName } = useAuth();
  const { addToast } = useToast();
  const [categories, setCategories] = useState<QueryProps[]>([]);
  const [menuVisible, setMenuVisible] = useState(false);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [workouts, setWorkouts] = useState<WorkoutsData>({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: 0,
  });
  const [lives, setLives] = useState<WorkoutsData>({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: 0,
  });
  const [selectedworkout, setSelectedWorkout] = useState<IWorkouts>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [livesPageOptions, setLivesPageOptions] = useState<PageOptionsRequest>({
    page: 1,
    perpage: 4,
  });
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
    perpage: 4,
  });

  const { user } = useAuth();
  const { setLoading } = useLoading();

  const handleGetcategoriesId = async (): Promise<void> => {
    try {
      if (publicName?.length !== 0) {
        const response = await StudentService.getProfessionsStudentAssociatedProfessional(
          publicName,
        );
        const filteredId = response.data.filter(
          item => item.description === query,
        );
        setCategories(
          response.data?.map(item => {
            return {
              label: item.description,
              queryName: item.description,
              to: `my-workouts?level=${item.description}`,
              category_id: item.id,
            };
          }),
        );
        setCategoryId(filteredId?.length === 0 ? 0 : filteredId[0].id);
      }
    } catch (error: any) {
      const messageToToast = errorHandlerToToast({ error });
      if (messageToToast) addToast(messageToToast);
    }
  };
  useEffect(() => {
    handleGetcategoriesId();
  }, [publicName?.length !== 0, query]);

  const getStudentWorkouts = useCallback(async () => {
    setLoading(true);
    try {
      if (user.role === 'student' && categoryId) {
        const myLives = await WorkoutsService.getStudentLives(
          user.student.id,
          categoryId,
          livesPageOptions,
        );

        setLives(myLives.data);

        const myWorkouts = await WorkoutsService.getStudentWorkouts({
          studentId: user.student.id,
          categoryId,
          ...pageOptions,
        });
        setWorkouts(myWorkouts.data);
      }
    } catch (error: any) {
      console.log(error);
      const messageToToast = errorHandlerToToast({ error });
      if (messageToToast) addToast(messageToToast);
    } finally {
      setLoading(false);
    }
  }, [categoryId, pageOptions, livesPageOptions, setLoading, user]);

  useEffect(() => {
    getStudentWorkouts();
    return cleanRequests;
  }, [getStudentWorkouts, pageOptions]);

  const handleOpenModal = useCallback((workout: IWorkouts) => {
    if (!workout.is_converted) {
      addToast({
        title: 'O video estará disponível em breve!',
        type: 'info',
      });
      return;
    }

    setModalIsOpen(true);
    setSelectedWorkout(workout);
  }, []);

  return (
    <StudentLayout>
      <Container>
        <Header headerTitle="Minhas Aulas" />
        <div id="showonmobile">
          <ToggleMenu
            title="Categorias"
            alignItems="center"
            setContentVisible={setMenuVisible}
            contentVisible={menuVisible}
          >
            <CategoriesMenu
              handleClose={() => setMenuVisible(false)}
              options={categories}
              query={query ?? ''}
            />
          </ToggleMenu>
        </div>
        <div id="hideonmobile">
          <CategoriesMenu
            handleClose={setCategoryId}
            options={categories}
            query={query ?? ''}
          />
        </div>
        {/* Lives */}
        {lives && lives.data?.length > 0 && (
          <>
            <Header headerTitle="Ao Vivo" containerStyle={{ padding: 0 }} />
            <Workouts
              workouts={lives ? lives.data : []}
              handleOpenWorkoutModal={handleOpenModal}
              levelId={Number(categoryId) ?? 1}
            />
            <footer style={{ margin: 'var(--gap-md) 0' }}>
              <Paginate
                pageCount={lives.lastPage}
                pageRangeDisplayed={lives.page}
                {...{ setPageOptions: setLivesPageOptions }}
              />
            </footer>
          </>
        )}

        {/* Trainings: */}
        {workouts && workouts.data?.length > 0 && (
          <>
            <Header
              headerTitle="Aulas Gravadas"
              containerStyle={{ padding: 0 }}
            />
            <Workouts
              workouts={workouts ? workouts.data : []}
              handleOpenWorkoutModal={handleOpenModal}
              levelId={Number(categoryId)}
            />
          </>
        )}
        {workouts.total === 0 && (
          <NoCategoryWorkoutTitle>
            Você ainda não possui nenhuma aula cadastrada desta categoria.
          </NoCategoryWorkoutTitle>
        )}
        <footer style={{ margin: 'var(--gap-md) 0' }}>
          <Paginate
            pageCount={workouts.lastPage}
            pageRangeDisplayed={workouts.page}
            {...{ setPageOptions }}
          />
        </footer>
      </Container>

      <ViewWorkout
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        workout={selectedworkout}
      />
    </StudentLayout>
  );
};

export default MyWorkouts;
