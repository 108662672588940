import React from 'react';

import { Modal } from '..';

import { Container } from './styles';

interface ModalRedirectProps {
  modalIsOpen: boolean;
  setModalIsOpen: (visibility: boolean) => void;
}

const ModalRedirect: React.FC<ModalRedirectProps> = ({
  modalIsOpen,
  setModalIsOpen,
}) => {
  return (
    <Modal {...{ modalIsOpen, setModalIsOpen }}>
      <Container>
        <h2>Esse vídeo é muito longo!</h2>
        <h3>Sugerimos que tente comprimí-lo usando o site abaixo.</h3>
        <a
          href="https://www.fastreel.com/pt/video-compressor.html "
          rel="noreferrer noopener"
          target="_blank"
        >
          Clique aqui
        </a>
      </Container>
    </Modal>
  );
};

export default ModalRedirect;
