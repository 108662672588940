import styled from 'styled-components';

export const Container = styled.div``;

export const Text = styled.p`
  font-size: 15px;
  margin-left: 15px;
  padding: 18px;
  border-bottom: 2px solid white;
  color: #c2c2c2;
  font-weight: 400;
  width: 90%;
  line-clamp: 1;
`;
