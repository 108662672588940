import styled, { css } from 'styled-components';

interface ButtonProps {
  isOutline?: boolean;
  active?: boolean;
  IsGray?: boolean;
  size?: string;
}

export const Container = styled.button<ButtonProps>`
  background: ${props => (props.IsGray ? '#575757' : '#b88746')};
  box-shadow: 0px 3px 6px #57575729;
  border-radius: 60px;
  text-align: center;
  color: #fff;
  padding: 12px;
  max-width: ${props => (props.size ? props.size : '300px')};
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  height: 50px;

  ${props =>
    props.isOutline &&
    css`
      background: transparent;
      border: 2px solid #fdf5a6;
      box-shadow: 0px 3px 6px #57575729;
      color: #fff;
    `}

  ${props =>
    props.active &&
    css`
      background: #2c2c2c;
      color: #fff !important;
      font-weight: bold;
      border: 0 !important;
    `}
`;
