import { ReactElement, useCallback } from 'react';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Paginate, { ReactPaginateProps } from 'react-paginate';
import { v4 as uuidV4 } from 'uuid';

import {
  IOnPageChangeProps,
  ISalesReport,
  ISortTableProps,
} from '~/models/Reports';
import { formatTableCell, translateTable } from '~/utils/formatTable';

import {
  PaginateWrapper,
  TableBody,
  TableCell,
  TableEl,
  TableFooter,
  TableHeader,
  TableHeaderButton,
  TableHeaderButtons,
  TableHeaderCell,
  TableHeaderCellContent,
  TableRow,
  Wrapper,
} from './styles';

interface ITableProps {
  reportData: ISalesReport[];
  updateTable({ selected }: IOnPageChangeProps): Promise<void>;
  pageCount: number;
  sortTable({ order, column }: ISortTableProps): void;
}

const Table = ({
  reportData,
  updateTable,
  pageCount,
  sortTable,
}: ITableProps): ReactElement => {
  const handlePageClick = useCallback<
    Required<ReactPaginateProps>['onPageChange']
  >(
    ({ selected }) => {
      updateTable({ selected: selected + 1 });
    },
    [updateTable],
  );

  return (
    <>
      <section>
        {reportData?.length ? (
          <Wrapper>
            <TableEl>
              <TableHeader>
                <TableRow>
                  {Object.keys(reportData[0])?.map(cell => (
                    <TableHeaderCell key={cell}>
                      <TableHeaderCellContent>
                        {translateTable(cell)}
                        <TableHeaderButtons>
                          <TableHeaderButton>
                            <FaChevronUp
                              type="button"
                              style={{ color: '#fff' }}
                              onClick={() => {
                                sortTable({ order: 'asc', column: cell });
                              }}
                            />
                          </TableHeaderButton>
                          <TableHeaderButton>
                            <FaChevronDown
                              type="button"
                              style={{ color: '#fff' }}
                              onClick={() => {
                                sortTable({ order: 'desc', column: cell });
                              }}
                            />
                          </TableHeaderButton>
                        </TableHeaderButtons>
                      </TableHeaderCellContent>
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {reportData?.map(reportRow => (
                  <TableRow key={uuidV4()}>
                    {Object.entries(reportRow)?.map(cell => (
                      <TableCell key={uuidV4()}>
                        {formatTableCell(cell)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </TableEl>
          </Wrapper>
        ) : (
          <h1>Sem dados.</h1>
        )}
        <TableFooter>
          <PaginateWrapper>
            <Paginate
              {...{ pageCount }}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              previousLabel="Anterior"
              nextLabel="Próximo"
              onPageChange={handlePageClick}
              containerClassName="pagination"
              previousClassName="paginationItem paginationPrevious"
              nextClassName="paginationItem"
              disabledClassName="paginationLinkDisabled"
              activeClassName="paginationLinkActive"
              pageClassName="paginationLink paginationItem"
            />
          </PaginateWrapper>
        </TableFooter>
      </section>
    </>
  );
};

export default Table;
