/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import * as S from './styles';

import { Input } from '..';

interface IInputLinkProps {
  setShowLinkInput: (state: boolean) => void;
}

interface IButtonLinkProps {
  setShowLinkInput: (state: boolean) => void;
}

const InputLink: React.FC<IInputLinkProps> = ({ setShowLinkInput }) => {
  return (
    <S.Container>
      <h1>
        Não é permitido carregar vídeos maiores que 4GB direto do seu
        dispositivo.
      </h1>
      <h2>
        Faça upload do vídeo no Drive, Youtube, Vimeo ou outro e insira o link
        abaixo ou{' '}
        <span
          onClick={() => {
            setShowLinkInput(false);
          }}
        >
          clique aqui
        </span>{' '}
        para carregar um vídeo
      </h2>
      <h3>
        <strong>*Importante:</strong>
        <br />
        1 - Se o seu vídeo foi gravado pelo iPhone ou em outro formato que não seja MP4, você precisa mudar o formato para MP4 antes de enviar. Para isso, você pode usar um programa como o CapCut, que funciona no navegador, no Android e no IOS. É só abrir o seu vídeo no CapCut e exportar na qualidade que você quiser. O CapCut vai gerar um arquivo em MP4 para você submeter ao Seu.Coach.
        <br />
        2 - Se você for enviar seu vídeo por link, verifique se ele está público e sem restrições de acesso. Assim, a plataforma vai conseguir carregar o seu vídeo sem problemas.
        <br />
        3 - Depois que a plataforma carregar o seu vídeo, você pode apagá-lo do Drive, Vimeo, iCloud, Youtube, etc.
        <br />
        4 - Só é possível enviar vídeos maiores que 4GB por link. Não tente enviar um arquivo grande pelo upload direto do computador.


      </h3>
      <label htmlFor="url">Link do Vídeo</label>
      <Input name="link" id="url" type="url" style={{ width: '100%' }} />
    </S.Container>
  );
};

export const ButtonLink: React.FC<IButtonLinkProps> = ({
  setShowLinkInput,
}) => {
  return (
    <S.ButtonContainer>
      <button
        type="button"
        onClick={() => {
          setShowLinkInput(true);
        }}
      >
        Inserir vídeo por link
      </button>
    </S.ButtonContainer>
  );
};

export default InputLink;
