import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useCallback,
  ChangeEvent,
  RefObject,
} from 'react';

import { FormHandles, useField } from '@unform/core';

import { ButtonBlack } from '~/components';
import { useToast } from '~/hooks/Toast';
import { ButtonBlackColor } from '~/models/Common';
import errorHandlerToToast from '~/utils/errorHandler';
import handleChangeFile, { handleTypeFile } from '~/utils/handleFile';
import validateMediaType from '~/utils/validateMediaType';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  stateUpload?: ButtonBlackColor;
  setFileFunction: (type: handleTypeFile, newFile: any) => void;
  formRef: RefObject<FormHandles>;
  className?: string;
  accept?: string;
  isCourseVideo?: boolean;
  showLinkInput: boolean;
  setShowLinkInput: (state: boolean) => void;
}

const InputVideos: React.FC<InputProps> = ({
  name,
  setFileFunction,
  stateUpload,
  formRef,
  children,
  className,
  accept,
  isCourseVideo,
  showLinkInput,
  setShowLinkInput,
}) => {
  const inputRef = useRef<HTMLInputElement>(null); // HTMLInputElement - vai dar ao inputRef as propriedades de um input

  const { fieldName, registerField } = useField(name);

  const MAX_VIDEO_SIZE = 4 * 1073741824; // 4GB

  const { addToast } = useToast();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleUploadMedia = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newFile = handleChangeFile(e);
      let type: handleTypeFile;

      try {
        if (newFile) {
          if (/^image/g.test(newFile.file.type)) {
            type = 'image';
          } else if (/^video/g.test(newFile.file.type)) {
            type = 'video';
          } else if (/^file/g.test(newFile.file.type)) {
            type = 'file';
          } else {
            throw new Error('Problema com tipo da media.');
          }

          validateMediaType(type.toString(), newFile.file.name);
          if (newFile.file.size > MAX_VIDEO_SIZE) {
            throw new Error('Esse vídeo ultrapassa o tamanho permitido.');
          }
          setFileFunction(type, newFile);
        }
      } catch (error: any) {
        const responseErr = errorHandlerToToast({ error, formRef });
        if (responseErr) {
          addToast(responseErr);
          if (
            responseErr.title === 'Esse vídeo ultrapassa o tamanho permitido.'
          ) {
            setShowLinkInput(true);
          }
        }
        // if (error instanceof Error) {
        //   addToast({
        //     title: error.message,
        //     type: 'error',
        //   });
        // }
      }
    },
    [formRef, addToast, MAX_VIDEO_SIZE, setFileFunction, setShowLinkInput],
  );

  if (isCourseVideo) {
    return (
      <>
        <input
          type="file"
          accept="image/jpeg, image/png, video/mp4, video/x-msvideo, video/quicktime"
          id={name}
          title="Carregar Foto"
          onChange={handleUploadMedia.bind(this)}
        />
      </>
    );
  }

  return (
    <>
      <label htmlFor="video" className={className}>
        <ButtonBlack stateUpload={stateUpload}>{children}</ButtonBlack>
        <input
          type="file"
          id="video"
          accept={accept || 'video/mp4, video/x-msvideo, video/quicktime'}
          hidden
          onChange={handleUploadMedia.bind(this)}
        />
      </label>
    </>
  );
};

export default InputVideos;
