import { AxiosPromise } from 'axios';

import { IPagination, ISalesReport } from '~/models/Reports';

import api from './api';

const createQuery = (
  name?: string,
  initial_date?: string,
  final_date?: string,
): string => {
  let query = '?';
  if (name) {
    query = `${query}name=${name}&`;
  }
  if (initial_date) {
    query = `${query}initial_date=${initial_date}&`;
  }
  if (final_date) {
    query = `${query}final_date=${final_date}`;
  }
  return query === '?' ? '' : query;
};

interface IGetSalesReportsProps {
  professional_id: number;
  page?: number;
  name?: string;
  initial_date?: string;
  final_date?: string;
}

export default {
  getSalesReports({
    professional_id,
    page = 1,
    name,
    initial_date,
    final_date,
  }: IGetSalesReportsProps): AxiosPromise<IPagination<ISalesReport[]>> {
    return api.get(
      `professionals/${professional_id}/reports/sales${createQuery(
        name,
        initial_date,
        final_date,
      )}`,
      {
        params: {
          page,
          perpage: 15,
        },
      },
    );
  },
};
